import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "../wrappers/fetchWrapper";

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/application/school`;

const initialState = {
    action: "",
    applications: [],
    loading: false,
    error: null,
    message: null,
    status: "",
};

const name = "school_application";

const schoolApplicationSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        // Optionally add some synchronous reducers if needed
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        clearMessage: (state) => {
            state.message = null;
        },
        clearSchoolApplicationState: (state) => {
            state.action = "";
            state.applications = [];
            state.loading = false;
            state.error = null;
            state.message = null;
            state.status = "";
        },
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        updateSchoolApplicationStatus: (state, action) => {
            const { id, status } = action.payload;
            const applicationIndex = state.applications.findIndex(
                (application) => application.id === id
            );
            if (applicationIndex !== -1) {
                state.applications[applicationIndex].status = status;
            }
        },
        changeSchoolApplicationState: (state, action) => {
            const { action: actionType, data } = action.payload;

            if (actionType === 'create') {
                // Add new application to the list
                state.applications = [...state.applications, data];
            } else if (actionType === 'update') {
                // Update the existing application
                const index = state.applications.findIndex(application => application.id === data.id);
                if (index !== -1) {
                    state.applications[index] = data;
                }
            } else if (actionType === 'delete') {
                // Delete the application by id
                state.applications = state.applications.filter(application => application.id !== data.id);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addSchoolApplication.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(addSchoolApplication.fulfilled, (state, action) => {
                state.action = "addSchoolApplication";
                state.loading = false;
                state.message = "School application Added Successfully";
                state.applications.push(action.payload);
            })
            .addCase(addSchoolApplication.rejected, (state, action) => {
                state.action = "addSchoolApplication";
                state.loading = false;
                if (Array.isArray(action.payload)) {
                    state.error = action.payload;
                } else if (typeof action.payload === 'object') {
                    state.error = ['Unknown Error!'];
                } else {
                    state.error = action.payload;
                }
            })
            .addCase(fetchSchoolApplications.pending, (state) => {
                state.status = 'loading';
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(fetchSchoolApplications.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.loading = false;
                state.applications = action.payload;
            })
            .addCase(fetchSchoolApplications.rejected, (state, action) => {
                state.status = 'failed';
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteSchoolApplication.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(deleteSchoolApplication.fulfilled, (state, action) => {
                state.loading = false;
                state.message = "SchoolApplication Deleted Successfully";
                state.applications = state.applications.filter(
                    (application) => application.id !== action.payload.id
                );
            })
            .addCase(deleteSchoolApplication.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateSchoolApplication.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(updateSchoolApplication.fulfilled, (state, action) => {
                state.loading = false;
                state.message = "Application Updated Successfully";
                const index = state.applications.findIndex(
                    (application) => application.id === action.payload.id
                );
                if (index !== -1) {
                    state.applications[index] = action.payload;
                }
            })
            .addCase(updateSchoolApplication.rejected, (state, action) => {
                state.loading = false;
                if (Array.isArray(action.payload)) {
                    state.error = action.payload;
                } else if (typeof action.payload === "object") {
                    state.error = ["Unknown Error!"];
                } else {
                    state.error = action.payload;
                }
            });
    },
});

export const {
    setLoading,
    changeSchoolApplicationState,
    clearMessage,
    clearSchoolApplicationState,
    setStatus,
    updateSchoolApplicationStatus,
} = schoolApplicationSlice.actions;
export const schoolApplicationReducer = schoolApplicationSlice.reducer;

export const addSchoolApplication = createAsyncThunk(
    `${name}/addSchoolApplication`,
    async (application, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.post(
                `${baseUrl}/create`,
                application
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const fetchSchoolApplications = createAsyncThunk(
    `${name}/fetchSchoolApplication`,
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.get(
                `${baseUrl}/fetch?timestamp=${new Date().getTime()}`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateSchoolApplication = createAsyncThunk(
    `${name}/updateSchoolApplication`,
    async ({ id, application }, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.put(
                `${baseUrl}/update/${id}/`,
                application
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteSchoolApplication = createAsyncThunk(
    `${name}/deleteSchoolApplication`,
    async ({ id, uuid }, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.delete(
                `${baseUrl}/delete/${id}`, { uuid }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
