import * as React from "react";

import { addService, setLoading } from "../../store/service.slice";

import AddIcon from "@mui/icons-material/Add";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";

function AddServiceDialog(props) {
	const dispatch = useDispatch();

	const [form, setForm] = React.useState({ services: [""] });
	const [formErrors, setFormErrors] = React.useState({});
	const [isFormValid, setIsFormValid] = React.useState(false);
	const [disableAdd, setDisableAdd] = React.useState(true);

	const handleSubmit = async (event) => {
		event.preventDefault();

		const errors = validateForm();
		const isValid = !Object.values(errors).some((error) => error);

		setFormErrors(errors);
		setIsFormValid(isValid);

		if (isValid) {
			dispatch(setLoading(true));
			dispatch(addService(form.services)).then((response) => {
				if (response.type === "service/addService/fulfilled") {
					handleCloseDialog();
				}
			});
		}
	};

	const validateForm = () => {
		let errors = {};
		// Validate each service input
		form.services.forEach((service, index) => {
			if (!service.trim()) {
				errors[`service${index}`] = "Service is required";
			} else {
				errors[`service${index}`] = "";
			}
		});
		return errors;
	};

	const handleChange = (index, value) => {
		const updatedServices = [...form.services];
		updatedServices[index] = value;
		setForm({ ...form, services: updatedServices });

		let errors = { ...formErrors };
		errors[`service${index}`] = value.trim() ? "" : "Service is required";
		setFormErrors(errors);

		const isValid = Object.values(errors).every((error) => !error);
		setIsFormValid(isValid);
		setDisableAdd(!isValid || form.services.length > 15);
	};

	const handleAddInput = () => {
		setForm({ ...form, services: [...form.services, ""] });
		setDisableAdd(true);
	};

	const handleDeleteInput = (index) => {
		const updatedServices = [...form.services];
		updatedServices.splice(index, 1);
		setForm({ ...form, services: updatedServices });

		let errors = { ...formErrors };
		delete errors[`service${index}`];
		setFormErrors(errors);

		const isValid = Object.values(errors).every((error) => !error);
		setIsFormValid(isValid);
		setDisableAdd(false);
	};

	const handleCloseDialog = () => {
		props.setOpenDialog(false);
		setForm({ services: [""] });
		setFormErrors({});
		setIsFormValid(false);
	};

	return (
		<Dialog
			open={props.open}
			onClose={handleCloseDialog}
			aria-labelledby="form-dialog-title"
			maxWidth="md"
			fullWidth
		>
			<DialogTitle id="form-dialog-title">Add Service</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Please fill out the details below to add new services.
				</DialogContentText>
				<Box
					component="form"
					noValidate
					onSubmit={handleSubmit}
					sx={{ mt: 3 }}
				>
					{props.serviceState.error && (
						<Alert
							sx={{ width: "100%", mt: 2, mb: 2 }}
							severity="error"
						>
							{props.serviceState.error}
						</Alert>
					)}
					<Grid container spacing={2}>
						{form.services.map((service, index) => (
							<div
								style={{
									width: "100%",
									display: "flex",
									alignItems: "center",
									padding: "16px",
								}}
								key={index}
							>
								<Grid item xs={8} sm={8}>
									<TextField
										name={`service${index}`}
										required
										fullWidth
										id={`service${index}`}
										label={`Service ${index + 1}`}
										value={service}
										onChange={(e) =>
											handleChange(index, e.target.value)
										}
										error={Boolean(
											formErrors[`service${index}`]
										)}
										helperText={
											formErrors[`service${index}`]
										}
									/>
								</Grid>
								{form.services.length > 1 && (
									<Grid item xs={4} sm={4}>
										<IconButton
											aria-label="delete"
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												height: "100%",
											}}
											onClick={() =>
												handleDeleteInput(index)
											}
										>
											<DeleteIcon fontSize="inherit" />
										</IconButton>
									</Grid>
								)}
							</div>
						))}

						<Grid item xs={12}>
							<Button
								variant="contained"
								color="success"
								onClick={handleAddInput}
								startIcon={<AddIcon />}
								disabled={disableAdd}
							>
								Add
							</Button>
						</Grid>
					</Grid>
					<DialogActions sx={{ justifyContent: "flex-start", px: 0 }}>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							sx={{ mt: 3, mb: 2 }}
							disabled={!isFormValid}
						>
							Save
						</Button>
						<Button
							type="button"
							variant="contained"
							color="error"
							sx={{ mt: 3, mb: 2 }}
							onClick={handleCloseDialog}
						>
							Cancel
						</Button>
					</DialogActions>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

AddServiceDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpenDialog: PropTypes.func.isRequired,
	serviceState: PropTypes.object.isRequired,
};

export default AddServiceDialog;
