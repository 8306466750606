import * as React from "react";
import { useSelector } from 'react-redux';

import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import {Profile} from "../../utils/Profile"
import Loading from "./Loading";

export function PrivatePage(props) {

    const authUserState = useSelector(
        x => x.auth
    );
    const [allowed, setAllowed] = React.useState("LOADING");


    React.useEffect(() =>{
        
        const authUserProfile = new Profile(authUserState);
        if (props === undefined || 
            props.allowedGroups === undefined ||
            (!Array.isArray(props.allowedGroups)) ||
            props.allowedGroups[0] === "" 
        ){
            console.log("exit.. not allowed")
            setAllowed("NOT_ALLOWED")
        }
        else if (! authUserProfile.hasGroups(props.allowedGroups)){
            console.log("exit.. not allowed")
            setAllowed("NOT_ALLOWED")
        }else {
            setAllowed("ALLOWED")
        }
    }, [authUserState, props, props.allowedGroups] )

    if (allowed === "LOADING") {
        return (
			<React.Fragment>
					<Grid item md={12}>
						<Loading />
					</Grid>
			</React.Fragment>
		);
    }
    else if (allowed === "ALLOWED"){
        return (props.children)
    } else {
        return(
        <React.Fragment>
					<Alert
                        sx={{
                            width: "100%",
                            mt: 2,
                            mb: 2,
                        }}
                        severity="error"
                    >
                       {"Opps.. you can't access this page"} 
                    </Alert>
			</React.Fragment>
        )
    }
}