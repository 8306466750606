/**
 * Parse the authUserState in 
 * @argument authUserState the authUserState 
 */
export class Profile{

    constructor(authUserState){
        this._authUserState = authUserState
        
        let employee = {
            id: "",
            phone_number: "",
            user: {
                first_name: "",
                last_name: "",
                groups: []
            }
        }
        if (this._authUserState.employee  && this._authUserState.employee.profile){
            employee = this._authUserState.employee.profile
        }
        this.id = employee.id
        this.firstName = employee.user.first_name
        this.lastName = employee.user.last_name
        this.phoneNumber=  employee.phone_number
        this.groups= employee.user.groups.map(group => group.name)
    }

    /**
     * Get the Profile in json form
     * @returns 
     */
    getProfile(){
        return {
            id: this.id,
            firstName: this.firstName,
            last_name: this.lastName,
            phone_number: this.phoneNumber,
            groups: this.groups
        }
    }
    /**
     * Return True if the user belong to the given groups
     */
    hasGroups(groups){
        return this.groups.flatMap( group => groups.includes(group)).reduce((a,b) => a || b)
    }
}