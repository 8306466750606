import {
    Alert,
    Paper,
    Snackbar,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { addDraftLead, clearError, clearMessage, updateDraftLead } from '../../store/draftLead.slice';
import { fetchCitiesForCountries, setCities } from '../../store/city.slice';
import { useDispatch, useSelector } from 'react-redux';

import AddDraftLeadForm from './AddDraftLeadForm';
import AddDraftLeadSkeletonForm from './AddDraftLeadSkeletonForm';
import _ from "lodash";
import { parsePhoneNumber } from 'libphonenumber-js';
import { useEdvantageContext } from '../../EdvantageProvider';

const AddDraftLead = (props) => {
    const dispatch = useDispatch();
    const draftLeadState = useSelector((state) => state.draftLead);

    const { artifactLoading, countries, uuid } = useEdvantageContext();

    const initialState = {
        first_name: '',
        last_name: '',
        email: '',
        calling_code: '',
        phone_number: '',
        nationality: '',
        gender: '',
        dob: '',
        source: '',
        grade_system: '',
        gpa: '',
        english_test_status: '',
        test_date: '',
        toefl_score: '',
        ielts_score: '',
        consultation_type: '',
        intakes: [],
        preferred_programs: [],
        preferred_majors: [],
        preferred_countries: [],
        preferred_cities: [],
        additional_services: [],
        has_sponsorship: false,
        sponsorship_details: '',
        notes: '',
    };

    const mergedState = _.merge({}, initialState, _.pick(props.draftLead, Object.keys(initialState)));
    const [formData, setFormData] = useState(mergedState);
    const [errors, setErrors] = useState({});

    // Update formData whenever props.draftLead changes
    useEffect(() => {
        const updatedState = _.merge({}, initialState, _.pick(props.draftLead, Object.keys(initialState)));
        setFormData(updatedState);
    }, [props.draftLead]);

    const validateField = (name, value) => {
        let errorMsg = "";

        switch (name) {
            case "first_name":
            case "last_name":
                if (!value.trim()) {
                    errorMsg = `${name === "first_name" ? "First name" : "Last name"} is required.`;
                } else if (!/^[a-zA-Z\s]+$/.test(value)) {
                    errorMsg = `${name === "first_name" ? "First name" : "Last name"} should only contain letters.`;
                }
                break;

            case "calling_code":
                if (!value) {
                    errorMsg = "Calling code is required.";
                } else if (formData.phone_number) {
                    const country = countries.find((country) => country.calling_code === value);
                    if (country) {
                        try {
                            const phoneNumber = parsePhoneNumber(formData.phone_number, country.code2);
                            if (!phoneNumber || !phoneNumber.isValid()) {
                                errorMsg = "Phone number is invalid";
                            }
                        } catch (error) {
                            if (error.name === "ParseError" && error.message === "TOO_SHORT") {
                                errorMsg = "Phone number is too short";
                            } else {
                                errorMsg = "Invalid phone number format";
                            }
                        }
                    }
                }
                break;

            case "phone_number":
                if (!value) {
                    errorMsg = "Phone number is required.";
                } else if (formData.calling_code) {
                    const country = countries.find((country) => country.calling_code === formData.calling_code);
                    if (country) {
                        try {
                            // Check if phone number length is too short before parsing
                            if (value.length < 4) { // Adjust length threshold as appropriate
                                errorMsg = "Phone number is too short.";
                            } else {
                                const phoneNumber = parsePhoneNumber(value, country.code2);
                                if (!phoneNumber || !phoneNumber.isValid()) {
                                    errorMsg = "Phone number is invalid.";
                                }
                            }
                        } catch (error) {
                            if (error.name === "ParseError" && error.message === "TOO_SHORT") {
                                errorMsg = "Phone number is too short.";
                            } else {
                                errorMsg = "Invalid phone number format.";
                            }
                        }
                    }
                }
                break;

            default:
                break;
        }

        return errorMsg; // Return error message directly
    };

    const [showScores, setShowScores] = useState(false);
    const [showTestDate, setShowTestDate] = useState(false);

    const cityState = useSelector(state => state.city);
    const cities = cityState.cities;

    // Sample options

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let updatedFormData = { ...formData, [name]: value };
        if (name === 'preferred_countries') {
            // Determine which countries were removed
            const removedCountryIds = formData.preferred_countries.filter(countryId => !value.includes(countryId));

            if (removedCountryIds.length) {
                // Get the country objects for the removed IDs
                const removedCountries = countries.filter(country => removedCountryIds.includes(country.id));

                // Remove cities associated with removed countries
                const filteredCities = formData.preferred_cities.filter(city => {
                    // Check if the city belongs to any removed country
                    return !removedCountries.some(removedCountry => removedCountry.code2 === city.countryCode);
                });

                // Update preferred cities in formData
                updatedFormData.preferred_cities = filteredCities;

                // Optionally dispatch action to update cities in your state
                const newCities = cities.filter(city => !removedCountries.some(removedCountry => removedCountry.code2 === city.countryCode));
                dispatch(setCities(newCities));
            } else {
                // If no countries are removed, fetch cities for the selected countries
                const selectedCountries = value.map(countryId => {
                    return countries.find(c => c.id === countryId) || null; // Return the country object or null if not found
                }).filter(country => country !== null);
                dispatch(fetchCitiesForCountries(selectedCountries));
            }
        }

        setFormData(updatedFormData);
        const errorMessage = validateField(name, value);
        let updatedErrors = { ...errors };
        if (errorMessage) {
            if (name === "calling_code" && errorMessage !== "Calling code is required.") {
                delete updatedErrors.calling_code;
                updatedErrors["phone_number"] = errorMessage;
            } else {
                updatedErrors[name] = errorMessage;
            }
        } else {
            delete updatedErrors[name];
            if (name === "calling_code") {
                delete updatedErrors.phone_number;
            }
        }

        setErrors(updatedErrors);
    };

    const handleSwitchChange = (e) => {
        const { name, checked } = e.target;
        setFormData({ ...formData, [name]: checked });
    };

    const validateSubmit = () => {
        const fieldsToValidate = ['first_name', 'last_name', 'calling_code', 'phone_number'];

        // Use reduce to accumulate errors
        const accumulatedErrors = fieldsToValidate.reduce((acc, field) => {
            const errorMsg = validateField(field, formData[field]);
            if (errorMsg) {
                if (field !== "calling_code" || errorMsg === "Calling code is required.") {
                    // For calling_code, only add error message if it's "Calling code is required."
                    acc[field] = errorMsg;
                }
            }
            return acc;
        }, {});

        // Set accumulated errors in state
        setErrors(accumulatedErrors);

        // Return whether form is valid (i.e., no errors)
        return Object.keys(accumulatedErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(clearError());

        const isValid = validateSubmit();
        if (!isValid) {
            return;
        }

        const { first_name, last_name, email, calling_code, phone_number, dob, gender, nationality, ...restFormData } = formData;

        const payload = {
            ...restFormData, // Only remaining fields from formData
            student: {
                first_name,
                last_name,
                email,
                calling_code,
                phone_number,
                dob,
                gender,
                nationality,
            },
            uuid,
        };

        if (props.isEdit) {
            dispatch(updateDraftLead({ id: props.draftLead.id, draftLead: payload })).then((response) => {
                if (response.type === "draftLead/updateDraftLead/fulfilled") {
                    // Clear form data
                    props.handleBack();
                }
            });
        } else {
            dispatch(addDraftLead(payload)).then((response) => {
                if (response.type === "draftLead/addDraftLead/fulfilled") {
                    // Clear form data
                    setFormData(initialState);
                }
            });
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        dispatch(clearMessage());
    };

    useEffect(() => {
        setShowScores(formData.english_test_status === "Ready");
        setShowTestDate(
            ["Ready", "Scheduled"].includes(formData.english_test_status)
        );
    }, [formData.english_test_status]);

    return (
        <Paper elevation={3} sx={{ padding: 4, maxWidth: 800, margin: '0 auto' }}>
            {draftLeadState.message && (
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={true}
                    autoHideDuration={5000}
                    onClose={handleCloseSnackbar}
                >
                    <Alert sx={{ width: "100%", mb: 2 }} severity="success">
                        {draftLeadState.message}
                    </Alert>
                </Snackbar>
            )}
            {artifactLoading ? (
                <AddDraftLeadSkeletonForm />
            ) : (
                <AddDraftLeadForm
                    cities={cities}
                    cityState={cityState}
                    draftLeadState={draftLeadState}
                    formData={formData}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleSwitchChange={handleSwitchChange}
                    handleSubmit={handleSubmit}
                    showScores={showScores}
                    showTestDate={showTestDate}
                    isEdit={props.isEdit}
                />
            )}
        </Paper>
    );
};

export default AddDraftLead;
