import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDropzone } from 'react-dropzone';

const FileUpload = ({ accept, onChange }) => {
    const [file, setFile] = useState(null);

    const acceptFormats = accept.reduce((acc, mimeType) => {
        acc[mimeType] = mimeType.split('/').pop() === 'pdf' ? ['.pdf'] : ['.png', '.jpg', '.jpeg']; // Adjust according to your needs
        return acc;
    }, {});

    const onDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const selectedFile = acceptedFiles[0];
            setFile(selectedFile);
            onChange(selectedFile);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: acceptFormats,
        onDrop,
    });

    return (
        <Box
            {...getRootProps()}
            sx={{
                border: '2px dashed #ccc',
                padding: 3,
                textAlign: 'center',
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: '#f9f9f9',
                },
            }}
        >
            <input {...getInputProps()} style={{ display: 'none' }} />
            <CloudUploadIcon color='primary' fontSize="large" sx={{ marginBottom: 1 }} />
            {isDragActive ? (
                <Typography>Drop the files here...</Typography>
            ) : (
                <Typography>Drag &apos;n&apos; drop a file here, or click to select a file</Typography>
            )}
            {file && (
                <Typography variant="body2" sx={{ marginTop: 2 }}>
                    {file.name}
                </Typography>
            )}
        </Box>
    );
};

export default FileUpload;
