import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../wrappers/fetchWrapper';

const baseUrl = `${process.env.REACT_APP_COUNTRIESNOW_API_URL}`;

const initialState = {
    cities: [],
    country2cities: {},
    loading: false,
    error: null,
};

const name = "city";

// Action to update cities for a specific country
export const updateCitiesForCountry = (country, cities) => ({
    type: `${name}/updateCitiesForCountry`,
    payload: { country, cities },
});

const citySlice = createSlice({
    name: name,
    initialState,
    reducers: {
        clearCityState: (state) => {
            state.cities = [];
            state.country2cities = {};
            state.loading = false;
            state.error = null;
        },
        setCities: (state, action) => {
            state.cities = action.payload;
        },
        updateCitiesForCountry: (state, action) => {
            const { country, cities } = action.payload;
            state.country2cities[country] = cities; // Update the country2cities map
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCitiesForCountries.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCitiesForCountries.fulfilled, (state, action) => {
                state.cities = action.payload; // payload is the array of cities
                state.loading = false;
            })
            .addCase(fetchCitiesForCountries.rejected, (state, action) => {
                state.error = action.payload; // Use the error message from the rejected action
                state.loading = false;
            });
    }
});

// Action exports
export const { clearCityState, setCities } = citySlice.actions;

// Reducer export
export const cityReducer = citySlice.reducer;

// Async thunk for fetching cities based on selected countries
export const fetchCitiesForCountries = createAsyncThunk(
    `${name}/fetchCitiesForCountries`,
    async (countries, { getState, dispatch, rejectWithValue }) => {
        const state = getState();
        const country2cities = state.city.country2cities; // Get the existing cities from the state

        const promises = countries.map(async (country) => {
            try {
                // Check if cities have already been fetched for the current country
                if (country2cities[country.code2]) {
                    return country2cities[country.code2]; // Return existing cities
                }

                // Fetch cities if not already fetched
                const response = await fetchWrapper.post(`${baseUrl}/countries/cities`, { country: country.name }, { credentials: false });
                const cities = response.data.map(city => ({
                    name: city,
                    country: country.name,
                    countryCode: country.code2,
                }));

                dispatch(updateCitiesForCountry(country.code2, cities));
                return cities;
            } catch (error) {
                console.error(`Failed to fetch cities for country ${country.name}:`, error);
                return []; // Return an empty array or handle as needed
            }
        });

        try {
            const citiesArray = await Promise.all(promises);
            const uniqueCities = Array.from(new Set(citiesArray.flat())); // Flatten and remove duplicates
            return uniqueCities;
        } catch (error) {
            return rejectWithValue(error.message || 'Failed to fetch cities');
        }
    }
);
