import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Navigate } from 'react-router-dom';
import { history } from '../helper/history';

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {
	reloadProfile,
} from "../store/auth.slice";


function PrivateRoute({ children }) {

    const dispatch = useDispatch();
    const { employee: authUser } = useSelector(state => state.auth);
    const [status, setStatus] = React.useState("STARTED");


    React.useEffect(() => {
        if (!authUser && status=="STARTED" ) {
            // get me
            dispatch(reloadProfile()).then(
                (response) => {
                    if (response.type === "auth/reloadProfile/fulfilled") {
                        setStatus("COMPLETED")
                    }
                    if (response.type === "auth/reloadProfile/rejected") {
                        setStatus("COMPLETED")
                    }
                }
            )
        } else if(authUser && status=="STARTED" ){
            setStatus("COMPLETED")
        }
    }, [status, dispatch, authUser])

    // authorized so return child components
    if(!authUser && status == "COMPLETED") {
        console.log("Please log-in first.. redirecting")
        return (<Navigate to="/login" state={{ from: { pathname: history.location.pathname }, message: history.location.state?.message }} />)

    }
    else if(authUser && status == "COMPLETED"){
        return children
    } else{
        //console.log("Loading")
        return (
            <Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1000,
				}}
				open={true}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
        )
    }
}

export default PrivateRoute;
