import React from "react";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EditIcon from "@mui/icons-material/Edit";
import {
    Box,
    Button,
    Card,
    CardContent,
    Link,
    Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { makeSelectSchoolById } from "../../selectors/schoolSelectors";
import PageNotFound from "../common/PageNotFound";
import CoursesSection from "./CoursesSection";
import EditSchoolDialog from "./EditSchoolDialog";
import SchoolSlider from "./SchoolSlider";

/**
 * A component that displays the details of a school.
 *
 * This component is used by the School component to display the details of a school.
 * It shows the school's name, address, brochure URL, QS World Ranking, and Google Maps URL.
 * It also includes a button to edit the school's details and a slider to display the school's photos.
 *
 * @param {Number} schoolId The ID of the school to display.
 * @param {Function} setUrlParams A function to set the URL parameters.
 * @param {Function} onBack A function to go back to the previous page.
 */
export default function SchoolDetails({ programs, currencies, schoolId, setUrlParams, onBack }) {
    const selectSchool = React.useMemo(
        () => makeSelectSchoolById(schoolId),
        [schoolId]
    );
    const school = useSelector(selectSchool);

    const [openEditSchoolDialog, setOpenEditSchoolDialog] =
        React.useState(false);

    const handleEditSchool = () => {
        setOpenEditSchoolDialog(true);
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Button
                variant="contained"
                onClick={onBack}
                startIcon={<ArrowBackIosIcon />}
                sx={{ mt: 2, mb: 2 }}
            >
                Go Back
            </Button>
            {school ? (
                <React.Fragment>
                    {school.photos.length > 0 ? (
                        <SchoolSlider photos={school.photos} />
                    ) : (<Box
                        component="img"
                        src={`/static/images/school.png`}
                        alt={`${school.name} Banner`}
                        sx={{
                            width: "100%",
                            height: "auto",
                            maxHeight: 300,
                            objectFit: "cover",
                            borderRadius: 2,
                            boxShadow: 1,
                            mb: 2,
                        }}
                    />)}
                    <Card sx={{ maxWidth: "100%", mb: 2, position: "relative" }}>
                        <Button
                            variant="contained"
                            startIcon={<EditIcon />}
                            color="primary"
                            sx={{ position: "absolute", top: 8, right: 8 }}
                            onClick={handleEditSchool}
                        >
                            Edit
                        </Button>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                {school.name}
                            </Typography>
                            <Typography variant="body1" sx={{ mt: 1 }}>
                                <strong>Address: </strong>
                                {school.address.address_line_1},{" "}
                                {school.address.address_line_2},{" "}
                                {school.address.city} -{" "}
                                {school.address.postal_code},{" "}
                                {school.address.country}
                            </Typography>
                            <Typography variant="body1" sx={{ mt: 1 }}>
                                <strong>Google Maps URL:</strong>{" "}
                                <Link
                                    href={school.google_maps_url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {school.google_maps_url}
                                </Link>
                            </Typography>
                            <Typography variant="body1" sx={{ mt: 1 }}>
                                <strong>Brochure:</strong>{" "}
                                <Link
                                    href={school.brochure_url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {school.brochure_url}
                                </Link>
                            </Typography>
                            <Typography variant="body1" sx={{ mt: 1 }}>
                                <strong>World Rank:</strong>{" "}
                                {school.qs_world_ranking}
                            </Typography>
                        </CardContent>
                    </Card>
                    <CoursesSection
                        programs={programs}
                        currencies={currencies}
                        schoolId={schoolId}
                        courses={school.courses}
                    />
                    <EditSchoolDialog
                        open={openEditSchoolDialog}
                        handleClose={setOpenEditSchoolDialog}
                        school={school}
                    />
                </React.Fragment>
            ) : (
                <PageNotFound message="School not found!" setUrlParams={setUrlParams} />
            )}
        </Box>
    );
}
