import "./App.css";

import {
	Navigate,
	Route,
	Routes,
	useLocation,
	useNavigate,
} from "react-router-dom";

import AccountSettings from "./components/Settings/AccountSettings";
import ActivateUser from "./components/ActivateUser"
import Dashboard from "./components/Dashboard";
import Home from "./components/Home";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import React from 'react'
import SignUp from "./components/SignUp";
import { history } from "./helper/history";
import { modelStateChangeMap } from "./const";
import { updateStudentInLead } from "./store/lead.slice";
import { useDispatch } from "react-redux";
import { useEdvantageContext } from "./EdvantageProvider";
import useWebSocket from "./hooks/useWebSocket";

function App() {
	// init custom history object to allow navigation from
	// anywhere in the react app (inside or outside components)
	history.navigate = useNavigate();
	history.location = useLocation();

	const dispatch = useDispatch();
	const { authUser, uuid: clientUuid } = useEdvantageContext();

	const { sendMessage } = useWebSocket(`${process.env.REACT_APP_WS_URL}/state/updates/`, (payload) => {
		// Handle received data from WebSocket here
		const { model, uuid, ...rest } = payload;

		if (uuid !== clientUuid) {
			// Dispatch the action dynamically
			const changeStateFunction = modelStateChangeMap[model];
			if (changeStateFunction) {
				dispatch(changeStateFunction(rest)); // Pass the rest of the payload (e.g., { action, data })
				if (model === "student" && rest.action === "update") {
					dispatch(updateStudentInLead(rest.data));
				}
			} else {
				console.error(`No change state function found for model: ${model}`);
			}
		}
	});

	// Use useRef to ensure that the register message is sent only once
	const hasSentRegisterMessage = React.useRef(false);

	React.useEffect(() => {
		if (authUser && !hasSentRegisterMessage.current) {
			const response = sendMessage({ type: 'register', userId: authUser.profile.id });
			if (response.status === 'success') {
				hasSentRegisterMessage.current = true;
			}
		}
	}, [authUser]);

	return (
		<>
			<div>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/register" element={<SignUp />} />
					<Route path="/login" element={<Login />} />
					<Route
						path="/dashboard"
						element={
							<PrivateRoute>
								<Dashboard />
							</PrivateRoute>
						}
					/>
					<Route
						path="/settings"
						element={
							<PrivateRoute>
								<AccountSettings />
							</PrivateRoute>
						}
					/>
					<Route path="/activate" element={<ActivateUser />} />
					<Route path="*" element={<Navigate to="/" />} />
				</Routes>
			</div>
		</>
	);
}

export default App;
