import * as React from 'react';

import { Box, IconButton, Typography } from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ProgramCell from './ProgramCell';
import { deleteProgram } from '../../store/program.slice';
import { useDispatch } from 'react-redux';

const columns = (handleDeleteClick) => [
    {
        field: 'id',
        headerName: 'ID',
        flex: 1,
    },
    {
        field: 'name',
        headerName: 'Program',
        description: 'The name of program.',
        sortable: true,
        flex: 2,
        renderCell: (params) => (
            <ProgramCell params={params} />
        ),
    },
    {
        field: 'category',
        headerName: 'Category',
        description: 'Category of program.',
        sortable: true,
        flex: 1,
    },
    {
        field: 'added_by',
        headerName: 'Added By',
        flex: 1,
    },
    {
        field: 'updated_by',
        headerName: 'Updated By',
        width: 200,
        flex: 1,
    },
    {
        field: 'updated_at',
        headerName: 'Last Update Time',
        flex: 2,
        renderCell: (params) => {
            const formattedDate = new Date(params.value).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            });
            return <Typography sx={{ display: 'flex', height: '100%', justifyContent: 'flex-start', alignItems: 'center' }} >{formattedDate}</Typography>;
        },
    },
    {
        field: 'actions',
        headerName: '',
        flex: 1,
        renderCell: (params) => (
            <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
                <IconButton aria-label="edit">
                    <EditIcon />
                </IconButton>
                <IconButton aria-label="delete" onClick={() => handleDeleteClick(params.id, params.row.name)}>
                    <DeleteIcon />
                </IconButton>
            </div>
        ),
    },
];

export default function ProgramTable(props) {

    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState(null);
    const [deleteProgramName, setDeleteProgramName] = React.useState('');

    const contentText = (
        <>
            Are you sure you want to delete the program{" "}
            <Typography component="span" sx={{ fontWeight: 'bold' }}>
                {deleteProgramName}
            </Typography>
            ?
        </>
    );

    const handleDeleteClick = (id, name) => {
        setDeleteId(id);
        setDeleteProgramName(name);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setDeleteId(null);
        setDeleteProgramName('');
    };

    const handleConfirmDelete = () => {
        // Logic to delete the program
        dispatch(deleteProgram({ id: deleteId, uuid: props.uuid }))
            .then((response) => {
                if (response.type === 'program/deleteProgram/fulfilled') {
                    handleClose();
                }
            });
    };

    return (
        <Box sx={{ width: '100%' }}>
            <DataGrid
                rows={props.programs}
                columns={columns(handleDeleteClick)}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            // Hide columns id, the other columns will remain visible
                            id: false,
                        },
                    },
                    sorting: {
                        sortModel: [
                            {
                                field: "updated_at", // Sort by updated_at field
                                sort: "desc", // Sort in descending order
                            },
                        ],
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection={false}
                disableRowSelectionOnClick
            />
            <DeleteConfirmationDialog
                open={open}
                handleClose={handleClose}
                onConfirm={handleConfirmDelete}
                contentText={contentText}
                state={props.programState}
            />
        </Box>
    );
}
