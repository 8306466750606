import * as React from 'react';

import { Box, Button, Chip, Typography } from '@mui/material';

import { DRAFT_LEAD_STATUS } from '../../const';
import { DataGrid } from '@mui/x-data-grid';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import DraftActionsPopover from './DraftActionPopover';
import DraftLeadTableToolbar from './DraftLeadTableToolbar';
import FilterDraftLeadCollapse from './FilterDraftLeadCollapse';
import { FilterList } from "@mui/icons-material";
import { deleteDraftLead } from '../../store/draftLead.slice';
import { useDispatch } from 'react-redux';

export default function DraftLeadTable(props) {

    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState(null);
    const [deleteDraftLeadName, setDeleteDraftLeadName] = React.useState('');
    const [filterBoxVisible, setFilterBoxVisible] = React.useState(false);

    const columns = React.useMemo(() => [
        {
            field: 'id',
            headerName: 'ID',
            flex: 1,
        },
        {
            field: 'actions',
            headerName: '',
            flex: 1,
            renderCell: (params) => (
                <DraftActionsPopover
                    handleConvert={() => props.handleConvert(params.row)}
                    handleEdit={() => props.handleEdit(params.row)}
                    handleDelete={() => handleDeleteClick(params.row)}
                    rowId={params.id}
                    rowName={params.row.name}
                    status={params.row.status}
                />
            ),
        },
        {
            field: 'name',
            headerName: 'Student',
            description: 'Student full name.',
            sortable: true,
            flex: 1.5,
            renderCell: (params) => (
                <Typography
                    sx={{
                        display: 'flex',
                        height: '100%',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        fontFamily: 'Roboto, sans-serif', // Use a professional font
                        fontWeight: 500,
                        fontSize: '0.9rem',
                    }}
                >
                    {`${params.row.student.first_name} ${params.row.student.last_name}`}
                </Typography>
            ),
        },
        {
            field: 'email',
            headerName: 'Email',
            description: 'Email of student.',
            sortable: true,
            flex: 1.5,
            renderCell: (params) => (
                <Typography sx={{ display: 'flex', height: '100%', justifyContent: 'flex-start', alignItems: 'center' }} >{params.row.student.email}</Typography>
            ),
        },
        {
            field: 'phone',
            headerName: 'Phone',
            description: 'Phone number of student.',
            sortable: true,
            flex: 1,
            renderCell: (params) => (
                <Typography sx={{ display: 'flex', height: '100%', justifyContent: 'flex-start', alignItems: 'center' }} >{params.row.student.phone_number}</Typography>
            ),
        },
        {
            field: 'status',
            headerName: 'Status',
            description: 'Current status of the draft.',
            sortable: true,
            flex: 1,
            renderCell: (params) => {
                // Determine color for the chip based on status value
                return (
                    <Chip
                        label={params.value}
                        variant="outlined"
                        size='small'
                        sx={{
                            fontFamily: 'Roboto, sans-serif', // Use the same professional font
                            fontWeight: 500,
                            fontSize: '0.8rem',
                            color: DRAFT_LEAD_STATUS[params.value].color,
                            borderColor: DRAFT_LEAD_STATUS[params.value].color,
                        }}
                    />
                );
            },
        },
        {
            field: 'added_by',
            headerName: 'Added By',
            flex: 1,
            valueGetter: (value) => props.employeeById.get(value).full_name,
        },
        {
            field: 'updated_by',
            headerName: 'Updated By',
            width: 200,
            flex: 1,
            valueGetter: (value) => props.employeeById.get(value).full_name,
        },
        {
            field: 'updated_at',
            headerName: 'Last Update Time',
            flex: 2,
            renderCell: (params) => {
                const formattedDate = new Date(params.value).toLocaleString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                });
                return <Typography sx={{ display: 'flex', height: '100%', justifyContent: 'flex-start', alignItems: 'center' }} >{formattedDate}</Typography>;
            },
        },
    ], [props.handleConvert, props.handleEdit]);

    const contentText = (
        <>
            Are you sure you want to delete the draft lead {" "}
            <Typography component="span" sx={{ fontWeight: 'bold' }}>
                {deleteDraftLeadName}
            </Typography>
            ?
        </>
    );

    const toggleFilterBox = () => {
        setFilterBoxVisible((prev) => !prev);
    };

    const handleDeleteClick = (draftLead) => {
        setDeleteId(draftLead.id);
        setDeleteDraftLeadName(`${draftLead.student.first_name} ${draftLead.student.last_name}`);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setDeleteId(null);
        setDeleteDraftLeadName('');
    };

    const handleConfirmDelete = () => {
        // Logic to delete the program
        dispatch(deleteDraftLead({ id: deleteId, uuid: props.uuid }))
            .then((response) => {
                if (response.type === 'draftLead/deleteDraftLead/fulfilled') {
                    handleClose();
                }
            });
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}
            >
                <Button
                    startIcon={<FilterList />}
                    onClick={toggleFilterBox}
                >
                    Filters
                </Button>
            </Box>
            <FilterDraftLeadCollapse
                searchFilters={{}}
                handleSearchChange={() => { }}
                filterBoxVisible={filterBoxVisible}
            />
            <DataGrid
                rows={props.draftLeads}
                columns={columns}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            // Hide columns id, the other columns will remain visible
                            id: false,
                        },
                    },
                    sorting: {
                        sortModel: [
                            {
                                field: "updated_at", // Sort by updated_at field
                                sort: "desc", // Sort in descending order
                            },
                        ],
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                slots={{
                    noRowsOverlay: () => (
                        <Typography sx={{ textAlign: 'center', margin: '1rem' }}>
                            No draft leads available. Please add a new lead!
                        </Typography>
                    ),
                    toolbar: () => (
                        <DraftLeadTableToolbar
                            setUrlParams={props.setUrlParams}
                        />
                    ),
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection={false}
                disableRowSelectionOnClick
            />
            <DeleteConfirmationDialog
                open={open}
                handleClose={handleClose}
                onConfirm={handleConfirmDelete}
                contentText={contentText}
                state={props.draftLeadState}
            />
        </Box>
    );
}
