import * as React from "react";

import { clearMessage } from "../../store/service.slice";
import { useDispatch, useSelector } from "react-redux";

import AddIcon from "@mui/icons-material/Add";
import AddServiceDialog from "../Service/AddServiceDialog";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import ServiceTable from "../Service/ServiceTable";
import Snackbar from "@mui/material/Snackbar";
import { useEdvantageContext } from "../../EdvantageProvider";

function Service() {
	const dispatch = useDispatch();
	const serviceState = useSelector((state) => state.service);

	const { services } = useEdvantageContext();

	const [openDialog, setOpenDialog] = React.useState(false);
	const handleOpenDialog = () => {
		setOpenDialog(true);
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		dispatch(clearMessage());
	};

	return (
		<Container component="main" sx={{ backgroundColor: "#FFFFFF" }}>
			{serviceState.message && (
				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					open={true}
					autoHideDuration={5000}
					onClose={handleCloseSnackbar}
				>
					<Alert sx={{ width: "100%", mb: 2 }} severity="success">
						{serviceState.message}
					</Alert>
				</Snackbar>
			)}
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				{serviceState.error &&
					serviceState.action === "updateMajor" && (
						<Alert
							sx={{ width: "100%", mt: 2, mb: 2 }}
							severity="error"
						>
							{serviceState.error}
						</Alert>
					)}
				<Box
					sx={{
						width: "100%",
						display: "flex",
						justifyContent: "flex-end",
						mb: 2,
					}}
				>
					<Button
						variant="contained"
						color="primary"
						startIcon={<AddIcon />} // Add icon before the text
						onClick={handleOpenDialog}
					>
						Add Service
					</Button>
				</Box>
				{services.length ? (
					<ServiceTable serviceState={serviceState} />
				) : (
					<Alert
						sx={{ width: "100%", mt: 2, mb: 2 }}
						severity="warning"
					>
						No Service Found! Add a service
					</Alert>
				)}
				<AddServiceDialog
					open={openDialog}
					setOpenDialog={setOpenDialog}
					serviceState={serviceState}
				/>
			</Box>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1000,
				}}
				open={serviceState.loading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Container>
	);
}

export default Service;
