import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "../wrappers/fetchWrapper";

const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/employee`;
const SLICE_NAME = "user";

const initialState = {
	action: "",
	users: [],
	loading: false,
	error: null,
	message: null,
	status: 'idle',
}; 

const userSlice = createSlice({
	name: SLICE_NAME,
	initialState,
	reducers: {
		// Optionally add some synchronous reducers if needed
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		clearMessage: (state) => {
			state.message = null;
		},
		clearError: (state) => {
			state.error = null;
		},
		clearUserState: (state) => {
			state.users = [];
			state.loading = false;
			state.error = null;
			state.message = null;
			state.status = 'idle';
		},
		updateUserStatus: (state, action) => {
			const { id, status } = action.payload;
			const userIndex = state.users.findIndex(
				(user) => user.id === id
			);
			if (userIndex !== -1) {
				state.users[userIndex].status = status;
			}
		},
	},
	extraReducers: (builder) => {
		builder
			// ADD employee users
			.addCase(addUser.pending, (state) => {
				state.action = "addUser";
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(addUser.fulfilled, (state, action) => {
				state.action = "addUser";
				state.loading = false;
				state.message = "User Added Successfully";
				state.users.push(action.payload);
			})
			.addCase(addUser.rejected, (state, action) => {
				state.action = "addUser";
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			})

			// UPDATE employee users
			.addCase(updateUser.pending, (state) => {
				state.action = "updateUser";
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(updateUser.fulfilled, (state, action) => {
				state.action = "updateUser";
				state.loading = false;
				state.message = "User Updated Successfully";
				const index = state.users.findIndex(
					(user) => user.id === action.payload.id
				);
				if (index !== -1) {
					state.users[index] = action.payload;
				}
			})
			.addCase(updateUser.rejected, (state, action) => {
				state.action = "updateUser";
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			})

			// UPDATE user Groups
			.addCase(updateUserGroup.pending, (state) => {
				state.action = "updateUserGroup";
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(updateUserGroup.fulfilled, (state, action) => {
				state.action = "updateUserGroup";
				state.loading = false;
				state.message = "User Group Updated Successfully";
				const index = state.users.findIndex(
					(user) => user.id === action.payload.id
				);
				if (index !== -1) {
					state.users[index] = action.payload;
				}
			})
			.addCase(updateUserGroup.rejected, (state, action) => {
				state.action = "updateUserGroup";
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			})

			// FETCH employee users
			.addCase(fetchUsers.pending, (state) => {
				state.action = "fetchUser";
				state.status = 'loading';
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(fetchUsers.fulfilled, (state, action) => {
				state.action = "fetchUser";
				state.status = 'succeeded';
				state.loading = false;
				state.users = action.payload;
			})
			.addCase(fetchUsers.rejected, (state, action) => {
				state.action = "fetchUser";
				state.status = 'failed';
				state.loading = false;
				state.error = action.payload;
			})

			// GET by magic link
			.addCase(getUserByMagicLink.pending, (state) => {
				state.action = "getUserByMagicLink";
				state.status = 'loading';
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(getUserByMagicLink.fulfilled, (state, action) => {
				state.action = "getUserByMagicLink";
				state.status = 'succeeded';
				state.loading = false;
				state.users = action.payload;
			})
			.addCase(getUserByMagicLink.rejected, (state, action) => {
				state.action = "getUserByMagicLink";
				state.status = 'failed';
				state.loading = false;
				state.error = action.payload;
			})

			// POST Activate User
			.addCase(activateUser.pending, (state) => {
				state.action = "activateUser";
				state.status = 'loading';
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(activateUser.fulfilled, (state, action) => {
				state.action = "activateUser";
				state.status = 'succeeded';
				state.loading = false;
				state.users = action.payload;
			})
			.addCase(activateUser.rejected, (state, action) => {
				state.action = "activateUser";
				state.status = 'failed';
				state.loading = false;
				state.error = action.payload;
			})

			// POST Disable User
			.addCase(disableUser.pending, (state) => {
				state.action = "disableUser";
				state.status = 'loading';
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(disableUser.fulfilled, (state, action) => {
				state.action = "disableUser";
				state.status = 'succeeded';
				state.loading = false;
				state.users = action.payload;
			})
			.addCase(disableUser.rejected, (state, action) => {
				state.action = "disableUser";
				state.status = 'failed';
				state.loading = false;
				state.error = action.payload;
			})

			// POST Enable User
			.addCase(enableUser.pending, (state) => {
				state.action = "enableUser";
				state.status = 'loading';
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(enableUser.fulfilled, (state, action) => {
				state.action = "enableUser";
				state.status = 'succeeded';
				state.loading = false;
				state.users = action.payload;
			})
			.addCase(enableUser.rejected, (state, action) => {
				state.action = "enableUser";
				state.status = 'failed';
				state.loading = false;
				state.error = action.payload;
			})

			// POST Resend Password
			.addCase(resendPassword.pending, (state) => {
				state.action = "resendPassword";
				state.status = 'loading';
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(resendPassword.fulfilled, (state, action) => {
				state.action = "resendPassword";
				state.status = 'succeeded';
				state.loading = false;
				state.users = action.payload;
			})
			.addCase(resendPassword.rejected, (state, action) => {
				state.action = "resendPassword";
				state.status = 'failed';
				state.loading = false;
				state.error = action.payload;
			})
			
			// DELETE employee user
			.addCase(deleteUser.pending, (state) => {
				state.action = "deleteUser";
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(deleteUser.fulfilled, (state, action) => {
				state.action = "deleteUser";
				state.loading = false;
				state.message = "User Deleted Successfully";
				state.users = state.users.filter(
					(repo) => repo.pk !== action.payload.pk
				);
			})
			.addCase(deleteUser.rejected, (state, action) => {
				state.action = "deleteUser";
				state.loading = false;
				state.error = action.payload;
			});
	},
});

export const {
	setLoading,
	clearMessage,
	clearError,
	clearUserState,
	updateUserStatus,
} = userSlice.actions;

export const userReducer = userSlice.reducer;

///////
// THUNKS

// Adding a new User
export const addUser = createAsyncThunk(
	`${SLICE_NAME}/addUser`,
	async (user, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.post(`${BASE_URL}/add`, user);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// Fetching Users
export const fetchUsers = createAsyncThunk(
	`${SLICE_NAME}/fetchUsers`,
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.get(
				`${BASE_URL}/fetch?timestamp=${new Date().getTime()}`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// Updating User
export const updateUser = createAsyncThunk(
	`${SLICE_NAME}/updateUser`,
	async ({ id, data }, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.put(
				`${BASE_URL}/update/${id}`,
				data
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// Updating User Group
export const updateUserGroup = createAsyncThunk(
	`${SLICE_NAME}/updateUserGroup`,
	async ({ id, data }, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.put(
				`${BASE_URL}/${id}/groups/update`,
				data
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// get User by magic link
export const getUserByMagicLink = createAsyncThunk(
	`${SLICE_NAME}/getByMagicLink`,
	async (id, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.get(
				`${BASE_URL}/magiclink/${id}`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// Activate User
export const activateUser = createAsyncThunk(
	`${SLICE_NAME}/activateUser`,
	async ( {magiclink, data}, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.post(
				`${BASE_URL}/activate/${magiclink}`,
				data
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// Disable User
export const disableUser = createAsyncThunk(
	`${SLICE_NAME}/disableUser`,
	async ( {id, data}, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.post(
				`${BASE_URL}/${id}/disable`,
				data
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// Enable User
export const enableUser = createAsyncThunk(
	`${SLICE_NAME}/enableUser`,
	async ( {id, data}, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.post(
				`${BASE_URL}/${id}/enable`,
				data
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// Resend password
export const resendPassword = createAsyncThunk(
	`${SLICE_NAME}/resendPassword`,
	async ( {id, data}, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.post(
				`${BASE_URL}/${id}/resend_password`,
				data
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// Delete User
export const deleteUser = createAsyncThunk(
	`${SLICE_NAME}/deleteUser`,
	async (id, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.delete(
				`${BASE_URL}/${id}`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
