import { useDispatch, useSelector } from "react-redux";

import { fetchGroups } from "../store/group.slice";
import { useEffect } from "react";

function useGroup() {
	const dispatch = useDispatch();

	const groups = useSelector((state) => state.group.groups);
	const groupStatus = useSelector((state) => state.group.status);

	useEffect(() => {
		async function onLoad() {
			if (groupStatus === "idle") {
				dispatch(fetchGroups());
			}
		}

		onLoad();
	}, [groupStatus, dispatch]);

	return {
		groups,
	};
}

export default useGroup;
