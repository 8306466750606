import * as React from "react";

import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import { isValidCurrency, isValidFee, isValidURL } from "../../utils/Utils";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import UniversityCourseForm from "./UniversityCourseForm";
import { addUniversityCourses } from "../../store/university.slice";
import { useEdvantageContext } from "../../EdvantageProvider";

function AddCourseDialog(props) {
	const dispatch = useDispatch();

	const { open, handleClose, universityId } = props;

	const universityState = useSelector((state) => state.university);
	const { uuid } = useEdvantageContext();

	const initialCourseData = {
		major: "",
		program: "",
		application_method: "",
		status: "",
		partnership_status: "",
		intakes: [],
		is_sponsored: false,
		sponsor: "",
		fee: "",
		currency: "",
		course_url: "",
		university: universityId,
	};

	const [form, setForm] = React.useState([initialCourseData]);
	const [formErrors, setFormErrors] = React.useState([]);
	const [isFormValid, setIsFormValid] = React.useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();
		dispatch(
			addUniversityCourses({ university_id: universityId, courses: form, uuid })
		).then((response) => {
			if (response.type === "university/addUniversityCourses/fulfilled") {
				handleCloseDialog();
			}
		});
	};

	React.useEffect(() => {
		const errors = form.map((course) => validateCourseForm(course));
		setIsFormValid(
			errors.every((error) => Object.keys(error).length === 0)
		);
	}, [form]);

	const addCourse = () => {
		setForm([...form, initialCourseData]);
		setFormErrors([...formErrors, {}]);
	};

	const handleDeleteCourse = (index) => {
		const updatedCourses = form.filter((_, idx) => idx !== index);
		setForm(updatedCourses);
		const updatedErrors = formErrors.filter((_, idx) => idx !== index);
		setFormErrors(updatedErrors);
	};

	const validateCourseForm = (form) => {
		const errors = {};

		// Required fields and their corresponding error messages
		const requiredFields = {
			major: "Major is required",
			program: "Program is required",
			status: "Merit status is required",
		};

		// Validate required fields
		for (const field in requiredFields) {
			if (!form[field]) {
				errors[field] = requiredFields[field];
			}
		}

		if (form.intakes.length === 0) {
			errors.intakes = "At least one intake is required";
		}

		// Validate fee and currency together
		if ((form.fee && !form.currency) || (!form.fee && form.currency)) {
			if (!form.fee) {
				errors.fee = "Fee is required when currency is set";
			}
			if (!form.currency) {
				errors.currency = "Currency is required when fee is set";
			}
		}

		// Validate the fee field if it's present
		if (form.fee && !isValidFee(form.fee)) {
			errors.fee = "Invalid fee amount";
		}

		// Validate the currency field if it's present
		if (form.currency && !isValidCurrency(form.currency)) {
			errors.currency = "Invalid currency";
		}

		// Validate the course URL field if it's present
		if (form.course_url && !isValidURL(form.course_url)) {
			errors.course_url = "Invalid course URL";
		}

		return errors;
	};

	const validateCourseField = (name, value, updatedForm, index) => {
		let error = "";
		let updatedErrors = [...formErrors];

		// Validation logic
		if (!value) {
			if (name === "major") error = "Major is required";
			if (name === "program") error = "Program is required";
			if (name === "status") error = "Status is required";
		}

		if (name === "intakes" && value.length === 0) error = "At least one intake is required";

		if (name === "fee") {
			if (value && !isValidFee(value)) {
				error = "Invalid fee amount";
			} else if (value && !isValidCurrency(updatedForm[index].currency)) {
				updatedErrors[index] = {
					...updatedErrors[index],
					currency: "Currency is required",
				};
			} else if (!value && isValidCurrency(updatedForm[index].currency)) {
				error = "Fee is required when currency is set";
			} else if (
				!value &&
				!isValidCurrency(updatedForm[index].currency)
			) {
				updatedErrors[index] = {
					...updatedErrors[index],
					currency: "",
				};
			}
		} else if (name === "currency") {
			if (value && !isValidCurrency(value)) {
				error = "Invalid currency";
			} else if (value && !isValidFee(updatedForm[index].fee)) {
				updatedErrors[index] = {
					...updatedErrors[index],
					fee: "Fee is required when currency is set",
				};
			} else if (!value && isValidFee(updatedForm[index].fee)) {
				error = "Currency is required when fee is set";
			} else if (!value && !isValidFee(updatedForm[index].fee)) {
				updatedErrors[index] = {
					...updatedErrors[index],
					fee: "",
				};
			}
		}

		if (name === "course_url") {
			if (value && !isValidURL(value)) {
				error = "Invalid URL";
			}
		}

		updatedErrors[index] = {
			...updatedErrors[index],
			[name]: error,
		};
		setFormErrors(updatedErrors);
	};

	const handleChange = (event, index) => {
		const { name, value, type, checked } = event.target;
		const updatedForm = [...form];
		updatedForm[index] = {
			...updatedForm[index],
			[name]: type === "checkbox" ? checked : value,
		};

		setForm(updatedForm);
		validateCourseField(name, value, updatedForm, index);
	};

	const handleCloseDialog = () => {
		handleClose(false);
		setForm([initialCourseData]);
		setFormErrors([]);
		setIsFormValid(false);
	};

	return (
		<Dialog
			open={open}
			onClose={handleCloseDialog}
			aria-labelledby="form-dialog-title"
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle id="form-dialog-title">Add Courses</DialogTitle>
			<DialogContent>
				<Box sx={{ mt: 3 }}>
					{universityState.error && (
						<Alert
							sx={{ width: "100%", mt: 2, mb: 2 }}
							severity="error"
						>
							{universityState.error}
						</Alert>
					)}
					<UniversityCourseForm
						coursesData={form}
						handleChange={handleChange}
						addCourse={addCourse}
						handleDelete={handleDeleteCourse}
						errors={formErrors}
						isCourseFormValid={isFormValid}
					/>
					<DialogActions sx={{ justifyContent: "flex-start", px: 0 }}>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							sx={{ mt: 3, mb: 2 }}
							disabled={!isFormValid}
							onClick={handleSubmit}
						>
							Save
						</Button>
						<Button
							type="button"
							variant="contained"
							color="error"
							sx={{ mt: 3, mb: 2 }}
							onClick={handleCloseDialog}
						>
							Cancel
						</Button>
					</DialogActions>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

AddCourseDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	universityId: PropTypes.string.isRequired,
};

export default AddCourseDialog;
