import "jspdf-autotable";

import {
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarContainer,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import React from "react";
import UniversityExportButton from "./UniversityExportButton";
import universityColumns from "./universityColumns";

function CustomToolbar(props) {

    const handleAddClick = () => {
        props.setUrlParams(prevParams => ({
            ...prevParams,
            view: "add"
        }));
    };

    return (
        <GridToolbarContainer>
            <Button
                variant="text"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddClick}
            >
                Add University
            </Button>
            <GridToolbarColumnsButton />
            <UniversityExportButton
                rows={props.rows}
                columns={props.columns}
                universities={props.universities}
                filterCourse={props.filterCourse}
            />
        </GridToolbarContainer>
    );
}

export default function UniversityTable(props) {
    const { currencyById, intakeById, majorById, programById, searchFilters, filterCourse, setUrlParams, universities, handleDelete } = props;

    const onViewDetails = (universityId) => {
        setUrlParams(prevParams => ({
            ...prevParams,
            view: "details",
            id: universityId,
        }));
    };

    const [expandedRows, setExpandedRows] = useState({});
    const [filteredRows, setFilteredRows] = useState([]);

    useEffect(() => {
        const rows = universities.flatMap((university) => {
            const universityRow = {
                id: university.id,
                name: university.name,
                website: university.website,
                major: "", // Placeholder for courses data
                program: "", // Placeholder for courses data
                intakes: [], // Placeholder for courses data
                application_method: "", // Placeholder for courses data
                fee: "", // Placeholder for courses data
                status: "", // Placeholder for courses data,
                is_sponsored: "", // Placeholder for courses data
                brochure_url: university.brochure_url,
                city: university.address.city,
                country: university.address.country,
                qs_world_ranking: university.qs_world_ranking,
                google_maps_url: university.google_maps_url,
                isUniversity: true,
            };
            const courseRows =
                filterCourse || expandedRows[university.id]
                    ? university.courses.map((course, index) => ({
                        id: `${university.id}-${index}`,
                        name: "", // Empty for course rows
                        major: majorById.get(course.major).name,
                        program: programById.get(course.program).name,
                        intakes: course.intakes.map((intakeId) => intakeById.get(intakeId).text),
                        application_method: course.application_method,
                        currency: currencyById.get(course.currency)?.code,
                        fee: course.fee,
                        status: course.status,
                        is_sponsored: course.is_sponsored, // Empty for course rows
                        brochure_url: "", // Empty for course rows
                        city: "", // Empty for course rows
                        country: "", // Empty for course rows
                        qs_world_ranking: "", // Empty for course rows
                        google_maps_url: "", // Empty for course rows
                        isUniversity: false,
                    }))
                    : [];
            return [universityRow, ...courseRows];
        });

        const filteredUniversityIds = new Set();
        const filteredCourseParentIds = new Set();
        const newFilteredRows = rows.filter((row) => {
            if (
                Object.values(searchFilters).every((filter) => {
                    if (Array.isArray(filter)) {
                        return filter.length === 0;
                    } else if (typeof filter === "boolean") {
                        return filter === undefined;
                    }
                    return !filter.trim();
                })
            ) {
                return true; // Show all rows if all search fields are empty
            }

            let match = true;

            if (row.isUniversity) {
                if (searchFilters.name) {
                    match =
                        match &&
                        row.name
                            .toLowerCase()
                            .includes(searchFilters.name.trim().toLowerCase());
                }
                if (searchFilters.city) {
                    match =
                        match &&
                        row.city
                            .toLowerCase()
                            .includes(searchFilters.city.trim().toLowerCase());
                }
                if (searchFilters.countries.length) {
                    match =
                        match && searchFilters.countries.includes(row.country);
                }

                if (match) {
                    filteredUniversityIds.add(row.id);
                }
            } else {
                const courseParentId = row.id.split("-")[0];

                if (!filteredUniversityIds.has(courseParentId)) {
                    match = false;
                }

                if (searchFilters.majors.length) {
                    match = match && searchFilters.majors.includes(row.major);
                }

                if (searchFilters.programs.length) {
                    match =
                        match && searchFilters.programs.includes(row.program);
                }

                if (searchFilters.is_sponsored !== "") {
                    match =
                        match &&
                        (row.is_sponsored ? "Yes" : "No") ===
                        searchFilters.is_sponsored;
                }

                if (match) {
                    filteredCourseParentIds.add(courseParentId);
                }
            }

            return match;
        });

        // If course filter is applied, only show universities that has at least one course that matches the filter
        const finalFilteredRows = newFilteredRows.filter((row) => {
            if (row.isUniversity) {
                return filterCourse
                    ? filteredCourseParentIds.has(row.id)
                    : true;
            }
            return true;
        });

        setFilteredRows(finalFilteredRows);
    }, [searchFilters, expandedRows, universities]);

    const handleExpandClick = (id) => {
        setExpandedRows((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const getRowClassName = (params) => {
        return params.row.isUniversity ? "university-row" : "course-row";
    };

    const getRowHeight = (params) => {
        const contentLength = params.model.name?.length || 0;
        return contentLength > 50 ? 100 : 60;
    };

    return (
        <DataGrid
            rows={filteredRows}
            columns={universityColumns(
                expandedRows,
                handleExpandClick,
                handleDelete,
                onViewDetails
            )}
            checkboxSelection={false}
            disableColumnMenu={true}
            disableColumnFilter={true}
            disableColumnSorting={true}
            disableColumnResize={true}
            autoHeight
            getRowHeight={getRowHeight}
            pageSize={10}
            pageSizeOptions={[10, 25, 50, 100]}
            initialState={{
                pagination: {
                    paginationModel: { pageSize: 10 },  // Default to 10 rows per page
                },
            }}
            getRowClassName={getRowClassName}
            slots={{
                toolbar: () => (
                    <CustomToolbar
                        rows={filteredRows}
                        columns={universityColumns(
                            expandedRows,
                            handleExpandClick
                        )}
                        universities={universities}
                        filterCourse={filterCourse}
                        setUrlParams={setUrlParams}
                    />
                ),
            }}
            sx={{
                "& .MuiDataGrid-row.course-row": {
                    backgroundColor: "#f5f5f5",
                    "&:hover": {
                        backgroundColor: "#e0e0e0",
                    },
                },
                "& .MuiDataGrid-columnHeaders": {
                    whiteSpace: "nowrap",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                },
            }}
            disableRowSelectionOnClick
        />
    );
}
