// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.title {
	font-family: Arial, sans-serif;
	font-size: 1.5rem;
	margin-bottom: 1rem;
}

.cardInfo {
	font-family: Arial, sans-serif;
	font-size: 15px !important;
	margin-bottom: 0px !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/lead-details.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,YAAY;AACb;;AAEA;CACC,8BAA8B;CAC9B,iBAAiB;CACjB,mBAAmB;AACpB;;AAEA;CACC,8BAA8B;CAC9B,0BAA0B;CAC1B,6BAA6B;AAC9B","sourcesContent":[".card {\n\tdisplay: flex;\n\tflex-direction: column;\n\theight: 100%;\n}\n\n.title {\n\tfont-family: Arial, sans-serif;\n\tfont-size: 1.5rem;\n\tmargin-bottom: 1rem;\n}\n\n.cardInfo {\n\tfont-family: Arial, sans-serif;\n\tfont-size: 15px !important;\n\tmargin-bottom: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
