import * as React from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { addUser, clearUserState } from "../../store/user.slice";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from "react-redux";
import useGroup from "../../hooks/useGroup";

const validationSchema = Yup.object({
	firstName: Yup.string().max(20, 'Must be 20 characters or less'),
	lastName: Yup.string().max(20, 'Must be 20 characters or less'),
	email: Yup.string().email('Invalid email address').required('Email is required'),
  })

function AddUserDialog(props) {
	const dispatch = useDispatch();

	const { open, setOpenDialog } = props;

	const [groups, setGroups] = React.useState([]);
	const fetched_groups = useSelector((state) => state.group.groups);

	// load groups
	useGroup()

	// initialize Formik
	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			group: "Employee",
		},
		validationSchema: validationSchema,
		onSubmit: values => {
				const data = {
					phone_number: values.phoneNumber,
					role:"Frontline", //TODO REMOVE - OLD MODEL
					department:"dept", //TODO REMOVE - OLD MODEL
					group: values.group,
					user: {
						first_name: values.firstName,
						last_name: values.lastName,
						email: values.email
					}
				}
				dispatch(addUser(data)).then(
					(response) => {
						if (response.type === "user/addUser/fulfilled") {
							dispatch(clearUserState());
							setOpenDialog(false);
						}
					}
				);
			}
	});

	React.useEffect( () => {
		const select_group = []
		for (const gr of fetched_groups){
			select_group.push({value:gr.name, label:gr.name})
		}
		setGroups(select_group)


	}, [dispatch, fetched_groups])

	function handleClickClose() {
		formik.resetForm();
		dispatch(clearUserState());
		setOpenDialog(false);
	}
		
	return (
		<Dialog
			open={open}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">Add New User</DialogTitle>
			<form id="userForm" onSubmit={formik.handleSubmit}>
			<DialogContent>
				<DialogContentText>
					Please insert the user&apos;s email addess
				</DialogContentText>
				
				<Box
					noValidate
					sx={{ mt: 3 }}
				>
				{props.userState.error &&
					props.userState.action === "addUser" && (
						<Alert
							sx={{ width: "100%", mt: 2, mb: 2 }}
							severity="error"
						>
							{props.userState.error}
						</Alert>
					)}
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<TextField
								id="firstName"
								name="firstName"
								label="First Name"
								value={formik.values.firstName}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								fullWidth
								error={formik.touched.firstName && Boolean(formik.errors.firstName)}
								helperText={formik.touched.firstName && formik.errors.firstName}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								name="lastName"
								id="lastName"
								label="Last Name"
								value={formik.values.lastName}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								fullWidth
								error={formik.touched.lastName && Boolean(formik.errors.lastName)}
								helperText={formik.touched.lastName && formik.errors.lastName}

							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								name="email"
								id="email"
								label="Email Address (*)"
								value={formik.values.email}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								fullWidth
								error={formik.touched.email && Boolean(formik.errors.email)}
								helperText={formik.touched.email && formik.errors.email}
							/>
						</Grid>
						<Grid item xs={12}>
						<TextField
								id="group"
								select
								label="Group"
								value={formik.values.group}
								onChange={formik.handleChange("group")}
								margin="normal"
								defaultValue="Employee"
								fullWidth
								>
								{groups.map((option) => (
										<MenuItem key={option.value} value={option.value}>
										{option.label}
										</MenuItem>
									))}
							</TextField>
						</Grid>
						
					</Grid>
					
					<DialogActions sx={{ justifyContent: "flex-start" }}>
						<Button
							type="submit"
							form="userForm"
							variant="contained"
							color="primary"
							sx={{ mt: 3, mb: 2 }}
							disabled={!(formik.isValid && formik.dirty)}
						>
							Add User
						</Button>
						<Button
							type="button"
							variant="contained"
							color="primary"
							sx={{ mt: 3, mb: 2 }}
							onClick={handleClickClose}
						>
							Cancel
						</Button>
					</DialogActions>
				</Box>
			</DialogContent>
			</form>
		</Dialog>
	);
}

AddUserDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpenDialog: PropTypes.func.isRequired,
	userState: PropTypes.object.isRequired,
};

export default AddUserDialog;
