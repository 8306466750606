import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
    Popper,
    Skeleton,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { highlightMentions } from "../../helper/CommentHelper";

const MentionTextField = ({
    options,
    value,
    onChange,
    replyToId = "root",
    mentions,
    setMentions,
    loading,
    rows = 1,
    sx = {},
    InputProps = {},
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [previousInputState, setPreviousInputState] = useState({ value: "", selectionStart: 0 });
    const [mentionIndex, setMentionIndex] = useState(null); // Tracks the start of the mention
    const inputRef = useRef();
    const popperRef = useRef();

    // Close Popper on outside click or ESC key press
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                popperRef.current &&
                !popperRef.current.contains(event.target) &&
                !inputRef.current.contains(event.target)
            ) {
                setAnchorEl(null); // Close Popper
            }
        };

        const handleEscKey = (event) => {
            if (event.key === "Escape") {
                setAnchorEl(null); // Close Popper
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("keydown", handleEscKey);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("keydown", handleEscKey);
        };
    }, []);

    const handleCursorMovement = (e) => {
        const { selectionStart, selectionEnd } = e.target;

        // Update the previousInputState with the new cursor position
        setPreviousInputState((prevState) => ({
            ...prevState,
            selectionStart,
            selectionEnd,
        }));

    };

    const handleInputChange = (e) => {
        const { value: inputValue, selectionStart, selectionEnd } = e.target;
        const { value: oldValue, selectionStart: oldCursorStart, selectionEnd: oldCursorEnd } = previousInputState;

        const changeLength = inputValue.length - oldValue.length;

        // Adjust mentions after the cursor position
        const updatedMentions = mentions.filter((mention) => {
            // Remove mentions completely inside the deleted range
            return oldCursorStart > mention.end_index ||
                (oldCursorStart === mention.end_index && changeLength > 0) ||
                oldCursorEnd < mention.start_index ||
                (oldCursorEnd === mention.start_index && changeLength < 0);
        }).map((mention) => {
            if (mention.start_index >= oldCursorEnd) {
                return {
                    ...mention,
                    start_index: mention.start_index + changeLength,
                    end_index: mention.end_index + changeLength,
                };
            }
            return mention;
        });
        setMentions(updatedMentions);

        onChange(e, replyToId);
        setPreviousInputState({ value: inputValue, selectionStart, selectionEnd });

        const lastChar = inputValue[selectionStart - 1];
        if (lastChar === "@") {
            setMentionIndex(selectionStart);
            setFilteredOptions(options);
            setAnchorEl(inputRef.current);
        } else if (mentionIndex !== null) {
            const query = inputValue.slice(mentionIndex, selectionStart).toLowerCase();
            const filtered = options.filter((opt) =>
                opt.full_name.toLowerCase().includes(query)
            );
            setFilteredOptions(filtered);
            if (filtered.length === 0 || query === "") {
                setAnchorEl(null);
            }
        }
    };


    const handleOptionSelect = (option) => {
        const beforeMention = value.slice(0, mentionIndex - 1);
        const afterMention = value.slice(previousInputState.selectionStart);
        const newValue = `${beforeMention}@${option.full_name} ${afterMention}`;

        const newMention = {
            mention_name: option.full_name,
            mention_id: option.id,
            start_index: mentionIndex, // Position of @ in the text
            end_index: mentionIndex + option.full_name.length, // End of mention
        };

        const adjustedMentions = mentions.map((mention) => {
            if (mention.start_index >= mentionIndex) {
                return {
                    ...mention,
                    start_index: mention.start_index + option.full_name.length - (previousInputState.selectionStart - mentionIndex) + 1, // Adjust for '@' and trailing space
                    end_index: mention.end_index + option.full_name.length - (previousInputState.selectionStart - mentionIndex) + 1,
                };
            }
            return mention;
        });

        // Combine the new mention with the adjusted mentions
        const allMentions = [...adjustedMentions, newMention];

        // Sort mentions by start_index in ascending order
        allMentions.sort((a, b) => a.start_index - b.start_index);

        setMentions(allMentions);

        onChange({ target: { value: newValue } }, replyToId);
        setPreviousInputState(previousInputState => ({
            ...previousInputState,
            value: newValue
        }));

        const newCursorPosition = mentionIndex + option.full_name.length + 1;
        // Use the inputRef to set the cursor position
        // Use a timeout to ensure the cursor position is set after rendering
        setTimeout(() => {
            if (inputRef?.current) {
                inputRef.current.focus();
                inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
            }
        }, 0);

        setAnchorEl(null);
        setMentionIndex(null);
    };

    return (
        <Box sx={{ position: "relative", width: "100%", ...sx }}>
            {/* Highlighted Text Layer */}
            <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    pointerEvents: "none",
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                    color: "transparent",
                    zIndex: 1,
                    padding: "16.5px 14px", // Match TextField padding
                }}
            >
                {highlightMentions(value, mentions)}
            </Box>

            {/* Input Layer */}
            <TextField
                inputRef={inputRef}
                label="Add a comment..."
                variant="outlined"
                fullWidth
                multiline
                rows={rows}
                value={value}
                onChange={handleInputChange}
                onSelect={handleCursorMovement}
                onClick={handleCursorMovement}
                sx={{
                    position: "relative",
                    "& .MuiInputBase-input": {
                        color: "default",
                        caretColor: "black",
                    },
                }}
                InputProps={InputProps}
            />

            {/* Popper for Mention Suggestions */}
            <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                ref={popperRef}
                modifiers={[
                    {
                        name: "offset",
                        options: {
                            offset: [0, 8],
                        },
                    },
                ]}
            >
                <Paper elevation={3} sx={{ width: 300, maxHeight: 200, overflowY: "auto" }}>
                    <List>
                        {loading ? (
                            <>
                                <Skeleton variant="text" width="100%" height={30} />
                                <Skeleton variant="text" width="100%" height={30} />
                                <Skeleton variant="text" width="100%" height={30} />
                            </>
                        ) : (
                            filteredOptions.map((option) => (
                                <ListItem key={option.id} disablePadding>
                                    <ListItemButton onClick={() => handleOptionSelect(option)}>
                                        <ListItemText primary={option.full_name} />
                                    </ListItemButton>
                                </ListItem>
                            ))
                        )}
                        {filteredOptions.length === 0 && !loading && (
                            <Typography variant="caption" sx={{ padding: 1, color: "gray" }}>
                                No matches found.
                            </Typography>
                        )}
                    </List>
                </Paper>
            </Popper>
        </Box>
    );
};

export default MentionTextField;
