import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "../wrappers/fetchWrapper";

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/university`;

const initialState = {
	action: "",
	universities: [],
	loading: false,
	error: null,
	message: null,
	status: "",
};

const name = "university";

const universitySlice = createSlice({
	name: name,
	initialState,
	reducers: {
		// Optionally add some synchronous reducers if needed
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		clearMessage: (state) => {
			state.message = null;
		},
		setStatus: (state, action) => {
			state.status = action.payload;
		},
		clearUniversityState: (state) => {
			state.universities = [];
			state.loading = false;
			state.error = null;
			state.message = null;
			state.status = "";
		},
		changeUniversityState: (state, action) => {
			const { action: actionType, data } = action.payload;

			if (actionType === 'create') {
				// Add new university to the list
				state.universities = [...state.universities, data];
			} else if (actionType === 'update') {
				// Update the existing university
				const index = state.universities.findIndex(university => university.id === data.id);
				if (index !== -1) {
					state.universities[index] = data;
				}
			} else if (actionType === 'delete') {
				// Delete the university by id
				state.universities = state.universities.filter(university => university.id !== data.id);
			} else if (actionType === 'create_courses') {
				// Add new course to the university
				const universityIndex = state.universities.findIndex(
					(university) => university.id === data.university
				);

				if (universityIndex !== -1) {
					// Use the spread operator to create a new array with the updated university
					state.universities = state.universities.map(
						(university, index) =>
							index === universityIndex
								? {
									...university,
									courses: [
										...university.courses,
										...data.courses,
									],
								}
								: university
					);
				}
			} else if (actionType === 'update_course') {
				// Update the existing course in the university
				const university = state.universities.find(
					(university) => university.id === data.university
				);

				if (university) {
					const course = university.courses.find(
						(course) => course.id === data.id
					);

					if (course) {
						Object.assign(course, data);
					}
				}
			} else if (actionType === 'delete_courses') {
				data.forEach((courseData) => {
					// Find the university index based on the university ID from the course data
					const universityIndex = state.universities.findIndex(
						(university) => university.id === courseData.university
					);

					// If the university is found, filter out the course with the specified ID
					if (universityIndex !== -1) {
						state.universities = state.universities.map((university, index) =>
							index === universityIndex
								? {
									...university,
									courses: university.courses.filter(
										(course) => course.id !== courseData.id
									),
								}
								: university
						);
					}
				});
			}

		}
	},
	extraReducers: (builder) => {
		builder
			// Add Cred
			.addCase(addUniversity.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(addUniversity.fulfilled, (state, action) => {
				state.action = "addUniversity";
				state.loading = false;
				state.message = "University Added Successfully";
				state.universities.push(action.payload);
			})
			.addCase(addUniversity.rejected, (state, action) => {
				state.action = "addUniversity";
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			})
			// Update Cred
			.addCase(updateUniversity.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(updateUniversity.fulfilled, (state, action) => {
				state.loading = false;
				state.message = "University Updated Successfully";
				const index = state.universities.findIndex(
					(university) => university.id === action.payload.id
				);
				if (index !== -1) {
					state.universities[index] = action.payload;
				}
			})
			.addCase(updateUniversity.rejected, (state, action) => {
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			})
			.addCase(updateUniversityCourse.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(updateUniversityCourse.fulfilled, (state, action) => {
				state.loading = false;
				state.message = "Course Updated Successfully";

				const university = state.universities.find(
					(university) => university.id === action.payload.university
				);

				if (university) {
					const course = university.courses.find(
						(course) => course.id === action.payload.id
					);

					if (course) {
						Object.assign(course, action.payload);
					}
				}
			})
			.addCase(updateUniversityCourse.rejected, (state, action) => {
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			})
			.addCase(addUniversityCourses.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(addUniversityCourses.fulfilled, (state, action) => {
				state.loading = false;
				state.message = "Course Added Successfully";

				// Find the index of the university to update
				const universityIndex = state.universities.findIndex(
					(university) => university.id === action.payload.university
				);

				if (universityIndex !== -1) {
					// Use the spread operator to create a new array with the updated university
					state.universities = state.universities.map(
						(university, index) =>
							index === universityIndex
								? {
									...university,
									courses: [
										...university.courses,
										...action.payload.courses,
									],
								}
								: university
					);
				}
			})
			.addCase(addUniversityCourses.rejected, (state, action) => {
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			})
			.addCase(fetchUniversities.pending, (state) => {
				state.loading = true;
				state.status = "loading";
				state.error = null;
				state.message = null;
			})
			.addCase(fetchUniversities.fulfilled, (state, action) => {
				state.loading = false;
				state.status = "succeeded";
				state.universities = action.payload;
			})
			.addCase(fetchUniversities.rejected, (state, action) => {
				state.loading = false;
				state.status = "failed";
				state.error = action.payload;
			})
			.addCase(deleteUniversity.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(deleteUniversity.fulfilled, (state, action) => {
				state.loading = false;
				state.message = "University Deleted Successfully";
				state.universities = state.universities.filter(
					(university) => university.id !== action.payload.id
				);
			})
			.addCase(deleteUniversity.rejected, (state, action) => {
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			})
			.addCase(deleteUniversityCourse.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(deleteUniversityCourse.fulfilled, (state, action) => {
				state.loading = false;
				state.message = "Course Deleted Successfully";

				// Find the index of the university to update
				const universityIndex = state.universities.findIndex(
					(university) => university.id === action.payload.university
				);

				if (universityIndex !== -1) {
					// Use the spread operator to create a new array with the updated university
					state.universities = state.universities.map(
						(university, index) =>
							index === universityIndex
								? {
									...university,
									courses: university.courses.filter(
										(course) =>
											course.id !== action.payload.id
									),
								}
								: university
					);
				}
			})
			.addCase(deleteUniversityCourse.rejected, (state, action) => {
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			});
	},
});

export const { changeUniversityState, setLoading, clearMessage, clearUniversityState, setStatus } =
	universitySlice.actions;
export const universityReducer = universitySlice.reducer;

export const addUniversity = createAsyncThunk(
	`${name}/addUniversity`,
	async (university, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.post(
				`${baseUrl}/add`,
				university
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const fetchUniversities = createAsyncThunk(
	`${name}/fetchUniversities`,
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.get(
				`${baseUrl}/fetch?timestamp=${new Date().getTime()}`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const updateUniversity = createAsyncThunk(
	`${name}/updateUniversity`,
	async ({ id, university, uuid }, { rejectWithValue }) => {
		try {
			const payload = { ...university, uuid };
			const response = await fetchWrapper.put(
				`${baseUrl}/update_university/${id}/`,
				payload
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const updateUniversityCourse = createAsyncThunk(
	`${name}/updateUniversityCourse`,
	async ({ id, course, uuid }, { rejectWithValue }) => {
		try {
			const payload = { ...course, uuid };
			const response = await fetchWrapper.put(
				`${baseUrl}/update_course/${id}/`,
				payload
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const addUniversityCourses = createAsyncThunk(
	`${name}/addUniversityCourses`,
	async ({ university_id, courses, uuid }, { rejectWithValue }) => {
		try {
			const payload = { courses, uuid };
			const response = await fetchWrapper.post(
				`${baseUrl}/add_courses/${university_id}/`,
				payload
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const deleteUniversity = createAsyncThunk(
	`${name}/deleteUniversity`,
	async ({ id, uuid }, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.delete(
				`${baseUrl}/delete_university/${id}/`, { uuid }
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const deleteUniversityCourse = createAsyncThunk(
	`${name}/deleteUniversityCourse`,
	async ({ id, uuid }, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.delete(
				`${baseUrl}/delete_course/${id}/`, { uuid }
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
