import React from "react";

import { Box } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/UniversitySlider.css";

export default function UniversitySlider({ photos }) {

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <Slider {...sliderSettings}>
            {photos.map((photo, index) => (
                <Box key={index} sx={{ position: "relative", height: 300, boxShadow: 1, mb: 2 }}>
                    <img
                        src={photo}
                        alt={`University Photo ${index + 1}`}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: 8,
                        }}
                    />
                </Box>
            ))}
        </Slider>
    );
}
