import {
	Collapse,
	Grid,
	Paper,
	TextField,
} from "@mui/material";

import PropTypes from "prop-types";
import React from "react";

function FilterDraftLeadCollapse(props) {
	const { searchFilters, handleSearchChange, filterBoxVisible } = props;

	return (
		<Collapse in={filterBoxVisible}>
			<Paper sx={{ p: 2, mb: 2 }}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={2}>
						<TextField
							label="University Name"
							variant="outlined"
							fullWidth
							value={searchFilters.name}
							onChange={handleSearchChange("name")}
						/>
					</Grid>
				</Grid>
			</Paper>
		</Collapse>
	);
}

FilterDraftLeadCollapse.propTypes = {
	searchFilters: PropTypes.object,
	handleSearchChange: PropTypes.func,
	filterBoxVisible: PropTypes.bool,
};

export default FilterDraftLeadCollapse;
