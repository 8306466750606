// FileUploader.js
import { Button } from "@mui/material"; // Import Button from MUI
import PropTypes from "prop-types";
import React, { useRef } from "react";

const FileUploader = ({ onFileChange }) => {
    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            onFileChange(file);
        }
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    return (
        <>
            {/* Hidden File Input */}
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }} // Hide the file input
                accept="image/*" // Optional: restrict to image files
            />
            {/* Visible Upload Button */}
            <Button variant="text" color="primary" sx={{ p: 2 }} onClick={triggerFileInput}>
                Change Profile Picture
            </Button>
        </>
    );
};

FileUploader.propTypes = {
    onFileChange: PropTypes.func.isRequired,
};

export default FileUploader;
