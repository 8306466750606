// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-container > main {
    background-color: rgb(249, 250, 251);
    padding-left: 0;
    padding-right: 0;
}

.dashboard-container {
    background-color: rgb(249, 250, 251);
}
`, "",{"version":3,"sources":["webpack://./src/styles/dashboard.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":[".dashboard-container > main {\n    background-color: rgb(249, 250, 251);\n    padding-left: 0;\n    padding-right: 0;\n}\n\n.dashboard-container {\n    background-color: rgb(249, 250, 251);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
