import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "../wrappers/fetchWrapper";

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/lead`;

const initialState = {
	action: "",
	leads: [],
	loading: false,
	error: null,
	message: null,
	status: "",
};

const name = "lead";

const leadSlice = createSlice({
	name: name,
	initialState,
	reducers: {
		// Optionally add some synchronous reducers if needed
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		clearMessage: (state) => {
			state.message = null;
		},
		clearError: (state) => {
			state.error = null;
		},
		clearLeadState: (state) => {
			state.leads = [];
			state.loading = false;
			state.error = null;
			state.message = null;
			state.status = "";
		},
		setStatus: (state, action) => {
			state.status = action.payload;
		},
		updateStudentInLead: (state, action) => {
			const updatedStudent = action.payload;

			state.leads = state.leads.map((lead) => {
				// Check if the lead has the student object with the matching ID
				if (lead.student && lead.student.id === updatedStudent.id) {
					return {
						...lead,
						student: { ...lead.student, ...updatedStudent },
					};
				}
				return lead;
			});
		},
		changeLeadState: (state, action) => {
			const { action: actionType, data } = action.payload;

			if (actionType === 'create') {
				// Add new lead to the list
				state.leads = [...state.leads, data];
			} else if (actionType === 'update') {
				// Update the existing lead
				const index = state.leads.findIndex(lead => lead.id === data.id);
				if (index !== -1) {
					state.leads[index] = data;
				}
			} else if (actionType === 'delete') {
				// Delete the lead by id
				state.leads = state.leads.filter(lead => lead.id !== data.id);
			}
		},
		setLeadsInStore: (state, action) => {
			state.leads = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder
			// Add Cred
			.addCase(addLead.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(addLead.fulfilled, (state, action) => {
				state.action = "addLead";
				state.loading = false;
				state.message = "Lead Added Successfully";
				state.leads = [...state.leads, action.payload];
			})
			.addCase(addLead.rejected, (state, action) => {
				state.action = "addLead";
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			})
			// Update Lead
			.addCase(updateLead.pending, (state) => {
				state.loading = true;
				state.action = "updateLead";
				state.error = null;
				state.message = null;
			})
			.addCase(updateLead.fulfilled, (state, action) => {
				state.loading = false;
				state.action = "";
				state.message = "Lead Updated Successfully";
				const index = state.leads.findIndex(
					(lead) => lead.id === action.payload.id
				);
				if (index !== -1) {
					state.leads[index] = action.payload;
				}
			})
			.addCase(updateLead.rejected, (state, action) => {
				state.loading = false;
				state.action = "";
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			})
			.addCase(fetchLeads.pending, (state) => {
				state.loading = true;
				state.status = 'loading';
				state.action = "fetchLeads";
				state.error = null;
				state.message = null;
			})
			.addCase(fetchLeads.fulfilled, (state, action) => {
				state.action = "";
				state.leads = action.payload;
				state.loading = false;
				state.status = 'succeeded';
			})
			.addCase(fetchLeads.rejected, (state, action) => {
				state.action = "";
				state.error = action.payload;
				state.loading = false;
				state.status = 'failed';
			})
			.addCase(deleteLead.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(deleteLead.fulfilled, (state, action) => {
				state.loading = false;
				state.message = "Lead Deleted Successfully";
				state.leads = state.leads.filter(
					(lead) => lead.id !== action.payload.id
				);
			})
			.addCase(deleteLead.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});

export const { changeLeadState, setLoading, clearError, clearMessage, clearLeadState, setLeadsInStore, setStatus, updateStudentInLead } = leadSlice.actions;
export const leadReducer = leadSlice.reducer;

export const addLead = createAsyncThunk(
	`${name}/addLead`,
	async (lead, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.post(`${baseUrl}/add`, lead);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const fetchLeads = createAsyncThunk(
	`${name}/fetchLeads`,
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.get(
				`${baseUrl}/fetch?timestamp=${new Date().getTime()}`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const updateLead = createAsyncThunk(
	`${name}/updateLead`,
	async ({ id, lead, uuid }, { rejectWithValue }) => {
		try {
			const payload = { ...lead, uuid };
			const response = await fetchWrapper.put(
				`${baseUrl}/update/${id}/`,
				payload
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const deleteLead = createAsyncThunk(
	`${name}/deleteLead`,
	async (id, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.delete(
				`${baseUrl}/delete/${id}`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
