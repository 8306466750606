import * as React from 'react';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import AddProgramDialog from '../Program/AddProgramDialog';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import ProgramTable from '../Program/ProgramTable';
import Snackbar from '@mui/material/Snackbar';
import { clearMessage } from '../../store/program.slice';
import { useEdvantageContext } from '../../EdvantageProvider';

const defaultTheme = createTheme();

function Program() {

    const dispatch = useDispatch();
    const programState = useSelector(state => state.program);
    const { programs, artifactLoading, uuid } = useEdvantageContext();

    const [openDialog, setOpenDialog] = React.useState(false);
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(clearMessage());
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" sx={{ backgroundColor: '#FFFFFF' }}>
                {programState.message &&
                    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={true} autoHideDuration={5000} onClose={handleCloseSnackbar}>
                        <Alert sx={{ width: '100%', mb: 2 }} severity="success">{programState.message}</Alert>
                    </Snackbar>
                }
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {programState.error && programState.action === 'updateProgram' && <Alert sx={{ width: '100%', mt: 2, mb: 2 }} severity="error">{programState.error}</Alert>}
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />} // Add icon before the text
                            onClick={handleOpenDialog}
                        >
                            Add Program
                        </Button>
                    </Box>
                    {programs.length ?
                        <ProgramTable programs={programs} programState={programState} uuid={uuid} /> :
                        <Alert sx={{ width: '100%', mt: 2, mb: 2 }} severity="warning">No Programs Found! Add a program</Alert>
                    }
                    <AddProgramDialog open={openDialog} setOpenDialog={setOpenDialog} programState={programState} uuid={uuid} />
                </Box>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                    open={artifactLoading || programState.loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        </ThemeProvider>
    );
}

export default Program;
