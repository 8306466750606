import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import NavBar from './Home/NavBar';
import Hero from './Home/Hero';
import LogoCollection from './Home/LogoCollection';
import Highlights from './Home/Highlights';
import Pricing from './Home/Pricing';
import Features from './Home/Features';
import Testimonials from './Home/Testimonials';
import FAQ from './Home/FAQ';
import Footer from './Home/Footer';

function Home() {
    const [mode, setMode] = React.useState('light');
    const defaultTheme = createTheme({ palette: { mode } });

    const toggleColorMode = () => {
        setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <NavBar mode={mode} toggleColorMode={toggleColorMode} />
            <Hero />
            <Box sx={{ bgcolor: 'background.default' }}>
                <LogoCollection />
                <Features />
                <Divider />
                <Testimonials />
                <Divider />
                <Highlights />
                <Divider />
                <Pricing />
                <Divider />
                <FAQ />
                <Divider />
                <Footer />
            </Box>
        </ThemeProvider>
    );
}

export default Home;
