import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "../wrappers/fetchWrapper";

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/school`;

const initialState = {
    action: "",
    schools: [],
    loading: false,
    error: null,
    message: null,
    status: "idle",
};

const name = "school";

const schoolSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        // Optionally add some synchronous reducers if needed
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        clearMessage: (state) => {
            state.message = null;
        },
        clearSchoolState: (state) => {
            state.schools = [];
            state.loading = false;
            state.error = null;
            state.message = null;
            state.status = "idle";
        },
        changeSchoolState: (state, action) => {
            const { action: actionType, data } = action.payload;

            if (actionType === "create") {
                // Add new programs to the list
                state.schools = [...state.schools, data];
            } else if (actionType === "update") {
                // Update the existing program
                state.schools = state.schools.map(school => school.id === data.id ? data : school);
            } else if (actionType === "delete") {
                // Delete the program by id
                state.schools = state.schools.filter(school => school.id !== data.id);
            } else if (actionType === 'create_courses') {
                // Add new course to the university
                const schoolIndex = state.schools.findIndex(
                    (school) => school.id === data.school
                );

                if (schoolIndex !== -1) {
                    // Use the spread operator to create a new array with the updated university
                    state.schools = state.schools.map(
                        (school, index) =>
                            index === schoolIndex
                                ? {
                                    ...school,
                                    courses: [
                                        ...school.courses,
                                        data,
                                    ],
                                }
                                : school
                    );
                }
            } else if (actionType === 'update_courses') {
                // Update the existing course
                state.schools = state.schools.map((school) => {
                    const courseIndex = school.courses.findIndex((course) => course.id === data.id);
                    if (courseIndex !== -1) {
                        return {
                            ...school,
                            courses: [
                                ...school.courses.slice(0, courseIndex),
                                data,
                                ...school.courses.slice(courseIndex + 1),
                            ],
                        };
                    }
                    return school;
                });
            } else if (actionType === 'delete_courses') {
                // Delete the course by id
                state.schools = state.schools.map((school) => {
                    const courseIndex = school.courses.findIndex((course) => course.id === data.id);
                    if (courseIndex !== -1) {
                        return {
                            ...school,
                            courses: [
                                ...school.courses.slice(0, courseIndex),
                                ...school.courses.slice(courseIndex + 1),
                            ],
                        };
                    }
                    return school;
                });
            }
        }
    },
    extraReducers: (builder) => {
        builder
            // Add Cred
            .addCase(addSchool.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(addSchool.fulfilled, (state, action) => {
                state.action = "addSchool";
                state.loading = false;
                state.message = "School Added Successfully";
                state.schools.push(action.payload);
            })
            .addCase(addSchool.rejected, (state, action) => {
                state.action = "addSchool";
                state.loading = false;
                if (Array.isArray(action.payload)) {
                    state.error = action.payload;
                } else if (typeof action.payload === "object") {
                    state.error = ["Unknown Error!"];
                } else {
                    state.error = action.payload;
                }
            })
            // Update Cred
            .addCase(updateSchool.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(updateSchool.fulfilled, (state, action) => {
                state.loading = false;
                state.message = "School Updated Successfully";
                const index = state.schools.findIndex(
                    (school) => school.id === action.payload.id
                );
                if (index !== -1) {
                    state.schools[index] = action.payload;
                }
            })
            .addCase(updateSchool.rejected, (state, action) => {
                state.loading = false;
                if (Array.isArray(action.payload)) {
                    state.error = action.payload;
                } else if (typeof action.payload === "object") {
                    state.error = ["Unknown Error!"];
                } else {
                    state.error = action.payload;
                }
            })
            .addCase(updateSchoolCourse.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(updateSchoolCourse.fulfilled, (state, action) => {
                state.loading = false;
                state.message = "Course Updated Successfully";

                const school = state.schools.find(
                    (school) => school.id === action.payload.school
                );

                if (school) {
                    const course = school.courses.find(
                        (course) => course.id === action.payload.id
                    );

                    if (course) {
                        Object.assign(course, action.payload);
                    }
                }
            })
            .addCase(updateSchoolCourse.rejected, (state, action) => {
                state.loading = false;
                if (Array.isArray(action.payload)) {
                    state.error = action.payload;
                } else if (typeof action.payload === "object") {
                    state.error = ["Unknown Error!"];
                } else {
                    state.error = action.payload;
                }
            })
            .addCase(addSchoolCourses.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(addSchoolCourses.fulfilled, (state, action) => {
                state.loading = false;
                state.message = "Course Added Successfully";

                // Find the index of the school to update
                const schoolIndex = state.schools.findIndex(
                    (school) => school.id === action.payload.school
                );

                if (schoolIndex !== -1) {
                    // Use the spread operator to create a new array with the updated school
                    state.schools = state.schools.map(
                        (school, index) =>
                            index === schoolIndex
                                ? {
                                    ...school,
                                    courses: [
                                        ...school.courses,
                                        ...action.payload.courses,
                                    ],
                                }
                                : school
                    );
                }
            })
            .addCase(addSchoolCourses.rejected, (state, action) => {
                state.loading = false;
                if (Array.isArray(action.payload)) {
                    state.error = action.payload;
                } else if (typeof action.payload === "object") {
                    state.error = ["Unknown Error!"];
                } else {
                    state.error = action.payload;
                }
            })
            .addCase(fetchSchools.pending, (state) => {
                state.loading = true;
                state.status = "loading";
                state.error = null;
                state.message = null;
            })
            .addCase(fetchSchools.fulfilled, (state, action) => {
                state.loading = false;
                state.status = "succeeded";
                state.schools = action.payload;
            })
            .addCase(fetchSchools.rejected, (state, action) => {
                state.loading = false;
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(deleteSchool.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(deleteSchool.fulfilled, (state, action) => {
                state.loading = false;
                state.message = "School Deleted Successfully";
                state.schools = state.schools.filter(
                    (repo) => repo.id !== action.payload.id
                );
            })
            .addCase(deleteSchool.rejected, (state, action) => {
                state.loading = false;
                if (Array.isArray(action.payload)) {
                    state.error = action.payload;
                } else if (typeof action.payload === "object") {
                    state.error = ["Unknown Error!"];
                } else {
                    state.error = action.payload;
                }
            })
            .addCase(deleteSchoolCourse.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(deleteSchoolCourse.fulfilled, (state, action) => {
                state.loading = false;
                state.message = "Course Deleted Successfully";

                // Find the index of the school to update
                const schoolIndex = state.schools.findIndex(
                    (school) => school.id === action.payload.school
                );

                if (schoolIndex !== -1) {
                    // Use the spread operator to create a new array with the updated school
                    state.schools = state.schools.map(
                        (school, index) =>
                            index === schoolIndex
                                ? {
                                    ...school,
                                    courses: school.courses.filter(
                                        (course) =>
                                            course.id !== action.payload.id
                                    ),
                                }
                                : school
                    );
                }
            })
            .addCase(deleteSchoolCourse.rejected, (state, action) => {
                state.loading = false;
                if (Array.isArray(action.payload)) {
                    state.error = action.payload;
                } else if (typeof action.payload === "object") {
                    state.error = ["Unknown Error!"];
                } else {
                    state.error = action.payload;
                }
            });
    },
});

export const { setLoading, clearMessage, clearSchoolState, changeSchoolState } =
    schoolSlice.actions;
export const schoolReducer = schoolSlice.reducer;

export const addSchool = createAsyncThunk(
    `${name}/addSchool`,
    async (school, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.post(
                `${baseUrl}/add`,
                school
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const fetchSchools = createAsyncThunk(
    `${name}/fetchSchools`,
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.get(
                `${baseUrl}/fetch?timestamp=${new Date().getTime()}`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateSchool = createAsyncThunk(
    `${name}/updateSchool`,
    async ({ id, school }, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.put(
                `${baseUrl}/update_school/${id}/`,
                school
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateSchoolCourse = createAsyncThunk(
    `${name}/updateSchoolCourse`,
    async ({ id, course }, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.put(
                `${baseUrl}/update_course/${id}/`,
                course
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const addSchoolCourses = createAsyncThunk(
    `${name}/addSchoolCourses`,
    async ({ school_id, courses }, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.post(
                `${baseUrl}/add_courses/${school_id}/`,
                courses
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteSchool = createAsyncThunk(
    `${name}/deleteSchool`,
    async (id, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.delete(
                `${baseUrl}/delete/${id}/`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteSchoolCourse = createAsyncThunk(
    `${name}/deleteSchoolCourse`,
    async (id, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.delete(
                `${baseUrl}/delete_course/${id}/`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
