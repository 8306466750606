import React from 'react'
import { IconButton, TextField, Typography } from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import { useState } from 'react';

function IntakeCell({ params }) {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(params.value);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        setIsEditing(false);
        console.log('Saving: ' + value);
        // Update the data here with the new value
        // params.api.updateRows([{ id: params.id, major: value }]);
    };

    return isEditing ? (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <TextField
                fullWidth
                value={value}
                onChange={(e) => setValue(e.target.value)}
                size="small"
                variant="outlined"
            />
            <IconButton onClick={handleSaveClick}>
                <SaveIcon />
            </IconButton>
        </div>
    ) : (
        <Typography
            sx={{
                color: '#5b5959',
                lineHeight: 'calc(var(--height) - 1px)',
                fontFamily: '"Public Sans", sans-serif',
                fontWeight: 600,
                fontSize: '0.875rem',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
            }}
        >
            {params.value}
        </Typography>
    );
}

export default IntakeCell;
