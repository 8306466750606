import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "../wrappers/fetchWrapper";

const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/groups`;
const SLICE_NAME = "group";

const initialState = {
	action: "",
	groups: [],
	loading: false,
	error: null,
	message: null,
	status: 'idle',
}; 

const groupSlice = createSlice({
	name: SLICE_NAME,
	initialState,
	reducers: {
		// Optionally add some synchronous reducers if needed
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		clearMessage: (state) => {
			state.message = null;
		},
		clearError: (state) => {
			state.error = null;
		},
		clearGroupState: (state) => {
			state.groups = [];
			state.loading = false;
			state.error = null;
			state.message = null;
			state.status = 'idle';
		},
		updateGroupStatus: (state, action) => {
			const { id, status } = action.payload;
			const userIndex = state.groups.findIndex(
				(groups) => groups.id === id
			);
			if (userIndex !== -1) {
				state.groups[userIndex].status = status;
			}
		}
	},
	extraReducers: (builder) => {
		builder

			// FETCH employee users
			.addCase(fetchGroups.pending, (state) => {
				state.action = "fetchGroups";
				state.status = 'loading';
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(fetchGroups.fulfilled, (state, action) => {
				state.action = "fetchGroups";
				state.status = 'succeeded';
				state.loading = false;
				state.groups = action.payload;
			})
			.addCase(fetchGroups.rejected, (state, action) => {
				state.action = "fetchGroups";
				state.status = 'failed';
				state.loading = false;
				state.error = action.payload;
			})
	},
});

export const {
	setLoading,
	clearMessage,
	clearError,
	clearGroupState,
	updatGroupStatus,
} = groupSlice.actions;

export const groupReducer = groupSlice.reducer;

///////
// THUNKS

// Fetch groups
export const fetchGroups = createAsyncThunk(
	`${SLICE_NAME}/fetchGroups`,
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.get(`${BASE_URL}/fetch`);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

