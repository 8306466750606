import { logout } from "../store/auth.slice";
import { store } from "../store";
import { isJsonString } from "../utils/Utils"

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
};

const defaultConfigurationOptions = {
    refresh_token_on_error: true,
    credentials: true,
    headers: {}
}

function request(method) {
    let count_retries = 0
    return async (url, body, options = {}) => {
        // request custom options
        const confOptions = {
            ...defaultConfigurationOptions,
            ...options
        }
        // fetch request options
        const requestOptions = {
            method,
            headers: {
                ...confOptions.headers //set headers
            },
        };

        // Check body and set Content-Type accordingly
        if (body instanceof FormData) {
            requestOptions.body = body;
            delete requestOptions.headers['Content-Type']; // Explicitly remove Content-Type header
        } else if (body) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
        }

        // check credentials
        if (confOptions.credentials) {
            requestOptions.credentials = 'include';
        }
        let response = await fetch(url, requestOptions);
        // when unauthorized refresh token and try again
        if (confOptions.refresh_token_on_error == true && !response.ok && [401, 403].includes(response.status)) {
            await fetchWrapper.get(`${process.env.REACT_APP_BASE_API_URL}/employee/token/refresh`, null, { refresh_token_on_error: false })
            // retry with refreshed tokens
            response = await fetch(url, requestOptions, { refresh_token_on_error: false });
        }
        // increase retry
        count_retries = count_retries + 1
        return handleResponse(response);
    }
}

// helper functions
// Deprecated - Authentication based on cookies
//function authHeader(url) {
//    // return auth header with jwt if user is logged in and request is to the api url
//    const token = authToken();
//    const isLoggedIn = !!token;
//    const isApiUrl = url.startsWith(process.env.REACT_APP_BASE_API_URL);
//    if (isLoggedIn && isApiUrl) {
//        return { Authorization: `Token ${token}` };
//    } else {
//        return {};
//    }
//}

// DEPRECATED - Authentication based on cookies
//function authToken() {
//    return store.getState().auth.employee?.token;
//}

function handleResponse(response) {
    return response.text().then(text => {
        let data = {}
        if (isJsonString(text)) {
            data = text && JSON.parse(text);
        } else {
            data = { error: "Error parsing response" };
        }
        //console.log(response)
        if (!response.ok) {
            if ([401, 403].includes(response.status) &&
                response.url.toLowerCase().indexOf("/logout") === -1 &&
                response.url.toLowerCase().indexOf("/refresh") === -1
            ) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                store.dispatch(logout());
            }

            const error = (data && data.error) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
