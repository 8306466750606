import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "../wrappers/fetchWrapper";

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/service`;

const initialState = {
	services: [],
	loading: false,
	message: null,
	error: null,
};

const name = "service";

const serviceSlice = createSlice({
	name: name,
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		clearMessage: (state) => {
			state.message = null;
		},
		clearServiceState: (state) => {
			state.services = [];
			state.loading = false;
			state.error = null;
		},
		setServices: (state, action) => {
			state.services = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchServices.pending, (state) => {
				state.loading = true;
				state.message = null;
				state.error = null;
			})
			.addCase(fetchServices.fulfilled, (state, action) => {
				state.loading = false;
				state.message = "Services fetched successfully";
				state.services = action.payload;
			})
			.addCase(fetchServices.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(addService.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(addService.fulfilled, (state, action) => {
				state.loading = false;
				state.message = "Service added successfully";
				state.services = [...state.services, ...action.payload];
			})
			.addCase(addService.rejected, (state, action) => {
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			})
			.addCase(deleteService.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(deleteService.fulfilled, (state, action) => {
				state.loading = false;
				state.message = "Service deleted successfully";
				state.services = state.services.filter(
					(service) => service.id !== action.payload.id
				);
			})
			.addCase(deleteService.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
            .addCase(updateService.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(updateService.fulfilled, (state, action) => {
                state.loading = false;
                state.message = "Service updated successfully";
                state.services = state.services.map((service) =>
                    service.id === action.payload.id ? action.payload : service
                );
            })
            .addCase(updateService.rejected, (state, action) => {
                state.loading = false;
                if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
            })
            ;
	},
});

export const { clearMessage, clearServiceState, setLoading, setServices } =
	serviceSlice.actions;
export const serviceReducer = serviceSlice.reducer;

export const fetchServices = createAsyncThunk(
	`${name}/fetchServices`,
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.get(
				`${baseUrl}/fetch?timestamp=${new Date().getTime()}`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const addService = createAsyncThunk(
	`${name}/addService`,
	async (service, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.post(`${baseUrl}/add`, service);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const updateService = createAsyncThunk(
	`${name}/updateService`,
	async ({id, service}, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.put(
				`${baseUrl}/update/${id}/`,
				service
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
        }
    }
);

export const deleteService = createAsyncThunk(
	`${name}/deleteService`,
	async (id, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.delete(
				`${baseUrl}/delete/${id}`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
