// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.commentText {
    margin: 0.25em 0 0.5em;
    font-size: 1em !important;
    word-wrap: break-word;
    color: rgba(0, 0, 0, 0.87);
    line-height: 1.3;
}

.commentMetadata {
	color: rgba(0,0,0,.4);
	font-size: .875em !important;
	margin-left: 8px !important;
	display: inline;
}

.commentAuthor {
	display: inline;
	font-size: 1em !important;
    color: rgba(0, 0, 0, .87);
    font-weight: 700 !important;
}

.commentAuthor:hover {
	text-decoration: underline;
	color: #4183c4;
}
`, "",{"version":3,"sources":["webpack://./src/styles/CommentSection.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,yBAAyB;IACzB,qBAAqB;IACrB,0BAA0B;IAC1B,gBAAgB;AACpB;;AAEA;CACC,qBAAqB;CACrB,4BAA4B;CAC5B,2BAA2B;CAC3B,eAAe;AAChB;;AAEA;CACC,eAAe;CACf,yBAAyB;IACtB,yBAAyB;IACzB,2BAA2B;AAC/B;;AAEA;CACC,0BAA0B;CAC1B,cAAc;AACf","sourcesContent":[".commentText {\n    margin: 0.25em 0 0.5em;\n    font-size: 1em !important;\n    word-wrap: break-word;\n    color: rgba(0, 0, 0, 0.87);\n    line-height: 1.3;\n}\n\n.commentMetadata {\n\tcolor: rgba(0,0,0,.4);\n\tfont-size: .875em !important;\n\tmargin-left: 8px !important;\n\tdisplay: inline;\n}\n\n.commentAuthor {\n\tdisplay: inline;\n\tfont-size: 1em !important;\n    color: rgba(0, 0, 0, .87);\n    font-weight: 700 !important;\n}\n\n.commentAuthor:hover {\n\ttext-decoration: underline;\n\tcolor: #4183c4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
