import React from 'react';
import Typography from '@mui/material/Typography';

export const highlightMentions = (content, mentions) => {
    if (!mentions.length) return content;

    const parts = [];
    let cursor = 0;

    mentions.forEach((mention) => {
        if (cursor < mention.start_index) {
            parts.push(content.slice(cursor, mention.start_index)); // Add plain text before mention
        }

        // Add mention with custom styling
        parts.push(
            <Typography
                key={mention.start_index}
                component="span"
                sx={{
                    backgroundColor: "#1877f233",
                    color: "primary.main",
                    fontWeight: 600,
                }}
            >
                {content.slice(mention.start_index, mention.end_index)}
            </Typography>
        );
        cursor = mention.end_index;
    });

    // Add the remaining plain text after the last mention
    if (cursor < content.length) {
        parts.push(content.slice(cursor));
    }

    // Use React.Fragment to prevent unnecessary DOM nodes
    return <>{parts}</>;
};
