import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../wrappers/fetchWrapper';
import { history } from '../helper/history'; // Assuming history is exported from this file

const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/employee`;

const SLICE_NAME = 'auth';

const initialState = {
    action: "",
    employee: null,
    error: null,
    loading: false,
    message: null,
    status: 'idle',
};

const slice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        logoutSuccess(state) {
        },
        logoutFailure(state, action) {
        },
    },
    extraReducers: (builder) => {

        // LOGIN EXTRA REDUCER
        builder.addCase(login.pending, (state) => {
            state.action = "login";
            state.loading = true;
            state.error = null;
            state.message = null;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            const employee = action.payload;
            // add current employee info on storage
            //localStorage.setItem('employee', JSON.stringify(employee));
            state.employee = employee;
            state.action = "login";
            state.loading = false;
            state.message = "Login Successfully";
            const { from } = history.location.state || { from: { pathname: '/dashboard' } };
            history.navigate(from);
        });
        builder.addCase(login.rejected, (state, action) => {
            state.action = "login";
            state.loading = false;
            state.error = action.payload;
            state.message = "Login Error";
        });

        // LOGOUT EXTRA REDUCER
        builder.addCase(logout.pending, (state) => {
            state.action = "logout";
            state.loading = true;
            state.error = null;
            state.message = null;
        });
        builder.addCase(logout.fulfilled, (state, action) => {
            // clean storage
            //localStorage.removeItem('employee');
            state.employee = null;
            state.action = "logout";
            state.loading = false;
            state.message = "Logout Successfully";
            history.location.state = { message: 'You have been logged out successfully!' };
        });
        builder.addCase(logout.rejected, (state, action) => {
            console.log(`Failed to logout => ${action.payload}`); // You can provide a more descriptive error message
            state.action = "login";
            state.loading = false;
            state.error = action.error;
            state.message = "Login Error";
        });

        // LOAD PROFILE EXTRA REDUCER
        builder.addCase(reloadProfile.pending, (state) => {
            state.action = "reloadProfile";
            state.loading = true;
            state.error = null;
            state.message = null;
        });
        builder.addCase(reloadProfile.fulfilled, (state, action) => {
            const employee = action.payload;
            //const storageEmployee = JSON.parse(localStorage.getItem('employee'))
            //storageEmployee.profile = employee
            //localStorage.setItem('employee', JSON.stringify(storageEmployee));
            state.employee = { profile: employee };
            state.action = "reloadProfile";
            state.loading = false;
            state.message = "reloadProfile Successfully";
        });
        builder.addCase(reloadProfile.rejected, (state, action) => {
            state.action = "reloadProfile";
            state.loading = false;
            state.message = "reloadProfile Error";
            console.log(action)
            if (Array.isArray(action.payload)) {
                state.error = action.payload;
            } else if (typeof action.payload === "object") {
                state.error = ["Unknown Error!"];
            } else {
                state.error = action.payload;
            }
        });
    }
});

export const {
    logoutSuccess,
    logoutFailure
} = slice.actions;

export const authReducer = slice.reducer;

///////
// THUNKS

// Login
export const login = createAsyncThunk(
    `${SLICE_NAME}/login`,
    async ({ email, password }, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.post(`${BASE_URL}/login`, { email, password });
            return response.data;
        } catch (error) {
            return rejectWithValue({ 'message': error });
        }
    }
);

// Logou
export const logout = createAsyncThunk(
    `${SLICE_NAME}/logout`,
    async () => {
        try {
            await fetchWrapper.post(`${BASE_URL}/logout`);
            return; // No need to return data for logout
        } catch (error) {
            console.log(`Failed to logout => ${error}`); // You can provide a more descriptive error message
        }
    }
);

// reload user profile
export const reloadProfile = createAsyncThunk(
    `${SLICE_NAME}/reloadProfile`,
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.get(
                `${BASE_URL}/me`
            );
            return response.data;
        } catch (error) {
            console.log(error)
            return rejectWithValue(error);
        }
    }
);

