import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../wrappers/fetchWrapper';

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/currency`;

const initialState = {
    currencies: [],
    loading: false,
    error: null,
};

const name = "currency"

const currencySlice = createSlice({
    name: name,
    initialState,
    reducers: {
        clearCurrencyState: (state) => {
            state.currencies = [];
            state.loading = false;
            state.error = null;
        },
        setCurrencies: (state, action) => {
            state.currencies = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCurrencies.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCurrencies.fulfilled, (state, action) => {
                state.loading = false;
                state.currencies = action.payload;
            })
            .addCase(fetchCurrencies.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const { clearCurrencyState, setCurrencies } = currencySlice.actions;
export const currencyReducer = currencySlice.reducer;

export const fetchCurrencies = createAsyncThunk(
    `${name}/fetchCurrencies`,
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.get(`${baseUrl}/fetch?timestamp=${new Date().getTime()}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
