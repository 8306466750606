import { useDispatch, useSelector } from "react-redux";

import { fetchUsers } from "../store/user.slice";
import { useEffect } from "react";

function useUser() {
	const dispatch = useDispatch();

	const users = useSelector((state) => state.user.users);
	const userStatus = useSelector((state) => state.user.status);

	useEffect(() => {
		async function onLoad() {
			if (userStatus === "idle") {
				dispatch(fetchUsers());
			}
		}

		onLoad();
	}, [userStatus, dispatch]);

	return {
		users,
	};
}

export default useUser;
