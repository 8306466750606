import {
    Alert,
    FormControlLabel,
    Grid,
    Switch
} from "@mui/material";
import { generateMenuItems, isValidGPA } from "../../utils/Utils";
import { useDispatch, useSelector } from "react-redux";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { GRADE_SYSTEM_CHOICES } from '../../const';
import React from 'react';
import TextField from '@mui/material/TextField';
import { updateLead } from "../../store/lead.slice";
import { useEdvantageContext } from "../../EdvantageProvider";

/**
 * A dialog to edit the basic information of a lead.
 *
 * @param {Object} props component props
 * @param {boolean} props.open whether the dialog is open or not
 * @param {Function} props.onClose callback function to close the dialog
 * @param {Object} props.lead the lead object to edit
 *
 * @returns {React.Component} the edit lead information dialog component
 */
const EditEligibilityDialog = (props) => {
    const { open, onClose, lead } = props;

    const dispath = useDispatch();
    const { uuid } = useEdvantageContext();
    const leadState = useSelector((state) => state.lead);

    const initialData = {
        grade_system: lead.grade_system || '',
        gpa: lead.gpa || '',
        toefl_score: lead.toefl_score || '',
        ielts_score: lead.ielts_score || '',
        has_sponsorship: lead.has_sponsorship === undefined ? false : lead.has_sponsorship,
        sponsorship_details: lead.sponsorship_details || '',
    };

    // Local state to manage form input
    const [formData, setFormData] = React.useState(initialData);
    const [formErrors, setFormErrors] = React.useState({});
    const [isFormValid, setIsFormValid] = React.useState(true);

    const isFormUnchanged =
        formData.grade_system === initialData.grade_system &&
        formData.gpa === initialData.gpa &&
        formData.consultation_type === initialData.consultation_type &&
        formData.toefl_score === initialData.toefl_score &&
        formData.ielts_score === initialData.ielts_score &&
        formData.has_sponsorship === initialData.has_sponsorship &&
        formData.sponsorship_details === initialData.sponsorship_details;

    // Handle form change
    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    React.useEffect(() => {
        const errors = validateEligibilityForm();
        setIsFormValid(Object.keys(errors).length === 0);
    }, [formData]);

    // Handle save action
    const handleSave = () => {
        const updatedLeadData = {
            ...formData,
            toefl_score: formData.toefl_score
                ? parseInt(formData.toefl_score, 10)
                : null,
            ielts_score: formData.ielts_score
                ? parseInt(formData.ielts_score, 10)
                : null,
        }

        dispath(updateLead({ id: lead.id, lead: updatedLeadData, uuid })).then(
            (response) => {
                if (response.type === "lead/updateLead/fulfilled") {
                    onClose();
                }
            }
        );
    };

    const validateEligibilityForm = () => {
        const errors = {};
        if (!formData.grade_system)
            errors.grade_system = "Grade system is required";
        if (
            formData.gpa === undefined ||
            formData.gpa === null ||
            formData.gpa === ""
        ) {
            errors.gpa = "GPA is required";
        } else if (!isValidGPA(formData.gpa, formData.grade_system)) {
            errors.gpa = "GPA is not valid for the selected grading scale";
        }

        setFormErrors(errors);
        return errors;
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Edit Student Eligibility</DialogTitle>
            <DialogContent>
                {leadState.error && (
                    <Alert
                        sx={{ width: "100%", mt: 2, mb: 2 }}
                        severity="error"
                    >
                        {leadState.error}
                    </Alert>
                )}
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            name="grade_system"
                            label="Grade System"
                            margin="dense"
                            value={formData.grade_system}
                            onChange={handleChange}
                            error={Boolean(formErrors.grade_system)}
                            helperText={formErrors.grade_system}
                            required
                            fullWidth
                        >
                            {generateMenuItems(GRADE_SYSTEM_CHOICES)}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="gpa"
                            label="GPA"
                            type="text"
                            margin="dense"
                            value={formData.gpa}
                            onChange={handleChange}
                            error={Boolean(formErrors.gpa)}
                            helperText={formErrors.gpa}
                            disabled={!formData.grade_system}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="toefl_score"
                            label="TOEFL Score"
                            type="number"
                            margin="dense"
                            value={formData.toefl_score}
                            onChange={handleChange}
                            error={Boolean(formErrors.toefl_score)}
                            helperText={formErrors.toefl_score}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="ielts_score"
                            label="IELTS Score"
                            type="number"
                            margin="dense"
                            value={formData.ielts_score}
                            onChange={handleChange}
                            error={Boolean(formErrors.ielts_score)}
                            helperText={formErrors.ielts_score}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formData.has_sponsorship}
                                    onChange={handleChange}
                                    name="has_sponsorship"
                                    color="primary"
                                />
                            }
                            labelPlacement="start"
                            label="Has Sponsorship?"
                            sx={{ ml: 0 }}
                        />
                    </Grid>
                    {formData.has_sponsorship && (
                        <Grid item xs={12}>
                            <TextField
                                name="sponsorship_details"
                                label="Sponsorship Details"
                                margin="dense"
                                value={formData.sponsorship_details}
                                onChange={handleChange}
                                error={Boolean(formErrors.sponsorship_details)}
                                helperText={formErrors.sponsorship_details}
                                fullWidth
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions sx={{ pr: 3, pb: 2 }}>
                <Button onClick={onClose} variant='contained' color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSave} variant='contained' color="primary" disabled={isFormUnchanged || !isFormValid}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditEligibilityDialog;
