import {
	Box,
	Button,
	Card,
	CardContent,
	Link,
	Typography,
} from "@mui/material";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CoursesSection from "./CoursesSection";
import EditIcon from "@mui/icons-material/Edit";
import EditUniversityDialog from "./EditUniversityDialog";
import PageNotFound from "../common/PageNotFound";
import React from "react";
import UniversitySlider from "./UniversitySlider";
import { makeSelectUniversityById } from "../../selectors/universitySelectors";
import { useSelector } from "react-redux";

/**
 * A component that displays the details of a university.
 *
 * This component is used by the School component to display the details of a university.
 * It shows the university's name, address, brochure URL, QS World Ranking, and Google Maps URL.
 * It also includes a button to edit the university's details and a slider to display the university's photos.
 *
 * @param {Number} universityId The ID of the university to display.
 * @param {Function} setUrlParams A function to set the URL parameters.
 * @param {Function} onBack A function to go back to the previous page.
 */
export default function UniversityDetails({ uuid, universityId, setUrlParams, onBack }) {
	const selectUniversity = React.useMemo(
		() => makeSelectUniversityById(universityId),
		[universityId]
	);
	const university = useSelector(selectUniversity);

	const [openEditUniversityDialog, setOpenEditUniversityDialog] =
		React.useState(false);

	const handleEditUniversity = () => {
		setOpenEditUniversityDialog(true);
	};

	return (
		<Box sx={{ padding: 2 }}>
			<Button
				variant="contained"
				onClick={onBack}
				startIcon={<ArrowBackIosIcon />}
				sx={{ mt: 2, mb: 2 }}
			>
				Go Back
			</Button>
			{university ? (
				<React.Fragment>
					{university.photos.length > 0 ? (
						<UniversitySlider photos={university.photos} />
					) : (<Box
						component="img"
						src={`/static/images/university.png`}
						alt={`${university.name} Banner`}
						sx={{
							width: "100%",
							height: "auto",
							maxHeight: 300,
							objectFit: "cover",
							borderRadius: 2,
							boxShadow: 1,
							mb: 2,
						}}
					/>)}
					<Card sx={{ maxWidth: "100%", mb: 2, position: "relative" }}>
						<Button
							variant="contained"
							startIcon={<EditIcon />}
							color="primary"
							sx={{ position: "absolute", top: 8, right: 8 }}
							onClick={handleEditUniversity}
						>
							Edit
						</Button>
						<CardContent>
							<Typography variant="h5" component="div">
								{university.name}
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								<strong>Address: </strong>
								{university.address.address_line_1},{" "}
								{university.address.address_line_2},{" "}
								{university.address.city} -{" "}
								{university.address.postal_code},{" "}
								{university.address.country}
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								<strong>Google Maps URL:</strong>{" "}
								<Link
									href={university.google_maps_url}
									target="_blank"
									rel="noreferrer"
								>
									{university.google_maps_url}
								</Link>
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								<strong>Brochure:</strong>{" "}
								<Link
									href={university.brochure_url}
									target="_blank"
									rel="noreferrer"
								>
									{university.brochure_url}
								</Link>
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								<strong>World Rank:</strong>{" "}
								{university.qs_world_ranking}
							</Typography>
						</CardContent>
					</Card>
					<CoursesSection
						universityId={universityId}
						courses={university.courses}
					/>
					<EditUniversityDialog
						open={openEditUniversityDialog}
						handleClose={setOpenEditUniversityDialog}
						university={university}
						uuid={uuid}
					/>
				</React.Fragment>
			) : (
				<PageNotFound message="University not found!" setUrlParams={setUrlParams} />
			)}
		</Box>
	);
}
