import * as React from "react";

import { Chip, Link, Typography } from "@mui/material";

import ActionsPopover from "./ActionsPopover";
import { DataGrid } from "@mui/x-data-grid";
import { GENDER_DETAILS } from "../../const";

function getColumns(handleEdit, handleDelete) {
	return [
		{
			field: "actions",
			headerName: "",
			flex: 1,
			minWidth: 100,
			renderCell: (params) => (
				<ActionsPopover
					handleEdit={() => handleEdit(params.row)}
					handleDelete={() => handleDelete(params.row)}
				/>
			),
		},
		{ field: "id", headerName: "ID", flex: 1 },
		{
			field: "fullName",
			headerName: "Full name",
			description: "This column has a value getter and is not sortable.",
			sortable: true,
			flex: 2,
			minWidth: 200,
			valueGetter: (value, row) =>
				`${row.user.first_name || ""} ${row.user.last_name || ""}`,
			renderCell: (params) => (
				<Typography
					sx={{
						color: "#5b5959",
						lineHeight: "calc(var(--height) - 1px)",
						fontFamily: '"Public Sans", sans-serif',
						fontWeight: 600,
						fontSize: "0.875rem",
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
					}}
				>
					{params.value}
				</Typography>
			),
		},
		{
			field: "dob",
			headerName: "DOB",
			flex: 1,
			minWidth: 150,
			renderCell: (params) => {
				const date = new Date(params.value);
				const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11, so we add 1
				const day = String(date.getDate()).padStart(2, "0");
				const year = date.getFullYear();
				return `${month}/${day}/${year}`;
			},
		},
		{
			field: "gender",
			headerName: "Gender",
			flex: 1,
			minWidth: 120,
			renderCell: (params) => {
				const gender = params.value || "Unknown";
				return (
					<Chip
						avatar={GENDER_DETAILS[gender].avatar}
						label={gender}
						variant="outlined"
						size="small"
						style={{
							color: GENDER_DETAILS[gender].color,
							borderColor: GENDER_DETAILS[gender].color,
						}}
					/>
				);
			},
		},
		{
			field: "email",
			headerName: "Email",
			flex: 2,
			minWidth: 200,
			valueGetter: (value, row) => row.user.email,
		},
		{
			field: "phone_number",
			headerName: "Phone Number",
			flex: 2,
			minWidth: 200,
			renderCell: (params) => {
				const phoneNumber = `${params.row.calling_code}${params.row.phone_number}`;
				const whatsappUrl = `https://wa.me/${phoneNumber.replace(
					/\D/g,
					""
				)}`;

				return (
					<Link
						href={whatsappUrl}
						target="_blank"
						rel="noopener noreferrer"
						underline="hover"
					>
						{phoneNumber}
					</Link>
				);
			},
		},
		{
			field: "school",
			headerName: "School",
			flex: 2,
			minWidth: 200,
		},
		{
			field: "city",
			headerName: "City",
			flex: 1,
			minWidth: 100,
			valueGetter: (value, row) => row.address?.city,
		},
		{
			field: "country",
			headerName: "Country",
			flex: 1,
			minWidth: 100,
			valueGetter: (value, row) => row.address?.country,
		},
		{
			field: "status",
			headerName: "Status",
			flex: 2,
			minWidth: 200,
			renderCell: (params) => (
				<Chip
					label={params.value}
					variant="contained"
					size="small"
					sx={{
						backgroundColor: getStatusBackgroundColor(params.value),
						color: "#FFFFFF",
					}}
				/>
			),
		},
		{
			field: "updated_at",
			headerName: "Last Updated",
			flex: 2,
			minWidth: 200,
			type: "dateTime", // Adjust if using plain dates
			valueGetter: (value, row) => new Date(row.updated_at),
		},
	];
}

function getStatusBackgroundColor(status) {
	switch (status) {
		case "New":
			return "#4CAF50"; // Green
		case "Lead Created":
			return "#2196F3"; // Blue
		case "Application Submitted":
			return "#FFC107"; // Yellow
		default:
			return "#9E9E9E"; // Gray
	}
}

export default function StudentTable(props) {
	const { studentState, handleEdit, handleDelete } = props;

	return (
		<DataGrid
			rows={studentState.students}
			columns={getColumns(handleEdit, handleDelete)}
			initialState={{
				columns: {
					columnVisibilityModel: {
						// Hide columns id, the other columns will remain visible
						id: false,
					},
				},
				pagination: {
					paginationModel: { pageSize: 10 },  // Default to 10 rows per page
				},
				sorting: {
					sortModel: [
						{
							field: "updated_at", // Sort by updated_at field
							sort: "desc", // Sort in descending order
						},
					],
				},
			}}
			pageSizeOptions={[5, 10, 50, 100]}
			checkboxSelection={false}
			disableRowSelectionOnClick
		/>
	);
}
