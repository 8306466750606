import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	MenuItem,
	Paper,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ALLOWED_FILE_TYPES } from "../../const";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUpload from "../Document/FileUpload";
import { uploadDocument } from "../../store/document.slice";

const MAX_FILE_SIZE_MB = 10; // Maximum file size in MB

const documentTypes = [
	"CV",
	"GMAT Score",
	"GRE Score",
	"IELTS Score",
	"Passport",
	"Personal Statement",
	"Proof of Language",
	"Recommendation Letter",
	"TOEFL Score",
	"Transcript",
	"Other",
];

const UploadDocumentForm = ({
	studentId,
	setLeadData,
	setIsUploadDocumentFormValid,
	leadId,
	isDialog = false,
	onClose,
}) => {
	const [uploading, setUploading] = useState(false);
	const [formState, setFormState] = useState([]);
	const [formErrors, setFormErrors] = useState({});
	const [success, setSuccess] = useState(null);

	const dispatch = useDispatch();
	const documentState = useSelector((state) => state.document);

	const handleFileChange = (file) => {
		const errors = {};
		if (file) {
			if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
				errors.file = `File size exceeds ${MAX_FILE_SIZE_MB}MB.`;
				return setFormErrors(errors);
			} else if (!ALLOWED_FILE_TYPES.includes(file.type)) {
				errors.file = "Only image and PDF files are allowed.";
				return setFormErrors(errors);
			}

			setFormState((prevState) => [
				...prevState,
				{
					student_id: studentId,
					description: "",
					file,
					uploadingStatus: "pending",
				},
			]);
			setFormErrors({});
		}
	};

	React.useEffect(() => {
		if (formState.length > 0) {
			setIsUploadDocumentFormValid(formState.every((doc) => doc.uploadingStatus === "success"));	
		} else {
			setIsUploadDocumentFormValid(true);
		}
	}, [formState]);

	const handleChange = (index, e) => {
		const { name, value } = e.target;
		const fieldName = name.split("-")[0];
		setFormState((prevState) => {
			const newState = [...prevState];
			newState[index][fieldName] = value;
			return newState;
		});

		const errors = validateField(index, name, value);
		setFormErrors(errors);
	};

	const validateField = (index, name, value) => {
		let errors = { ...formErrors };
		switch (name) {
			case `document_type-${index}`:
				if (!value) {
					errors[`document_type-${index}`] =
						"Document type is required.";
				} else {
					delete errors[`document_type-${index}`];
				}
				break;
			default:
				break;
		}
		setFormErrors(errors);
		return errors;
	};

	const handleUpload = async () => {
		const errors = {};

		formState.forEach((doc, index) => {
			if (!doc.document_type) {
				errors[`document_type-${index}`] = "Document type is required.";
			}

			if (!doc.file) {
				errors[`file_${index}`] = "No file selected for upload.";
			}
		});

		setFormErrors(errors);

		if (Object.keys(errors).length > 0) {
			return;
		}

		setUploading(true);

		const uploadPromises = formState
			.map((doc, index) => {
				// if already uploaded, skip
				if (doc.uploadingStatus === "success") return null;

				setFormState((prevState) => {
					const newState = [...prevState];
					newState[index].uploadingStatus = "uploading";
					return newState;
				});

				const formData = new FormData();
				formData.append("file", doc.file);
				formData.append("student_id", studentId);
				formData.append("description", doc.description || "");
				formData.append("document_type", doc.document_type);
				if (leadId) formData.append("lead_id", leadId);

				return dispatch(uploadDocument(formData)).then((response) => {
					if (response.type === "document/uploadDocument/fulfilled") {
						setLeadData((prevData) => {
							const updatedDocuments = [
								...(prevData.documents || []),
								response.payload.document,
							];
							return {
								...prevData,
								documents: updatedDocuments,
							};
						});
						setFormState((prevState) => {
							const newState = [...prevState];
							newState[index].uploadingStatus = "success";
							return newState;
						});
					} else {
						setFormState((prevState) => {
							const newState = [...prevState];
							newState[index].uploadingStatus = "error";
							return newState;
						});
					}
					return response;
				});
			})
			.filter(Boolean);

		Promise.all(uploadPromises).then(() => {
			setSuccess("Documents uploaded successfully!");
			setUploading(false);
			if (onClose) onClose();
		});
	};

	const removeDocument = (index) => {
		setFormState((prevState) => prevState.filter((_, i) => i !== index));
	};

	const isUploadDisabled =
		uploading ||
		formState.length === 0 ||
		Object.keys(formErrors).length > 0 ||
		formState.every((doc) => doc.uploadingStatus === "success");

	return (
		<Box component="form" sx={{ mt: 2, width: "100%" }}>
			{!isDialog && (
				<Typography variant="h6" gutterBottom>
					Upload Documents
				</Typography>
			)}
			<Paper elevation={2} sx={{ p: 2, mb: 2, ...(isDialog && { boxShadow: "none" }) }}>
				{success && (
					<Alert
						sx={{ width: "100%", mt: 2, mb: 2 }}
						severity="success"
					>
						{success}
					</Alert>
				)}
				{documentState.error && (
					<Alert
						sx={{ width: "100%", mt: 2, mb: 2 }}
						severity="error"
					>
						{documentState.error}
					</Alert>
				)}
				<Grid container spacing={2}>
					{formState.length < 8 && (
						<Grid item xs={12}>
							<FileUpload
								accept={ALLOWED_FILE_TYPES}
								onChange={handleFileChange}
							/>
						</Grid>
					)}
					{formState.length < 8 ? (

						<Grid item xs={12}>
							<Alert
								icon={false}
								sx={{ width: "100%", mt: 2 }}
								severity="info"
							>
								You can select upto 8 documents at a time. Click{" "}
								<strong>UPLOAD</strong> to save.
							</Alert>
						</Grid>
					) :
						(
							<Grid item xs={12}>
								<Alert
									icon={false}
									sx={{ width: "100%", mt: 2 }}
									severity="warning"
								>
									Max documents upload limit reached
								</Alert>
							</Grid>
						)
					}
					{formState.length !== 0 &&
						formState.map((doc, index) => (
							<Grid
								item
								xs={12}
								key={index}
								container
								spacing={2}
								alignItems="end"
							>
								<Grid item xs={3}>
									<Typography variant="body2">
										{doc.file.name}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<TextField
										select
										fullWidth
										label="Document Type"
										name={`document_type-${index}`}
										value={doc.document_type || ""}
										onChange={(e) => handleChange(index, e)}
										variant="standard"
										margin="normal"
										error={
											!!formErrors[
											`document_type-${index}`
											]
										}
										helperText={
											formErrors[`document_type-${index}`]
										}
										FormHelperTextProps={{
											sx: {
												position: "absolute",
												bottom: "-1.5em",
												left: 0,
												color: "error.main",
												fontSize: "0.75rem",
											},
										}}
										disabled={formState[index].uploadingStatus === "success"}
									>
										{documentTypes.map((type) => (
											<MenuItem key={type} value={type}>
												{type}
											</MenuItem>
										))}
									</TextField>
								</Grid>
								<Grid item xs={5}>
									<TextField
										fullWidth
										label="Description"
										name={`description-${index}`}
										variant="standard"
										margin="normal"
										value={doc.description || ""}
										onChange={(e) => handleChange(index, e)}
										error={
											!!formErrors[`description_${index}`]
										}
										helperText={
											formErrors[`description_${index}`]
										}
										FormHelperTextProps={{
											sx: {
												position: "absolute",
												bottom: "-1.5em",
												left: 0,
												color: "error.main",
												fontSize: "0.75rem",
											},
										}}
										disabled={formState[index].uploadingStatus === "success"}
									/>
								</Grid>
								<Grid item xs={1}>
									<IconButton
										aria-label="delete"
										color="secondary"
										onClick={() => removeDocument(index)}
										disabled={
											doc.uploadingStatus ===
											"uploading" ||
											doc.uploadingStatus === "success"
										}
									>
										{doc.uploadingStatus === "uploading" ? (
											<CircularProgress size={24} />
										) : doc.uploadingStatus ===
											"success" ? (
											<Tooltip title="Uploaded">
												<CheckCircleIcon color="success" />
											</Tooltip>
										) : (
											<DeleteIcon />
										)}
									</IconButton>
								</Grid>
							</Grid>
						))}
					<Grid item xs={12}>
						<Button
							variant="contained"
							color="primary"
							onClick={handleUpload}
							disabled={isUploadDisabled}
						>
							{uploading ? "Uploading..." : "Upload"}
						</Button>
					</Grid>
				</Grid>
			</Paper>
			{formErrors.general && (
				<Typography color="error" sx={{ mt: 2 }}>
					{formErrors.general}
				</Typography>
			)}
		</Box>
	);
};

export default UploadDocumentForm;
