import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../wrappers/fetchWrapper";

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/event`;

const initialState = {
	events: [],
	status: "idle",
	loading: false,
	message: null,
	error: null,
};

const name = "event";

const eventSlice = createSlice({
	name: name,
	initialState,
	reducers: {
		clearEventState: (state) => {
			state.events = [];
			state.status = "idle";
			state.error = null;
		},
		clearMessage: (state) => {
			state.message = null;
		},
		setEvents: (state, action) => {
			state.events = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchEvents.pending, (state) => {
				state.status = "loading";
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchEvents.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.loading = false;
				state.events = action.payload;
			})
			.addCase(fetchEvents.rejected, (state, action) => {
				state.status = "failed";
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			})
			.addCase(createEvent.pending, (state) => {
				state.status = "loading";
				state.loading = true;
				state.error = null;
			})
			.addCase(createEvent.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.loading = false;
				state.events.push(action.payload);
			})
			.addCase(createEvent.rejected, (state, action) => {
				state.status = "failed";
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			})
			.addCase(updateEvent.pending, (state) => {
				state.status = "loading";
				state.loading = true;
				state.error = null;
			})
			.addCase(updateEvent.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.loading = false;
				const index = state.events.findIndex(
					(event) => event.id === action.payload.id
				);
				if (index !== -1) {
					state.message = "Event Updated Successfully";
					state.events[index] = action.payload;
				}
			})
			.addCase(updateEvent.rejected, (state, action) => {
				state.status = "failed";
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			})
			.addCase(deleteEvent.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(deleteEvent.fulfilled, (state, action) => {
				state.loading = false;
				const index = state.events.findIndex(
					(event) => event.id === action.payload.id
				);

				if (index !== -1) {
					state.events.splice(index, 1);
				}
			})
			.addCase(deleteEvent.rejected, (state, action) => {
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			});
	},
});

export const { clearEventState, clearMessage, setEvents } = eventSlice.actions;
export const eventReducer = eventSlice.reducer;

// Async thunk for fetching events
export const fetchEvents = createAsyncThunk(
	`${name}/fetchEvents`,
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.get(
				`${baseUrl}/fetch?timestamp=${new Date().getTime()}`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// Async thunk for creating an event
export const createEvent = createAsyncThunk(
	`${name}/createEvent`,
	async (event, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.post(
				`${baseUrl}/create`,
				event
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// Async thunk for updating an event
export const updateEvent = createAsyncThunk(
	`${name}/updateEvent`,
	async ({ id, event }, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.put(
				`${baseUrl}/update_event/${id}`,
				event
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// Async thunk for deleting an event
export const deleteEvent = createAsyncThunk(
	`${name}/deleteEvent`,
	async (id, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.delete(
				`${baseUrl}/delete_event/${id}`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
