import * as React from "react";

import AcUnitIcon from '@mui/icons-material/AcUnit';
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BarChartIcon from "@mui/icons-material/BarChart";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Collapse from "@mui/material/Collapse";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import DraftsIcon from '@mui/icons-material/Drafts';
import ExpandMore from "@mui/icons-material/ExpandMore";
import GroupIcon from "@mui/icons-material/Group";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LayersIcon from "@mui/icons-material/Layers";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import { Profile } from "../../utils/Profile";
import SchoolIcon from "@mui/icons-material/School";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import WorkIcon from "@mui/icons-material/Work";
import { useSelector } from "react-redux";

export const MainListItems = ({
	selectedListItem,
	setUrlParams,
}) => {
	const [open, setOpen] = React.useState({
		Application: ["School Applications", "Uni Applications"].includes(
			selectedListItem
		),
		Leads: selectedListItem === "Leads",
		Institutions: ["Universities", "Schools"].includes(selectedListItem),
	});
	const authUserProfile = new Profile(useSelector((state) => state.auth));

	const handleToggle = (name) => {
		setOpen((prevOpen) => ({
			...prevOpen,
			[name]: !prevOpen[name],
		}));
	};

	const isSelected = (item) => selectedListItem === item;
	const getSxForListItemText = (isSelected) => ({
		fontWeight: isSelected ? 500 : "normal",
		color: isSelected ? "primary.main" : "inherit",
	});

	const handleSelectedListItemClick = (urlParams) => {
		setUrlParams(urlParams);
	};

	React.useEffect(() => {
		setOpen({
			Application: ["School Applications", "Uni Applications"].includes(
				selectedListItem
			),
			Leads: selectedListItem === "Leads",
			Institutions: ["Universities", "Schools"].includes(selectedListItem),
		});
	}, [selectedListItem]);

	return (
		<React.Fragment>
			<ListItemButton
				selected={isSelected("Appointments")}
				onClick={() => handleSelectedListItemClick({ tab: "Appointments", view: "default" })}
			>
				<ListItemIcon>
					<CalendarMonthIcon
						color={
							isSelected("Appointments") ? "primary" : "inherit"
						}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Appointments"
					sx={getSxForListItemText(isSelected("Appointments"))}
				/>
			</ListItemButton>
			<ListItemButton
				selected={isSelected("DraftLeads")}
				onClick={() => handleSelectedListItemClick({ tab: "DraftLeads", view: "default" })}
			>
				<ListItemIcon>
					<DraftsIcon
						color={
							isSelected("DraftLeads") ? "primary" : "inherit"
						}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Draft Leads"
					sx={getSxForListItemText(isSelected("DraftLeads"))}
				/>
			</ListItemButton>
			<ListItemButton
				selected={isSelected("Leads")}
				onClick={() => {
					handleSelectedListItemClick({ tab: "Leads", view: "table" });
					handleToggle("Leads");
				}}
			>
				<ListItemIcon>
					<CrisisAlertIcon
						color={isSelected("Leads") ? "primary" : "inherit"}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Leads"
					sx={getSxForListItemText(isSelected("Leads"))}
				/>
				{open["Leads"] ? <ExpandMore /> : <KeyboardArrowRightIcon />}
			</ListItemButton>
			<Collapse in={open["Leads"]} timeout="auto" unmountOnExit>
				<ListItemButton
					sx={{ bgcolor: "#FFFFFF" }}
					selected={isSelected("Add Lead")}
					onClick={() => handleSelectedListItemClick({ tab: "Add Lead", view: "default" })}
				>
					<ListItemIcon sx={{ pl: "20px", mb: "5px" }}>
						<SubdirectoryArrowRightIcon
							color={
								isSelected("Add Lead") ? "primary" : "inherit"
							}
						/>
					</ListItemIcon>
					<ListItemText
						sx={getSxForListItemText(isSelected("Add Lead"))}
						primary="Add Lead"
					/>
				</ListItemButton>
			</Collapse>
			<ListItemButton
				selected={isSelected("Application")}
				onClick={() => handleToggle("Application")}
			>
				<ListItemIcon>
					<WorkIcon
						color={
							isSelected("Application") ? "primary" : "inherit"
						}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Applications"
					sx={getSxForListItemText(isSelected("Application"))}
				/>
				{open["Application"] ? (
					<ExpandMore />
				) : (
					<KeyboardArrowRightIcon />
				)}
			</ListItemButton>
			<Collapse in={open["Application"]} timeout="auto" unmountOnExit>
				<ListItemButton
					sx={{ bgcolor: "#FFFFFF" }}
					selected={isSelected("Uni Applications")}
					onClick={() => handleSelectedListItemClick({ tab: "Uni Applications", view: "table" })}
				>
					<ListItemIcon sx={{ pl: "20px", mb: "5px" }}>
						<SubdirectoryArrowRightIcon
							color={
								isSelected("Uni Applications") ? "primary" : "inherit"
							}
						/>
					</ListItemIcon>
					<ListItemText
						sx={getSxForListItemText(isSelected("Uni Applications"))}
						primary="University Applications"
					/>
				</ListItemButton>
				<ListItemButton
					sx={{ bgcolor: "#FFFFFF" }}
					selected={isSelected("School Applications")}
					onClick={() => handleSelectedListItemClick({ tab: "School Applications", view: "table" })}
				>
					<ListItemIcon sx={{ pl: "20px", mb: "5px" }}>
						<SubdirectoryArrowRightIcon
							color={
								isSelected("School Applications") ? "primary" : "inherit"
							}
						/>
					</ListItemIcon>
					<ListItemText
						sx={getSxForListItemText(isSelected("School Applications"))}
						primary="School Applications"
					/>
				</ListItemButton>
			</Collapse>
			<ListItemButton
				selected={isSelected("Institutions")}
				onClick={() => handleToggle("Institutions")}
			>
				<ListItemIcon>
					<AccountBalanceIcon
						color={
							isSelected("Institutions") ? "primary" : "inherit"
						}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Institutions"
					sx={getSxForListItemText(isSelected("Institutions"))}
				/>
				{open["Institutions"] ? (
					<ExpandMore />
				) : (
					<KeyboardArrowRightIcon />
				)}
			</ListItemButton>
			<Collapse in={open["Institutions"]} timeout="auto" unmountOnExit>
				<ListItemButton
					sx={{ bgcolor: "#FFFFFF" }}
					selected={isSelected("Universities")}
					onClick={() => handleSelectedListItemClick({ tab: "Universities", view: "table" })}
				>
					<ListItemIcon sx={{ pl: "20px", mb: "5px" }}>
						<SubdirectoryArrowRightIcon
							color={
								isSelected("Universities") ? "primary" : "inherit"
							}
						/>
					</ListItemIcon>
					<ListItemText
						sx={getSxForListItemText(isSelected("Universities"))}
						primary="Universities"
					/>
				</ListItemButton>
				<ListItemButton
					sx={{ bgcolor: "#FFFFFF" }}
					selected={isSelected("Schools")}
					onClick={() => handleSelectedListItemClick({ tab: "Schools", view: "table" })}
				>
					<ListItemIcon sx={{ pl: "20px", mb: "5px" }}>
						<SubdirectoryArrowRightIcon
							color={
								isSelected("Schools") ? "primary" : "inherit"
							}
						/>
					</ListItemIcon>
					<ListItemText
						sx={getSxForListItemText(isSelected("Schools"))}
						primary="Schools"
					/>
				</ListItemButton>
			</Collapse>
			<ListItemButton
				selected={isSelected("Programs")}
				onClick={() => handleSelectedListItemClick({ tab: "Programs", view: "default" })}
			>
				<ListItemIcon>
					<BookmarkIcon
						color={isSelected("Programs") ? "primary" : "inherit"}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Programs"
					sx={getSxForListItemText(isSelected("Programs"))}
				/>
			</ListItemButton>
			<ListItemButton
				selected={isSelected("Majors")}
				onClick={() => handleSelectedListItemClick({ tab: "Majors", view: "default" })}
			>
				<ListItemIcon>
					<SchoolIcon
						color={isSelected("Majors") ? "primary" : "inherit"}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Majors"
					sx={getSxForListItemText(isSelected("Majors"))}
				/>
			</ListItemButton>
			<ListItemButton
				selected={isSelected("Intakes")}
				onClick={() => handleSelectedListItemClick({ tab: "Intakes", view: "default" })}
			>
				<ListItemIcon>
					<AcUnitIcon
						color={isSelected("Intakes") ? "primary" : "inherit"}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Intakes"
					sx={getSxForListItemText(isSelected("Intakes"))}
				/>
			</ListItemButton>
			<ListItemButton
				selected={isSelected("Services")}
				onClick={() => handleSelectedListItemClick({ tab: "Services", view: "default" })}
			>
				<ListItemIcon>
					<MiscellaneousServicesIcon
						color={isSelected("Services") ? "primary" : "inherit"}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Services"
					sx={getSxForListItemText(isSelected("Services"))}
				/>
			</ListItemButton>
			<ListItemButton
				selected={isSelected("Reports")}
				onClick={() => handleSelectedListItemClick({ tab: "Reports", view: "default" })}
			>
				<ListItemIcon>
					<BarChartIcon
						color={isSelected("Reports") ? "primary" : "inherit"}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Reports"
					sx={getSxForListItemText(isSelected("Reports"))}
				/>
			</ListItemButton>
			<ListItemButton
				selected={isSelected("Integrations")}
				onClick={() => handleSelectedListItemClick({ tab: "Integrations", view: "default" })}
			>
				<ListItemIcon>
					<LayersIcon
						color={
							isSelected("Integrations") ? "primary" : "inherit"
						}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Integrations"
					sx={getSxForListItemText(isSelected("Integrations"))}
				/>
			</ListItemButton>
			{/* USERS */}
			{authUserProfile.hasGroups("Administrator") && (
				<ListItemButton
					selected={isSelected("Users")}
					onClick={() => handleSelectedListItemClick({ tab: "Users", view: "default" })}
				>
					<ListItemIcon>
						<GroupIcon
							color={isSelected("Users") ? "primary" : "inherit"}
						/>
					</ListItemIcon>
					<ListItemText
						primary="Users"
						sx={getSxForListItemText(isSelected("Users"))}
					/>
				</ListItemButton>
			)}
		</React.Fragment>
	);
};

export const secondaryListItems = (
	<React.Fragment>
		<ListSubheader component="div" inset>
			Saved reports
		</ListSubheader>
		<ListItemButton>
			<ListItemIcon>
				<AssignmentIcon />
			</ListItemIcon>
			<ListItemText primary="Current month" />
		</ListItemButton>
		<ListItemButton>
			<ListItemIcon>
				<AssignmentIcon />
			</ListItemIcon>
			<ListItemText primary="Last quarter" />
		</ListItemButton>
		<ListItemButton>
			<ListItemIcon>
				<AssignmentIcon />
			</ListItemIcon>
			<ListItemText primary="Year-end sale" />
		</ListItemButton>
	</React.Fragment>
);
