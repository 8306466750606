import React from "react";
import { Box, Typography, Button, Container, Link } from "@mui/material";

const PageNotFound = ({ message, setUrlParams }) => {

    const goToHome = () => {
        setUrlParams({
            tab: "Application",
            view: "default",
        });
    };

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                minHeight: "100vh",
                textAlign: "center",
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom>
                {message}
            </Typography>
            <Box sx={{ mb: 4 }}>
                <img
                    src="/static/images/404.webp"
                    alt="404 Not Found"
                    style={{ width: "100%", maxWidth: "400px" }}
                />
            </Box>
            <Button
                variant="contained"
                color="primary"
                component={Link}
                onClick={goToHome}
                sx={{ mt: 2 }}
            >
                Go Back to Home
            </Button>
        </Container>
    );
};

export default PageNotFound;
