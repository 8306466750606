import * as React from "react";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { clearMessage } from "../../store/user.slice";
import { useDispatch, useSelector } from "react-redux";

import AddIcon from "@mui/icons-material/Add";
import AddUserDialog from "../User/AddUserDialog";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import EditUserDialog from "../User/EditUserDialog";
import EnableDisableUserDialog from "../User/EnableDisableUserDialog"
import ResetUserDialog from "../User/ResetUserDialog"
import AddRemoveGroupsDialog from "../User/AddRemoveGroupsDialog"

import Snackbar from "@mui/material/Snackbar";
import UserTable from "../User/UserTable";
import useUser from "../../hooks/useUser";

const defaultTheme = createTheme();

function Users() {
	const dispatch = useDispatch();
	const userState = useSelector((state) => state.user);
	useUser(); // fetch employee Users

	const [openDialog, setOpenDialog] = React.useState(false);
	const [openEditDialog, setOpenEditDialog] = React.useState(false);
	const [openDisableDialog, setOpenDisableDialog] = React.useState(false);
	const [openResetDialog, setOpenResetDialog] = React.useState(false);
	const [openAddRemoveGroupsDialog, setOpenAddRemoveGroupsDialog] = React.useState(false);


	const [selectedUser, setSelectedUser] = React.useState(null);

	const handleOpenDialog = () => {
		setOpenDialog(true);
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		dispatch(clearMessage());
	};

	const handleEditUser = (user) => {
		setSelectedUser(user);
		setOpenEditDialog(true);
	};

	const handleDisableUser = (user) => {
		setSelectedUser(user);
		setOpenDisableDialog(true);
	}

	const handleResetUser = (user) => {
		setSelectedUser(user);
		setOpenResetDialog(true);
	}

	const handleAddRemoveGroups = (user) => {
		setSelectedUser(user);
		setOpenAddRemoveGroupsDialog(true);
	}

	return (
		<ThemeProvider theme={defaultTheme}>
			<Container component="main" maxWidth='false' sx={{ backgroundColor: "#FFFFFF" }}>
				{userState.message && (
					<Snackbar
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={true}
						autoHideDuration={5000}
						onClose={handleCloseSnackbar}
					>
						<Alert sx={{ width: "100%", mb: 2 }} severity="success">
							{userState.message}
						</Alert>
					</Snackbar>
				)}
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						width: "100%",
						overflowX: "auto",
					}}
				>
					<Box
						sx={{
							width: "100%",
							display: "flex",
							justifyContent: "flex-end",
							mb: 2,
						}}
					>
						<Button
							variant="contained"
							color="primary"
							startIcon={<AddIcon />} // Add icon before the text
							onClick={handleOpenDialog}
						>
							Add User
						</Button>
					</Box>
					<UserTable
						userState={userState}
						handleEdit={handleEditUser}
						handleDisable={handleDisableUser}
						handleReset={handleResetUser}
						handleAddRemoveGroups={handleAddRemoveGroups}
					/>
				</Box>
				<AddUserDialog
					open={openDialog}
					setOpenDialog={setOpenDialog}
					userState={userState}
				/>
				{selectedUser && (
					<EditUserDialog
						open={openEditDialog}
						setOpenDialog={setOpenEditDialog}
						user={selectedUser}
                        userState={userState}
					/>
				)}
				{selectedUser && (
					<AddRemoveGroupsDialog
						open={openAddRemoveGroupsDialog}
						setOpenDialog={setOpenAddRemoveGroupsDialog}
						user={selectedUser}
                        userState={userState}
					/>
				)}
				{selectedUser && (
					<EnableDisableUserDialog
						open={openDisableDialog}
						setOpenDialog={setOpenDisableDialog}
						user={selectedUser}
                        userState={userState}
					/>
				)}
				{selectedUser && (
					<ResetUserDialog
						open={openResetDialog}
						setOpenDialog={setOpenResetDialog}
						user={selectedUser}
                        userState={userState}
					/>
				)}
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1000,
					}}
					open={userState.loading}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			</Container>
		</ThemeProvider>
	);
}

export default Users;
