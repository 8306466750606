// CoursesSection.js
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import AddCourseDialog from "./AddCourseDialog";
import AddIcon from "@mui/icons-material/Add";
import DeleteConfirmationDialog from "../DeleteConfirmationDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import EditCourseDialog from "./EditCourseDialog";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import { deleteSchoolCourse } from "../../store/school.slice";

const CoursesSection = ({ programs, currencies, schoolId, courses }) => {
    const dispatch = useDispatch();

    const schoolState = useSelector((state) => state.school);

    const [selectedCourse, setSelectedCourse] = React.useState(null);
    const [openEditCourseDialog, setOpenEditCourseDialog] =
        React.useState(false);
    const [openAddCourseDialog, setOpenAddCourseDialog] = React.useState(false);
    const [openDeleteCourseDialog, setOpenDeleteCourseDialog] =
        React.useState(false);

    const getCourseDeleteConfirmationText = (course) => {
        return (
            <>
                Are you sure you want to delete the course{" "}
                <Typography component="span" sx={{ fontWeight: "bold" }}>
                    {course.major} - {course.program}
                </Typography>
                ?
            </>
        );
    };

    const handleEditCourse = (courseId) => {
        setSelectedCourse(courses.find((course) => course.id === courseId));
        setOpenEditCourseDialog(true);
    };

    const handleAddCourse = () => {
        setOpenAddCourseDialog(true);
    };

    const handleDeleteCourse = (courseId) => {
        setSelectedCourse(courses.find((course) => course.id === courseId));
        setOpenDeleteCourseDialog(true);
    };

    const deleteCourse = (courseId) => {
        dispatch(deleteSchoolCourse(courseId)).then((response) => {
            if (
                response.type === "school/deleteSchoolCourse/fulfilled"
            ) {
                setOpenDeleteCourseDialog(false);
            }
        });
    };

    return (
        <Box sx={{ mb: 2 }}>
            <Typography
                variant="h6"
                component="div"
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                Available Courses
                <Button
                    aria-hidden="false"
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleAddCourse}
                >
                    Add Course
                </Button>
            </Typography>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Grid container spacing={2}>
                {courses.map((course) => (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        key={course.id}
                        sx={{ display: "flex", flexDirection: "column" }}
                    >
                        <Card
                            sx={{
                                maxWidth: "100%",
                                display: "flex",
                                flexDirection: "column",
                                flex: "1 0 auto",
                                maxHeight: 350,
                            }}
                        >
                            <CardContent
                                sx={{
                                    flexGrow: 1,
                                    maxHeight: 250,
                                    overflowY: "auto",
                                }}
                            >
                                <Typography variant="h6" component="div">
                                    {course.program}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    <strong>Application Method:</strong>{" "}
                                    {course.application_method}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    <strong>Fee:</strong>{" "}
                                    {course.fee ? `${course.currency_symbol}${course.fee}` : "N/A"}
                                </Typography>
                            </CardContent>
                            <CardActions sx={{ px: 2, boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)" }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        alignItems: "flex-start",
                                        width: "100%",
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{ fontStyle: "italic" }}
                                    >
                                        <strong>Added By:</strong>{" "}
                                        {course.added_by}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{ fontStyle: "italic" }}
                                    >
                                        <strong>Updated By:</strong>{" "}
                                        {course.updated_by}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{ fontStyle: "italic" }}
                                    >
                                        <strong>Last Updated:</strong>{" "}
                                        {new Date(
                                            course.updated_at
                                        ).toLocaleString()}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            width: "100%",
                                            mt: 2,
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            startIcon={<EditIcon />}
                                            onClick={() =>
                                                handleEditCourse(course.id)
                                            }
                                        >
                                            Edit
                                        </Button>
                                        <Tooltip title="Delete">
                                            <IconButton
                                                color="error"
                                                area-label="delete"
                                                onClick={() =>
                                                    handleDeleteCourse(
                                                        course.id
                                                    )
                                                }
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <AddCourseDialog
                open={openAddCourseDialog}
                handleClose={setOpenAddCourseDialog}
                schoolId={schoolId}
                programs={programs}
                currencies={currencies}
            />
            {selectedCourse && (
                <>
                    <EditCourseDialog
                        open={openEditCourseDialog}
                        handleClose={setOpenEditCourseDialog}
                        course={selectedCourse}
                        programs={programs}
                        currencies={currencies}
                    />
                    <DeleteConfirmationDialog
                        open={openDeleteCourseDialog}
                        handleClose={() => setOpenDeleteCourseDialog(false)}
                        onConfirm={() => deleteCourse(selectedCourse.id)}
                        contentText={getCourseDeleteConfirmationText(
                            selectedCourse
                        )}
                        state={schoolState}
                    />
                </>
            )}
        </Box>
    );
};

export default CoursesSection;
