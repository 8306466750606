import {
	Alert,
	Backdrop,
	Box,
	CircularProgress,
	Snackbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { clearError as cleadLeadError, clearMessage as cleadLeadMessage } from "../../store/lead.slice";
import { useDispatch, useSelector } from "react-redux";

import LeadDetail from "../Leads/LeadDetail";
import LeadEditDialog from "../Leads/LeadEditDialog";
import LeadTable from "../Leads/LeadTable";
import { clearMessage as clearApplicationMessage } from "../../store/application.slice";
import { clearMessage as clearDocumentMessage } from "../../store/document.slice";
import { clearMessage as clearStudentMessage } from "../../store/student.slice";
import { useEdvantageContext } from "../../EdvantageProvider";
import useLead from "../../hooks/useLead";

export default function Lead({ urlParams, setUrlParams }) {
	const dispatch = useDispatch();
	const leadState = useSelector((state) => state.lead);
	const documentState = useSelector((state) => state.document);
	const studentState = useSelector((state) => state.student);
	const applicationState = useSelector((state) => state.application);
	const { leads } = useLead();
	const [selectedLead, setSelectedLead] = useState(null);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(leadState.loading || documentState.loading || studentState.loading);
	const { countriesById, majorById, programById, serviceById } = useEdvantageContext();

	useEffect(() => {
		setIsLoading(leadState.loading || documentState.loading || studentState.loading);
	}, [leadState.loading, documentState.loading, studentState.loading]);

	const handleDialogClose = () => {
		setIsDialogOpen(false);
		setSelectedLead(null);
	};

	const handleEditLead = (lead) => {
		setSelectedLead({
			...lead,
			preferred_countries_objs: lead.preferred_countries.map(
				(countryId) => countriesById.get(countryId)
			),
			additional_services: lead.additional_services,
		});
		setIsDialogOpen(true);
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		dispatch(cleadLeadMessage());
		dispatch(clearStudentMessage());
		dispatch(clearDocumentMessage());
		dispatch(clearApplicationMessage());
	};

	const handleViewLeadDetail = (leadId) => {
		setUrlParams(prevParams => ({
			...prevParams,
			view: "details",
			id: leadId,
		}));
	};

	const handleBack = () => {
		setUrlParams((prevParams) => {
			const { id, ...restParams } = prevParams;
			return {
				...restParams,
				view: "table",
			};
		});
		setSelectedLead(null);
	};

	useEffect(() => {
		if (urlParams.id) {
			const lead = leads.find((lead) => lead.id === urlParams.id);
			if (lead) {
				setSelectedLead({
					...lead,
					preferred_countries: lead.preferred_countries.map((countryId) => countriesById.get(countryId)),
					preferred_majors: lead.preferred_majors.map((majorId) => majorById.get(majorId)),
					preferred_programs: lead.preferred_programs.map((programId) => programById.get(programId)),
					preferred_countries_objs: lead.preferred_countries.map((countryId) => countriesById.get(countryId)),
				});
			}
		}
	}, [urlParams.id, leads]);

	// Clear message on component unmount
	React.useEffect(() => {
		return () => {
			dispatch(cleadLeadMessage());
			dispatch(clearStudentMessage());
			dispatch(clearDocumentMessage());
			dispatch(clearApplicationMessage());
			dispatch(cleadLeadError());
		};
	}, [dispatch]);

	return (
		<Box sx={{ width: "100%", overflowX: "auto" }}>
			{(leadState.message || documentState.message || studentState.message || applicationState.message) && (
				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					open={true}
					autoHideDuration={3000}
					onClose={handleCloseSnackbar}
				>
					<Alert sx={{ width: "100%", mb: 2 }} severity="success">
						{leadState.message || documentState.message || studentState.message || applicationState.message}
					</Alert>
				</Snackbar>
			)}
			{urlParams.view === "table" &&
				(!leadState.loading || leadState.action !== "fetchLeads") && (
					<LeadTable
						leads={leads}
						onEdit={handleEditLead}
						onViewDetail={handleViewLeadDetail}
					/>
				)}
			{urlParams.view === "details" && leadState.status === "succeeded" && !isLoading && (
				<LeadDetail
					lead={selectedLead}
					setUrlParams={setUrlParams}
					handleBack={handleBack}
					serviceById={serviceById}
				/>
			)}
			{selectedLead && (
				<LeadEditDialog
					open={isDialogOpen}
					onClose={handleDialogClose}
					lead={selectedLead}
				/>
			)}
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1000,
				}}
				open={isLoading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Box>
	);
}
