import React from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
	Autocomplete,
	Checkbox,
	Collapse,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	TextField,
} from "@mui/material";
import { useEdvantageContext } from "../../EdvantageProvider";
import CountrySelect from "../common/CountrySelect";

import PropTypes from "prop-types";

function FilterSchoolCollapse(props) {
	const { searchFilters, handleSearchChange, filterBoxVisible } = props;

	const { majors, programs } = useEdvantageContext();

	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	const checkedIcon = <CheckBoxIcon fontSize="small" />;

	return (
		<Collapse in={filterBoxVisible}>
			<Paper sx={{ p: 2, mb: 2 }}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={2}>
						<TextField
							label="School Name"
							variant="outlined"
							fullWidth
							value={searchFilters.name}
							onChange={handleSearchChange("name")}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={2}>
						<Autocomplete
							multiple
							limitTags={2}
							id="school-major"
							options={majors}
							disableCloseOnSelect
							getOptionLabel={(option) => option.name}
							value={majors.filter((option) =>
								searchFilters.majors.includes(option.name)
							)}
							onChange={handleSearchChange("majors")}
							renderOption={(props, option, { selected }) => {
								const { key, ...optionProps } = props;
								return (
									<li key={key} {...optionProps}>
										<Checkbox
											icon={icon}
											checkedIcon={checkedIcon}
											style={{
												marginRight: 8,
											}}
											checked={selected}
										/>
										{option.name}
									</li>
								);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Majors"
									placeholder="Select Majors"
									fullWidth
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={2}>
						<Autocomplete
							multiple
							limitTags={1}
							id="school-programs"
							options={programs}
							disableCloseOnSelect
							getOptionLabel={(option) => option.name}
							value={programs.filter((option) =>
								searchFilters.programs.includes(option.name)
							)}
							onChange={handleSearchChange("programs")}
							renderOption={(props, option, { selected }) => {
								const { key, ...optionProps } = props;
								return (
									<li key={key} {...optionProps}>
										<Checkbox
											icon={icon}
											checkedIcon={checkedIcon}
											style={{
												marginRight: 8,
											}}
											checked={selected}
										/>
										{option.name}
									</li>
								);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Programs"
									placeholder="Select Programs"
									fullWidth
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={2}>
						<FormControl fullWidth>
							<InputLabel id="sponsorship-select-label">
								Sponsorship
							</InputLabel>
							<Select
								labelId="sponsorship-select-label"
								id="sponsorship-select"
								value={searchFilters.is_sponsored}
								label="Sponsorship"
								onChange={handleSearchChange("is_sponsored")}
							>
								<MenuItem key={0} value={""}>
									All
								</MenuItem>
								<MenuItem key={1} value={"Yes"}>
									Yes
								</MenuItem>
								<MenuItem key={2} value={"No"}>
									No
								</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={2}>
						<TextField
							label="City"
							variant="outlined"
							fullWidth
							value={searchFilters.city}
							onChange={handleSearchChange("city")}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={2}>
						{/* <TextField
							label="Country"
							variant="outlined"
							fullWidth
							value={searchFilters.country}
							onChange={handleSearchChange("country")}
						/> */}
						<CountrySelect
							label="Countries"
							name="countries"
							value={searchFilters.countries}
							onChange={handleSearchChange("countries")}
							fullWidth
							multiple
						/>
					</Grid>
				</Grid>
			</Paper>
		</Collapse>
	);
}

FilterSchoolCollapse.propTypes = {
	searchFilters: PropTypes.object.isRequired,
	handleSearchChange: PropTypes.func.isRequired,
	filterBoxVisible: PropTypes.bool.isRequired,
};

export default FilterSchoolCollapse;
