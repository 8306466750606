import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "../wrappers/fetchWrapper";
import { updateStudentInLead } from "./lead.slice";

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/student`;

const initialState = {
	action: "",
	students: [],
	loading: false,
	error: null,
	message: null,
	status: 'idle',
};

const name = "student";

const studentSlice = createSlice({
	name: name,
	initialState,
	reducers: {
		// Optionally add some synchronous reducers if needed
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		clearMessage: (state) => {
			state.message = null;
		},
		clearError: (state) => {
			state.error = null;
		},
		changeStudentState: (state, action) => {
			const { action: actionType, data } = action.payload;

			if (actionType === 'create') {
				// Add new student to the list
				state.students = [...state.students, data];
			} else if (actionType === 'update') {
				// Update the existing student
				const index = state.students.findIndex(student => student.id === data.id);
				if (index !== -1) {
					state.students[index] = data;
				}
			} else if (actionType === 'delete') {
				// Delete the student by id
				state.students = state.students.filter(student => student.id !== data.id);
			}
		},
		clearStudentState: (state) => {
			state.students = [];
			state.loading = false;
			state.error = null;
			state.message = null;
			state.status = 'idle';
		},
		updateStudentStatus: (state, action) => {
			const { id, status } = action.payload;
			const studentIndex = state.students.findIndex(
				(student) => student.id === id
			);
			if (studentIndex !== -1) {
				state.students[studentIndex].status = status;
			}
		},
	},
	extraReducers: (builder) => {
		builder
			// Add Student
			.addCase(addStudent.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(addStudent.fulfilled, (state, action) => {
				state.action = "addStudent";
				state.loading = false;
				state.message = "Student Added Successfully";
				state.students.push(action.payload);
			})
			.addCase(addStudent.rejected, (state, action) => {
				state.action = "addStudent";
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			})
			// Update Student
			.addCase(updateStudent.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(updateStudent.fulfilled, (state, action) => {
				state.action = "updateStudent";
				state.loading = false;
				state.message = "Student Updated Successfully";
				const index = state.students.findIndex(
					(student) => student.id === action.payload.id
				);
				if (index !== -1) {
					state.students[index] = action.payload;
				}
			})
			.addCase(updateStudent.rejected, (state, action) => {
				state.action = "updateStudent";
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			})
			.addCase(fetchStudents.pending, (state) => {
				state.status = 'loading';
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(fetchStudents.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.loading = false;
				state.students = action.payload;
			})
			.addCase(fetchStudents.rejected, (state, action) => {
				state.status = 'failed';
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(deleteStudent.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(deleteStudent.fulfilled, (state, action) => {
				state.loading = false;
				state.message = "Student Deleted Successfully";
				state.students = state.students.filter(
					(student) => student.id !== action.payload.id
				);
			})
			.addCase(deleteStudent.rejected, (state, action) => {
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			});
	},
});

export const {
	changeStudentState,
	setLoading,
	clearMessage,
	clearError,
	clearStudentState,
	updateStudentStatus,
} = studentSlice.actions;
export const studentReducer = studentSlice.reducer;

export const addStudent = createAsyncThunk(
	`${name}/addStudent`,
	async (student, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.post(`${baseUrl}/add`, student);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const fetchStudents = createAsyncThunk(
	`${name}/fetchStudents`,
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.get(
				`${baseUrl}/fetch?timestamp=${new Date().getTime()}`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const updateStudent = createAsyncThunk(
	`${name}/updateStudent`,
	async ({ id, student, uuid }, { dispatch, rejectWithValue }) => {
		try {
			const payload = { ...student, uuid };
			const response = await fetchWrapper.put(
				`${baseUrl}/update/${id}`,
				payload
			);
			dispatch(updateStudentInLead(response.data));
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const deleteStudent = createAsyncThunk(
	`${name}/deleteStudent`,
	async (id, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.delete(
				`${baseUrl}/delete/${id}`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
