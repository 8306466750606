import * as React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';

function DeleteConfirmationDialog(props) {

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            sx={{
                '& .MuiDialog-paper': {
                    minWidth: '300px',
                    '@media (min-width: 600px)': {
                        minWidth: '500px',
                    },
                },
            }}
        >
            <DialogTitle>
                Confirm Deletion
                {props.state.error && <Alert sx={{ width: '100%', mt: 2, mb: 2 }} severity="error">{props.state.error}</Alert>}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.contentText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={props.handleClose}
                >
                    No
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={props.onConfirm}
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}

DeleteConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    contentText: PropTypes.node.isRequired,
    state: PropTypes.object.isRequired,
};

export default DeleteConfirmationDialog;
