import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "../wrappers/fetchWrapper";

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/employee`;

const initialState = {
	employees: [],
	status: "",
	error: null,
};

const name = "employee";

const employeeSlice = createSlice({
	name: name,
	initialState,
	reducers: {
		clearEmployeeState: (state) => {
			state.employees = [];
			state.status = "";
			state.error = null;
		},
		setEployees: (state, action) => {
			state.employees = action.payload;
		},
		setStatus: (state, action) => {
			state.status = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchEmployees.pending, (state) => {
				state.status = "loading";
				state.error = null;
			})
			.addCase(fetchEmployees.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.employees = action.payload;
			})
			.addCase(fetchEmployees.rejected, (state, action) => {
				state.status = "failed";
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			});
	},
});

export const { clearEmployeeState, setEployees, setStatus } = employeeSlice.actions;
export const employeeReducer = employeeSlice.reducer;

export const fetchEmployees = createAsyncThunk(
	`${name}/fetchEmployees`,
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.get(
				`${baseUrl}/fetch?timestamp=${new Date().getTime()}`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
