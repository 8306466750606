import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';

function useEdvantageFetch(model, field, fetchFunction) {

    const dispatch = useDispatch();
    const lockRef = useRef(false);

    const state = useSelector((state) => state[model]);
    const data = state[field];
    const status = state.status;

    useEffect(() => {
        async function onLoad() {
            if (status === 'idle' && !lockRef.current) {
                lockRef.current = true;
                dispatch(fetchFunction()).finally(() => {
                    // Release lock after completion
                    lockRef.current = false;
                });
            }
        }

        onLoad();
    }, [status, dispatch, model, field, fetchFunction]);

    return {
        data,
        status,
        state,
    };
}

export default useEdvantageFetch;
