import * as React from 'react';

import { Box, Grid, Skeleton } from '@mui/material';

export default function DraftLeadSkeletonTable() {
    return (
        <Box sx={{ width: '100%' }}>
            {/* Table Header Skeletons */}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Skeleton variant="text" height={30} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Skeleton variant="text" height={30} />
                </Grid>
            </Grid>

            {/* Skeleton for Rows */}
            <Grid container spacing={2}>
                {[...Array(5)].map((_, index) => (
                    <Grid item xs={12} key={index}>
                        <Grid container spacing={2}>
                            <Grid item xs={1}>
                                <Skeleton variant="text" width="100%" />
                            </Grid>
                            <Grid item xs={2}>
                                <Skeleton variant="text" width="100%" />
                            </Grid>
                            <Grid item xs={2}>
                                <Skeleton variant="text" width="100%" />
                            </Grid>
                            <Grid item xs={2}>
                                <Skeleton variant="text" width="100%" />
                            </Grid>
                            <Grid item xs={1}>
                                <Skeleton variant="rectangular" width="100%" height={36} />
                            </Grid>
                            <Grid item xs={2}>
                                <Skeleton variant="text" width="100%" />
                            </Grid>
                            <Grid item xs={2}>
                                <Skeleton variant="text" width="100%" />
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>

            {/* Skeleton for Footer / No Rows Message */}
            <Skeleton variant="rectangular" height={60} />
        </Box>
    );
}
