import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useFormik} from 'formik';
import * as Yup from 'yup';

import { updateUser, clearUserState } from "../../store/user.slice";
import { reloadProfile } from "../../store/auth.slice";

import Avatar from '@mui/material/Avatar';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const defaultTheme = createTheme();

const validationSchema = Yup.object({
	firstName: Yup.string().max(20, 'Must be 20 characters or less').required(),
	lastName: Yup.string().max(20, 'Must be 20 characters or less').required(),
	phoneNumber: Yup.string().matches(/^[0-9\- ]{8,14}$/, {message: "Please enter valid number.", excludeEmptyString: false}).required()
  })

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));

function Profile() {

    const dispatch = useDispatch();

    const authUserState = useSelector(x => x.auth);
    const userState = useSelector((state) => state.user);

    const {profile: authUser} = authUserState.employee

    const [form, ] = React.useState({
        id: authUser.id,
        firstName: authUser.user.first_name,
        lastName: authUser.user.last_name,
        email: authUser.user.email,
        phoneNumber: authUser.phone_number,
        groups: authUser.user.groups
    });


    // initialize Formik
	const updateUserFormik = useFormik({
		initialValues: {
			firstName: form.firstName,
			lastName: form.lastName,
			email: form.email,
			phoneNumber: form.phoneNumber
		},
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: values => {
			const data = {
				phone_number: values.phoneNumber,
				user: {
					first_name: values.firstName,
					last_name: values.lastName,
					email: values.email
				}
			}
			dispatch(updateUser({ id: form.id, data })).then(
				async (response) => {
					if (response.type === "user/updateUser/fulfilled") {
                        dispatch(reloadProfile()).then(
                            response => {
                                if (response.type === "auth/reloadProfile/rejected") {
                                    dispatch(clearUserState())
                                }
                            })
                    }
				}
			);
		}
		});

    const onChange = (e) => {
        // clean user
        dispatch(clearUserState())
        updateUserFormik.handleChange(e)
    }


    return (
        <React.Fragment>
           
        <ThemeProvider theme={defaultTheme}>
        
        <Container>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ 
                    bgcolor: "#ffffff", 
                    padding:"10px 10px", 
                    backgroundImage: "url(/static/images/bg-profile.jpg)",
                    backgroundPosition: "center center",
                    backgroundSize: "cover"
                }}
                
                >
                <div
                    style={{
                    display:"flex",
                    "flex-direction":"column",
                    width: "235px"
                    }}
                >
                        <Avatar 
                            sx={{ 
                                bgcolor: "ff0000", 
                                height: "110px", 
                                width: "110px",
                                margin: "13px 65px"
                            }} 
                            aria-label="recipe"
                        >
                            {updateUserFormik.values.firstName[0] || ""}{updateUserFormik.values.lastName[0] || ""}
                        </Avatar>
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 600,
                                fontFamily: `'Roboto', sans-serif !important`,
                                color: "rgb(18, 25, 38) !important",
                                lineHeight: `1.235 !important`,
                                textAlign: "center",
                                marginBottom: "28px",
                                fontSize: "25px"
                            }}
                        >
                            {updateUserFormik.values.firstName || ""} {updateUserFormik.values.lastName || ""}
                        </Typography>
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 300,
                                fontFamily: `'Roboto', sans-serif !important`,
                                fontSize:
                                    "1rem !important",
                                color: "rgb(18, 25, 38) !important",
                                lineHeight: `1.235 !important`,
                                textAlign: "center"
                            }}
                        >
                            {form.email|| ""}
                        </Typography>
                        <Typography 
                            variant="subtitle2"
                            sx={{
                                textAlign: "center"
                            }}
                        >
                            Groups: {form.groups.map(group => group.name).join(" - ")}
                        </Typography>
                </div>
            </Box>
        </Container>

        <Container>
            <Box
                sx={{
                    bgcolor: "#ffffff", 
                    padding: "6px 50px",
                    marginTop: "20px",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                    {authUserState.error && authUserState.action === "reloadProfile" && (
                                <Alert
                                    sx={{ width: "100%", mt: 2, mb: 2 }}
                                    severity="error"
                                >
                                    {authUserState.error}
                                </Alert>
                            )}
                {userState.error && userState.action === "updateUser" && (
                                <Alert
                                    sx={{ width: "100%", mt: 2, mb: 2 }}
                                    severity="error"
                                >
                                    {userState.error}
                                </Alert>
                            )}
                {userState.message && userState.action === "updateUser" && (
                    <Alert
                        sx={{ width: "100%", mt: 2, mb: 2 }}
                        severity="info"
                    >
                        {userState.message}
                    </Alert>
                )}
            
                <form id="updateMeForm" onSubmit={updateUserFormik.handleSubmit}>
                    <Box  sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography component="h1">
                                    First Name
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    value={updateUserFormik.values.firstName}
                                    onChange={onChange}
                                    onBlur={updateUserFormik.handleBlur}
                                    error={updateUserFormik.touched.firstName && Boolean(updateUserFormik.errors.firstName)}
                                    helperText={updateUserFormik.touched.firstName && updateUserFormik.errors.firstName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography component="h1">
                                    Last Name
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    name="lastName"
                                    value={updateUserFormik.values.lastName}
                                    onChange={onChange}
                                    onBlur={updateUserFormik.handleBlur}
                                    error={updateUserFormik.touched.lastName && Boolean(updateUserFormik.errors.lastName)}
                                    helperText={updateUserFormik.touched.lastName && updateUserFormik.errors.lastName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography component="h1">
                                    Phone number
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={updateUserFormik.values.phoneNumber}
                                    onChange={onChange}
                                    onBlur={updateUserFormik.handleBlur}
                                    error={updateUserFormik.touched.phoneNumber && Boolean(updateUserFormik.errors.phoneNumber)}
                                    helperText={updateUserFormik.touched.phoneNumber && updateUserFormik.errors.phoneNumber}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            form="updateMeForm"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={!(updateUserFormik.isValid && updateUserFormik.dirty)}
                        >
                            Save
                        </Button>
                    </Box>
                </form>
                </Box>
            </Container>
        </ThemeProvider>
        </React.Fragment>
    );
}

export default Profile;
