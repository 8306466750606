import * as React from "react";

import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { formatDate } from "../Leads/utils";
import universityApplicationColumns from "./universityApplicationColumns";

export default function UniversityApplicationTable(props) {
    const { applicationState, leads, setUrlParams } = props;
    const [expandedRows, setExpandedRows] = React.useState([]);

    const rows = React.useMemo(() => {
        return leads.flatMap((lead) => {
            const applicationRows = applicationState.applications
                .filter((application) => application.lead_id === lead.id)
                .map((application, index) => ({
                    id: `${lead.id}-${index}`,
                    parentId: lead.id,
                    student: "",
                    university: application.university,
                    university_id: application.university_id,
                    university_course: application.university_course,
                    major: application.major,
                    major_id: application.major_id,
                    offer_letter: application.offer_letter,
                    program: application.program,
                    program_id: application.program_id,
                    intake: application.intake,
                    intake_id: application.intake_id,
                    year: application.year,
                    email: "",
                    phone_number: "",
                    nationality: "",
                    added_by: application.added_by,
                    added_at: formatDate(application.added_at),
                    updated_by: application.updated_by,
                    updated_at: formatDate(application.updated_at),
                    status: application.status,
                    isLead: false,
                    application_id: application.id,
                    lead: lead,
                }));

            if (applicationRows.length > 0) {
                const leadRow = {
                    id: lead.id,
                    student: lead.student.full_name,
                    university: "",
                    university_id: "",
                    university_course: "",
                    major: "",
                    major_id: "",
                    offer_letter: "",
                    program: "",
                    program_id: "",
                    intake: "",
                    intake_id: "",
                    year: "",
                    email: lead.student.user.email,
                    phone_number: `${lead.student.calling_code || ''}${lead.student.phone_number}`,
                    nationality: lead.student.nationality,
                    added_by: "",
                    added_at: "",
                    updated_by: "",
                    updated_at: "",
                    status: "",
                    application_id: "",
                    isLead: true,
                };

                return [leadRow, ...applicationRows];
            }

            return [];
        });
    }, [applicationState.applications, leads]);

    const filteredRows = React.useMemo(() => {
        return rows.filter((row) => {
            if (row.isLead) {
                return true; // Always show lead rows
            }
            return expandedRows.includes(row.parentId);
        });
    }, [rows, expandedRows]);

    const handleExpandClick = (leadId) => {
        setExpandedRows((prevExpandedRows) =>
            prevExpandedRows.includes(leadId)
                ? prevExpandedRows.filter((id) => id !== leadId)
                : [...prevExpandedRows, leadId]
        );
    };

    const getRowClassName = (params) => {
        return params.row.isLead ? "MuiDataGrid-row--lead" : "";
    };


    return (
        <Box sx={{ width: "100%" }}>
            <DataGrid
                rows={filteredRows}
                columns={universityApplicationColumns(
                    expandedRows,
                    handleExpandClick,
                    setUrlParams
                )}
                getRowClassName={getRowClassName}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            // Hide columns id, the other columns will remain visible
                            id: false,
                        },
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 20, 50, 100]}
                checkboxSelection={false}
                disableRowSelectionOnClick
                sx={{
                    "& .MuiDataGrid-row": {
                        backgroundColor: "#ffffff", // White background for all rows
                    },
                    "& .MuiDataGrid-row--lead": {
                        backgroundColor: "#f5f5f5", // Different background color for lead rows
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        whiteSpace: "nowrap",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        whiteSpace: "nowrap",
                        overflow: "auto",
                    },
                }}
            />
        </Box>
    );
}
