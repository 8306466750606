import { useEffect } from "react";

/**
 * Custom hook to warn users about unsaved changes when they try to leave the page
 * @param {boolean} isDirty - Flag that indicates if the form has unsaved changes
 */
const useUnsavedChangesWarning = (isDirty) => {
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (isDirty) {
                event.preventDefault();
                event.returnValue = ""; // Show default browser alert
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [isDirty]);
};

export default useUnsavedChangesWarning;
