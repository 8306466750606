import { Box, Button, IconButton, Popover } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import MoreVertIcon from '@mui/icons-material/MoreVert'; // Optional icon for popover trigger
import React from 'react';

const DraftActionsPopover = ({ handleConvert, handleEdit, handleDelete, rowId, rowName, status }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            <IconButton aria-label="more-actions" onClick={handlePopoverOpen}>
                <MoreVertIcon />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box sx={{ p: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Button
                        fullWidth
                        startIcon={<GroupAddIcon />}
                        onClick={() => {
                            handleConvert(rowId);
                            handlePopoverClose();
                        }}
                        sx={{ justifyContent: 'flex-start' }}
                        disabled={status === 'Converted'}
                    >
                        Convert
                    </Button>
                    <Button
                        fullWidth
                        startIcon={<EditIcon />}
                        onClick={() => {
                            handleEdit(rowId);
                            handlePopoverClose();
                        }}
                        sx={{ justifyContent: 'flex-start' }}
                    >
                        Edit
                    </Button>
                    <Button
                        fullWidth
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                            handleDelete(rowId, rowName);
                            handlePopoverClose();
                        }}
                        sx={{ justifyContent: 'flex-start' }}
                    >
                        Delete
                    </Button>
                </Box>
            </Popover>
        </div>
    );
};

export default DraftActionsPopover;
