import * as React from 'react';

import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import TextField from '@mui/material/TextField';
import { addMajor } from '../../store/major.slice';
import { useDispatch } from 'react-redux';

function AddMajorDialog(props) {

    const dispatch = useDispatch();

    const [form, setForm] = React.useState({ majors: [''] });
    const [formErrors, setFormErrors] = React.useState({});
    const [isFormValid, setIsFormValid] = React.useState(false);
    const [disableAdd, setDisableAdd] = React.useState(true);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const errors = validateForm();
        const isValid = !Object.values(errors).some(error => error);

        setFormErrors(errors);
        setIsFormValid(isValid);

        if (isValid) {
            dispatch(addMajor(form.majors))
                .then((response) => {
                    if (response.type === 'major/addMajor/fulfilled') {
                        handleCloseDialog();
                    }
                });
        }
    };

    const validateForm = () => {
        let errors = {};
        // Validate each major input
        form.majors.forEach((major, index) => {
            if (!major.trim()) {
                errors[`major${index}`] = 'Major is required';
            } else {
                errors[`major${index}`] = '';
            }
        });
        return errors;
    };

    const handleChange = (index, value) => {
        const updatedMajors = [...form.majors];
        updatedMajors[index] = value;
        setForm({ ...form, majors: updatedMajors });

        let errors = { ...formErrors };
        errors[`major${index}`] = value.trim() ? '' : 'Major is required';
        setFormErrors(errors);

        const isValid = Object.values(errors).every(error => !error);
        setIsFormValid(isValid);
        setDisableAdd(!isValid || form.majors.length > 15);
    };

    const handleAddInput = () => {
        setForm({ ...form, majors: [...form.majors, ''] });
        setDisableAdd(true);
    };

    const handleDeleteInput = (index) => {
        const updatedMajors = [...form.majors];
        updatedMajors.splice(index, 1);
        setForm({ ...form, majors: updatedMajors });

        let errors = { ...formErrors };
        delete errors[`major${index}`];
        setFormErrors(errors);

        const isValid = Object.values(errors).every(error => !error);
        setIsFormValid(isValid);
        setDisableAdd(false);
    };

    const handleCloseDialog = () => {
        props.setOpenDialog(false);
        setForm({ majors: [''] });
        setFormErrors({});
        setIsFormValid(false);
    };

    return (
        <Dialog
            open={props.open}
            onClose={handleCloseDialog}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">Add Major</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please fill out the details below to add new majors.
                </DialogContentText>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    {props.majorState.error && props.majorState.action === 'addMajor' && <Alert sx={{ width: '100%', mt: 2, mb: 2 }} severity="error">{props.majorState.error}</Alert>}
                    <Grid container spacing={2}>
                        {form.majors.map((major, index) => (
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', padding: '16px' }} key={index}>
                                <Grid item xs={8} sm={8}>
                                    <TextField
                                        name={`major${index}`}
                                        required
                                        fullWidth
                                        id={`major${index}`}
                                        label={`Major ${index + 1}`}
                                        value={major}
                                        onChange={(e) => handleChange(index, e.target.value)}
                                        error={Boolean(formErrors[`major${index}`])}
                                        helperText={formErrors[`major${index}`]}
                                    />
                                </Grid>
                                {form.majors.length > 1 && (
                                    <Grid item xs={4} sm={4}>
                                        <IconButton
                                            aria-label="delete"
                                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}
                                            onClick={() => handleDeleteInput(index)}
                                        >
                                            <RemoveCircleIcon fontSize="inherit" sx={{ color: '#f44336' }} />
                                        </IconButton>
                                    </Grid>
                                )}
                            </div>
                        ))}

                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handleAddInput}
                                startIcon={<AddIcon />}
                                disabled={disableAdd}
                            >
                                Add
                            </Button>
                        </Grid>
                    </Grid>
                    <DialogActions sx={{ justifyContent: 'flex-start', px: 0 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={!isFormValid}
                        >
                            Save
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            color="error"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleCloseDialog}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

AddMajorDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpenDialog: PropTypes.func.isRequired,
    majorState: PropTypes.object.isRequired,
};

export default AddMajorDialog;
