import { Box, MenuItem } from "@mui/material";

import React from "react";
import axios from "axios";
import { toast } from "react-toastify";

export const setAxiosAuthToken = (token) => {
	if (typeof token !== "undefined" && token) {
		// Apply for every request
		axios.defaults.headers.common["Authorization"] = "Token " + token;
	} else {
		// Delete auth header
		delete axios.defaults.headers.common["Authorization"];
	}
};

export const toastOnError = (error) => {
	if (error.response) {
		// known error
		toast.error(JSON.stringify(error.response.data));
	} else if (error.message) {
		toast.error(JSON.stringify(error.message));
	} else {
		toast.error(JSON.stringify(error));
	}
};

export const arraysEqual = (a, b) => {
	if (a === b) return true;
	if (a == null || b == null) return false;
	if (a.length !== b.length) return false;

	for (let i = 0; i < a.length; ++i) {
		if (a[i] !== b[i]) return false;
	}
	return true;
};

export const generateMenuItems = (choices) => {
	return Object.entries(choices).map(([value, label], index) => (
		<MenuItem key={index} value={value}>
			{label}
		</MenuItem>
	));
};

export const renderCenteredCell = (value) => {
	return (
		<Box
			sx={{
				height: "100%",
				display: "flex",
				alignItems: "center",
				whiteSpace: "normal",
				lineHeight: 1.5,
				overflowWrap: "break-word",
				wordBreak: "break-word",
			}}
		>
			{value}
		</Box>
	);
};

export const isValidFee = (fee) => {
	return fee !== "" && !isNaN(fee) && Number(fee) >= 0;
};

export const isValidCurrency = (currency) => {
	return currency && currency.trim().length > 0;
};

/**
 * Checks if a given string is a valid URL.
 * @param {string} url - The URL to validate.
 * @returns {boolean} - Returns true if the URL is valid, otherwise false.
 */
export const isValidURL = (url) => {
	// Define the URL pattern
	const urlPattern = new RegExp(
		"^(https?:\\/\\/)?" + // protocol
		"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
		"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
		"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
		"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
		"(\\#[-a-z\\d_]*)?$", // fragment locator
		"i"
	);

	// Test the URL pattern
	return urlPattern.test(url);
};

/**
 * Check if a string a valid json
 * @param {*} str 
 * @returns boolean
 */
export function isJsonString(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

export const isValidGPA = (gpa, scale) => {
	switch (scale) {
		case "4.0":
			return gpa >= 0.0 && gpa <= 4.0;
		case "5.0":
			return gpa >= 0.0 && gpa <= 5.0;
		case "10.0":
			return gpa >= 0.0 && gpa <= 10.0;
		case "100":
			return gpa >= 0.0 && gpa <= 100;
		case "A-F":
			return /^[A-F]$/i.test(gpa);
		default:
			return false; // Unknown scale
	}
};

export const isValidEmail = (email) => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return emailRegex.test(email);
};

export const getUpdatedFields = (original, updated) => {
	return Object.keys(updated).reduce((changes, key) => {
		if (typeof updated[key] === "object" && updated[key] !== null && original[key]) {
			// Recursive comparison for nested objects
			const nestedChanges = getUpdatedFields(original[key], updated[key]);
			if (Object.keys(nestedChanges).length > 0) {
				changes[key] = nestedChanges;
			}
		} else if (original[key] !== updated[key]) {
			// Add changed fields directly
			changes[key] = updated[key];
		}
		return changes;
	}, {});
};

export function getStatusBackgroundColor(status) {
	switch (status) {
		case "Submitted":
			return "#4CAF50"; // Green
		case "Accepted":
			return "#2196F3"; // Blue
		case "Conditional Offer":
			return "#FF9800"; // Orange
		case "Unconditional Offer":
			return "#009688"; // Teal
		case "Rejected":
			return "#FF5722"; // Red
		case "Withdrawn":
			return "#FFC107"; // Yellow
		case "Pending":
			return "#9E9E9E"; // Gray
		default:
			return "#9E9E9E"; // Default to Gray for unknown statuses
	}
}

export function queryStringToObject(queryString) {
    const params = new URLSearchParams(queryString);
    const result = {};
    params.forEach((value, key) => {
        result[key] = value;
    });
    return result;
}
