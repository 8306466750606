import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";

import { fetchDraftLeads } from "../store/draftLead.slice";

function useDraftLead() {
    const dispatch = useDispatch();
    const lockRef = useRef(false);

    const draftLeadState = useSelector((state) => state.draftLead);

    const draftLeads = draftLeadState.draftLeads;
    const draftLeadStatus = draftLeadState.status;
    const loading = draftLeadState.loading;

    useEffect(() => {
        async function onLoad() {
            if (draftLeadStatus === "idle" && !lockRef.current) {
                lockRef.current = true;
                dispatch(fetchDraftLeads()).finally(() => {
                    // Release lock after completion
                    lockRef.current = false;
                });
            }
        }

        onLoad();
    }, [draftLeadStatus, dispatch]);

    return {
        draftLeads,
        loading,
    };
}

export default useDraftLead;
