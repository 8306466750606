import "../styles/dashboard.css";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useSearchParams } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import Loading from "./common/Loading";
import { getUserByMagicLink, activateUser } from "../store/user.slice";
import { LOGO_SRC } from "../const";

function ActivatePage(props) {
	const defaultTheme = createTheme();

	return (
		<ThemeProvider theme={defaultTheme}>
			<Container justify="center">
				<React.Suspense fallback={<Loading />}>
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						minHeight="100vh"
					>
						<CssBaseline />
						<Grid
							container
							width="700px"
							justify="center"
							style={{ padding: "10px 10px" }}
						>
							{props.children}
						</Grid>
					</Box>
				</React.Suspense>
			</Container>
		</ThemeProvider>
	);
}

const validationSchema = Yup.object({
	firstName: Yup.string().max(20, "Must be 20 characters or less"),
	lastName: Yup.string().max(20, "Must be 20 characters or less"),
	password: Yup.string()
		.min(8, "Your password is too short. Min 8 Characters")
		.required("Password is required"),
	passwordConfirmation: Yup.string()
		.oneOf([Yup.ref("password")], "Passwords must match")
		.required("Confirm your password"),
});

export default function ActivateUser() {
	const [searchParams] = useSearchParams();
	const [user, setUser] = React.useState({ fist_name: "", last_name: "" });
	const [activationStatus, setActivationStatus] = React.useState("STARTED");

	const userState = useSelector((state) => state.user);
	const dispatch = useDispatch();

	const navigate = useNavigate();

	const magiclink = searchParams.get("id");

	// set Status
	React.useEffect(() => {

		if ((magiclink === "") | (magiclink === null)) {
			setActivationStatus("LINK_NOT_VALID");
		} else if (
			(activationStatus == "STARTED") &
			(magiclink !== "") &
			(magiclink !== null)
		) {
			setActivationStatus("LOAD");
		}
	}, [activationStatus, magiclink]);

	// initialize Page
	React.useEffect(() => {
		async function onLoad() {
			if (activationStatus === "LOAD") {
				setActivationStatus("FETCHED");
				dispatch(getUserByMagicLink(magiclink));
			}
		}
		onLoad();
		if (userState.status === "succeeded") {
			const data = {
				first_name: userState.users.user["first_name"],
				last_name: userState.users.user["last_name"],
			};
			setUser(data, userState);
		}
	}, [activationStatus, userState, dispatch, magiclink]);

	// initialize Formik
	const activateUserFormik = useFormik({
		initialValues: {
			firstName: user.first_name,
			lastName: user.last_name,
			password: "",
			passwordConfirmation: "",
		},
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: (values) => {
			const data = {
				first_name: values.firstName,
				last_name: values.lastName,
				password: values.password,
			};
			dispatch(activateUser({ magiclink: magiclink, data })).then(
				(response) => {
					if (response.type === "user/activateUser/fulfilled") {
						setActivationStatus("COMPLETED");
					}
				}
			);
		},
	});

	if (userState.loading) {
		return (
			<React.Fragment>
				<ActivatePage>
					<Grid item md={12}>
						<Loading />
					</Grid>
				</ActivatePage>
			</React.Fragment>
		);
	} else if (activationStatus == "LINK_NOT_VALID") {
		return (
			<ActivatePage>
				<Alert variant="filled" severity="error">
					Error: Link not valid or expired. Please contact your
					administrator.
				</Alert>
			</ActivatePage>
		);
	} else if (userState.error && userState.action === "getUserByMagicLink") {
		return (
			<ActivatePage>
				<Alert variant="filled" severity="error">
					Error: Link not valid or user already activate. Please
					contact your administrator.
				</Alert>
			</ActivatePage>
		);
	} else {
		return (
			<ActivatePage>
				<React.Fragment>
					<Grid item md={12}>
						<Card>
							<Grid
								md={12}
								item
								container
								direction="column"
								justifyContent="center"
								alignItems="center"
								style={{
									paddingBottom: "10px",
									paddingTop: "20px",
								}}
							>
								<Avatar
									src={LOGO_SRC}
									alt="Edvantage"
									sx={{
										width: "40%",
										height: 90,
										borderRadius: 0,
										"& img": {
											objectFit: "contain",
										},
									}}
								/>
							</Grid>
							<CardHeader title="Please complete your profile to activate your account"></CardHeader>
							{activationStatus == "FETCHED" && (
								<React.Fragment>
									<form
										id="activateUserForm"
										onSubmit={
											activateUserFormik.handleSubmit
										}
									>
										<CardContent>
											<Grid
												item
												container
												spacing={1}
												justify="center"
											>
												{userState.error &&
													userState.action ===
														"activateUser" && (
														<Alert
															sx={{
																width: "100%",
																mt: 2,
																mb: 2,
															}}
															severity="error"
														>
															{userState.error}
														</Alert>
													)}
												<Grid
													item
													xs={12}
													sm={6}
													md={6}
												>
													<TextField
														label="First Name"
														fullWidth
														name="firstName"
														value={String(
															activateUserFormik
																.values
																.firstName
														)}
														onChange={
															activateUserFormik.handleChange
														}
														onBlur={
															activateUserFormik.handleBlur
														}
														error={
															activateUserFormik
																.touched
																.firstName &&
															Boolean(
																activateUserFormik
																	.errors
																	.firstName
															)
														}
														helperText={
															activateUserFormik
																.touched
																.firstName &&
															activateUserFormik
																.errors
																.firstName
														}
													/>
												</Grid>
												<Grid
													item
													xs={12}
													sm={6}
													md={6}
												>
													<TextField
														label="Last Name"
														fullWidth
														name="lastName"
														value={
															activateUserFormik
																.values.lastName
														}
														onChange={
															activateUserFormik.handleChange
														}
														onBlur={
															activateUserFormik.handleBlur
														}
														error={
															activateUserFormik
																.touched
																.lastName &&
															Boolean(
																activateUserFormik
																	.errors
																	.lastName
															)
														}
														helperText={
															activateUserFormik
																.touched
																.lastName &&
															activateUserFormik
																.errors.lastName
														}
													/>
												</Grid>
												<Grid
													item
													xs={12}
													sm={6}
													md={6}
												>
													<TextField
														label="Password"
														type="password"
														fullWidth
														name="password"
														value={
															activateUserFormik
																.values.password
														}
														onChange={
															activateUserFormik.handleChange
														}
														onBlur={
															activateUserFormik.handleBlur
														}
														error={
															activateUserFormik
																.touched
																.password &&
															Boolean(
																activateUserFormik
																	.errors
																	.password
															)
														}
														helperText={
															activateUserFormik
																.touched
																.password &&
															activateUserFormik
																.errors.password
														}
													/>
												</Grid>
												<Grid
													item
													xs={12}
													sm={6}
													md={6}
												>
													<TextField
														label="Confirm Password"
														type="password"
														fullWidth
														name="passwordConfirmation"
														value={
															activateUserFormik
																.values
																.passwordConfirmation
														}
														onChange={
															activateUserFormik.handleChange
														}
														onBlur={
															activateUserFormik.handleBlur
														}
														error={
															activateUserFormik
																.touched
																.passwordConfirmation &&
															Boolean(
																activateUserFormik
																	.errors
																	.passwordConfirmation
															)
														}
														helperText={
															activateUserFormik
																.touched
																.passwordConfirmation &&
															activateUserFormik
																.errors
																.passwordConfirmation
														}
													/>
												</Grid>
											</Grid>
										</CardContent>
										<CardActions
											sx={{
												paddingTop: "40px",
												justifyContent: "flex-start",
											}}
										>
											<Grid
												item
												md={12}
												container
												direction="column"
												justifyContent="center"
												alignItems="center"
												style={{
													paddingBottom: "15px",
												}}
											>
												<Button
													variant="contained"
													color="primary"
													type="Submit"
													form="activateUserForm"
													disabled={
														!(
															activateUserFormik.isValid &&
															activateUserFormik.dirty
														)
													}
												>
													Activate
												</Button>
											</Grid>
										</CardActions>
									</form>
								</React.Fragment>
							)}
							{activationStatus == "COMPLETED" && (
								<React.Fragment>
									<div style={{ padding: "30px 10px" }}>
										<Alert
											variant="outlined"
											severity="success"
										>
											Your profile has been activated
										</Alert>
									</div>
									<CardActions
										sx={{
											paddingTop: "40px",
											justifyContent: "flex-start",
										}}
									>
										<Grid
											md={12}
											container
											direction="column"
											justifyContent="center"
											alignItems="center"
											style={{ paddingBottom: "15px" }}
										>
											<Button
												variant="contained"
												color="primary"
												onClick={() => navigate("/dashboard")}
											>
												Home
											</Button>
										</Grid>
									</CardActions>
								</React.Fragment>
							)}
						</Card>
					</Grid>
				</React.Fragment>
			</ActivatePage>
		);
	}
}
