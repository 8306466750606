import * as React from 'react';

import { addIntake, setError } from '../../store/intake.slice';

import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { INTAKE_SEASON_CHOICES } from '../../const';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import TextField from '@mui/material/TextField';
import { generateMenuItems } from '../../utils/Utils';
import { useDispatch } from 'react-redux';

/**
 * A dialog component to add new intakes.
 *
 * This component displays a dialog box with a form to add new intakes. The form
 * contains fields for the season and year of the intake. The user can add more
 * input fields by clicking the "Add" button. The user can delete input fields by
 * clicking the "Delete" button. The user can submit the form by clicking the
 * "Save" button. The user can cancel the dialog box by clicking the "Cancel"
 * button.
 *
 * The component also displays any error messages returned by the server.
 *
 * @param {Object} props The component props.
 * @param {boolean} props.open Whether the dialog box should be open or not.
 * @param {function} props.setOpenDialog A function to set the open state of the dialog box.
 * @param {Object} props.intakeState The state of the intakes from the Redux store.
 * @returns {ReactElement} The component element.
 */
function AddIntakeDialog(props) {

    const dispatch = useDispatch();

    const existingIntakesSet = new Set(
        props.intakeState.intakes.map(intake => intake.season)
    );

    const [form, setForm] = React.useState([{ season: '' }]);
    const [formErrors, setFormErrors] = React.useState({});
    const [isFormValid, setIsFormValid] = React.useState(false);
    const [disableAdd, setDisableAdd] = React.useState(true);

    const hasDuplicateIntakes = () => {
        const errors = [];
        const formCombinationsSet = new Set(); // To store combinations within the form

        form.forEach((intake) => {
            const key = `${INTAKE_SEASON_CHOICES[intake.season]}-${intake.year}`;

            // Check if the intake already exists in the existing intakes
            if (existingIntakesSet.has(key)) {
                errors.push(`Intake for ${intake.season} already exists.`);
            }

            // Check for duplicates within the form itself
            if (formCombinationsSet.has(key)) {
                errors.push(`Duplicate intake for ${intake.season} in the form.`);
            } else {
                formCombinationsSet.add(key);
            }
        });

        return errors;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const isFormValid = validateForm(form);
        const duplicateErrors = hasDuplicateIntakes();

        const isValid = isFormValid && duplicateErrors.length === 0;

        setIsFormValid(isValid);

        if (isValid) {
            dispatch(addIntake({ intakes: form, uuid: props.uuid }))
                .then((response) => {
                    if (response.type === 'intake/addIntake/fulfilled') {
                        handleCloseDialog();
                    }
                });
        } else if (duplicateErrors.length > 0) {
            dispatch(setError({ action: "addIntake", error: duplicateErrors.join('\n') }));
        }
    };

    const validateForm = (newForm) => {
        let valid = true; // Assume the form is valid to start with
        const errors = {};

        newForm.forEach((intake, index) => {
            // Validate Season field
            if (!intake.season) {
                valid = false;
                errors[`season-${index}`] = 'Season is required';
            }
        });

        setFormErrors(errors); // Update the form errors state

        return valid; // Return whether the form is valid or not
    };

    const handleChange = (index, field, value) => {
        const updatedForm = [...form];
        updatedForm[index] = {
            ...updatedForm[index],
            [field]: value,
        };
        setForm(updatedForm);

        const isValid = validateForm(updatedForm);
        setIsFormValid(isValid);
        setDisableAdd(!isValid || form.length > 15);
    };

    const handleAddInput = () => {
        setForm([...form, { season: '' }]);
        setDisableAdd(true);
    };

    const handleDeleteInput = (index) => {
        const updatedForm = [...form]; // Assuming form is directly an array of objects
        updatedForm.splice(index, 1);
        setForm(updatedForm);

        const isValid = validateForm(updatedForm);
        setIsFormValid(isValid);

        setDisableAdd(false);
    };

    const handleCloseDialog = () => {
        props.setOpenDialog(false);
        dispatch(setError({ action: '', error: null }));
        setForm([{ season: '' }]);
        setFormErrors({});
        setIsFormValid(false);
    };

    return (
        <Dialog
            open={props.open}
            onClose={handleCloseDialog}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">Add Intake</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please fill out the details below to add new intakes.
                </DialogContentText>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    {props.intakeState.error && props.intakeState.action === 'addIntake' && <Alert sx={{ width: '100%', mt: 2, mb: 2 }} severity="error">{props.intakeState.error}</Alert>}
                    {form.map((intake, index) => (
                        <Grid container key={index} spacing={2}>
                            <Grid item xs={6} sm={6}>
                                <TextField
                                    select
                                    fullWidth
                                    label={`Season`}
                                    name={`season-${index}`}
                                    value={intake.season}
                                    onChange={(e) => handleChange(index, 'season', e.target.value)}
                                    error={Boolean(formErrors[`season-${index}`])}
                                    helperText={formErrors[`season-${index}`]}
                                    margin='dense'
                                    required
                                >
                                    {generateMenuItems(INTAKE_SEASON_CHOICES)}
                                </TextField>
                            </Grid>
                            {form.length > 1 && (
                                <Grid item xs={2} sm={2}>
                                    <IconButton
                                        aria-label="delete"
                                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}
                                        onClick={() => handleDeleteInput(index)}
                                    >
                                        <RemoveCircleIcon fontSize="inherit" sx={{ color: '#f44336' }} />
                                    </IconButton>
                                </Grid>
                            )}
                        </Grid>
                    ))}

                    <Grid item xs={12} sx={{ mt: 3 }}>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleAddInput}
                            startIcon={<AddIcon />}
                            disabled={disableAdd}
                        >
                            Add
                        </Button>
                    </Grid>
                    <DialogActions sx={{ justifyContent: 'flex-start', px: 0 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={!isFormValid}
                        >
                            Save
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            color="error"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleCloseDialog}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

AddIntakeDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpenDialog: PropTypes.func.isRequired,
    intakeState: PropTypes.object.isRequired,
};

export default AddIntakeDialog;
