import * as React from "react";

import { Chip, Typography } from "@mui/material";

import ActionsPopover from "./ActionsPopover";
import { DataGrid } from "@mui/x-data-grid";
import Alert from "@mui/material/Alert";

function getColumns(handleEdit, handleDisable, handleReset, handleAddRemoveGroup) {
	return [
		{ 
			field: "id", headerName: "id",  minWidth: 90
		},
		
		{
			field: "Full Name",
			headerName: "Full Name",
			description: "User full name",
			sortable: true,
			flex: 1,
			minWidth: 200,
			valueGetter: (value, row) =>
				`${row.user.first_name || ""} ${row.user.last_name || ""}`,
			renderCell: (params) => (
				<Typography
					sx={{
						color: "#5b5959",
						lineHeight: "calc(var(--height) - 1px)",
						fontFamily: '"Public Sans", sans-serif',
						fontWeight: 600,
						fontSize: "0.875rem",
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
					}}
				>
					{params.value}
				</Typography>
			),
		},
		{
			field: "user_email",
			headerName: "Email",
			flex: 2,
			minWidth: 200,
			valueGetter: (value, row) => row.user.email,
		},
		{
			field: "group",
			headerName: "Groups",
			flex: 1,
			minWidth: 150,
			valueGetter: (value, row) => { return row.user.groups.map( group => group.name).join(', ')}
		},
		{
			field: "phone_number",
			headerName: "Phone Number",
			flex: 2,
			minWidth: 200
		},
		{
			field: "last log-in",
			headerName: "Last Log-In",
			minWidth: 200,
			valueGetter: (value, row) => { return row.user.last_login? new Date(row.user.last_login).toISOString().split("T")[0] : "-" },
		},
		{ 
			field: "status", minWidth: 20, maxWidth: 100, headerName: "Status",
			renderCell: (params) => (
				<Chip
					label={params.value}
					variant="contained"
					size="small"
					sx={{
						backgroundColor: getStatusBackgroundColor(params.value),
						color: "#FFFFFF",
					}}
				/>
			),
		},
		{
			field: "actions",
			headerName: "",
			maxWidth: 40,
			renderCell: (params) => (
				<ActionsPopover 
					handleEdit={() => handleEdit(params.row)}
					handleDisable={() => handleDisable(params.row)}
					handleReset={() => handleReset(params.row)}
					handleAddRemoveGroup= {() => handleAddRemoveGroup(params.row)}
				/>
			),
		},
	];
}

function getStatusBackgroundColor(status) {
	switch (status.toLowerCase()) {
		case "active":
			return "#4CAF50"; // Green
		case "pending":
			return "#9E9E9E"; // Gray
		case "disabled":
			return "#FF0007"; // red
		default:
			return "#9E9E9E"; // Gray
	}
}

export default function UserTable(props) {
	const { userState, handleEdit, handleDisable, handleReset, handleAddRemoveGroups } = props;

	return (
		userState.users.length ? (
			<DataGrid
			rows={userState.users}
			columns={getColumns(handleEdit, handleDisable, handleReset, handleAddRemoveGroups)}
			initialState={{
				columns: {
					columnVisibilityModel: {
						// Hide columns id, the other columns will remain visible
						id: false,
					},
				},
				sorting: {
					sortModel: [
						{
							field: "updated_at", // Sort by updated_at field
							sort: "desc", // Sort in descending order
						},
					],
				},
				pagination: {
					paginationModel: { page: 0, pageSize: 5 },
				},
			}}
			pageSizeOptions={[5, 10]}
			checkboxSelection={false}
			disableRowSelectionOnClick
		/>
		) :  (
			<Alert
				sx={{ width: "100%", mt: 2, mb: 2 }}
				severity="warning"
			>
				No Users Found! Add an user
			</Alert>
		)
	);
}
