import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import { fetchSchools } from "../store/school.slice";

function useSchool() {
	const dispatch = useDispatch();

	const schools = useSelector((state) => state.school.schools);
	const schoolStatus = useSelector((state) => state.school.status);

	useEffect(() => {
		async function onLoad() {
			if (schoolStatus === "idle") {
				dispatch(fetchSchools());
			}
		}

		onLoad();
	}, [schoolStatus, dispatch]);

	return {
		schools,
	};
}

export default useSchool;
