import * as React from "react";

import { generateMenuItems, isValidEmail } from "../../utils/Utils";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import CountrySelect from "../common/CountrySelect";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { GENDER_CHOICES } from "../../const";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { clearError } from "../../store/student.slice";
import parsePhoneNumber from "libphonenumber-js";
import { useDispatch } from 'react-redux';
import { useEdvantageContext } from "../../EdvantageProvider";

export default function StudentForm(props) {
	const { leadData, setLeadData, studentState, isNewStudent, setIsStudentFormValid, isDraftLead } = props;

	const { countries } = useEdvantageContext();
	const dispatch = useDispatch();

	const [isStudentFormVisible, setIsStudentFormVisible] =
		React.useState(false);
	const [formErrors, setFormErrors] = React.useState({
		first_name: "",
		last_name: "",
		email: "",
		calling_code: "",
		phone_number: "",
		dob: "",
		gender: "",
		nationality: "",
		isAddressAvailable: false,
		address_line_1: "",
		address_line_2: "",
		city: "",
		postal_code: "",
		country: "",
	});

	const [touched, setTouched] = React.useState({
		first_name: !isNewStudent,
		last_name: !isNewStudent,
		email: !isNewStudent,
		calling_code: !isNewStudent,
		phone_number: !isNewStudent,
		dob: !isNewStudent,
		gender: !isNewStudent,
		nationality: !isNewStudent,
		isAddressAvailable: !isNewStudent,
		address_line_1: !isNewStudent,
		address_line_2: !isNewStudent,
		city: !isNewStudent,
		postal_code: !isNewStudent,
		country: !isNewStudent,
	});

	// Update touched state onBlur event
	const handleBlur = (field) => {
		setTouched((prevTouched) => ({
			...prevTouched,
			[field]: true,
		}));
	};

	const validateStudentForm = (form) => {
		const errors = {};
		// if (!leadData.student.id && touched.id) errors.id = "Student is required";
		if (!form.user.first_name) {
			errors.first_name = "First name is required";
		} else if (form.user.first_name.length < 3) {
			errors.first_name = "Please provide a vald first name";
		}

		if (!form.user.last_name)
			errors.last_name = "Last name is required";
		if (!isValidEmail(form.user.email))
			errors.email = "Please provide a valid email";
		if (!form.calling_code) {
			errors.calling_code = "Calling code is required";
		} else if (!form.phone_number) {
			errors.phone_number = "Phone number is required";
		} else {
			const phoneNumber = parsePhoneNumber(form.phone_number, form.phone_country_code);
			if (!phoneNumber || !phoneNumber.isValid()) {
				errors.phone_number = "Phone number is invalid";
			}
		}
		if (!form.gender) errors.gender = "Gender is required";
		if (!form.nationality)
			errors.nationality = "Nationality is required";
		if (
			form.is_address_available &&
			!form.address.country
		)
			errors.country = "Country is required";

		setFormErrors(errors);
		return errors;
	};

	React.useEffect(() => {
		setIsStudentFormVisible(isNewStudent || leadData.student.id !== "");
	}, [leadData.student]);

	const handleChange = (event) => {
		const { name, value, checked } = event.target;

		// Deep copy leadData to avoid mutating read-only properties
		let updatedLeadData = {
			...leadData,
			student: {
				...leadData.student,
				user: { ...leadData.student.user },
				address: { ...leadData.student.address },
			}
		};

		if (name === "isAddressAvailable") {
			updatedLeadData.student.is_address_available = checked;

			if (!checked) {
				// Clear address fields if unchecked
				updatedLeadData.student.address = {
					address_line_1: "",
					address_line_2: "",
					city: "",
					postal_code: "",
					country: "",
				};
			}
		} else {
			// Update fields based on name
			if (name in updatedLeadData.student.user) {
				updatedLeadData.student.user = { ...updatedLeadData.student.user, [name]: value };
			} else if (name in updatedLeadData.student.address) {
				updatedLeadData.student.address = { ...updatedLeadData.student.address, [name]: value };
			} else {
				updatedLeadData.student = { ...updatedLeadData.student, [name]: value };
				if (name === "calling_code") {
					updatedLeadData.student.phone_country_code = countries.find(
						(country) => country.calling_code === value
					).code2;
				}
			}
		}

		// Update leadData state with the modified updatedLeadData
		setLeadData(updatedLeadData);
		const errors = validateStudentForm(updatedLeadData.student);
		setIsStudentFormValid(Object.keys(errors).length === 0);
	};

	// Clear errors upon unmount
	React.useEffect(() => {
		if (isDraftLead) {
			const errors = validateStudentForm(leadData.student);
			setIsStudentFormValid(Object.keys(errors).length === 0);
		}

		return () => {
			dispatch(clearError());
		};
		// Empty dependency array ensures this only runs once on mount
	}, []);

	return (
		<Box
			component="form"
			noValidate
			sx={{
				mt: 3,
				display: isStudentFormVisible ? "flex" : "none",
				flexDirection: "column",
			}}
		>
			{studentState.error && studentState.action === "addStudent" && (
				<Alert sx={{ width: "100%", mt: 2, mb: 2 }} severity="error">
					{studentState.error}
				</Alert>
			)}
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<TextField
						name="first_name"
						required
						fullWidth
						id="firstName"
						label="First name"
						autoFocus
						value={leadData.student.user.first_name || ""}
						onChange={handleChange}
						onBlur={() => handleBlur("first_name")}
						error={Boolean(touched.first_name && formErrors.first_name)}
						helperText={touched.first_name && formErrors.first_name}
						disabled={!isNewStudent}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						name="last_name"
						required
						fullWidth
						id="lastName"
						label="Last name"
						value={leadData.student.user.last_name}
						onChange={handleChange}
						onBlur={() => handleBlur("last_name")}
						error={Boolean(touched.last_name && formErrors.last_name)}
						helperText={touched.last_name && formErrors.last_name}
						disabled={!isNewStudent}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						name="email"
						required
						fullWidth
						id="email"
						label="Email Address"
						autoComplete="email"
						onChange={handleChange}
						onBlur={() => handleBlur("email")}
						value={leadData.student.user.email}
						error={Boolean(touched.email && formErrors.email)}
						helperText={touched.email && formErrors.email}
						disabled={!isNewStudent}
					/>
				</Grid>
				<Grid item xs={4} sm={2}>
					<FormControl
						fullWidth
						disabled={!isNewStudent}
						error={Boolean(touched.calling_code && formErrors.calling_code)}
					>
						<InputLabel id="select-country-code">
							Calling Code
						</InputLabel>
						<Select
							name="calling_code"
							value={leadData.student.calling_code || ""}
							label="Calling Code"
							onChange={handleChange}
						>
							<MenuItem key="0" value="">
								<em>None</em>
							</MenuItem>
							{countries.map((country) => (
								<MenuItem
									key={country.code2}
									value={country.calling_code}
								>
									{country.code2} ({country.calling_code})
								</MenuItem>
							))}
						</Select>
						<FormHelperText>
							{touched.calling_code && formErrors.calling_code}
						</FormHelperText>
					</FormControl>
				</Grid>
				<Grid item xs={8} sm={4}>
					<TextField
						name="phone_number"
						required
						fullWidth
						id="phoneNumber"
						label="Phone Number"
						autoComplete="phoneNumber"
						value={leadData.student.phone_number || ""}
						onChange={handleChange}
						onBlur={() => handleBlur("phone_number")}
						error={Boolean(touched.phone_number && formErrors.phone_number)}
						helperText={touched.phone_number && formErrors.phone_number}
						disabled={!isNewStudent}
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<TextField
						name="dob"
						label="D.O.B."
						type="date"
						value={leadData.student.dob}
						onChange={handleChange}
						onBlur={() => handleBlur("dob")}
						disabled={!isNewStudent}
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{
							max: new Date().toISOString().split("T")[0], // Disable future dates
						}}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<TextField
						select
						name="gender"
						label="Gender"
						value={leadData.student.gender || ""}
						onChange={handleChange}
						onBlur={() => handleBlur("gender")}
						required
						disabled={!isNewStudent}
						error={Boolean(touched.gender && formErrors.gender)}
						helperText={touched.gender && formErrors.gender}
						fullWidth
					>
						{generateMenuItems(GENDER_CHOICES)}
					</TextField>
				</Grid>
				<Grid item xs={12} sm={4}>
					<CountrySelect
						name="nationality"
						label="Nationality"
						value={leadData.student.nationality || ""}
						onChange={handleChange}
						onBlur={() => handleBlur("nationality")}
						error={Boolean(touched.nationality && formErrors.nationality)}
						helperText={touched.nationality && formErrors.nationality ? formErrors.nationality : ""}
						disabled={!isNewStudent}
						required
					/>
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel
						control={
							<Switch
								checked={
									leadData.student.is_address_available ||
									false
								}
								onChange={handleChange}
								name="isAddressAvailable"
								color="primary"
								disabled={!isNewStudent}
							/>
						}
						label="Is address available?"
					/>
				</Grid>
				{leadData.student.is_address_available && (
					<React.Fragment>
						<Grid item xs={12} sm={6}>
							<TextField
								name="address_line_1"
								required
								fullWidth
								id="addressLineOne"
								label="Address line 1"
								value={
									leadData.student.address.address_line_1 ||
									""
								}
								onChange={handleChange}
								onBlur={() => handleBlur("addressLineOne")}
								error={Boolean(touched.addressLineOne && formErrors.addressLineOne)}
								helperText={touched.addressLineOne && formErrors.addressLineOne}
								disabled={!isNewStudent}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								name="address_line_2"
								fullWidth
								id="addressLineTwo"
								label="Address line 2"
								value={
									leadData.student.address.address_line_2 ||
									""
								}
								onChange={handleChange}
								onBlur={() => handleBlur("addressLineTwo")}
								disabled={!isNewStudent}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								name="city"
								required
								fullWidth
								id="city"
								label="City"
								value={leadData.student.address.city || ""}
								onChange={handleChange}
								onBlur={() => handleBlur("city")}
								error={Boolean(touched.city && formErrors.city)}
								helperText={touched.city && formErrors.city}
								disabled={!isNewStudent}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								name="postal_code"
								required
								fullWidth
								id="postalCode"
								label="Postal Code"
								value={
									leadData.student.address.postal_code || ""
								}
								onChange={handleChange}
								onBlur={() => handleBlur("postalCode")}
								error={Boolean(touched.postalCode && formErrors.postalCode)}
								helperText={touched.postalCode && formErrors.postalCode}
								disabled={!isNewStudent}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<CountrySelect
								name="country"
								label="Country"
								value={leadData.student.address.country || ""}
								onChange={handleChange}
								onBlur={() => handleBlur("country")}
								error={Boolean(touched.country && formErrors.country)}
								helperText={touched.country && formErrors.country ? formErrors.country : ""}
								disabled={!isNewStudent}
							/>
						</Grid>
					</React.Fragment>
				)}
			</Grid>
		</Box>
	);
}
