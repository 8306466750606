import { Box, Grid, TextField } from "@mui/material";
import React from "react";

import { Autocomplete } from "@react-google-maps/api";
import CountrySelect from "../common/CountrySelect";

export default function SchoolForm({
	handleChange,
	handleChangeBulk,
	schoolData,
	errors,
}) {
	const [searchResult, setSearchResult] = React.useState(null);

	const getFirstLineOfAddress = (addressComponents) => {
		// Extract address components
		const streetNumberComponent = addressComponents.find((component) =>
			component.types.includes("street_number")
		);
		const routeComponent = addressComponents.find((component) =>
			component.types.includes("route")
		);

		// Combine street number and route to form the first line of the address
		const streetNumber = streetNumberComponent
			? streetNumberComponent.long_name
			: "";
		const route = routeComponent ? routeComponent.long_name : "";

		return `${streetNumber} ${route}`.trim();
	};

	const onPlaceChanged = () => {
		if (searchResult) {
			const place = searchResult.getPlace();
			const name = place.name || "";
			const addressComponents = place.address_components || [];
			const formattedAddress = place.formatted_address || "";
			const website = place.website || "";
			const google_maps_url = place.url || "";
			let city = "";
			let postalCode = "";
			let country = "";

			addressComponents.forEach((component) => {
				if (component.types.includes("locality")) {
					city = component.long_name;
				}
				if (component.types.includes("postal_code")) {
					postalCode = component.long_name;
				}
				if (component.types.includes("country")) {
					country = component.long_name;
				}
			});

			handleChangeBulk({
				name,
				addressLine1:
					getFirstLineOfAddress(addressComponents) ||
					formattedAddress,
				city,
				postalCode,
				country,
				website,
				google_maps_url,
				photos: place.photos ? place.photos.map(photo => photo.getUrl({ minWidth: 1000 })) : []
			});
		}
	};

	return (
		<Box component="form" sx={{ width: "100%" }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Autocomplete
						onPlaceChanged={onPlaceChanged}
						onLoad={(autocomplete) => setSearchResult(autocomplete)}
					>
						<TextField
							fullWidth
							label="School Name"
							name="name"
							value={schoolData.name}
							onChange={handleChange}
							required
							error={Boolean(errors.name)}
							helperText={errors.name}
						/>
					</Autocomplete>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						label="Address Line 1"
						name="addressLine1"
						value={schoolData.addressLine1}
						onChange={handleChange}
						required
						error={Boolean(errors.addressLine1)}
						helperText={errors.addressLine1}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						label="Address Line 2"
						name="addressLine2"
						value={schoolData.addressLine2}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						label="City"
						name="city"
						value={schoolData.city}
						onChange={handleChange}
						required
						error={Boolean(errors.city)}
						helperText={errors.city}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						label="Postal Code "
						name="postalCode"
						value={schoolData.postalCode}
						onChange={handleChange}
						required
						error={Boolean(errors.postalCode)}
						helperText={errors.postalCode}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<CountrySelect
						name="country"
						label="Country"
						value={schoolData.country}
						onChange={handleChange}
						error={Boolean(errors.country)}
						helperText={errors.country}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						label="Website"
						name="website"
						value={schoolData.website}
						onChange={handleChange}
						required
						error={Boolean(errors.website)}
						helperText={errors.website}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						label="Google Maps URL"
						name="google_maps_url"
						value={schoolData.google_maps_url}
						onChange={handleChange}
					/>
				</Grid>
			</Grid>
		</Box>
	);
}
