import "jspdf-autotable";

import {
	DataGrid,
	GridToolbarColumnsButton,
	GridToolbarContainer,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import React from "react";
import SchoolExportButton from "./SchoolExportButton";
import schoolColumns from "./schoolColumns";

/**
 * A custom toolbar for the school data grid. It includes buttons to add a
 * new school and to export the data as a PDF.
 *
 * @param {Object} props The component props.
 * @param {Array} props.rows The data rows to be displayed in the grid.
 * @param {Array} props.columns The columns to be displayed in the grid.
 * @param {Array} props.schools The list of schools to be used in the export.
 * @param {Function} props.filterCourse A function to filter the courses in the
 * export, given the current course filter.
 * @param {Function} props.setUrlParams A function to set the URL parameters.
 */
function CustomToolbar(props) {

	const handleAddClick = () => {
		props.setUrlParams(prevParams => ({
			...prevParams,
			view: "add"
		}));
	};

	return (
		<GridToolbarContainer>
			<Button
				variant="text"
				color="primary"
				startIcon={<AddIcon />}
				onClick={handleAddClick}
			>
				Add School
			</Button>
			<GridToolbarColumnsButton />
			<SchoolExportButton
				rows={props.rows}
				columns={props.columns}
				schools={props.schools}
				filterCourse={props.filterCourse}
			/>
		</GridToolbarContainer>
	);
}

export default function SchoolTable(props) {
	const { searchFilters, schools, filterCourse, onViewDetails, setUrlParams, handleDelete } = props;

	const [expandedRows, setExpandedRows] = useState({});
	const [filteredRows, setFilteredRows] = useState([]);

	useEffect(() => {
		const rows = schools.flatMap((school) => {
			const schoolRow = {
				id: school.id,
				name: school.name,
				program: "",
				website: school.website,
				city: school.address.city,
				country: school.address.country,
				google_maps_url: school.google_maps_url,
				isSchool: true,
			};
			const courseRows =
				filterCourse || expandedRows[school.id]
					? school.courses.map((course, index) => ({
						id: `${school.id}-${index}`,
						name: "", // Empty for course rows
						program: course.program,
						website: "", // Empty for course rows
						city: "", // Empty for course rows
						country: "", // Empty for course rows
						google_maps_url: "", // Empty for course rows
						isSchool: false,
					}))
					: [];
			return [schoolRow, ...courseRows];
		});

		const filteredSchoolIds = new Set();
		const filteredCourseParentIds = new Set();
		const newFilteredRows = rows.filter((row) => {
			if (
				Object.values(searchFilters).every((filter) => {
					if (Array.isArray(filter)) {
						return filter.length === 0;
					} else if (typeof filter === "boolean") {
						return filter === undefined;
					}
					return !filter.trim();
				})
			) {
				return true; // Show all rows if all search fields are empty
			}

			let match = true;

			if (row.isSchool) {
				if (searchFilters.name) {
					match =
						match &&
						row.name
							.toLowerCase()
							.includes(searchFilters.name.trim().toLowerCase());
				}
				if (searchFilters.city) {
					match =
						match &&
						row.city
							.toLowerCase()
							.includes(searchFilters.city.trim().toLowerCase());
				}
				if (searchFilters.countries.length) {
					match =
						match && searchFilters.countries.includes(row.country);
				}

				if (match) {
					filteredSchoolIds.add(row.id);
				}
			} else {
				const courseParentId = row.id.split("-")[0];

				if (!filteredSchoolIds.has(courseParentId)) {
					match = false;
				}

				if (match) {
					filteredCourseParentIds.add(courseParentId);
				}
			}

			return match;
		});

		// If course filter is applied, only show schools that has at least one course that matches the filter
		const finalFilteredRows = newFilteredRows.filter((row) => {
			if (row.isSchool) {
				return filterCourse
					? filteredCourseParentIds.has(row.id)
					: true;
			}
			return true;
		});

		setFilteredRows(finalFilteredRows);
	}, [searchFilters, expandedRows, schools]);

	const handleExpandClick = (id) => {
		setExpandedRows((prev) => ({
			...prev,
			[id]: !prev[id],
		}));
	};

	const getRowClassName = (params) => {
		return params.row.isSchool ? "school-row" : "course-row";
	};

	const getRowHeight = (params) => {
		const contentLength = params.model.name?.length || 0;
		return contentLength > 50 ? 100 : 60;
	};

	return (
		<DataGrid
			rows={filteredRows}
			columns={schoolColumns(
				expandedRows,
				handleExpandClick,
				handleDelete,
				onViewDetails
			)}
			checkboxSelection={false}
			disableColumnMenu={true}
			disableColumnFilter={true}
			disableColumnSorting={true}
			autoHeight
			getRowHeight={getRowHeight}
			pageSize={5}
			getRowClassName={getRowClassName}
			slots={{
				toolbar: () => (
					<CustomToolbar
						rows={filteredRows}
						columns={schoolColumns(
							expandedRows,
							handleExpandClick
						)}
						schools={schools}
						filterCourse={filterCourse}
						setUrlParams={setUrlParams}
					/>
				),
			}}
			sx={{
				"& .MuiDataGrid-row.course-row": {
					backgroundColor: "#f5f5f5",
					"&:hover": {
						backgroundColor: "#e0e0e0",
					},
				},
				"& .MuiDataGrid-columnHeaders": {
					whiteSpace: "nowrap",
				},
				"& .MuiDataGrid-columnHeaderTitle": {
					whiteSpace: "nowrap",
					overflow: "hidden",
					textOverflow: "ellipsis",
				},
			}}
			disableRowSelectionOnClick
		/>
	);
}
