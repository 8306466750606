import {
	Alert,
	Box,
	Button,
	CircularProgress,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Switch,
	TextField,
	Typography,
} from "@mui/material";
import { PARTNERSHIP_STATUS_CHOICES, PROGRAM_CATEGORY_CHOICES } from "../../const";

import DeleteIcon from "@mui/icons-material/Delete";
import EdvantageAutocomplete from "../common/EdvantageAutocomplete";
import GenericMultiSelect from "../common/GenericMultiSelect";
import PropTypes from "prop-types";
import React from "react";
import { useEdvantageContext } from "../../EdvantageProvider";

function UniversityCourseForm(props) {
	const {
		coursesData,
		handleChange,
		handleDelete,
		errors,
		isCourseFormValid,
	} = props;

	const {
		currencies,
		currenciesLoading,
		currenciesError,
		intakes,
		intakesLoading,
		intakesError,
		majors,
		programs,
	} = useEdvantageContext();

	if (currenciesLoading || intakesLoading) {
		return <CircularProgress />;
	}

	if (currenciesError || intakesError) {
		return (
			<Alert severity="error">{currenciesError || intakesError}</Alert>
		);
	}

	return (
		<Box>
			{coursesData.map((course, index) => (
				<Paper
					key={index}
					elevation={3}
					sx={{ mb: 2, borderRadius: "4px", padding: "16px" }}
				>
					<Typography variant="subtitle1" sx={{ mb: 2 }}>
						Course {index + 1}
						<IconButton
							onClick={() => handleDelete(index)}
							size="small"
							sx={{ ml: 1 }}
						>
							<DeleteIcon />
						</IconButton>
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<EdvantageAutocomplete
								name="major"
								label="Major"
								value={course.major}
								onChange={handleChange}
								error={Boolean(errors[index]?.major)}
								helperText={errors[index]?.major}
								options={majors}
								getOptionLabel={(option) => option.name}
								getOptionValue={(newValue) => newValue.id}
								isOptionEqualToValue={(option, value) =>
									option.id === value
								}
								index={index}
								required
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<EdvantageAutocomplete
								name="program"
								label="Program"
								value={course.program}
								onChange={handleChange}
								error={Boolean(errors[index]?.program)}
								helperText={errors[index]?.program}
								options={programs.filter(program => program.category === PROGRAM_CATEGORY_CHOICES.University)}
								getOptionLabel={(option) => option.name}
								getOptionValue={(newValue) => newValue.id}
								isOptionEqualToValue={(option, value) =>
									option.id === value
								}
								index={index}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormControl fullWidth>
								<InputLabel id="application-select-label">
									Application Method
								</InputLabel>
								<Select
									labelId="application-select-label"
									id="application-select"
									value={course.application_method}
									label="Application Method"
									name="application_method"
									onChange={(e) => handleChange(e, index)}
								>
									<MenuItem key={0} value={"UCAS"}>
										UCAS
									</MenuItem>
									<MenuItem key={1} value={"Direct"}>
										Direct
									</MenuItem>
									<MenuItem key={2} value={"Pathway"}>
										Pathway
									</MenuItem>
									<MenuItem key={3} value={"Other"}>
										Other
									</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6}>
							<GenericMultiSelect
								name="intakes"
								label="Intakes"
								value={course.intakes}
								onChange={(e, index) => handleChange(e, index)}
								error={Boolean(errors[index]?.intake)}
								helperText={errors[index]?.intake}
								options={intakes.filter((intake) => intake.is_active)}
								getOptionLabel={(option) => option.season}
								optionValueKey="id"
								limitTags={2}
								required
								index={index}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormControl fullWidth required>
								<InputLabel id="status-select-label">
									Merit Status
								</InputLabel>
								<Select
									labelId="status-select-label"
									id="status-select"
									value={course.status}
									label="Status"
									name="status"
									onChange={(e) => handleChange(e, index)}
								>
									<MenuItem key={0} value={"Unknown"}>
										Unknown
									</MenuItem>
									<MenuItem key={1} value={"Merit"}>
										Merit
									</MenuItem>
									<MenuItem key={2} value={"Non-Merit"}>
										Non-Merit
									</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								select
								fullWidth
								label="Partnership Status"
								name="partnership_status"
								value={course.partnership_status}
								onChange={(e) => handleChange(e, index)}
							>
								{PARTNERSHIP_STATUS_CHOICES.map(
									(option, index) => (
										<MenuItem
											key={index}
											value={option.value}
										>
											{option.label}
										</MenuItem>
									)
								)}
							</TextField>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="Fee"
								name="fee"
								type="number"
								value={course.fee}
								onChange={(e) => handleChange(e, index)}
								error={Boolean(errors[index]?.fee)}
								helperText={errors[index]?.fee}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormControl
								fullWidth
								error={Boolean(errors[index]?.currency)}
							>
								<InputLabel id="currency-select-label">
									Currency
								</InputLabel>
								<Select
									labelId="currency-select-label"
									id="currency-select"
									value={course.currency || ""}
									label="Currency"
									name="currency"
									onChange={(e) => handleChange(e, index)}
								>
									<MenuItem value="">
										<em>None</em>
									</MenuItem>
									{currencies.map((currency) => (
										<MenuItem
											key={currency.code}
											value={currency.id}
										>
											{currency.name} ({currency.symbol})
										</MenuItem>
									))}
								</Select>
								<FormHelperText>
									{errors[index]?.currency}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label="Course URL"
								name="course_url"
								type="url"
								value={course.course_url || ""}
								onChange={(e) => handleChange(e, index)}
								error={Boolean(errors[index]?.course_url)}
								helperText={errors[index]?.course_url}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography
								component="div"
								variant="body1"
								gutterBottom
							>
								Sponsored
								<Switch
									checked={course.is_sponsored}
									onChange={(e) => handleChange(e, index)}
									name="is_sponsored"
									inputProps={{ "aria-label": "controlled" }}
								/>
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="Sponsor"
								name="sponsor"
								value={course.sponsor}
								onChange={(e) => handleChange(e, index)}
							/>
						</Grid>
					</Grid>
				</Paper>
			))}
			<Button
				onClick={props.addCourse}
				sx={{ mt: 2 }}
				disabled={!isCourseFormValid}
			>
				Add Another Course
			</Button>
		</Box>
	);
}

UniversityCourseForm.propTypes = {
	coursesData: PropTypes.array.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleDelete: PropTypes.func.isRequired,
	addCourse: PropTypes.func.isRequired,
	errors: PropTypes.array.isRequired,
	isCourseFormValid: PropTypes.bool,
};

export default UniversityCourseForm;
