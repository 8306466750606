import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../wrappers/fetchWrapper';

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/country`;

const initialState = {
    countries: [],
    status: 'idle',
    error: null,
};

const name = "country"

const countrySlice = createSlice({
    name: name,
    initialState,
    reducers: {
        clearCountryState: (state) => {
            state.countries = [];
            state.status = 'idle';
            state.error = null;
        },
        setCountries: (state, action) => {
            state.countries = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCountries.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchCountries.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.countries = action.payload;
            })
            .addCase(fetchCountries.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const { clearCountryState, setCountries } = countrySlice.actions;
export const countryReducer = countrySlice.reducer;

export const fetchCountries = createAsyncThunk(
    `${name}/fetchCountrys`,
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.get(`${baseUrl}/fetch?timestamp=${new Date().getTime()}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
