import * as React from 'react';

import { Backdrop, CircularProgress } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { prepareConvertLeadData, prepareEditDraftData } from '../../helper/DraftLeadHelper';
import { useDispatch, useSelector } from 'react-redux';

import AddDraftLead from '../DraftLeads/AddDraftLead';
import AddLead from '../Leads/AddLead';
import Alert from '@mui/material/Alert';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import DraftLeadSkeletonTable from '../DraftLeads/DraftLeadSkeletonTable';
import DraftLeadTable from '../DraftLeads/DraftLeadTable';
import Snackbar from '@mui/material/Snackbar';
import { clearMessage } from '../../store/draftLead.slice';
import useDraftLead from '../../hooks/useDraftLead';
import { useEdvantageContext } from '../../EdvantageProvider';

const defaultTheme = createTheme();

function DraftLead({ urlParams, setUrlParams }) {
    const dispatch = useDispatch();
    const { countries, employeeById, uuid } = useEdvantageContext();
    const { draftLeads, loading } = useDraftLead();
    const draftLeadState = useSelector(state => state.draftLead);

    const [draftLead, setDraftLead] = React.useState(null);
    const [isEdit, setIsEdit] = React.useState(false);

    // This useEffect runs when the component mounts or when `urlParams` changes
    React.useEffect(() => {
        if (urlParams.view === 'edit' && urlParams.id) {
            const draftLeadData = draftLeads.find(d => d.id === urlParams.id);
            if (draftLeadData) {
                setDraftLead(prepareEditDraftData(draftLeadData));
                setIsEdit(true);
            }
        } else if (urlParams.view === 'convert' && urlParams.id) {
            const draftLeadData = draftLeads.find(d => d.id === urlParams.id);
            if (draftLeadData) {
                setDraftLead(prepareConvertLeadData(draftLeadData, countries));
                setIsEdit(false);
            }
        } else {
            // Reset draftLead if no view or ID in URL params
            setDraftLead(null);
            setIsEdit(false);
        }
    }, [urlParams, draftLeads, countries]);

    const handleBack = React.useCallback(() => {
        setUrlParams((prevParams) => {
            const { id, ...restParams } = prevParams;
            return { ...restParams, view: "default" };
        });
        setDraftLead(null);
        setIsEdit(false);
    }, [setUrlParams]);

    const handleConvert = React.useCallback((row) => {
        setUrlParams(prevParams => ({ ...prevParams, view: "convert", id: row.id }));
    }, [setUrlParams]);

    const handleEdit = React.useCallback((row) => {
        setUrlParams(prevParams => ({ ...prevParams, view: "edit", id: row.id }));
    }, [setUrlParams]);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') return;
        dispatch(clearMessage());
    };

    // Memoize rendering logic to avoid unnecessary re-renders
    const renderView = React.useMemo(() => {
        if (urlParams.view === "default") {
            return draftLeads.length ? (
                <DraftLeadTable
                    handleConvert={handleConvert}
                    handleEdit={handleEdit}
                    setUrlParams={setUrlParams}
                    draftLeads={draftLeads}
                    draftLeadState={draftLeadState}
                    employeeById={employeeById}
                    uuid={uuid}
                />
            ) : loading ? (
                <DraftLeadSkeletonTable />
            ) : (
                <Alert sx={{ width: '100%', mt: 2, mb: 2 }} severity="success">
                    No draft leads available! Add a draft lead.
                </Alert>
            );
        }

        if (["add", "edit"].includes(urlParams.view)) {
            return (
                <Box sx={{ width: '100%' }}>
                    <Button
                        variant="contained"
                        onClick={handleBack}
                        startIcon={<ArrowBackIosIcon />}
                        sx={{ mt: 2, mb: 2 }}
                    >
                        Go Back
                    </Button>
                    <AddDraftLead isEdit={isEdit} draftLead={draftLead} setUrlParams={setUrlParams} handleBack={handleBack} />
                </Box>
            );
        }

        if (urlParams.view === "convert" && draftLead) {
            return (
                <Box sx={{ width: '100%' }}>
                    <Button
                        variant="contained"
                        onClick={handleBack}
                        startIcon={<ArrowBackIosIcon />}
                        sx={{ mt: 2, mb: 2 }}
                    >
                        Go Back
                    </Button>
                    <AddLead initialLeadData={draftLead} draftLeadId={urlParams.id} />
                </Box>
            );
        }
    }, [urlParams.view, draftLeads.length, draftLead, draftLeadState, countries, uuid, handleBack, handleConvert, handleEdit]);

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" sx={{ backgroundColor: '#FFFFFF' }}>
                {draftLeadState.message && (
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true}
                        autoHideDuration={3000}
                        onClose={handleCloseSnackbar}
                    >
                        <Alert sx={{ width: '100%', mb: 2 }} severity="success">{draftLeadState.message}</Alert>
                    </Snackbar>
                )}
                <CssBaseline />
                <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {draftLeadState.error && draftLeadState.action === 'updateProgram' && (
                        <Alert sx={{ width: '100%', mt: 2, mb: 2 }} severity="error">
                            {draftLeadState.error}
                        </Alert>
                    )}
                    {renderView}
                </Box>
                <Backdrop
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1000,
                    }}
                    open={loading && draftLeadState.action !== 'fetchDraftLeads'}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        </ThemeProvider>
    );
}

export default DraftLead;
