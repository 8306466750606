import * as React from "react";

import { CONSULTATION_CHOICES, SOURCE_CHOICES } from "../../const";
import {
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";

import { generateMenuItems } from "../../utils/Utils";

export default function AboutLeadForm(props) {
	const { leadData, setLeadData, setIsFormValid } = props;
	const [formErrors, setFormErrors] = React.useState({});
	const [touched, setTouched] = React.useState({});

	React.useEffect(() => {
		const formErrors = validateForm();
		setIsFormValid(Object.keys(formErrors).length === 0);
	}, [leadData.source, leadData.consultation_type]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleChange = (e) => {
		const { name, value } = e.target;
		setLeadData({ ...leadData, [name]: value });

		// Mark the field as touched
		setTouched({ ...touched, [name]: true });

		// Validate the specific field being changed
		setIsFormValid(validateField(name, value) === "");
	};

	const validateField = (name, value) => {
		let error = "";

		switch (name) {
			case "source":
				if (!value) error = "Source is required";
				break;
			case "consultation_type":
				if (!value) error = "Consultation type is required";
				break;
			default:
				break;
		}
		setFormErrors({ ...formErrors, [name]: error });

		return error;
	};

	const validateForm = () => {
		const errors = {};
		if (!leadData.consultation_type) {
			errors.consultation_type = "Consultation type is required";
		}
		if (!leadData.source) {
			errors.source = "Source is required";
		}

		setFormErrors(errors);

		return errors;
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TextField
					select
					name="source"
					label="Source"
					value={leadData.source}
					onChange={handleChange}
					error={Boolean(touched.source && formErrors.source)}
					helperText={touched.source && formErrors.source}
					fullWidth
				>
					{generateMenuItems(SOURCE_CHOICES)}
				</TextField>
			</Grid>
			<Grid item xs={12}>
				<FormControl
					fullWidth
					error={Boolean(
						touched.consultation_type &&
							formErrors.consultation_type
					)}
				>
					<InputLabel id="consultation-select-label">
						Consultation Type
					</InputLabel>
					<Select
						labelId="consultation-select-label"
						id="consultation-select"
						value={leadData.consultation_type}
						label="Consultation Type"
						name="consultation_type"
						onChange={handleChange}
					>
						{Object.entries(CONSULTATION_CHOICES).map(
							([value, label]) => (
								<MenuItem key={value} value={value}>
									{label}
								</MenuItem>
							)
						)}
					</Select>
					{touched.consultation_type &&
						formErrors.consultation_type && (
							<FormHelperText>
								{formErrors.consultation_type}
							</FormHelperText>
						)}
				</FormControl>
			</Grid>
		</Grid>
	);
}
