import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "../wrappers/fetchWrapper";

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/draftlead`;

const initialState = {
	action: "",
	draftLeads: [],
	loading: false,
	error: null,
	message: null,
	status: "",
};

const name = "draftLead";

const draftLeadSlice = createSlice({
	name: name,
	initialState,
	reducers: {
		// Optionally add some synchronous reducers if needed
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		clearMessage: (state) => {
			state.message = null;
		},
		clearError: (state) => {
			state.error = null;
		},
		clearDraftLeadState: (state) => {
			state.draftLeads = [];
			state.loading = false;
			state.error = null;
			state.message = null;
			state.status = "";
		},
		changeDraftLeadState: (state, action) => {
			const { action: actionType, data } = action.payload;

			if (actionType === 'create') {
				// Add new lead to the list
				state.draftLeads = [...state.draftLeads, data];
			} else if (actionType === 'update') {
				// Update the existing lead
				const index = state.draftLeads.findIndex(lead => lead.id === data.id);
				if (index !== -1) {
					state.draftLeads[index] = data;
				}
			} else if (actionType === 'delete') {
				// Delete the lead by id
				state.draftLeads = state.draftLeads.filter(lead => lead.id !== data.id);
			}
		},
		setStatus: (state, action) => {
			state.status = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			// Add Cred
			.addCase(addDraftLead.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(addDraftLead.fulfilled, (state, action) => {
				state.loading = false;
				state.message = "Draft Lead Added Successfully!";
				state.draftLeads = [...state.draftLeads, action.payload];
			})
			.addCase(addDraftLead.rejected, (state, action) => {
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			})
			.addCase(fetchDraftLeads.pending, (state) => {
				state.action = "fetchDraftLeads";
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(fetchDraftLeads.fulfilled, (state, action) => {
				state.action = "";
				state.loading = false;
				state.draftLeads = action.payload;
			})
			.addCase(fetchDraftLeads.rejected, (state, action) => {
				state.action = "";
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			})
			.addCase(updateDraftLead.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(updateDraftLead.fulfilled, (state, action) => {
				state.loading = false;
				state.message = "Draft Lead Updated Successfully!";
				state.draftLeads = state.draftLeads.map((draftLead) =>
					draftLead.id === action.payload.id ? action.payload : draftLead
				);
			})
			.addCase(updateDraftLead.rejected, (state, action) => {
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			})
			.addCase(deleteDraftLead.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(deleteDraftLead.fulfilled, (state, action) => {
				state.loading = false;
				state.message = "Draft Lead Deleted Successfully!";
				state.draftLeads = state.draftLeads.filter(
					(draftLead) => draftLead.id !== action.payload.id
				);
			})
			.addCase(deleteDraftLead.rejected, (state, action) => {
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			});
	},
});

export const { setLoading, changeDraftLeadState, clearError, clearMessage, clearDraftLeadState, setStatus } = draftLeadSlice.actions;
export const draftLeadReducer = draftLeadSlice.reducer;

export const addDraftLead = createAsyncThunk(
	`${name}/addDraftLead`,
	async (draftLead, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.post(`${baseUrl}/add`, draftLead);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const fetchDraftLeads = createAsyncThunk(
	`${name}/fetchDraftLeads`,
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.get(
				`${baseUrl}/fetch?timestamp=${new Date().getTime()}`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const updateDraftLead = createAsyncThunk(
	`${name}/updateDraftLead`,
	async ({ id, draftLead }, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.put(`${baseUrl}/update/${id}/`, draftLead);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const deleteDraftLead = createAsyncThunk(
	`${name}/deleteDraftLead`,
	async ({ id, uuid }, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.delete(`${baseUrl}/delete/${id}/`, { uuid });
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
