// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* UniversitySlider.css */
.slick-prev,
.slick-next {
    z-index: 1;
    background: transparent;
}

.slick-prev {
    left: 10px;
}

.slick-next {
    right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/SchoolSlider.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;;IAEI,UAAU;IACV,uBAAuB;AAC3B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;AACf","sourcesContent":["/* UniversitySlider.css */\n.slick-prev,\n.slick-next {\n    z-index: 1;\n    background: transparent;\n}\n\n.slick-prev {\n    left: 10px;\n}\n\n.slick-next {\n    right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
