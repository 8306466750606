import * as React from "react";

import { IconButton, Menu, MenuItem } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function ActionsPopover(props) {
	const { handleEdit, handleDelete } = props;
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			<IconButton onClick={handleClick}>
				<MoreVertIcon />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<MenuItem
					onClick={() => { handleClose(); handleEdit(); }}
					sx={{ display: "flex", alignItems: "center" }}
				>
					<EditIcon sx={{ marginRight: "8px" }} />
					Edit
				</MenuItem>
				<MenuItem
					onClick={handleDelete}
					sx={{ display: "flex", alignItems: "center" }}
				>
					<DeleteIcon sx={{ marginRight: "8px" }} />
					Delete
				</MenuItem>
			</Menu>
		</React.Fragment>
	);
}
