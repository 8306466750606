import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";

import { fetchNotifications } from "../store/notification.slice";

function useNotification() {
	const dispatch = useDispatch();
	const lockRef = useRef(false);

	const notifications = useSelector((state) => state.notification.notifications);
	const notificationStatus = useSelector((state) => state.notification.status);

	useEffect(() => {
		async function onLoad() {
			if (notificationStatus === "idle" && !lockRef.current) {
				lockRef.current = true;
				dispatch(fetchNotifications()).finally(() => {
					// Release lock after completion
					lockRef.current = false;
				});
			}
		}

		onLoad();
	}, [notificationStatus, dispatch]);

	return {
		notifications,
	};
}

export default useNotification;
