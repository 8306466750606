import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";

import { fetchLeads } from "../store/lead.slice";

function useLead() {
	const dispatch = useDispatch();
	const lockRef = useRef(false);

	const leads = useSelector((state) => state.lead.leads);
	const leadStatus = useSelector((state) => state.lead.status);

	useEffect(() => {
		async function onLoad() {
			if (leadStatus === "idle" && !lockRef.current) {
				lockRef.current = true;
				dispatch(fetchLeads()).finally(() => {
					// Release lock after completion
					lockRef.current = false;
				});
			}
		}

		onLoad();
	}, [leadStatus, dispatch]);

	return {
		leads,
	};
}

export default useLead;
