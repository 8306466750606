import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "../wrappers/fetchWrapper";

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/document`;

const initialState = {
	documents: [],
	signedUrls: {},
	loading: false,
	message: null,
	error: null,
};

const name = "document";

const documentSlice = createSlice({
	name: name,
	initialState,
	reducers: {
		clearDocumentState: (state) => {
			state.documents = [];
			state.signedUrls = {};
			state.loading = false;
			state.message = null;
			state.error = null;
		},
		clearMessage: (state) => {
			state.message = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(uploadDocument.pending, (state) => {
				state.loading = true;
				state.message = null;
				state.error = null;
			})
			.addCase(uploadDocument.fulfilled, (state, action) => {
				state.loading = false;
				state.message = "Document uploaded successfully!";
				state.documents.push(action.payload.document);

				if (action.payload.lead) {
					const { lead_id, signed_url } = action.payload.lead;
					const document_type = action.payload.document.document_type;
					// Initialize the lead's signedUrls object if it doesn't exist
					if (!state.signedUrls[lead_id]) {
						state.signedUrls[lead_id] = {};
					}

					// Group by document type
					if (!state.signedUrls[lead_id][document_type]) {
						state.signedUrls[lead_id][document_type] = [];
					}

					// Add the new signed URL to the list for that document type
					state.signedUrls[lead_id][document_type].push({
						document_id: action.payload.document.id,
						file_name: action.payload.document.file_name,
						document_type: document_type,
						signed_url: signed_url,
					});
				}
			})
			.addCase(uploadDocument.rejected, (state, action) => {
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			})
			.addCase(getSignedUrls.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getSignedUrls.fulfilled, (state, action) => {
				state.loading = false;
				const groupedDocuments = action.payload.signedUrls.reduce(
					(grouped, document) => {
						const type = document.document_type;
						if (!grouped[type]) {
							grouped[type] = [];
						}
						grouped[type].push(document);
						return grouped;
					},
					{}
				);
				state.signedUrls[action.payload.leadId] = groupedDocuments;
			})
			.addCase(getSignedUrls.rejected, (state, action) => {
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			})
			.addCase(deleteDocument.pending, (state) => {
				state.loading = true;
				state.message = null;
				state.error = null;
			})
			.addCase(deleteDocument.fulfilled, (state, action) => {
				state.loading = false;
				state.message = "Document deleted successfully!";
				state.documents = state.documents.filter(
					(document) => document.id !== action.payload.id
				);
				state.signedUrls[action.payload.leadId][
					action.payload.documentType
				] = state.signedUrls[action.payload.leadId][
					action.payload.documentType
				].filter(
					(document) => document.document_id !== action.payload.id
				);

				if (
					state.signedUrls[action.payload.leadId][
						action.payload.documentType
					].length === 0
				) {
					delete state.signedUrls[action.payload.leadId][
						action.payload.documentType
					];
				}
			})
			.addCase(deleteDocument.rejected, (state, action) => {
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			});
	},
});

export const { clearDocumentState, clearMessage } = documentSlice.actions;
export const documentReducer = documentSlice.reducer;

export const uploadDocument = createAsyncThunk(
	`${name}/uploadDocument`,
	async (formData, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.post(
				`${baseUrl}/upload`,
				formData
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getSignedUrls = createAsyncThunk(
	`${name}/getSignedUrls`,
	async (leadId, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.get(
				`${baseUrl}/get_signed_urls/${leadId}`
			);
			return { leadId: leadId, signedUrls: response.data };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const deleteDocument = createAsyncThunk(
	`${name}/deleteDocument`,
	async ({ id, documentType, leadId }, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.delete(
				`${baseUrl}/delete/${id}`
			);
			return {
				id: response.data?.id,
				documentType: documentType,
				leadId: leadId,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
