import { useDispatch, useSelector } from "react-redux";

import { fetchEvents } from "../store/event.slice";
import { useEffect } from "react";

function useEvent() {
	const dispatch = useDispatch();

	const events = useSelector((state) => state.event.events);
	const eventStatus = useSelector((state) => state.event.status);

	useEffect(() => {
		async function onLoad() {
			if (eventStatus === "idle") {
				dispatch(fetchEvents());
			}
		}

		onLoad();
	}, [eventStatus, dispatch]);

	return {
		events,
	};
}

export default useEvent;
