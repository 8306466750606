import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import UploadDocumentForm from "../Leads/UploadDocumentForm";

const UploadDocumentsDialog = ({
    open,
    onClose,
    leadId,
    studentId,
    setLeadData,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            aria-labelledby="upload-documents-dialog"
        >
            <DialogTitle sx={{ m: 0, p: 2 }}>
                Upload Documents
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <UploadDocumentForm
                    studentId={studentId}
                    setLeadData={setLeadData}
                    setIsUploadDocumentFormValid={() => {}}
                    isDialog={true}
                    leadId={leadId}
                    onClose={onClose}
                />
            </DialogContent>
        </Dialog>
    );
};

export default UploadDocumentsDialog;
