import "../../styles/CommentSection.css";

import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import MentionTextField from "../comment/MentionTextField";
import SendIcon from "@mui/icons-material/Send";
import { addComment } from "../../store/comment.slice";
import { formatDistanceToNow } from "date-fns";
import { highlightMentions } from "../../helper/CommentHelper";
import useComment from "../../hooks/useComment";
import { useDispatch } from "react-redux";
import { useEdvantageContext } from "../../EdvantageProvider";

const useStyles = {
    commentBox: {
        maxHeight: 500,
        overflowY: "auto",
        paddingRight: 1,
        marginBottom: 1,
        scrollbarWidth: "thin", // For Firefox
        "&::-webkit-scrollbar": {
            width: "0px", // For Chrome/Safari
            background: "transparent",
        },
    },
    replyButton: {
        padding: 0,
        textTransform: "none",
        minWidth: "auto",
        fontWeight: 400,
        "&:hover": {
            backgroundColor: "transparent", // Remove background on hover
        },
    },
    avatar: { width: 40, height: 40 },
    commentText: { marginBottom: "8px" },
};

const CommentSection = ({ modelName, instanceId, boxStyles = {} }) => {
    const dispatch = useDispatch();
    const { authUser, employees, employeesLoading, highlightedElementId: highlightedCommentId, scrollToElement, urlParams, uuid } = useEdvantageContext();
    const { comments } = useComment(modelName, instanceId);
    const filteredEmployees = useMemo(() => {
        return employees?.filter((emp) => emp.id !== authUser.profile.id) || [];
    }, [employees, authUser.profile.id]);

    const [newComment, setNewComment] = useState({});
    const [mentions, setMentions] = useState([]);
    const [openReplyInputText, setOpenReplyInputText] = useState({});
    const [isPosting, setIsPosting] = useState(false);

    const commentsBoxRef = useRef(null);

    // Handlers
    const handleCommentChange = useCallback((e, replyToId = "root") => {
        setNewComment((prev) => ({
            ...prev,
            [replyToId]: e.target.value,
        }));
    }, []);

    const handleCommentSubmit = useCallback((replyToId, parentId) => {
        const content = newComment[replyToId]?.trim();
        if (content) {
            const newCommentObj = {
                model_name: modelName,
                instance_id: instanceId,
                content,
                parent: parentId || null,
                mentions,
                url_params: new URLSearchParams(urlParams).toString(),
                uuid,
            };

            setIsPosting(true);
            setNewComment((prev) => ({ ...prev, [replyToId]: "" }));
            setOpenReplyInputText((prev) => ({ ...prev, [replyToId]: false }));

            dispatch(addComment(newCommentObj)).then(() => {
                setIsPosting(false);
                setMentions([]);

                if (!parentId) {
                    commentsBoxRef.current?.scrollTo({
                        top: commentsBoxRef.current.scrollHeight,
                        behavior: "smooth",
                    });
                }
            });
        }
    }, [newComment, mentions, modelName, instanceId, dispatch, urlParams, uuid]);

    const handleReplyClick = useCallback((commentId) => {
        setOpenReplyInputText((prev) => ({ ...prev, [commentId]: true }));
    }, []);

    const renderReplies = useCallback(
        (parentId) =>
            comments
                .filter((reply) => reply.parent === parentId)
                .map((reply) => (
                    <div
                        key={reply.id}
                        id={reply.id}
                        style={{
                            marginBottom: "8px",
                            border: highlightedCommentId === reply.id ? "2px solid #3f51b5" : "none",
                            padding: highlightedCommentId === reply.id ? "5px" : "0",
                            borderRadius: "4px",
                            backgroundColor: highlightedCommentId === reply.id ? "#e3f2fd" : "transparent",
                            animation: highlightedCommentId === reply.id ? "blink 2s ease-in-out" : "none"
                        }}
                    >
                        <Grid container spacing={2} alignItems="flex-start">
                            <Grid item>
                                <Avatar
                                    alt={reply.added_by}
                                    src="https://react.semantic-ui.com/images/avatar/small/matt.jpg"
                                    sx={useStyles.avatar}
                                />
                            </Grid>
                            <Grid item xs>
                                <Link href="#" sx={{ textDecoration: "none" }}>
                                    <Typography variant="body1" className="commentAuthor">
                                        {reply.added_by}
                                    </Typography>
                                </Link>
                                <Typography variant="caption" className="commentMetadata">
                                    {formatDistanceToNow(new Date(reply.added_at), { addSuffix: true })}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    className="commentText"
                                    style={useStyles.commentText}
                                >
                                    {highlightMentions(reply.content, reply.mentions)}
                                    asdasdasd
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                )),
        [comments]
    );

    const renderComments = useCallback(
        () =>
            comments
                .filter((comment) => comment.parent === null)
                .map((comment) => (
                    <div
                        key={comment.id}
                        id={comment.id}
                        style={{
                            marginBottom: "16px",
                            border: highlightedCommentId === comment.id ? "2px solid #3f51b5" : "none",
                            padding: highlightedCommentId === comment.id ? "5px" : "0",
                            borderRadius: "4px",
                            backgroundColor: highlightedCommentId === comment.id ? "#e3f2fd" : "transparent",
                            animation: highlightedCommentId === comment.id ? "blink 2s ease-in-out" : "none"
                        }}
                    >
                        <Grid container spacing={2} alignItems="flex-start">
                            <Grid item>
                                <Avatar
                                    alt={comment.added_by}
                                    src="https://react.semantic-ui.com/images/avatar/small/matt.jpg"
                                    sx={useStyles.avatar}
                                />
                            </Grid>
                            <Grid item xs>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <Link href="#" sx={{ textDecoration: "none" }}>
                                            <Typography variant="body1" className="commentAuthor">
                                                {comment.added_by}
                                            </Typography>
                                        </Link>
                                        <Typography variant="caption" className="commentMetadata">
                                            {formatDistanceToNow(new Date(comment.added_at), { addSuffix: true })}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Typography
                                    variant="body2"
                                    className="commentText"
                                    style={useStyles.commentText}
                                >
                                    {highlightMentions(comment.content, comment.mentions)}
                                </Typography>
                                <Button
                                    variant="text"
                                    color="primary"
                                    sx={useStyles.replyButton}
                                    onClick={() => handleReplyClick(comment.id)}
                                >
                                    Reply
                                </Button>
                                {openReplyInputText[comment.id] && (
                                    <MentionTextField
                                        options={filteredEmployees}
                                        value={newComment[comment.id]}
                                        onChange={(e) => handleCommentChange(e, comment.id)}
                                        mentions={mentions}
                                        setMentions={setMentions}
                                        replyToId={comment.id}
                                        loading={employeesLoading}
                                        rows={2}
                                        sx={{ mt: 1, mb: 1 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() =>
                                                            handleCommentSubmit(comment.id, comment.id)
                                                        }
                                                        size="small"
                                                        color="primary"
                                                    >
                                                        <SendIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                                {renderReplies(comment.id)}
                            </Grid>
                        </Grid>
                    </div>
                )),
        [comments, handleCommentChange, handleCommentSubmit, handleReplyClick, mentions, openReplyInputText, renderReplies]
    );

    useEffect(() => {

        if (urlParams.comment_id) {
            const observer = new MutationObserver(() => {
                const element = scrollToElement(urlParams.comment_id);
                if (element) {
                    observer.disconnect();
                }
            });
            observer.observe(commentsBoxRef.current, { childList: true, subtree: true });
            return () => observer.disconnect();
        }
    }, [urlParams.comment_id]);

    return (
        <div>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                Comments
            </Typography>
            <Card variant="outlined">
                <CardContent>
                    <Box
                        ref={commentsBoxRef}
                        sx={{ ...useStyles.commentBox, ...boxStyles }}
                    >
                        {renderComments()}
                    </Box>
                    <MentionTextField
                        options={filteredEmployees}
                        value={newComment["root"] || ""}
                        onChange={(e) => handleCommentChange(e, "root")}
                        mentions={mentions}
                        setMentions={setMentions}
                        loading={employeesLoading}
                        rows={4}
                        sx={{ mb: 2 }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleCommentSubmit("root", undefined)}
                        disabled={isPosting}
                        startIcon={isPosting && <CircularProgress size={20} color="inherit" />}
                    >
                        {isPosting ? "Posting..." : "Add Comment"}
                    </Button>
                </CardContent>
            </Card>
        </div>
    );
};

export default React.memo(CommentSection);
