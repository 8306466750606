import "../styles/dashboard.css";

import * as React from "react";

import { GOOGLE_MAPS_API_KEY, GOOGLE_MAPS_LIBRARIES, LOGO_SRC } from "../const";
import { MainListItems, secondaryListItems } from "./Dashboard/listitems";
import { ThemeProvider, createTheme, styled, useTheme } from "@mui/material/styles";

import AddLead from "./Leads/AddLead";
import AppBarComponent from "./Dashboard/AppBarComponent";
import Appointments from "./Dashboard/Appointments";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import DraftLead from "./Dashboard/DraftLead";
import { GlobalStyles } from "@mui/system";
import Intake from "./Dashboard/Intake";
import Lead from "./Dashboard/Lead";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import Major from "./Dashboard/Major";
import MuiDrawer from "@mui/material/Drawer";
import { PrivatePage } from "./common/PrivatePage";
import Program from "./Dashboard/Program";
import School from "./Dashboard/School";
import SchoolApplication from "./Dashboard/SchoolApplication";
import Service from "./Dashboard/Service";
import Students from "./Dashboard/Student";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import University from "./Dashboard/University";
import UniversityApplication from "./Dashboard/UniversityApplication";
import Users from "./Dashboard/Users";
import { useEdvantageContext } from "../EdvantageProvider";
import { useLoadScript } from "@react-google-maps/api";
import useMediaQuery from "@mui/material/useMediaQuery";
import { userGroup } from "../utils/enum";

function Copyright(props) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{"Copyright © "}
			<Link color="inherit" href="https://edvantage.me/">
				Edvantage
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	"& .MuiDrawer-paper": {
		position: "relative",
		whiteSpace: "nowrap",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: "border-box",
		...(!open && {
			overflowX: "hidden",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up("sm")]: {
				width: theme.spacing(9),
			},
		}),
	},
}));

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Dashboard() {
	const theme = useTheme();

	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: GOOGLE_MAPS_API_KEY,
		libraries: GOOGLE_MAPS_LIBRARIES,
	});
	const { urlParams, setUrlParams } = useEdvantageContext();

	const [open, setOpen] = React.useState(true);

	const toggleDrawer = () => {
		setOpen(!open);
	};

	React.useEffect(() => {
		const params = { ...urlParams };

		if (!params.tab) {
			params.tab = "Uni Applications";
		}
		if (!params.view) {
			params.view = "table";
		}

		setUrlParams(params);
	}, []);

	const renderSelectedComponent = () => {
		switch (urlParams.tab) {
			case "Application":
				return;
			case "Uni Applications":
				return <UniversityApplication setUrlParams={setUrlParams} />;
			case "School Applications":
				return <SchoolApplication setUrlParams={setUrlParams} />;
			case "Appointments":
				return <Appointments />;
			case "DraftLeads":
				return <DraftLead urlParams={urlParams} setUrlParams={setUrlParams} />;
			case "Institutions":
				return;
			case "Universities":
				return <University isGoogleMapsScriptLoaded={isLoaded} urlParams={urlParams} setUrlParams={setUrlParams} />;
			case "Schools":
				return <School isGoogleMapsScriptLoaded={isLoaded} urlParams={urlParams} setUrlParams={setUrlParams} />;
			case "Students":
				return <Students isGoogleMapsScriptLoaded={isLoaded} />;
			case "Leads":
				return <Lead urlParams={urlParams} setUrlParams={setUrlParams} />;
			case "Add Lead":
				return <AddLead />;
			case "Programs":
				return <Program />;
			case "Majors":
				return <Major />;
			case "Intakes":
				return <Intake />;
			case "Users":
				return <PrivatePage allowedGroups={[userGroup.Administrator]}><Users /></PrivatePage>;
			case "Services":
				return <Service />;
			default:
				return null;
		}
	};

	const goToHome = () => {
		setUrlParams({
			tab: "Uni Applications",
			view: "table",
		});
	};

	React.useEffect(() => {
		setOpen(!isMobile);
	}, [isMobile]);

	return (
		<ThemeProvider theme={defaultTheme}>
			<GlobalStyles
				styles={{
					".pac-container": {
						zIndex: 1300,
					},
				}}
			/>
			<Box sx={{ display: "flex" }}>
				<CssBaseline />
				<AppBarComponent
					selectedListItem={urlParams.tab}
					open={open}
					toggleDrawer={toggleDrawer}
					setUrlParams={setUrlParams}
				/>
				<Drawer
					variant={isMobile ? "temporary" : "permanent"}
					open={open}
					PaperProps={{
						sx: {
							backgroundColor: "rgb(249, 250, 251)",
						},
					}}
				>
					<Toolbar>
						<Link
							component="button"
							sx={{ textDecoration: "none" }}
							onClick={goToHome}
						>
							<Avatar
								src={LOGO_SRC}
								alt="Edvantage"
								sx={{
									width: "100%",
									height: 60,
									borderRadius: 0,
									"& img": {
										objectFit: "contain",
									},
								}}
							/>
						</Link>
					</Toolbar>
					<Divider />
					<List component="nav">
						<MainListItems
							selectedListItem={urlParams.tab}
							setUrlParams={setUrlParams}
						/>
						<Divider sx={{ my: 1 }} />
						{secondaryListItems}
					</List>
				</Drawer>
				<Box
					component="main"
					sx={{
						bgcolor: "rgb(249, 250, 251)",
						flexGrow: 1,
						width: "100%",
						height: "inherit",
						minHeight: "100vh",
						display: "flex",
						flexDirection: "column",
						overflow: "auto",
					}}
				>
					<Toolbar />
					<Divider />
					<Container
						maxWidth="false"
						className="dashboard-container"
						sx={{ flexGrow: 1, mt: 4, mb: 4, bgcolor: "rgb(249, 250, 251)" }}
					>
						{renderSelectedComponent()}
					</Container>
					<Box
						component="footer"
						sx={{ py: 3, px: 2, mt: "auto", textAlign: "center" }}
					>
						<Copyright />
					</Box>
				</Box>
			</Box>
		</ThemeProvider>
	);
}
