import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import React from "react";
import TextField from "@mui/material/TextField";

function EdvantageAutocomplete({
	name,
	label,
	value,
	margin,
	onChange,
	error,
	helperText,
	index,
	options,
	getOptionLabel,
	getOptionValue,
	isOptionEqualToValue,
	noOptionsText,
	loading,
	required,
}) {
	return (
		<Autocomplete
			id={`${name}-select`}
			sx={{ width: "100%" }}
			options={options}
			autoHighlight
			getOptionLabel={getOptionLabel}
			value={options.find((option) => isOptionEqualToValue(option, value)) || null}
			onChange={(event, newValue) => {
				const newValueProcessed = newValue
					? getOptionValue
						? getOptionValue(newValue)
						: newValue
					: "";
				onChange(
					{
						target: {
							name,
							value: newValueProcessed,
							type: "text",
						},
					},
					index
				);
			}}
			renderOption={(props, option) => (
				<li {...props} key={option.id}>
					{getOptionLabel(option)}
				</li>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					margin={margin || "none"}
					error={error}
					helperText={helperText}
					required={required || false}
					autoComplete="off"
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{loading ? (
									<CircularProgress color="inherit" size={20} />
								) : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
					inputProps={{
						...params.inputProps,
						autoComplete: "off", // disable autocomplete and autofill
					}}
				/>
			)}
			noOptionsText={noOptionsText || "No options"}
		/>
	);
}

EdvantageAutocomplete.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	value: PropTypes.any,
	margin: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	error: PropTypes.bool,
	helperText: PropTypes.string,
	index: PropTypes.number,
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	getOptionLabel: PropTypes.func.isRequired,
	getOptionValue: PropTypes.func.isRequired,
	isOptionEqualToValue: PropTypes.func.isRequired,
	noOptionsText: PropTypes.string,
	loading: PropTypes.bool,
	required: PropTypes.bool,
};

export default EdvantageAutocomplete;
