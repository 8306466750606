import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from "@mui/material";
import { Document, Page } from 'react-pdf';

import FileViewer from 'react-file-viewer';
import PropTypes from "prop-types";
import React from 'react'

function PreviewDialog(props) {
	const { open, onClose, document } = props;

	const renderDocumentPreview = () => {
		const fileType = document.signed_url
			.split("/")
			.pop()
			.split("?")[0]
			.split(".")
			.pop()
			.toLowerCase();
		if (fileType === "pdf") {
			return (
				<Document
					file={document.signed_url}
					options={{
						httpHeaders: {
							"Content-Type": "application/pdf",
							"Access-Control-Allow-Origin": "*",
						}
					}}
				>
					<Page pageNumber={1} />
				</Document>
			);
		} else if (["jpg", "jpeg", "png", "gif"].includes(fileType)) {
			return (
				<img
					src={document.signed_url}
					alt={document.document_type}
					style={{ width: "100%", height: "auto" }}
				/>
			);
		} else if (["doc", "docx"].includes(fileType)) {
			return (
				<FileViewer
					fileType={fileType}
					filePath={document.signed_url}
					onError={(e) => console.error("File Viewer Error:", e)}
				/>
			)
		} else {
			return (
				<Typography variant="body1">Unsupported file type</Typography>
			);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="document-preview-title"
			fullWidth
			maxWidth="md"
		>
			<DialogTitle id="document-preview-title">
				Document Preview
			</DialogTitle>
			<DialogContent dividers>{renderDocumentPreview()}</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
}

PreviewDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	document: PropTypes.object.isRequired,
};

export default PreviewDialog;
