import React, { useEffect, useState } from "react";

import {
	Alert,
	Typography,
	Backdrop,
	CircularProgress,
	Snackbar,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import EditorDialog from "../Appointment/EditorDialog";
import { Scheduler } from "@aldabil/react-scheduler";
import CustomViewer from "../Appointment/CustomViewer";
import AppointmentCell from "../Appointment/AppointmentCell";
import useEvent from "../../hooks/useEvent";
import useStudent from "../../hooks/useStudent";
import DeleteConfirmationDialog from "../DeleteConfirmationDialog";
import { clearMessage, deleteEvent } from "../../store/event.slice";

const Appointments = () => {
	const { events } = useEvent();
	const { students } = useStudent();
	const eventState = useSelector((state) => state.event);
	const studentState = useSelector((state) => state.student);
	const [formattedEvents, setFormattedEvents] = useState([]);
	const [openEditor, setOpenEditor] = useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [selectedEvent, setSelectedEvent] = useState(null);

	const dispatch = useDispatch();

	useEffect(() => {
		// Convert ISO strings back to Date objects whenever events state changes
		const updatedFormattedEvents = events.map((event) => ({
			...event,
			start: new Date(event.start), // Convert ISO string back to Date object
			end: new Date(event.end), // Convert ISO string back to Date object
		}));
		setFormattedEvents(updatedFormattedEvents);
	}, [events]);

	const getDeleteEventConfirmationText = (event) => {
		return (
			<>
				Are you sure you want to delete the event{" "}
				<Typography component="span" sx={{ fontWeight: "bold" }}>
					{event.title}
				</Typography>
				?
			</>
		);
	};

	const handleDeleteEvent = (event, close) => {
		close();
		setSelectedEvent({
			...event,
			host: event.host.id,
			invitees: event.invitees.map((invitee) => invitee.id),
		});
		setOpenDeleteDialog(true);
	};

	const handleDeleteConfirm = (eventId) => {
		dispatch(deleteEvent(eventId)).then((response) => {
			if (response.type === "event/deleteEvent/fulfilled") {
				setOpenDeleteDialog(false);
			}
		});
	};

	const handleEditEvent = (event, close) => {
		close();
		setSelectedEvent({
			...event,
			host: event.host.id,
			invitees: event.invitees.map((invitee) => invitee.id),
		});
		setOpenEditor(true);
	};

	const handleCloseEditor = () => {
		setOpenEditor(false);
		setSelectedEvent(null);
	};

	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
		setSelectedEvent(null);
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		dispatch(clearMessage());
	};

	return (
		<div style={{ height: "100vh", padding: "16px" }}>
			{eventState.message && (
				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					open={true}
					autoHideDuration={5000}
					onClose={handleCloseSnackbar}
				>
					<Alert sx={{ width: "100%", mb: 2 }} severity="success">
						{eventState.message}
					</Alert>
				</Snackbar>
			)}
			<Scheduler
				view="week"
				events={formattedEvents}
				eventRenderer={({ event, ...props }) => (
					<AppointmentCell event={event} {...props} />
				)}
				customEditor={(scheduler) => (
					<EditorDialog
						open={true}
						action="create"
						students={students}
						scheduler={scheduler}
						onClose={scheduler.close}
					/>
				)}
				customViewer={(event, close) => (
					<CustomViewer
						event={event}
						onClose={close}
						onEdit={handleEditEvent}
						onDelete={handleDeleteEvent}
					/>
				)}
			/>
			{openEditor && (
				<EditorDialog
					open={openEditor}
					action="edit"
					students={students}
					selectedEvent={selectedEvent}
					onClose={handleCloseEditor}
				/>
			)}
			{openDeleteDialog && (
				<DeleteConfirmationDialog
					open={openDeleteDialog}
					handleClose={handleCloseDeleteDialog}
					onConfirm={() => handleDeleteConfirm(selectedEvent.id)}
					contentText={getDeleteEventConfirmationText(selectedEvent)}
					state={eventState}
				/>
			)}
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1000,
				}}
				open={eventState.loading || studentState.loading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	);
};

export default Appointments;
