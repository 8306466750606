import { Box, Chip, IconButton, Link, Typography } from "@mui/material";

import ActionsPopover from "./ActionsPopover";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from 'react'
import { getFileNameFromUrl } from "../../const";
import { getStatusBackgroundColor } from "../../utils/Utils";

export default function universityApplicationColumns(
	expandedRows,
	handleExpandClick,
	setUrlParams
) {

	const openLeadDetailView = (leadId) => {
		setUrlParams(prevParams => ({
			...prevParams,
			tab: "Leads",
			view: "details",
			id: leadId,
		}));
	};

	return [
		{
			field: "actions",
			headerName: "",
			flex: 1,
			minWidth: 50,
			renderCell: (params) =>
				params.row.isLead ? (
					<IconButton
						onClick={() => handleExpandClick(params.row.id)}
						sx={{ marginRight: 1 }}
					>
						{expandedRows.includes(params.row.id) ? (
							<ExpandMoreIcon />
						) : (
							<ChevronRightIcon />
						)}
					</IconButton>
				) : (
					<ActionsPopover
						application={params.row}
						applicationType="university"
					/>
				),
		},
		{ field: "id", headerName: "ID", flex: 1, minWidth: 100 },
		{
			field: "student",
			headerName: "Student",
			description: "Full name of applicant",
			sortable: true,
			flex: 2,
			minWidth: 200,
			renderCell: (params) =>
				params.row.isLead && (
					<Link
						component="button"
						variant="body2"
						underline="hover"
						onClick={() => openLeadDetailView(params.row.id)}
					>
						{params.value}
					</Link>
				),
		},
		{
			field: "university",
			headerName: "University",
			description: "University applied to",
			flex: 2,
			minWidth: 200,
			renderCell: (params) =>
				!params.row.isLead && (
					<Box
						sx={{
							height: "100%",
							display: "flex",
							alignItems: "center",
							whiteSpace: "normal",
							lineHeight: 1.5,
							overflowWrap: "anywhere",
						}}
					>
						{params.value}
					</Box>
				),
		},
		{
			field: "course",
			headerName: "Course",
			description: "Course applied to",
			flex: 2,
			minWidth: 200,
			renderCell: (params) =>
				!params.row.isLead && (
					<Box
						sx={{
							height: "100%",
							display: "flex",
							alignItems: "center",
							whiteSpace: "normal",
							lineHeight: 1.5,
							overflowWrap: "anywhere",
						}}
					>
						{params.row.program} - {params.row.major}
					</Box>
				),
		},
		{
			field: "intake",
			headerName: "Intake",
			flex: 2,
			minWidth: 200,
			renderCell: (params) =>
				!params.row.isLead && (
					<Box
						sx={{
							height: "100%",
							display: "flex",
							alignItems: "center",
							whiteSpace: "normal",
							lineHeight: 1.5,
							overflowWrap: "anywhere",
						}}
					>
						{params.row.intake}
					</Box>
				),
		},
		{
			field: "year",
			headerName: "Year",
			flex: 1,
			minWidth: 100,
			renderCell: (params) =>
				!params.row.isLead && (
					<Box
						sx={{
							height: "100%",
							display: "flex",
							alignItems: "center",
							whiteSpace: "normal",
							lineHeight: 1.5,
							overflowWrap: "anywhere",
						}}
					>
						{params.value}
					</Box>
				),
		},
		{
			field: "status",
			headerName: "Status",
			flex: 1,
			minWidth: 150,
			renderCell: (params) =>
				!params.row.isLead && (
					<Chip
						label={params.value}
						variant="contained"
						size="small"
						sx={{
							backgroundColor: getStatusBackgroundColor(
								params.value
							),
							color: "#FFFFFF",
						}}
					/>
				),
		},
		{
			field: "offer_letter",
			headerName: "Offer Letter",
			flex: 1,
			minWidth: 150,
			renderCell: (params) =>
				!params.row.isLead && params.row.offer_letter && (
					<Box sx={{ display: "flex", gap: 1 }}>
						<Link
							href={params.row.offer_letter}
							target="_blank"
							rel="noopener noreferrer"
							aria-label="view offer letter"
							sx={{ textDecoration: "none" }}
						>
							{getFileNameFromUrl(params.row.offer_letter)}
						</Link>
					</Box>
				),
		},
		{
			field: "phone_number",
			headerName: "Phone Number",
			flex: 1,
			minWidth: 150,
			renderCell: (params) => {
				const whatsappUrl = `https://wa.me/${params.value.replace(
					/\D/g,
					""
				)}`;

				return (
					<Link
						href={whatsappUrl}
						target="_blank"
						rel="noopener noreferrer"
						underline="hover"
					>
						{params.value}
					</Link>
				);
			},
		},
		{
			field: "email",
			headerName: "Email",
			flex: 2,
			minWidth: 200,
			renderCell: (params) =>
				params.row.isLead && (
					<Typography
						component="a"
						href={`mailto:${params.value}`}
						sx={{
							color: "#1e88e5", // Link color
							textDecoration: "none",
							overflow: "visible", // Ensure full text is visible
							textOverflow: "unset", // Remove ellipsis
							whiteSpace: "nowrap", // Prevent line break
						}}
					>
						{params.value}
					</Typography>
				),
		},
		{
			field: "nationality",
			headerName: "Nationality",
			flex: 1,
			minWidth: 150,
		},
		{
			field: "added_by",
			headerName: "Added By",
			flex: 1,
			minWidth: 150,
		},
		{
			field: "added_at",
			headerName: "Added At",
			flex: 1,
			minWidth: 200,
		},
		{
			field: "updated_by",
			headerName: "Updated By",
			flex: 1,
			minWidth: 150,
		},
		{
			field: "updated_at",
			headerName: "Updated At",
			flex: 1,
			minWidth: 200,
		},
	];
}
