import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";

import { fetchStudents } from "../store/student.slice";

function useStudent() {
	const dispatch = useDispatch();
	const lockRef = useRef(false);

	const students = useSelector((state) => state.student.students);
	const studentStatus = useSelector((state) => state.student.status);

	useEffect(() => {
		async function onLoad() {
			if (studentStatus === "idle" && !lockRef.current) {
				lockRef.current = true;
				dispatch(fetchStudents()).finally(() => {
					// Release lock after completion
					lockRef.current = false;
				});
			}
		}

		onLoad();
	}, [studentStatus, dispatch]);

	return {
		students,
	};
}

export default useStudent;
