import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../wrappers/fetchWrapper';

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/employee_notification`;

const initialState = {
    status: 'idle',
    notifications: [],
    unread_count: 0,
    unseen_count: 0,
    loading: false,
    error: null,
    page: 1,
    offset: 0,
    limit: 10,
    has_more: true, // To handle infinite scrolling
};

const name = "notifications";

export const fetchNotifications = createAsyncThunk(
    `${name}/fetchNotifications`,
    async (_, { getState, rejectWithValue }) => {
        const { offset, limit } = getState().notification;
        try {
            // Fetch data using pagination
            const response = await fetchWrapper.get(`${baseUrl}/fetch?offset=${offset}&limit=${limit}&timestamp=${new Date().getTime()}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateNotifications = createAsyncThunk(
    `${name}/updateNotifications`,
    async ({ notificationIds, action }, { rejectWithValue }) => {
        try {
            // Update notifications with the provided action
            const response = await fetchWrapper.put(`${baseUrl}/batch_update`, {
                notification_ids: notificationIds,
                action: action, // e.g., 'mark_read' or 'mark_seen'
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

const notificationSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        clearNotificationState: (state) => {
            state.status = 'idle';
            state.notifications = [];
            state.unread_count = 0;
            state.unseen_count = 0;
            state.loading = false;
            state.error = null;
            state.page = 1;
            state.offset = 0;
            state.limit = 10;
            state.has_more = true;
        },
        markNotificationAsRead: (state, action) => {
            // Update the specific notification as read (if applicable)
            const notificationId = action.payload;
            const notification = state.notifications.find(n => n.id === notificationId);
            if (notification) {
                notification.read = true;
            }
            // Adjust the unread count
            state.unreadCount = state.notifications.filter(n => !n.read).length;
        },
        changeNotificationState: (state, action) => {
            const { action: actionType, data } = action.payload;

            if (actionType === 'create') {
                // Add new lead to the list
                state.notifications = [...state.notifications, data];
            } else if (actionType === 'update') {
                // Update the existing lead
                const index = state.notifications.findIndex(lead => lead.id === data.id);
                if (index !== -1) {
                    state.notifications[index] = data;
                }
            } else if (actionType === 'delete') {
                // Delete the lead by id
                state.notifications = state.notifications.filter(lead => lead.id !== data.id);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotifications.pending, (state) => {
                state.status = 'loading';
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchNotifications.fulfilled, (state, action) => {
                const { notifications, unread_count, unseen_count, has_more } = action.payload;

                // Append only new notifications (filter out already existing ones)
                const newNotifications = notifications.filter(
                    (newNotif) => !state.notifications.some((notif) => notif.id === newNotif.id)
                );

                // Concatenate only new notifications to the state
                state.notifications = [...state.notifications, ...newNotifications];
                state.unread_count = unread_count;
                state.unseen_count = unseen_count;

                // Update pagination
                state.offset += notifications.length;
                state.has_more = has_more;

                // Set status and loading states
                state.loading = false;
                state.status = 'succeeded';
            })
            .addCase(fetchNotifications.rejected, (state, action) => {
                state.loading = false;
                state.status = 'failed';
                if (Array.isArray(action.payload)) {
                    state.error = action.payload;
                } else if (typeof action.payload === 'object') {
                    state.error = ['Unknown Error!'];
                } else {
                    state.error = action.payload;
                }
            })
            .addCase(updateNotifications.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(updateNotifications.fulfilled, (state, action) => {
                const updatedNotifications = action.payload.notifications;

                if (action.payload.action === 'mark_read') {
                    state.unread_count -= updatedNotifications.length;
                } else if (action.payload.action === 'mark_seen') {
                    state.unseen_count -= updatedNotifications.length;
                }

                updatedNotifications.forEach((updatedNotification) => {
                    const notificationIndex = state.notifications.findIndex(n => n.id === updatedNotification.id);

                    // If the notification exists in the state, replace it fully
                    if (notificationIndex !== -1) {
                        state.notifications[notificationIndex] = updatedNotification; // Fully overwrite the notification
                    }
                });

                state.status = 'succeeded';
            })
            .addCase(updateNotifications.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const { changeNotificationState, clearNotificationState, markNotificationAsRead } = notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;
