import * as React from 'react';

import { Box, IconButton, TextField, Typography } from '@mui/material';
import { deleteMajor, updateMajor } from '../../store/major.slice'; // Assuming you have an updateMajor action

import CancelIcon from '@mui/icons-material/Cancel';
import { DataGrid } from '@mui/x-data-grid';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MajorCell from './MajorCell';
import SaveIcon from '@mui/icons-material/Save';
import { useDispatch } from 'react-redux';

const columns = (handleDeleteClick, handleEditClick, handleCancelEdit, editRowId, handleInputChange, editedValue, originalValue) => [
    {
        field: 'actions',
        headerName: '',
        flex: 0.5,
        renderCell: (params) => (
            <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
                {params.id === editRowId ? (
                    <>
                        <IconButton aria-label="save" onClick={() => handleEditClick(params.id)} disabled={editedValue === originalValue}>
                            <SaveIcon color={editedValue === originalValue ? 'disabled' : 'primary'} />
                        </IconButton>
                        <IconButton aria-label="cancel" onClick={() => handleCancelEdit()}>
                            <CancelIcon color='warning' />
                        </IconButton>
                    </>
                ) : (
                    <>
                        <IconButton aria-label="delete" onClick={() => handleDeleteClick(params.id, params.row.name)}>
                            <DeleteIcon />
                        </IconButton>
                        <IconButton aria-label="edit" onClick={() => handleEditClick(params.id, params.row.name)}>
                            <EditIcon />
                        </IconButton>
                    </>
                )}
            </div>
        ),
    },
    {
        field: 'name',
        headerName: 'Major',
        description: 'The name of major.',
        sortable: true,
        flex: 2,
        renderCell: (params) => {
            if (params.id === editRowId) {
                // Render input field for editing
                return (
                    <TextField
                        value={editedValue}
                        onChange={(e) => handleInputChange(e.target.value)}
                        variant="outlined"
                        size="small"
                    />
                );
            }
            return <MajorCell params={params} />;
        },
    },
    {
        field: 'added_by',
        headerName: 'Added By',
        flex: 1,
    },
    {
        field: 'updated_by',
        headerName: 'Updated By',
        width: 200,
        flex: 1,
    },
    {
        field: 'updated_at',
        headerName: 'Last Update Time',
        flex: 2,
        renderCell: (params) => {
            const formattedDate = new Date(params.value).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            });
            return <Typography sx={{ display: 'flex', height: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>{formattedDate}</Typography>;
        },
    },
];

export default function MajorTable(props) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState(null);
    const [deleteMajorName, setDeleteMajorName] = React.useState('');
    const [editRowId, setEditRowId] = React.useState(null);
    const [editedValue, setEditedValue] = React.useState('');
    const [originalValue, setOriginalValue] = React.useState('');

    const contentText = (
        <>
            Are you sure you want to delete the major{" "}
            <Typography component="span" sx={{ fontWeight: 'bold' }}>
                {deleteMajorName}
            </Typography>
            ? This will delete all the courses related to this major.
        </>
    );

    const handleDeleteClick = (id, name) => {
        setDeleteId(id);
        setDeleteMajorName(name);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setDeleteId(null);
        setDeleteMajorName('');
    };

    const handleConfirmDelete = () => {
        dispatch(deleteMajor(deleteId)).then((response) => {
            if (response.type === 'major/deleteMajor/fulfilled') {
                handleClose();
            }
        });
    };

    const handleEditClick = (id, value) => {
        if (editRowId === id) {
            // Save changes if already editing
            dispatch(updateMajor({ id, major: { name: editedValue } }));
            setEditRowId(null);
            setEditedValue('');
            setOriginalValue('');
        } else {
            // Start editing
            setEditRowId(id);
            setEditedValue(value);
            setOriginalValue(value);
        }
    };

    const handleCancelEdit = () => {
        setEditRowId(null); // Reset the edit row ID
        setEditedValue('');  // Clear the edited value
    };

    const handleInputChange = (value) => {
        setEditedValue(value);
    };

    return (
        <Box
            sx={{
                height: 400,
                width: '100%',
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                overflow: 'hidden',
            }}
        >
            <DataGrid
                rows={props.majorState.majors}
                columns={columns(handleDeleteClick, handleEditClick, handleCancelEdit, editRowId, handleInputChange, editedValue, originalValue)}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                    sorting: {
                        sortModel: [
                            {
                                field: "updated_at",
                                sort: "desc",
                            },
                        ],
                    },
                }}
                pageSizeOptions={[5, 10, 50, 100]}
                checkboxSelection={false}
                disableRowSelectionOnClick
            />
            <DeleteConfirmationDialog
                open={open}
                handleClose={handleClose}
                onConfirm={handleConfirmDelete}
                contentText={contentText}
                state={props.majorState}
            />
        </Box>
    );
}
