import {
	CONSULTATION_CHOICES,
	GRADE_SYSTEM_CHOICES,
	LEAD_STATUS_CHOICES,
	SOURCE_CHOICES,
} from "../../const";
import { Container, Grid, Typography } from "@mui/material";

import React from "react";
import { useEdvantageContext } from "../../EdvantageProvider";
import { useSelector } from "react-redux";

const LeadPreview = (props) => {
	const { leadData } = props;

	const { intakes, services } = useEdvantageContext();

	const majorState = useSelector((state) => state.major);
	const majors = majorState.majors;

	const programState = useSelector((state) => state.program);
	const programs = programState.programs;

	const countryState = useSelector((state) => state.country);
	const countries = countryState.countries;

	const renderField = (fieldName, fieldValue, optValue) => (
		<React.Fragment>
			<Grid item xs={6}>
				<Typography variant="subtitle1">{fieldName}</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography variant="body1">
					{fieldValue}
					{optValue && ` (${optValue})`}
				</Typography>
			</Grid>
		</React.Fragment>
	);

	const getIntakeNameById = (id) => {
		const intake = intakes.find((intake) => intake.id === id);
		return intake ? intake.text : "Unknown";
	};

	const getProgramNameById = (id) => {
		const program = programs.find((program) => program.id === id);
		return program ? program.name : "Unknown";
	};

	const getMajorNameById = (id) => {
		const major = majors.find((major) => major.id === id);
		return major ? major.name : "Unknown";
	};

	const getCountryNameById = (id) => {
		const country = countries.find((country) => country.id === id);
		return country ? country.name : "Unknown";
	};

	const getServiceNameById = (id) => {
		const service = services.find((service) => service.id === id);
		return service ? service.name : "Unknown";
	};

	const getFieldNames = (ids, getNameById) => {
		return ids.map((id) => getNameById(id)).join(", ");
	};

	const getStatusLabel = (statusValue) => {
		const status = LEAD_STATUS_CHOICES.find(
			({ value }) => value === statusValue
		);
		return status ? status.label : "Unknown";
	};

	return (
		<Container
			component="main"
			sx={{
				backgroundColor: "#FFFFFF",
				width: "100%",
				maxWidth: 600,
				margin: "0 auto",
				padding: 2,
			}}
		>
			<Typography variant="h6" gutterBottom>
				Preview Lead Data
			</Typography>
			<Grid container spacing={2}>
				{renderField("Source", SOURCE_CHOICES[leadData.source])}
				{renderField("Consultation Type", CONSULTATION_CHOICES[leadData.consultation_type])}
				{renderField("Student Full Name", leadData.student.full_name)}
				{renderField("Phone Number", `${leadData.student.calling_code}${leadData.student.phone_number}`)}
				{renderField("Nationality", leadData.student.nationality)}
				{renderField(
					"English Test Status",
					leadData.english_test_status
				)}
				{renderField("GPA", leadData.gpa, GRADE_SYSTEM_CHOICES[leadData.grade_system])}
				{renderField("TOEFL Score", leadData.toefl_score)}
				{renderField("IELTS Score", leadData.ielts_score)}
				{renderField("Test Date", leadData.test_date)}
				{renderField(
					"Intakes",
					getFieldNames(leadData.intakes, getIntakeNameById)
				)}
				{renderField(
					"Preferred Programs",
					getFieldNames(
						leadData.preferred_programs,
						getProgramNameById
					)
				)}
				{renderField(
					"Preferred Majors",
					getFieldNames(leadData.preferred_majors, getMajorNameById)
				)}
				{renderField(
					"Preferred Cities",
					leadData.preferred_cities.map(city => city.name).join(", ")
				)}
				{renderField(
					"Preferred Countries",
					getFieldNames(
						leadData.preferred_countries,
						getCountryNameById
					)
				)}
				{renderField(
					"Documents",
					leadData.documents
						.map((doc) => doc.document_type)
						.join(", ")
				)}
				{renderField(
					"Additional Services",
					getFieldNames(
						leadData.additional_services,
						getServiceNameById
					)
				)}
				{renderField("Status", getStatusLabel(leadData.status))}
			</Grid>
		</Container>
	);
};

export default LeadPreview;
