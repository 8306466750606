import * as React from "react";

import { addSchoolCourses } from "../../store/school.slice";

import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { isValidCurrency, isValidFee } from "../../utils/Utils";
import SchoolCourseForm from "./SchoolCourseForm";

/**
 * A modal dialog for adding courses to a school.
 *
 * This component renders a dialog with a form to add multiple courses to a school.
 * It uses the SchoolCourseForm component to render the form and handles the submission
 * of the form.
 *
 * @param {boolean} open - Whether the dialog is open.
 * @param {func} handleClose - The function to call when the dialog is closed.
 * @param {string} schoolId - The ID of the school to add courses to.
 */
function AddCourseDialog(props) {
    const dispatch = useDispatch();

    const { open, handleClose, schoolId, programs, currencies } = props;

    const schoolState = useSelector((state) => state.school);

    const initialCourseData = {
        program: "",
        application_method: "",
        fee: "",
        currency: "",
    };

    const [form, setForm] = React.useState([initialCourseData]);
    const [formErrors, setFormErrors] = React.useState([]);
    const [isFormValid, setIsFormValid] = React.useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        dispatch(
            addSchoolCourses({ school_id: schoolId, courses: form })
        ).then((response) => {
            if (response.type === "school/addSchoolCourses/fulfilled") {
                handleCloseDialog();
            }
        });
    };

    React.useEffect(() => {
        const errors = form.map((course) => validateCourseForm(course));
        setIsFormValid(
            errors.every((error) => Object.keys(error).length === 0)
        );
    }, [form]);

    const addCourse = () => {
        setForm([...form, initialCourseData]);
        setFormErrors([...formErrors, {}]);
    };

    const handleDeleteCourse = (index) => {
        const updatedCourses = form.filter((_, idx) => idx !== index);
        setForm(updatedCourses);
        const updatedErrors = formErrors.filter((_, idx) => idx !== index);
        setFormErrors(updatedErrors);
    };

    const validateCourseForm = (form) => {
        const errors = {};

        // Required fields and their corresponding error messages
        const requiredFields = {
            program: "Program is required",
        };

        // Validate required fields
        for (const field in requiredFields) {
            if (!form[field]) {
                errors[field] = requiredFields[field];
            }
        }

        // Validate fee and currency together
        if ((form.fee && !form.currency) || (!form.fee && form.currency)) {
            if (!form.fee) {
                errors.fee = "Fee is required when currency is set";
            }
            if (!form.currency) {
                errors.currency = "Currency is required when fee is set";
            }
        }

        // Validate the fee field if it's present
        if (form.fee && !isValidFee(form.fee)) {
            errors.fee = "Invalid fee amount";
        }

        // Validate the currency field if it's present
        if (form.currency && !isValidCurrency(form.currency)) {
            errors.currency = "Invalid currency";
        }

        return errors;
    };

    const validateCourseField = (name, value, updatedForm, index) => {
        let error = "";
        let updatedErrors = [...formErrors];

        // Validation logic
        if (!value) {
            if (name === "program") error = "Program is required";
        }

        if (name === "fee") {
            if (value && !isValidFee(value)) {
                error = "Invalid fee amount";
            } else if (value && !isValidCurrency(updatedForm[index].currency)) {
                updatedErrors[index] = {
                    ...updatedErrors[index],
                    currency: "Currency is required",
                };
            } else if (!value && isValidCurrency(updatedForm[index].currency)) {
                error = "Fee is required when currency is set";
            } else if (
                !value &&
                !isValidCurrency(updatedForm[index].currency)
            ) {
                updatedErrors[index] = {
                    ...updatedErrors[index],
                    currency: "",
                };
            }
        } else if (name === "currency") {
            if (value && !isValidCurrency(value)) {
                error = "Invalid currency";
            } else if (value && !isValidFee(updatedForm[index].fee)) {
                updatedErrors[index] = {
                    ...updatedErrors[index],
                    fee: "Fee is required when currency is set",
                };
            } else if (!value && isValidFee(updatedForm[index].fee)) {
                error = "Currency is required when fee is set";
            } else if (!value && !isValidFee(updatedForm[index].fee)) {
                updatedErrors[index] = {
                    ...updatedErrors[index],
                    fee: "",
                };
            }
        }

        updatedErrors[index] = {
            ...updatedErrors[index],
            [name]: error,
        };
        setFormErrors(updatedErrors);
    };

    const handleChange = (event, index) => {
        const { name, value, type, checked } = event.target;
        const updatedForm = [...form];
        updatedForm[index] = {
            ...updatedForm[index],
            [name]: type === "checkbox" ? checked : value,
        };

        setForm(updatedForm);
        validateCourseField(name, value, updatedForm, index);

        // const isValid = Object.keys(validateCourseForm(newForm)).length === 0;
        // setIsFormValid(isValid);
    };

    const handleCloseDialog = () => {
        handleClose(false);
        setForm([initialCourseData]);
        setFormErrors([]);
        setIsFormValid(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleCloseDialog}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">Add Courses</DialogTitle>
            <DialogContent>
                <Box sx={{ mt: 3 }}>
                    {schoolState.error && (
                        <Alert
                            sx={{ width: "100%", mt: 2, mb: 2 }}
                            severity="error"
                        >
                            {schoolState.error}
                        </Alert>
                    )}
                    <SchoolCourseForm
                        programs={programs}
                        currencies={currencies}
                        coursesData={form}
                        handleChange={handleChange}
                        addCourse={addCourse}
                        handleDelete={handleDeleteCourse}
                        errors={formErrors}
                        isCourseFormValid={isFormValid}
                    />
                    <DialogActions sx={{ justifyContent: "flex-start", px: 0 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={!isFormValid}
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            color="error"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleCloseDialog}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

AddCourseDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    schoolId: PropTypes.string.isRequired,
};

export default AddCourseDialog;
