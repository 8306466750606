import * as React from "react";

import {
	clearError,
	setLoading,
	updateStudent,
} from "../../store/student.slice";

import Alert from "@mui/material/Alert";
import { Autocomplete } from "@react-google-maps/api";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CountrySelect from "../common/CountrySelect";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { GENDER_CHOICES } from "../../const";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { generateMenuItems } from "../../utils/Utils";
import parsePhoneNumber from "libphonenumber-js";
import { useDispatch } from "react-redux";
import { useEdvantageContext } from "../../EdvantageProvider";

function EditStudentDialog(props) {
	const dispatch = useDispatch();
	const { countries, uuid } = useEdvantageContext();
	const { open, setOpenDialog, student, studentState } = props;

	const initialFormState = React.useMemo(
		() => ({
			firstName: student.user.first_name,
			lastName: student.user.last_name,
			email: student.user.email,
			countryCallingCode: student.calling_code,
			phoneCountryCode: countries.find(
				(country) => country.calling_code === student.calling_code
			)?.code2,
			phoneNumber: student.phone_number,
			school: student.school || "",
			dob: student.dob,
			gender: student.gender || "",
			nationality: student.nationality,
			isAddressAvailable: student.is_address_available,
			addressLineOne: student.address?.address_line_1 || "",
			addressLineTwo: student.address?.address_line_2 || "",
			city: student.address?.city || "",
			postalCode: student.address?.postal_code || "",
			country: student.address?.country || "",
		}),
		[student, countries]
	);

	const [form, setForm] = React.useState(initialFormState);

	React.useEffect(() => {
		setForm(initialFormState);
	}, [initialFormState]);

	const [formErrors, setFormErrors] = React.useState({});
	const [isFormValid, setIsFormValid] = React.useState(true);

	const [searchResult, setSearchResult] = React.useState(null);

	const onLoad = (autocomplete) => {
		setSearchResult(autocomplete);
	};

	const onPlaceChanged = () => {
		if (searchResult) {
			const place = searchResult.getPlace();
			setForm({
				...form,
				school: place.name,
			});
		}
	};

	const [isFormChanged, setIsFormChanged] = React.useState(false);

	React.useEffect(() => {
		const hasFormChanged =
			JSON.stringify(form) !== JSON.stringify(initialFormState);
		setIsFormChanged(hasFormChanged);
	}, [form, initialFormState]);

	const handleSubmit = async (event) => {
		event.preventDefault();

		let errors = {};
		for (const field of Object.keys(form)) {
			const fieldErrors = validateForm(field, form[field]);
			errors = { ...errors, ...fieldErrors };
		}

		let isValid = true;
		for (let error of Object.values(errors)) {
			if (error) {
				isValid = false;
				break;
			}
		}

		setFormErrors(errors);
		setIsFormValid(isValid);
		if (isValid) {
			dispatch(setLoading(true));

			const payload = {};

			if (form.firstName !== student.user.first_name) {
				payload.user = {
					...(payload.user || {}),
					first_name: form.firstName,
				};
			}
			if (form.lastName !== student.user.last_name) {
				payload.user = {
					...(payload.user || {}),
					last_name: form.lastName,
				};
			}
			if (form.email !== student.user.email) {
				payload.user = { ...(payload.user || {}), email: form.email };
			}
			if (form.countryCallingCode !== student.calling_code) {
				payload.calling_code = form.countryCallingCode;
			}
			if (form.phoneNumber !== student.phone_number) {
				payload.phone_number = form.phoneNumber;
			}
			if (form.school !== student.school) {
				payload.school = form.school;
			}
			if (form.dob !== student.dob) {
				payload.dob = form.dob;
			}
			if (form.gender !== student.gender) {
				payload.gender = form.gender;
			}
			if (form.nationality !== student.nationality) {
				payload.nationality = form.nationality;
			}
			if (form.isAddressAvailable !== student.is_address_available) {
				payload.is_address_available = form.isAddressAvailable;
			}

			if (form.isAddressAvailable) {
				if (form.addressLineOne !== student.address?.address_line_1) {
					payload.address = {
						...(payload.address || {}),
						address_line_1: form.addressLineOne,
					};
				}
				if (form.addressLineTwo !== student.address?.address_line_2) {
					payload.address = {
						...(payload.address || {}),
						address_line_2: form.addressLineTwo,
					};
				}
				if (form.city !== student.address?.city) {
					payload.address = {
						...(payload.address || {}),
						city: form.city,
					};
				}
				if (form.postalCode !== student.address?.postal_code) {
					payload.address = {
						...(payload.address || {}),
						postal_code: form.postalCode,
					};
				}
				if (form.country !== student.address?.country) {
					payload.address = {
						...(payload.address || {}),
						country: form.country,
					};
				}
			}
			dispatch(updateStudent({ id: student.id, student: payload, uuid })).then(
				(response) => {
					if (response.type === "student/updateStudent/fulfilled") {
						handleCloseDialog();
					}
				}
			);
		}
	};

	const validateForm = (fieldName, value) => {
		let errors = {};

		switch (fieldName) {
			case "firstName":
				errors.firstName = value.trim() ? "" : "First name is required";
				break;
			case "lastName":
				errors.lastName = value.trim() ? "" : "Last name is required";
				break;
			case "email":
				errors.email = value.trim()
					? validateEmail(value)
						? ""
						: "Please provide a valid email"
					: "Email is required";
				break;
			case "phoneCountryCode":
				if (!value) {
					errors.phoneCountryCode = "Invalid country code";
				} else if (form.phoneNumber) {
					const phoneNumber = parsePhoneNumber(
						form.phoneNumber,
						value
					);
					if (!phoneNumber || !phoneNumber.isValid()) {
						errors.phoneNumber = "Phone number is invalid";
					} else {
						errors.phoneNumber = "";
					}
				}
				break;
			case "phoneNumber": {
				const phoneNumber = parsePhoneNumber(
					value,
					form.phoneCountryCode
				);
				if (!phoneNumber || !phoneNumber.isValid()) {
					errors.phoneNumber = "Phone number is invalid";
				} else {
					errors.phoneNumber = "";
				}
				break;
			}
			case "gender":
				errors.gender = value.trim() ? "" : "Gender is required";
				break;
			case "nationality":
				errors.nationality = value.trim()
					? ""
					: "Nationality is required";
				break;
			case "addressLineOne":
				if (form.isAddressAvailable) {
					errors.addressLineOne = value.trim()
						? ""
						: "First line of address is required";
				}
				break;
			case "city":
				if (form.isAddressAvailable) {
					errors.city = value.trim() ? "" : "City is required";
				}
				break;
			case "postalCode":
				if (form.isAddressAvailable) {
					errors.postalCode = value.trim()
						? ""
						: "Postal code is required";
				}
				break;
			case "country":
				if (form.isAddressAvailable) {
					errors.country = value.trim() ? "" : "Country is required";
				}
				break;
			default:
				break;
		}

		return errors;
	};

	const validateEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const handleChange = (event) => {
		const { name, value, checked, type } = event.target;
		const fieldValue = type === "checkbox" ? checked : value;

		if (name === "phoneCountryCode") {
			const selectedCountry = countries.find(
				(country) => country.code2 === value
			);

			setForm({
				...form,
				[name]: selectedCountry.code2,
				countryCallingCode: selectedCountry.calling_code,
			});
		} else {
			setForm({ ...form, [name]: fieldValue });
		}

		const fieldErrors = validateForm(name, value);
		let isValid = true;
		for (let error of Object.values(fieldErrors)) {
			if (error) {
				isValid = false;
				break;
			}
		}
		setIsFormValid(isValid);
		setFormErrors({ ...formErrors, ...fieldErrors });
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
		setFormErrors({});
		setIsFormValid(false);
		dispatch(clearError());
	};

	return (
		<Dialog
			open={open}
			onClose={handleCloseDialog}
			aria-labelledby="form-dialog-title"
			closeAfterTransition={false}
		>
			<DialogTitle id="form-dialog-title">
				Update Student{" "}
				<em>
					{student.user.first_name} {student.user.last_name}
				</em>
			</DialogTitle>
			<DialogContent>
				<Box
					component="form"
					noValidate
					onSubmit={handleSubmit}
					sx={{ mt: 3 }}
				>
					{studentState.error &&
						["addStudent", "updateStudent"].includes(
							studentState.action
						) && (
							<Alert
								sx={{ width: "100%", mt: 2, mb: 2 }}
								severity="error"
							>
								{studentState.error}
							</Alert>
						)}
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<TextField
								name="firstName"
								required
								fullWidth
								id="firstName"
								label="First name"
								autoFocus
								value={form.firstName}
								onChange={handleChange}
								error={Boolean(formErrors.firstName)}
								helperText={formErrors.firstName}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								name="lastName"
								required
								fullWidth
								id="lastName"
								label="Last name"
								value={form.lastName}
								onChange={handleChange}
								error={Boolean(formErrors.lastName)}
								helperText={formErrors.lastName}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								name="email"
								required
								fullWidth
								id="email"
								label="Email Address"
								autoComplete="email"
								value={form.email}
								onChange={handleChange}
								error={Boolean(formErrors.email)}
								helperText={formErrors.email}
							/>
						</Grid>
						<Grid item xs={4} sm={4}>
							<FormControl
								fullWidth
								error={Boolean(formErrors.phoneCountryCode)}
							>
								<InputLabel id="select-country-code">
									Calling Code
								</InputLabel>
								<Select
									name="phoneCountryCode"
									value={form.phoneCountryCode}
									label="Calling Code"
									onChange={handleChange}
								>
									<MenuItem key="0" value="">
										<em>None</em>
									</MenuItem>
									{countries.map((country) => (
										<MenuItem
											key={country.code2}
											value={country.code2}
										>
											{country.code2} (
											{country.calling_code})
										</MenuItem>
									))}
								</Select>
								<FormHelperText>
									{formErrors.phoneCountryCode}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid item xs={8} sm={8}>
							<TextField
								name="phoneNumber"
								required
								fullWidth
								id="phoneNumber"
								label="Phone Number"
								autoComplete="phoneNumber"
								value={
									form.phoneNumber.replace(/^\+\d+/, "") || ""
								}
								onChange={handleChange}
								error={Boolean(formErrors.phoneNumber)}
								helperText={formErrors.phoneNumber}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<TextField
								name="dob"
								label="D.O.B."
								type="date"
								value={form.dob}
								onChange={handleChange}
								error={Boolean(formErrors.dob)}
								helperText={formErrors.dob}
								InputLabelProps={{
									shrink: true,
								}}
								inputProps={{
									max: new Date().toISOString().split("T")[0], // Disable future dates
								}}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<TextField
								select
								name="gender"
								label="Gender"
								value={form.gender}
								onChange={handleChange}
								required
								error={Boolean(formErrors.gender)}
								helperText={formErrors.gender}
								fullWidth
							>
								{generateMenuItems(GENDER_CHOICES)}
							</TextField>
						</Grid>
						<Grid item xs={12} md={4}>
							<CountrySelect
								name="nationality"
								label="Nationality"
								value={form.nationality}
								required
								onChange={handleChange}
								error={Boolean(formErrors.nationality)}
								helperText={formErrors.nationality}
							/>
						</Grid>
						<Grid item xs={12}>
							<Autocomplete
								onPlaceChanged={onPlaceChanged}
								onLoad={onLoad}
							>
								<TextField
									name="school"
									fullWidth
									label="School Name"
									value={form.school}
									onChange={handleChange}
									error={Boolean(formErrors.school)}
									helperText={formErrors.school}
								/>
							</Autocomplete>
						</Grid>
						<Grid item xs={12}>
							<FormControlLabel
								control={
									<Switch
										checked={
											form.isAddressAvailable || false
										}
										onChange={handleChange}
										name="isAddressAvailable"
										color="primary"
									/>
								}
								label="Is address available?"
							/>
						</Grid>
						{form.isAddressAvailable && (
							<React.Fragment>
								<Grid item xs={12} sm={6}>
									<TextField
										name="addressLineOne"
										required
										fullWidth
										id="addressLineOne"
										label="Address line 1"
										value={form.addressLineOne}
										onChange={handleChange}
										error={Boolean(
											formErrors.addressLineOne
										)}
										helperText={formErrors.addressLineOne}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										name="addressLineTwo"
										fullWidth
										id="addressLineTwo"
										label="Address line 2"
										value={form.addressLineTwo}
										onChange={handleChange}
										error={Boolean(
											formErrors.addressLineTwo
										)}
										helperText={formErrors.addressLineTwo}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										name="city"
										required
										fullWidth
										id="city"
										label="City"
										value={form.city}
										onChange={handleChange}
										error={Boolean(formErrors.city)}
										helperText={formErrors.city}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										name="postalCode"
										required
										fullWidth
										id="postalCode"
										label="Postal Code"
										value={form.postalCode}
										onChange={handleChange}
										error={Boolean(formErrors.postalCode)}
										helperText={formErrors.postalCode}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<CountrySelect
										name="country"
										label="Country"
										value={form.country}
										onChange={handleChange}
										error={Boolean(formErrors.country)}
										helperText={formErrors.country}
									/>
								</Grid>
							</React.Fragment>
						)}
					</Grid>
					<DialogActions sx={{ justifyContent: "flex-start" }}>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							sx={{ mt: 3, mb: 2 }}
							disabled={!isFormValid || !isFormChanged}
						>
							Update Student
						</Button>
						<Button
							type="button"
							variant="contained"
							color="primary"
							sx={{ mt: 3, mb: 2 }}
							onClick={handleCloseDialog}
						>
							Cancel
						</Button>
					</DialogActions>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

EditStudentDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpenDialog: PropTypes.func.isRequired,
	student: PropTypes.object.isRequired,
	studentState: PropTypes.object.isRequired,
};

export default EditStudentDialog;
