export const validateUniversityField = (name, value, setFormErrors) => {
	let error = "";
	if (!value) {
		if (name === "name") error = "University name is required";
		if (name === "addressLine1") error = "Address Line 1 is required";
		if (name === "city") error = "City is required";
		if (name === "postalCode") error = "Postal Code is required";
		if (name === "country") error = "Country is required";
		if (name === "website") error = "Website is required";
	} else if (name === "website") {
		// Regular expression for a simple URL validation
		const urlPattern =
		/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;
			if (!urlPattern.test(value)) {
				error = "Enter a valid URL for the website";
			}
		}

		if (setFormErrors) {
			setFormErrors((prevErrors) => ({
				...prevErrors,
				[name]: error,
			}));
		}

		return error;
	};

	export const validateUniversityForm = (form) => {
		const errors = {};
		if (!form.name) errors.name = "University name is required";
		if (!form.addressLine1) errors.addressLine1 = "Address Line 1 is required";
		if (!form.city) errors.city = "City is required";
		if (!form.postalCode) errors.postalCode = "Postal Code is required";
		if (!form.country) errors.country = "Country is required";
		if (!form.website) errors.website = "Website is required";
		return errors;
	};
