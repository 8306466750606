import { Box, Chip, Typography, Link, IconButton } from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EventNoteIcon from "@mui/icons-material/EventNote";
import CloseIcon from "@mui/icons-material/Close";

const CustomViewer = ({ event, onClose, onEdit, onDelete }) => {
	const formatDateTime = (date) => {
		return `${date.toLocaleDateString("en-GB", {
			day: "2-digit",
			month: "long",
			year: "numeric",
		})} ${date.toLocaleTimeString("en-US", {
			hour: "2-digit",
			minute: "2-digit",
			hour12: true,
		})}`;
	};

	return (
		<Box
			sx={{
				width: 400,
				// padding: "12px 4px",
				fontFamily: "Roboto, sans-serif",
				display: "flex",
				flexDirection: "column",
				gap: "8px",
				backgroundColor: "#f9f9f9",
				boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
				paddingBottom: "8px",
			}}
		>
			<Box
				sx={{
					width: "100%",
					backgroundColor: "#1976d2",
					color: "white",
					padding: "8px 12px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<IconButton
						aria-label="close"
						color="inherit"
						onClick={onClose}
						sx={{ marginRight: "auto", padding: 0 }} // Pushes close icon to the start
					>
						<CloseIcon />
					</IconButton>

					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						{" "}
						{/* Align edit/delete to the right */}
						<IconButton
							aria-label="edit"
							color="inherit"
							sx={{ paddingX: 0 }}
							onClick={() => onEdit(event, onClose)}
						>
							<EditIcon />
						</IconButton>
						<IconButton
							aria-label="delete"
							color="inherit"
							onClick={() => onDelete(event, onClose)}
						>
							<DeleteIcon />
						</IconButton>
					</Box>
				</Box>
				<Typography variant="body1">{event.title}</Typography>
			</Box>

			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					gap: "8px",
					paddingX: "8px",
				}}
			>
				<Typography variant="caption">
					<EventNoteIcon color="action" fontSize="small" />{" "}
					{formatDateTime(event.start)} - {formatDateTime(event.end)}
				</Typography>
			</Box>

			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					gap: "8px",
					paddingX: "8px",
				}}
			>
				<Typography
					variant="subtitle2"
					sx={{ fontWeight: 500, fontSize: "0.9rem" }}
				>
					Host:
				</Typography>
				<Chip
					label={event.host.full_name}
					sx={{
						backgroundColor: "#e3f2fd",
						color: "#0277bd",
						fontSize: "0.85rem",
						height: "24px",
					}}
				/>
			</Box>

			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					gap: "8px",
					paddingX: "8px",
				}}
			>
				<Typography
					variant="subtitle2"
					sx={{ fontWeight: 500, fontSize: "0.9rem" }}
				>
					Invitees:
				</Typography>
				<Box sx={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
					{event.invitees.map((invitee) => (
						<Chip
							key={invitee.id}
							label={invitee.full_name}
							sx={{
								backgroundColor: "#e3f2fd",
								color: "#0277bd",
								fontSize: "0.85rem",
								height: "24px",
							}}
						/>
					))}
				</Box>
			</Box>

			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					gap: "8px",
					paddingX: "8px",
				}}
			>
				<Typography
					variant="subtitle2"
					sx={{ fontWeight: 500, fontSize: "0.9rem" }}
				>
					Mode:
				</Typography>
				<Typography variant="body2" sx={{ fontSize: "0.85rem" }}>
					{event.event_mode}
				</Typography>
			</Box>

			{event.event_mode === "F2F" && (
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: "8px",
						paddingX: "8px",
					}}
				>
					<Typography
						variant="subtitle2"
						sx={{ fontWeight: 500, fontSize: "0.9rem" }}
					>
						Address:
					</Typography>
					<Typography variant="body2" sx={{ fontSize: "0.85rem" }}>
						{event.address}
					</Typography>
				</Box>
			)}
			{event.event_mode === "Phone" && (
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: "8px",
						paddingX: "8px",
					}}
				>
					<Typography
						variant="subtitle2"
						sx={{ fontWeight: 500, fontSize: "0.9rem" }}
					>
						Phone Number:
					</Typography>
					<Typography variant="body2" sx={{ fontSize: "0.85rem" }}>
						<Link
							href={`tel:${event.phone_number}`}
							sx={{
								color: "#1e88e5",
								textDecoration: "none",
							}}
						>
							{event.phone_number}
						</Link>
					</Typography>
				</Box>
			)}
			{event.event_mode === "VC" && (
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: "8px",
						paddingX: "8px",
					}}
				>
					<Typography
						variant="subtitle2"
						sx={{ fontWeight: 500, fontSize: "0.9rem" }}
					>
						Video Call Link:
					</Typography>
					<Typography variant="body2" sx={{ fontSize: "0.85rem" }}>
						<Link
							href={event.video_call_link}
							target="_blank"
							rel="noopener noreferrer"
							sx={{
								color: "#1e88e5",
								textDecoration: "none",
							}}
						>
							{event.video_call_link}
						</Link>
					</Typography>
				</Box>
			)}
		</Box>
	);
};

export default CustomViewer;
