import * as React from 'react';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from "react-router-dom";

export const MainListItems = ({ selectedListItem, setSelectedListItem }) => {

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <React.Fragment>
            <ListItemButton selected={selectedListItem === "Profile"} onClick={() => setSelectedListItem("Profile")}>
                <ListItemIcon>
                    <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText primary="Profile" />
            </ListItemButton>
        </React.Fragment>
    );
};


export const ExitListItems = () => {
    
    const navigate = useNavigate();

    return (
    <React.Fragment>
        <ListItemButton  sx={{
          bgcolor:"#5DADEC",
          borderRadius: 1,
          '&:hover': {
            bgcolor:"#6CA0DC"
          },
        }} onClick={() => navigate("/dashboard")}>
            <ListItemIcon sx={{ color: 'white',}}>
                <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText 
                primaryTypographyProps={{color: 'white'}}
                primary="Return Home"/>
        </ListItemButton>
    </React.Fragment>
    )
}