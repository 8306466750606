import { APPLICATION_STATUS_CHOICES, getFileNameFromUrl } from "../../const";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { Box } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EdvantageAutocomplete from "../common/EdvantageAutocomplete";
import React from "react";
import { Skeleton } from "@mui/material";
import debounce from "lodash/debounce";
import { fetchSchools } from "../../store/school.slice";
import { generateMenuItems } from "../../utils/Utils";
import { updateSchoolApplication } from "../../store/schoolApplication.slice";
import useEdvantageFetch from "../../hooks/useEdvantageFetch";

/**
 * A dialog to edit an application.
 *
 * @param {Object} props component props
 * @param {Object} props.application the application object to edit
 * @param {boolean} props.open whether the dialog is open or not
 * @param {Function} props.handleClose callback function to close the dialog
 *
 * @returns {React.Component} the edit application dialog component
 */
export default function EditSchoolApplicationDialog(props) {

    const { application, open, handleClose, programById, uuid } = props;

    const dispatch = useDispatch();

    const applicationState = useSelector((state) => state.schoolApplication);
    const { status: schoolStatus } = useEdvantageFetch('school', 'schools', fetchSchools);
    const selectedSchool = application.school;
    const availableCourses = selectedSchool?.courses || [];

    const initialState = {
        status: application.status,
        fileName: getFileNameFromUrl(application.offer_letter),
        offer_letter: null,
        school: application.school_id,
        school_course: application.school_course.id,
        start_date: application.start_date,
        end_date: application.end_date,
    };

    const [form, setForm] = React.useState(initialState);
    const [formErrors, setFormErrors] = React.useState({});

    React.useEffect(() => {
        if (open) {
            setForm({
                status: application.status,
                fileName: getFileNameFromUrl(application.offer_letter),
                offer_letter: null,
                school: application.school_id,
                school_course: application.school_course.id,
                start_date: application.start_date,
                end_date: application.end_date,
            });
            setFormErrors({});
        }
    }, [open, application]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setForm((prevForm) => ({ ...prevForm, [name]: value }));
        debounceValidation(name, value);
    };

    const debounceValidation = React.useCallback(debounce((name, value) => {
        const fieldErrors = validateFormField(name, value);
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            ...fieldErrors,
        }));
    }, 300), []);

    const [isFormValid, setIsFormValid] = React.useState(true);

    React.useEffect(() => {
        setIsFormValid(Object.values(formErrors).every((error) => !error));
    }, [formErrors]);

    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 5 MB
    const ALLOWED_FILE_TYPES = ["application/pdf", "image/*"];

    const validateFile = (file) => {
        if (!file) {
            return "Offer letter is required for this status";
        }
        if (file.size > MAX_FILE_SIZE) {
            return "File size exceeds the maximum limit.";
        } else if (!ALLOWED_FILE_TYPES.includes(file.type)) {
            return "Invalid file type. Please upload a PDF or image.";
        }
        return null;
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const error = file ? validateFile(file) : "Offer letter is required for this status";

        if (!error) {
            setForm((prevForm) => ({ ...prevForm, offer_letter: file, fileName: file.name }));
        } else {
            setForm((prevForm) => ({ ...prevForm, offer_letter: null }));
        }

        setFormErrors((prevErrors) => ({ ...prevErrors, offer_letter: error }));
    };

    const validateFormField = (field, value) => {
        const errors = {};

        switch (field) {
            case "status":
                if (!value) {
                    errors[field] = "Status is required";
                }
                break;
            case "start_date":
                if (!value) {
                    errors[field] = "Intake is required";
                }
                break;
            case "school_course":
                if (!value) {
                    errors[field] = "Course is required";
                }
                break;
            case "offer_letter":
                if (["Conditional Offer", "Unconditional Offer"].includes(form.status) && !form.fileName) {
                    errors.offer_letter = "Offer letter is required for this status";
                }
                break;

            default:
                break;
        }
        return errors;
    };

    const handleCloseDialog = (evt, reason) => {
        if (reason === "backdropClick") {
            return;
        }

        setFormErrors({});
        handleClose(false);
    };

    const handleSave = (e) => {
        e.preventDefault();

        // Use reduce to accumulate form errors
        const newErrors = Object.keys(form).reduce((errors, field) => {
            const fieldError = validateFormField(field, form[field]);
            return { ...errors, ...fieldError }; // Merge field errors with existing errors
        }, {});

        setFormErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
            return;
        }

        const formData = new FormData();
        formData.append("status", form.status);
        formData.append("school", form.school);
        formData.append("school_course", form.school_course);
        formData.append("start_date", form.start_date);
        formData.append("end_date", form.end_date);
        formData.append("uuid", uuid);
        if (form.offer_letter) {
            formData.append("offer_letter", form.offer_letter);
        }

        dispatch(
            updateSchoolApplication({
                id: application.application_id,
                application: formData,
            })
        ).then((response) => {
            if (response.type === "school_application/updateSchoolApplication/fulfilled") {
                handleCloseDialog();
            }
        });
    };

    const isFormChanged = React.useMemo(() => (
        form.status !== application.status ||
        form.school !== application.school_id ||
        form.start_date !== application.start_date ||
        form.end_date !== application.end_date ||
        form.school_course !== application.school_course.id ||
        form.offer_letter
    ), [form, application]);

    const theme = useTheme();

    const shouldDisplayFileUpload =
        form.status === "Conditional Offer" ||
        form.status === "Unconditional Offer";

    const minEndDate = form.start_date || application.start_date;

    return (

        <Dialog
            open={open}
            onClose={handleCloseDialog}
            sx={{
                "& .MuiDialog-paper": {
                    minWidth: {
                        xs: "100%", // full width on small screens
                        sm: "600px", // 600px on medium screens and up
                    },
                },
            }}
        >
            <DialogTitle>
                <Typography variant="body1" gutterBottom>
                    Applicant:{" "}
                    <strong>
                        {application.lead.student.full_name || ""}
                    </strong>{" , School: "}
                    <strong>{selectedSchool.name || ""}</strong>
                </Typography>
            </DialogTitle>
            <Divider />
            <DialogContent>
                {(applicationState.error) && (
                    <Typography
                        variant="body2"
                        color={theme.palette.error.main}
                        sx={{ mb: 2 }}
                    >
                        <em>
                            {applicationState.error}
                        </em>
                    </Typography>
                )}
                {schoolStatus === "loading" ? (
                    <>
                        <Skeleton variant="rectangular" width="100%" height={56} />
                        <Skeleton variant="rectangular" width="100%" height={56} sx={{ mt: 2, mb: 2 }} />
                        <Skeleton variant="rectangular" width="100%" height={56} sx={{ mt: 2, mb: 2 }} />
                        <Skeleton variant="rectangular" width="100%" height={56} />
                    </>
                ) : (
                    <>
                        <TextField
                            select
                            fullWidth
                            label="Status"
                            name="status"
                            value={form.status}
                            onChange={handleChange}
                            error={Boolean(formErrors.status)}
                            helperText={formErrors.status}
                            sx={{ mb: 3 }}
                            required
                        >
                            {generateMenuItems(APPLICATION_STATUS_CHOICES)}
                        </TextField>
                        {shouldDisplayFileUpload && (
                            <Box mb={3}>
                                <Button variant="contained" component="label" startIcon={<CloudUploadIcon />} fullWidth>
                                    Upload Offer Letter
                                    <input type="file" hidden onChange={handleFileChange} />
                                </Button>
                                {form.fileName && <p>{form.fileName}</p>}
                                {formErrors.offer_letter && <p style={{ fontSize: "0.75rem", color: '#d32f2f' }}>{formErrors.offer_letter}</p>}
                            </Box>
                        )}
                        <EdvantageAutocomplete
                            name="course"
                            label="Course"
                            value={form.school_course}
                            onChange={handleChange}
                            error={Boolean(formErrors.school_course)}
                            helperText={formErrors.school_course}
                            options={availableCourses}
                            getOptionLabel={(option) =>
                                programById.get(option.program).name
                            }
                            getOptionValue={(newValue) => newValue.id}
                            isOptionEqualToValue={(option, value) =>
                                option.id === value
                            }
                            noOptionsText={
                                availableCourses.length === 0
                                    ? "No courses found for the selected intake and school."
                                    : "No options available"
                            }
                            required
                        />
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={6}>
                                <TextField
                                    name="start_date"
                                    label="Start Date"
                                    type="date"
                                    value={form.start_date}
                                    onChange={handleChange}
                                    error={Boolean(formErrors.start_date)}
                                    helperText={formErrors.start_date}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="end_date"
                                    label="End Date"
                                    type="date"
                                    value={form.end_date}
                                    onChange={handleChange}
                                    error={Boolean(formErrors.end_date)}
                                    helperText={formErrors.end_date}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        min: minEndDate,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleCloseDialog}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleSave}
                    disabled={
                        !isFormChanged || !isFormValid ||
                        (shouldDisplayFileUpload && !form.fileName)
                    }
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
