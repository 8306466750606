import * as React from "react";
import { useFormik} from 'formik';
import * as Yup from 'yup';

import {
	updateUser,
} from "../../store/user.slice";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";

const validationSchema = Yup.object({
	firstName: Yup.string().max(20, 'Must be 20 characters or less'),
	lastName: Yup.string().max(20, 'Must be 20 characters or less'),
	phoneNumber: Yup.string().matches(/^[0-9\- ]{8,14}$/, {message: "Please enter valid number.", excludeEmptyString: false})
  })

function EditUserDialog(props) {
	const dispatch = useDispatch();

	const { open, setOpenDialog, user } = props;

	// initialize Formik
	const updateUserFormik = useFormik({
		initialValues: {
			firstName: user.user.first_name,
			lastName: user.user.last_name,
			email: user.user.email,
			phoneNumber: user.phone_number
		},
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: values => {
			const data = {
				phone_number: values.phoneNumber,
				user: {
					first_name: values.firstName,
					last_name: values.lastName,
					email: values.email
				}
			}
			dispatch(updateUser({ id: user.id, data })).then(
				(response) => {
					if (response.type === "user/updateUser/fulfilled") {
						setOpenDialog(false);
					}
				}
			);
		}
		});
	
	function handleClickClose() {
		updateUserFormik.resetForm();
		setOpenDialog(false);
		}


	return (
		<Dialog
			open={open}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">
				Update User{" "}
			</DialogTitle>
			<DialogContent>
			<form id="updateUserForm" onSubmit={updateUserFormik.handleSubmit}>
				<Box
					sx={{ mt: 3 }}
				>
					<Grid container spacing={2}>
						{props.userState.error &&
						props.userState.action === "updateUser" && (
							<Alert
								sx={{ width: "100%", mt: 2, mb: 2 }}
								severity="error"
							>
								{props.userState.error}
							</Alert>
						)}
						<Grid item xs={12} sm={6}>
							<TextField
								id="firstName"
								name="firstName"
								label="First Name"
								value={updateUserFormik.values.firstName}
								onChange={updateUserFormik.handleChange}
								onBlur={updateUserFormik.handleBlur}
								fullWidth
								error={updateUserFormik.touched.firstName && Boolean(updateUserFormik.errors.firstName)}
								helperText={updateUserFormik.touched.firstName && updateUserFormik.errors.firstName}
								data-testid="form-field-firstname"
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								name="lastName"
								fullWidth
								id="lastName"
								label="Last name"
								value={updateUserFormik.values.lastName}
								onChange={updateUserFormik.handleChange}
								onBlur={updateUserFormik.handleBlur}
								error={updateUserFormik.touched.lastName && Boolean(updateUserFormik.errors.lastName)}
								helperText={updateUserFormik.touched.lastName && updateUserFormik.errors.lastName}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								name="email"
								required
								fullWidth
								id="email"
								label="Email Address"
								autoComplete="email"
								value={updateUserFormik.values.email}
								onChange={updateUserFormik.handleChange}
								onBlur={updateUserFormik.handleBlur}
								error={updateUserFormik.touched.email && Boolean(updateUserFormik.errors.email)}
								helperText={updateUserFormik.touched.email && updateUserFormik.errors.email}
								disabled
							/>
						</Grid>
						<Grid item xs={8} sm={9}>
							<TextField
								name="phoneNumber"
								fullWidth
								id="phoneNumber"
								label="Phone Number"
								autoComplete="phoneNumber"
								value={updateUserFormik.values.phoneNumber}
								onChange={updateUserFormik.handleChange}
								onBlur={updateUserFormik.handleBlur}
								error={updateUserFormik.touched.phoneNumber && Boolean(updateUserFormik.errors.phoneNumber)}
								helperText={updateUserFormik.touched.phoneNumber && updateUserFormik.errors.phoneNumber}
							/>
						</Grid>
						
					</Grid>
					<DialogActions sx={{ justifyContent: "flex-start" }}>
						<Button
							type="submit"
							form="updateUserForm"
							variant="contained"
							color="primary"
							sx={{ mt: 3, mb: 2 }}
							disabled={!(updateUserFormik.isValid && updateUserFormik.dirty)}
						>
							Update User
						</Button>
						<Button
							type="button"
							variant="contained"
							color="primary"
							sx={{ mt: 3, mb: 2 }}
							onClick={handleClickClose}
						>
							Cancel
						</Button>
					</DialogActions>
				</Box>
				</form>
			</DialogContent>
		</Dialog>
	);
}

EditUserDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpenDialog: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	userState: PropTypes.object.isRequired,
};

export default EditUserDialog;
