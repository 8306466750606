import * as React from "react";

import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { formatDate } from "../Leads/utils";
import schoolApplicationColumns from "./schoolApplicationColumn";

export default function SchoolApplicationTable(props) {
    const { applicationState, courseById, employeeById, leads, programById, schoolById, setUrlParams, uuid } = props;
    const [expandedRows, setExpandedRows] = React.useState([]);

    const rows = React.useMemo(() => {
        return leads.flatMap((lead) => {
            const applicationRows = applicationState.applications
                .filter((application) => application.lead === lead.id)
                .map((application, index) => ({
                    id: `${lead.id}-${index}`,
                    parentId: lead.id,
                    student: "",
                    school: schoolById.get(application.school),
                    school_id: application.school,
                    school_course: courseById.get(application.school_course),
                    offer_letter: application.offer_letter,
                    start_date: application.start_date,
                    end_date: application.end_date || "",
                    email: "",
                    phone_number: "",
                    nationality: "",
                    added_by: employeeById.get(application.added_by).full_name,
                    added_at: formatDate(application.added_at),
                    updated_by: employeeById.get(application.updated_by).full_name,
                    updated_at: formatDate(application.updated_at),
                    status: application.status,
                    isLead: false,
                    application_id: application.id,
                    lead: lead,
                }));

            if (applicationRows.length > 0) {
                const leadRow = {
                    id: lead.id,
                    student: lead.student.full_name,
                    school: "",
                    school_id: "",
                    school_course: "",
                    offer_letter: "",
                    start_date: "",
                    end_date: "",
                    email: lead.student.user.email,
                    phone_number: `${lead.student.calling_code || ''}${lead.student.phone_number}`,
                    nationality: lead.student.nationality,
                    added_by: "",
                    added_at: "",
                    updated_by: "",
                    updated_at: "",
                    status: "",
                    application_id: "",
                    isLead: true,
                };

                return [leadRow, ...applicationRows];
            }

            return [];
        });
    }, [applicationState.applications, leads]);

    const filteredRows = React.useMemo(() => {
        return rows.filter((row) => {
            if (row.isLead) {
                return true; // Always show lead rows
            }
            return expandedRows.includes(row.parentId);
        });
    }, [rows, expandedRows]);

    const handleExpandClick = React.useCallback(
        (leadId) => {
            setExpandedRows((prev) =>
                prev.includes(leadId)
                    ? prev.filter((id) => id !== leadId)
                    : [...prev, leadId]
            );
        },
        [setExpandedRows]
    );

    const getRowClassName = (params) => {
        return params.row.isLead ? "MuiDataGrid-row--lead" : "";
    };

    return (
        <Box sx={{ width: "100%" }}>
            <DataGrid
                rows={filteredRows}
                columns={schoolApplicationColumns(
                    expandedRows,
                    handleExpandClick,
                    programById,
                    setUrlParams,
                    uuid
                )}
                getRowClassName={getRowClassName}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            // Hide columns id, the other columns will remain visible
                            id: false,
                        },
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                autoHeight={true}
                pageSizeOptions={[10, 20, 50, 100]}
                checkboxSelection={false}
                disableRowSelectionOnClick
                sx={{
                    "& .MuiDataGrid-row": {
                        backgroundColor: "#ffffff", // White background for all rows
                    },
                    "& .MuiDataGrid-row--lead": {
                        backgroundColor: "#f5f5f5", // Different background color for lead rows
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        whiteSpace: "nowrap",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        whiteSpace: "nowrap",
                        overflow: "auto",
                    },
                }}
            />
        </Box>
    );
}
