import * as React from "react";
import { useFormik } from 'formik';

import { updateUserGroup, clearUserState } from "../../store/user.slice";
import useGroup from "../../hooks/useGroup";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';


import { useDispatch, useSelector } from "react-redux";


function AddRemoveGroupsDialog(props) {
	const dispatch = useDispatch();

	const { open, setOpenDialog, user, userState } = props;

	const [groups, setGroups] = React.useState([]);
	const fetched_groups = useSelector((state) => state.group.groups);

	// load groups
	useGroup()

	React.useEffect( ()=>{
		setGroups(user.user.groups)
	},[user])

	// initialize Formik
	const updateUserGroupFormik = useFormik({
		initialValues: {
			groups: groups
		},
		enableReinitialize: true,
		onSubmit: values => {
			const data = {
				groups: values.groups
			}
			if (validateAutoComplete()) {
				dispatch(updateUserGroup({id:user.id, data})).then(
				(response) => {
						if (response.type === "user/updateUserGroup/fulfilled") {
							dispatch(clearUserState());
							updateUserGroupFormik.resetForm();
							setOpenDialog(false);
						}
					}
				);
			}
		}
		});
	
	function validateAutoComplete(){
		if(updateUserGroupFormik.values.groups.length > 0){
			return true
		}else {
			updateUserGroupFormik.setErrors({groups: "Users must have at least one group"})
			return false
		}
	}

	function formOnChange(e, value) {
		validateAutoComplete();
		setGroups(value)
		updateUserGroupFormik.setFieldValue("groups", value)
	}

	function handleClickClose() {
		updateUserGroupFormik.resetForm();
		dispatch(clearUserState());
		props.setOpenDialog(false);
		}

	return (
		<Dialog
			open={open}
			aria-labelledby="form-usergroup-dialog-title"
		>
			<DialogTitle id="form-usergroup-dialog-title">Modify Group for {user.user.first_name}</DialogTitle>
			<form id="updateUserGroupForm" onSubmit={updateUserGroupFormik.handleSubmit}>
			<DialogContent>
				<Box
					sx={{ mt: 3 }}
				>
					{userState.error &&
					userState.action === "updateUserGroup" && (
						<Alert
							sx={{ width: "100%", mt: 2, mb: 2 }}
							severity="error"
						>
							{props.userState.error}
						</Alert>
					)}
					{updateUserGroupFormik.touched.groups && 
						updateUserGroupFormik.errors.groups &&(
						<Alert
							sx={{ width: "100%", mt: 2, mb: 2 }}
							severity="error"
						>
							{updateUserGroupFormik.errors.groups}
						</Alert>
					)}
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12}>
							<Autocomplete
								multiple
								disableClearable
								disableCloseOnSelect
								filterSelectedOptions
								id="groups"
								value={groups}
								defaultValue={groups}
								getOptionLabel={(option) => option.name}
								options={fetched_groups}
								style={{ width: 500 }}
								isOptionEqualToValue={(option, value) => option.name === value.name}
								renderInput={(params) => <TextField {...params} label="Groups"  placeholder="Groups" />}
								onChange={formOnChange}
								onBlur={updateUserGroupFormik.handleBlur}
								/>
						</Grid>
					</Grid>
					<DialogActions sx={{ justifyContent: "flex-start" }}>
						<Button
							type="submit"
							form="updateUserGroupForm"
							variant="contained"
							color="primary"
							sx={{ mt: 3, mb: 2 }}
						>
							Update User
						</Button>
						<Button
							type="button"
							variant="contained"
							color="primary"
							sx={{ mt: 3, mb: 2 }}
							onClick={handleClickClose}
						>
							Cancel
						</Button>
					</DialogActions>
				</Box>
			</DialogContent>
			</form>
		</Dialog>
	);
}

AddRemoveGroupsDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpenDialog: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	userState: PropTypes.object.isRequired,
};

export default AddRemoveGroupsDialog;
