import * as React from "react";

import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from "@mui/material";

import AboutStudentCard from "../LeadDetails/AboutStudentCard";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import CommentSection from "../common/CommentSection";
import EditEligibilityDialog from "../LeadDetails/EditEligibilityDialog";
import EligibilityCard from "../LeadDetails/EligibilityCard";
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import LanguageIcon from "@mui/icons-material/Language";
import PageNotFound from "../common/PageNotFound";
import SchoolIcon from "@mui/icons-material/School";
import Sidebar from "../LeadDetails/Sidebar";
import XIcon from "@mui/icons-material/X";
import { useTheme } from "@mui/material/styles";

const sidebar = {
    title: "About Lead",
    description:
        "Etiam porta sem malesuada magna mollis euismod. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur.",
    archives: [
        { title: "March 2020", url: "#" },
        { title: "February 2020", url: "#" },
        { title: "January 2020", url: "#" },
        { title: "November 1999", url: "#" },
        { title: "October 1999", url: "#" },
        { title: "September 1999", url: "#" },
        { title: "August 1999", url: "#" },
        { title: "July 1999", url: "#" },
        { title: "June 1999", url: "#" },
        { title: "May 1999", url: "#" },
        { title: "April 1999", url: "#" },
    ],
    social: [
        { name: "GitHub", icon: GitHubIcon },
        { name: "X", icon: XIcon },
        { name: "Facebook", icon: FacebookIcon },
    ],
};

const preferences = [
    {
        key: "preferred_programs",
        title: "Preferred Programs",
        icon: <BookmarkIcon />,
    },
    {
        key: "preferred_majors",
        title: "Preferred Majors",
        icon: <SchoolIcon />,
    },
    {
        key: "preferred_countries",
        title: "Preferred Countries",
        icon: <LanguageIcon />,
    },
];

function PreferredList({ title, icon, items }) {
    const theme = useTheme();

    return (
        <ListItem alignItems="flex-start">
            <ListItemAvatar>
                <Avatar
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                    }}
                >
                    {icon}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={title}
                secondary={
                    <React.Fragment>
                        {items.map((item, index) => (
                            <Typography
                                key={index}
                                sx={{ display: "block" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                {item.name}
                            </Typography>
                        ))}
                    </React.Fragment>
                }
            />
        </ListItem>
    );
}

function LeadDetail({ lead, setUrlParams, handleBack, serviceById }) {

    const [openEditEligibilityDialog, setOpenEditEligibilityDialog] = React.useState(false);

    return (
        <Box sx={{ p: 3 }}>
            <Button
                variant="contained"
                onClick={handleBack}
                startIcon={<ArrowBackIosIcon />}
                sx={{ mt: 2, mb: 2 }}
            >
                Go Back
            </Button>
            {lead ? (
                <React.Fragment>
                    <Grid container spacing={4} sx={{ height: "auto" }}>
                        <AboutStudentCard key={0} lead={lead} />
                        <EligibilityCard key={1} setOpenEditEligibilityDialog={setOpenEditEligibilityDialog} lead={lead} />
                    </Grid>
                    <Grid container spacing={5} sx={{ mt: 3 }}>
                        <Grid
                            item
                            xs={12}
                            md={8}
                            sx={{
                                "& .markdown": {
                                    py: 3,
                                },
                            }}
                        >
                            <Typography variant="h6" gutterBottom>
                                Preferences
                            </Typography>
                            <Divider />
                            <List sx={{ width: "100%" }}>
                                {preferences.map((preference) => (
                                    <React.Fragment key={preference.key}>
                                        <PreferredList
                                            title={preference.title}
                                            icon={preference.icon}
                                            items={lead[preference.key]}
                                        />
                                        <Divider variant="inset" component="li" />
                                    </React.Fragment>
                                ))}
                            </List>
                            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                                Additional Services
                            </Typography>
                            <Divider />
                            {lead.additional_services.length ? (
                                <List sx={{ width: "100%", px: 1 }}>
                                    {lead.additional_services.map((serviceId) => (
                                        <ListItem
                                            key={serviceId}
                                            sx={{ px: 2, py: 0 }}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked
                                                        name={serviceId}
                                                    />
                                                }
                                                label={serviceById.get(serviceId).name}
                                                sx={{ width: "100%" }}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            ) : (
                                <Typography variant="body1" gutterBottom sx={{ mt: 2, px: 3 }}>
                                    <em>No additional services</em>
                                </Typography>
                            )}
                            {/* Comments Section */}
                            <CommentSection modelName={"Lead"} instanceId={lead.id} />
                        </Grid>
                        <Sidebar
                            title={sidebar.title}
                            lead={lead}
                            description={sidebar.description}
                            archives={sidebar.archives}
                            setUrlParams={setUrlParams}
                        />
                    </Grid>
                    <EditEligibilityDialog
                        open={openEditEligibilityDialog}
                        onClose={() => setOpenEditEligibilityDialog(false)}
                        lead={lead}
                    />
                </React.Fragment>
            ) : (
                <PageNotFound message="Lead not found" setUrlParams={setUrlParams} />
            )}
        </Box>
    );
}

export default LeadDetail;
