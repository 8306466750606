import * as React from "react";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { clearError, clearMessage, deleteStudent } from "../../store/student.slice";
import { useDispatch, useSelector } from "react-redux";

import AddIcon from "@mui/icons-material/Add";
import AddStudentDialog from "../Student/AddStudentDialog";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import DeleteConfirmationDialog from "../DeleteConfirmationDialog";
import EditStudentDialog from "../Student/EditStudentDialog";
import Snackbar from "@mui/material/Snackbar";
import StudentTable from "../Student/StudentTable";
import Typography from "@mui/material/Typography";
import useStudent from "../../hooks/useStudent";

const defaultTheme = createTheme();

function Student({ isGoogleMapsScriptLoaded }) {
	const dispatch = useDispatch();
	const studentState = useSelector((state) => state.student);
	const { students } = useStudent();

	const [openDialog, setOpenDialog] = React.useState(false);
	const [openEditDialog, setOpenEditDialog] = React.useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
	const [selectedStudent, setSelectedStudent] = React.useState(null);

	const handleOpenDialog = () => {
		setOpenDialog(true);
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		dispatch(clearMessage());
	};

	const handleEditStudent = (student) => {
		setSelectedStudent(student);
		setOpenEditDialog(true);
	};

	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
		setSelectedStudent(null);
	};

	const handleDeleteStudent = (student) => {
		setSelectedStudent(student);
		setOpenDeleteDialog(true);
	};

	const handleDeleteConfirm = (studentId) => {
		dispatch(deleteStudent(studentId)).then((response) => {
			if (response.type === "student/deleteStudent/fulfilled") {
				setOpenDeleteDialog(false);
			}
		});
	};

	const getDeleteStudentConfirmationText = (student) => {
		return (
			<>
				Are you sure you want to remove the student{" "}
				<Typography component="span" sx={{ fontWeight: "bold" }}>
					{student.full_name}
				</Typography>
				?
			</>
		);
	};

	// Clear message on component unmount
	React.useEffect(() => {
		return () => {
			dispatch(clearMessage());
			dispatch(clearError());
		};
	}, [dispatch]);

	return (
		<ThemeProvider theme={defaultTheme}>
			<Container component="main" sx={{ backgroundColor: "#FFFFFF" }}>
				{studentState.message && (
					<Snackbar
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={true}
						autoHideDuration={5000}
						onClose={handleCloseSnackbar}
					>
						<Alert sx={{ width: "100%", mb: 2 }} severity="success">
							{studentState.message}
						</Alert>
					</Snackbar>
				)}
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						width: "100%",
						overflowX: "auto",
					}}
				>
					<Box
						sx={{
							width: "100%",
							display: "flex",
							justifyContent: "flex-end",
							mb: 2,
						}}
					>
						<Button
							variant="contained"
							color="primary"
							startIcon={<AddIcon />} // Add icon before the text
							onClick={handleOpenDialog}
						>
							Add Student
						</Button>
					</Box>
					{students.length ? (
						<StudentTable
							studentState={studentState}
							handleEdit={handleEditStudent}
							handleDelete={handleDeleteStudent}
						/>
					) : (
						!studentState.loading && (
							<Alert
								sx={{ width: "100%", mt: 2, mb: 2 }}
								severity="warning"
							>
								No Student Found! Add a student
							</Alert>
						)
					)}
				</Box>
				{isGoogleMapsScriptLoaded && (
					<AddStudentDialog
						open={openDialog}
						setOpenDialog={setOpenDialog}
						studentState={studentState}
					/>
				)}
				{selectedStudent && (
					<React.Fragment>
						{isGoogleMapsScriptLoaded && (
							<EditStudentDialog
								open={openEditDialog}
								setOpenDialog={setOpenEditDialog}
								student={selectedStudent}
								studentState={studentState}
							/>
						)}
						<DeleteConfirmationDialog
							open={openDeleteDialog}
							handleClose={handleCloseDeleteDialog}
							onConfirm={() =>
								handleDeleteConfirm(selectedStudent.id)
							}
							contentText={getDeleteStudentConfirmationText(
								selectedStudent
							)}
							state={studentState}
						/>
					</React.Fragment>
				)}
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1000,
					}}
					open={studentState.loading}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			</Container>
		</ThemeProvider>
	);
}

export default Student;
