import * as React from "react";
import { useDispatch } from "react-redux";

import {
	clearUserState,
	disableUser,
  enableUser
} from "../../store/user.slice";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";


function EnableDisableUserDialog(props) {
	const dispatch = useDispatch();
	
	function handleClickClose() {
    dispatch(clearUserState());
		props.setOpenDialog(false);
		}

	const { open, setOpenDialog, user, userState } = props;

  const handleYesClick = () => {
    if(user.status == "Active"){
      dispatch(disableUser({ id: user.id })).then(
        (response) => {
          if (response.type === "user/disableUser/fulfilled") {
            dispatch(clearUserState());
            setOpenDialog(false);
          }
        }
      );
    }
    else if(user.status == "Disabled"){
      dispatch(enableUser({ id: user.id })).then(
        (response) => {
          if (response.type === "user/enableUser/fulfilled") {
            dispatch(clearUserState());
            setOpenDialog(false);
          }
        }
      );
    }
  }


	return (
    <React.Fragment>
      {user.status=="Pending" ? (
         <Dialog
         open={open}
         onClose={handleClickClose}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
       >
         <DialogTitle id="alert-dialog-title">
           {"Confirmation"}
         </DialogTitle>
         <DialogContent>
           <DialogContentText id="alert-dialog-description">
           The user {user.user.first_name} {user.user.last_name} did not activate his profile.
           </DialogContentText>
         </DialogContent>
         <DialogActions>
           <Button onClick={handleClickClose} variant="outlined" size="small">Close</Button>
         </DialogActions>
       </Dialog>
      )
      :(
      <Dialog
          open={open}
          onClose={handleClickClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirmation"}
          </DialogTitle>
          <DialogContent>
          {userState.error &&
            (userState.action === "enableUser" || userState.action === "disableUser") && (
              <Alert
                sx={{ width: "100%", mt: 2, mb: 2 }}
                severity="error"
              >
                {props.userState.error}
              </Alert>
            )}
            <DialogContentText id="alert-dialog-description">
            Are you sure you want to {user.status=="Active"? 'disable': 'enable'} the user {user.user.first_name} {user.user.last_name}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickClose} variant="outlined" size="small">NO</Button>
            <Button onClick={handleYesClick} variant="contained" size="small" color="primary">YES</Button>
          </DialogActions>
        </Dialog>
      )
      }
      </React.Fragment>
	);
}

EnableDisableUserDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpenDialog: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	userState: PropTypes.object.isRequired,
};

export default EnableDisableUserDialog;
