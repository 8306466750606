import * as React from 'react';

import { Box, FormControlLabel, Typography } from '@mui/material';
import { deleteIntake, toggleIntakeActiveStatus } from '../../store/intake.slice';

import { DataGrid } from '@mui/x-data-grid';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import IOSSwitch from '../common/IOSSwitch';
import IntakeCell from './IntakeCell';
import { useDispatch } from 'react-redux';

const columns = (toggleActiveStatus) => [
    {
        field: 'id',
        headerName: 'ID',
        flex: 1,
    },
    {
        field: 'season',
        headerName: 'Season',
        description: 'Season of intake.',
        sortable: true,
        flex: 1.5,
        minWidth: 150,
        renderCell: (params) => (
            <IntakeCell params={params} />
        ),
    },
    {
        field: 'is_active',
        headerName: 'Active',
        description: 'Is intake active.',
        sortable: true,
        flex: 1,
        minWidth: 100,
        renderCell: (params) => {
            const isActive = params.value

            return (
                <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} checked={isActive} onChange={() => toggleActiveStatus(params.id, !isActive)} />}
                    label=""
                />
            );
        },
    },
    {
        field: 'added_by',
        headerName: 'Added By',
        flex: 1.5,
        minWidth: 150,
    },
    {
        field: 'updated_by',
        headerName: 'Updated By',
        flex: 1.5,
        minWidth: 150,
    },
    {
        field: 'updated_at',
        headerName: 'Last Update Time',
        flex: 2,
        minWidth: 200,
        renderCell: (params) => {
            const formattedDate = new Date(params.value).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            });
            return <Typography sx={{ display: 'flex', height: '100%', justifyContent: 'flex-start', alignItems: 'center' }} >{formattedDate}</Typography>;
        },
    },
];

export default function IntakeTable(props) {

    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState(null);
    const [deleteIntakeName, setDeleteIntakeName] = React.useState('');

    const contentText = (
        <>
            Are you sure you want to delete the intake{" "}
            <Typography component="span" sx={{ fontWeight: 'bold' }}>
                {deleteIntakeName}
            </Typography>
            ?
        </>
    );

    const handleClose = () => {
        setOpen(false);
        setDeleteId(null);
        setDeleteIntakeName('');
    };

    const toggleActiveStatus = (id, isActive) => {
        dispatch(toggleIntakeActiveStatus({ id, isActive, uuid: props.uuid }));
    };

    const handleConfirmDelete = () => {
        // Logic to delete the program
        dispatch(deleteIntake({ id: deleteId, uuid: props.uuid }))
            .then((response) => {
                if (response.type === 'intake/deleteIntake/fulfilled') {
                    handleClose();
                }
            });
    };

    return (
        <Box
            sx={{
                height: 400,
                width: '100%',
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                overflow: 'hidden',
            }}
        >
            <DataGrid
                rows={props.intakeState.intakes}
                columns={columns(toggleActiveStatus)}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            // Hide columns id, the other columns will remain visible
                            id: false,
                        },
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                    sorting: {
                        sortModel: [
                            {
                                field: "updated_at", // Sort by updated_at field
                                sort: "desc", // Sort in descending order
                            },
                        ],
                    },
                }}
                pageSizeOptions={[5, 10, 50, 100]}
                checkboxSelection={false}
                disableRowSelectionOnClick
            />
            <DeleteConfirmationDialog
                open={open}
                handleClose={handleClose}
                onConfirm={handleConfirmDelete}
                contentText={contentText}
                state={props.intakeState}
            />
        </Box>
    );
}
