import { ENGLISH_TEST_STATUS_CHOICES, GRADE_SYSTEM_CHOICES } from "../../const";
import { Grid, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { generateMenuItems, isValidGPA } from "../../utils/Utils";

import GenericMultiSelect from "../common/GenericMultiSelect";
import { useEdvantageContext } from "../../EdvantageProvider";

export default function AcademiaForm(props) {
	const { leadData, setLeadData, setIsAcademiaFormValid } = props;

	const { intakes } = useEdvantageContext();

	const [showScores, setShowScores] = useState(false);
	const [showTestDate, setShowTestDate] = useState(false);
	const [formErrors, setFormErrors] = useState({});
	const [touched, setTouched] = useState({});

	const today = new Date().toISOString().split("T")[0];

	useEffect(() => {
		setShowScores(leadData.english_test_status === "Ready");
		setShowTestDate(
			["Ready", "Scheduled"].includes(leadData.english_test_status)
		);
	}, [leadData.english_test_status]);

	useEffect(() => {
		const academiaFormErrors = validateAcademiaForm();
		setIsAcademiaFormValid(Object.keys(academiaFormErrors).length === 0);
	}, [
		leadData.intakes,
		leadData.grade_system,
		leadData.gpa,
		leadData.english_test_status,
		leadData.test_date,
		leadData.toefl_score,
		leadData.ielts_score,
		showScores,
		showTestDate,
	]); // eslint-disable-line react-hooks/exhaustive-deps

	const validateAcademiaForm = () => {
		const errors = {};
		if (!leadData.intakes.length)
			errors.intakes = "At least one intake is required";
		if (!leadData.grade_system)
			errors.grade_system = "Grade system is required";
		if (
			leadData.gpa === undefined ||
			leadData.gpa === null ||
			leadData.gpa === ""
		) {
			errors.gpa = "GPA is required";
		} else if (!isValidGPA(leadData.gpa, leadData.grade_system)) {
			errors.gpa = "GPA is not valid for the selected grading scale";
		}

		if (!leadData.english_test_status)
			errors.english_test_status = "English test status is required";
		if (showTestDate && !leadData.test_date) {
			errors.test_date = "Test date is required";
		} else if (
			leadData.test_date &&
			((leadData.english_test_status === "Ready" &&
				leadData.test_date > today) ||
				(leadData.english_test_status === "Scheduled" &&
					leadData.test_date < today))
		) {
			errors.test_date = `Test date must be ${leadData.english_test_status === "Ready"
				? "today or earlier"
				: "today or later"
				}`;
		}
		// Conditionally validate TOEFL and IELTS scores if the English test status is 'Ready'
		if (leadData.english_test_status === "Ready") {
			if (!leadData.toefl_score && !leadData.ielts_score) {
				errors.toefl_score = "TOEFLscore is required";
				errors.ielts_score = "IELTS score is required";
			}
		}

		setFormErrors(errors);
		return errors;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setLeadData({ ...leadData, [name]: value });

		// Mark the field as touched
		setTouched({ ...touched, [name]: true });
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<GenericMultiSelect
					name="intakes"
					label="Intakes"
					value={leadData.intakes}
					onChange={handleChange}
					error={Boolean(touched.intakes && formErrors.intakes)}
					helperText={touched.intakes && formErrors.intakes}
					options={intakes.filter((intake) => intake.is_active === true)}
					getOptionLabel={(option) => option.season}
					optionValueKey="id"
					required
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField
					select
					name="grade_system"
					label="Grade System"
					value={leadData.grade_system}
					onChange={handleChange}
					error={Boolean(
						touched.grade_system && formErrors.grade_system
					)}
					helperText={touched.grade_system && formErrors.grade_system}
					required
					fullWidth
				>
					{generateMenuItems(GRADE_SYSTEM_CHOICES)}
				</TextField>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField
					name="gpa"
					label="GPA"
					type="text"
					value={leadData.gpa}
					onChange={handleChange}
					error={Boolean(touched.gpa && formErrors.gpa)}
					helperText={touched.gpa && formErrors.gpa}
					disabled={!leadData.grade_system}
					autoComplete="off"
					required
					fullWidth
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					select
					name="english_test_status"
					label="English Test Status"
					value={leadData.english_test_status}
					onChange={handleChange}
					error={Boolean(
						touched.english_test_status &&
						formErrors.english_test_status
					)}
					helperText={
						touched.english_test_status &&
						formErrors.english_test_status
					}
					required
					fullWidth
				>
					{ENGLISH_TEST_STATUS_CHOICES.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
			</Grid>
			{showTestDate && (
				<Grid item xs={12}>
					<TextField
						name="test_date"
						label="Test Date"
						type="date"
						value={leadData.test_date}
						onChange={handleChange}
						error={Boolean(
							touched.test_date && formErrors.test_date
						)}
						helperText={touched.test_date && formErrors.test_date}
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{
							min:
								leadData.english_test_status === "Scheduled"
									? today
									: undefined,
							max:
								leadData.english_test_status === "Ready"
									? today
									: undefined,
						}}
						fullWidth
					/>
				</Grid>
			)}
			{showScores && (
				<>
					<Grid item xs={12}>
						<TextField
							name="toefl_score"
							label="TOEFL Score"
							type="number"
							value={leadData.toefl_score}
							onChange={handleChange}
							error={Boolean(
								touched.toefl_score && formErrors.toefl_score
							)}
							helperText={
								touched.toefl_score && formErrors.toefl_score
							}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							name="ielts_score"
							label="IELTS Score"
							type="number"
							value={leadData.ielts_score}
							onChange={handleChange}
							error={Boolean(
								touched.ielts_score && formErrors.ielts_score
							)}
							helperText={
								touched.ielts_score && formErrors.ielts_score
							}
							fullWidth
						/>
					</Grid>
				</>
			)}
		</Grid>
	);
}
