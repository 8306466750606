import * as React from 'react';

import { MainListItems, ExitListItems } from './listItems';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { LOGO_SRC } from "../../const";
import Avatar from "@mui/material/Avatar";
import AppBarComponent from '../Dashboard/AppBarComponent';
import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import MuiDrawer from '@mui/material/Drawer';
import Profile from './Profile';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { history } from '../../helper/history';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Houston
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function AccountSettings() {

    const [open, setOpen] = React.useState(true);
    const [selectedListItem, setSelectedListItem] = React.useState(history.location.state?.selectedListItem || 'Profile');

    const toggleDrawer = () => {
        setOpen(!open);
    };

    React.useEffect(() => {
        if (history.location.state?.selectedListItem) {
            const newState = { ...history.location.state, selectedListItem: null };
            history.navigate(history.location.pathname, { replace: true, state: newState });
        }
    });

    const renderSelectedComponent = () => {
        switch (selectedListItem) {
            case 'Profile':
                return <Profile />;
            default:
                return null;
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBarComponent selectedListItem={selectedListItem} open={open} toggleDrawer={toggleDrawer} />
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                         <Avatar
                            src={LOGO_SRC}
                            alt="Edvantage"
                            sx={{
                                width: "80%",
                                height: 60,
                                borderRadius: 0,
                                "& img": {
                                    objectFit: "contain",
                                },
                            }}
                        />
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                        <MainListItems selectedListItem={selectedListItem} setSelectedListItem={setSelectedListItem} />
                    </List>
                    <Divider />
                    <List component="nav">
                        <ExitListItems></ExitListItems>
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
                        {renderSelectedComponent()}
                        <Box component="footer" sx={{ py: 3, px: 2, mt: 'auto' }}>
                            <Copyright />
                        </Box>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
