import * as React from "react";

import { IconButton, Menu, MenuItem } from "@mui/material";

import PersonOffIcon from '@mui/icons-material/PersonOff';
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PinIcon from '@mui/icons-material/Pin';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

export default function ActionsPopover(props) {
	const { handleEdit, handleDisable, handleReset, handleAddRemoveGroup } = props;
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			<IconButton onClick={handleClick}>
				<MoreVertIcon />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<MenuItem
					onClick={() => { handleClose(); handleEdit(); }}
					sx={{ display: "flex", alignItems: "center" }}
				>
					<EditIcon sx={{ marginRight: "8px" }} />
					Edit
				</MenuItem>
				<MenuItem
					onClick={() => {handleClose(); handleAddRemoveGroup();}}
					sx={{ display: "flex", alignItems: "center" }}
				>
					<GroupsIcon sx={{ marginRight: "8px" }} />
					Add/Remove Groups
				</MenuItem>
				<MenuItem
					onClick={ () => {handleClose(); handleDisable();}}
					sx={{ display: "flex", alignItems: "center" }}
				>
					<PersonOffIcon sx={{ marginRight: "8px" }} />
					Enable/Disable
				</MenuItem>
				<MenuItem
					onClick={() => {handleClose(); handleReset();}}
					sx={{ display: "flex", alignItems: "center" }}
				>
					<PinIcon sx={{ marginRight: "8px" }} />
					Resend Password
				</MenuItem>
				<MenuItem
					onClick={() => {handleClose(); handleReset();}}
					sx={{ display: "flex", alignItems: "center" }}
					disabled //TO DO not implemented yet
				>
					<PersonRemoveIcon sx={{ marginRight: "8px" }} />
					Delete 
				</MenuItem>
			</Menu>
		</React.Fragment>
	);
}
