import * as React from "react";

import { updateService, setLoading } from "../../store/service.slice";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

function EditServiceDialog(props) {
	const dispatch = useDispatch();

	const [form, setForm] = React.useState({ name: props.service.name });
	const [formErrors, setFormErrors] = React.useState("");
	const [isFormValid, setIsFormValid] = React.useState(true);

	const handleSubmit = async (event) => {
		event.preventDefault();

		const error = validateForm();
		setFormErrors(error);

		if (!error && form.name.trim() !== props.service.name.trim()) {
			dispatch(setLoading(true));
			dispatch(updateService({ id: props.service.id, service: form }))
				.then((response) => {
					if (response.type === "service/updateService/fulfilled") {
						handleCloseDialog();
					}
				})
				.finally(() => {
					dispatch(setLoading(false));
				});
		}
	};

	const validateForm = () => {
		if (!form.name.trim()) {
			setIsFormValid(false);
			return "Service name is required";
		}
		setIsFormValid(true);
		return "";
	};

	const handleChange = (e) => {
		const { value } = e.target;
		setForm({ name: value });

		const error = validateForm();
		setFormErrors(error);
	};

	const handleCloseDialog = () => {
		props.handleClose();
		setForm({ name: props.service.name });
		setFormErrors("");
		setIsFormValid(true);
	};

	return (
		<Dialog
			open={props.open}
			onClose={handleCloseDialog}
			aria-labelledby="form-dialog-title"
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle id="form-dialog-title">
				Edit Service: <em>{props.service.name}</em>
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Please update the service name below.
				</DialogContentText>
				<Box
					component="form"
					noValidate
					onSubmit={handleSubmit}
					sx={{ mt: 3 }}
				>
					{props.serviceState.error && (
						<Alert
							sx={{ width: "100%", mt: 2, mb: 2 }}
							severity="error"
						>
							{props.serviceState.error}
						</Alert>
					)}
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								name="name"
								required
								fullWidth
								id="name"
								label="Service Name"
								value={form.name}
								onChange={handleChange}
								error={Boolean(formErrors)}
								helperText={formErrors}
							/>
						</Grid>
					</Grid>
					<DialogActions sx={{ justifyContent: "flex-start", px: 0 }}>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							sx={{ mt: 3, mb: 2 }}
							disabled={
								!isFormValid ||
								form.name.trim() === props.service.name.trim()
							}
						>
							Save
						</Button>
						<Button
							type="button"
							variant="contained"
							color="error"
							sx={{ mt: 3, mb: 2 }}
							onClick={handleCloseDialog}
						>
							Cancel
						</Button>
					</DialogActions>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

EditServiceDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	service: PropTypes.object.isRequired, // Service to edit
	serviceState: PropTypes.object.isRequired,
};

export default EditServiceDialog;
