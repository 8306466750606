import React from "react";
import { Paper, Typography, Box } from "@mui/material";

// Separate component for rendering each event using Paper instead of Card
const AppointmentCell = ({ event, ...props }) => {
	// Format the start and end time
	const formatTime = (date) => {
		return new Date(date).toLocaleTimeString("en-US", {
			hour: "2-digit",
			minute: "2-digit",
		});
	};

	return (
		<Paper
			{...props}
			elevation={0}
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				height: "100%",
				backgroundColor: "#1976d2",
				color: "white",
				padding: "2px 6px",
				overflow: "hidden",
			}}
		>
			<Box>
				<Typography
					variant="subtitle2"
					component="h6"
					sx={{ fontSize: "12px" }}
				>
					{event.title}
				</Typography>
				{/* Display the start and end time */}
				<Typography variant="subtitle2" sx={{ fontSize: "11px" }}>
					{formatTime(event.start)} - {formatTime(event.end)}
				</Typography>
				<Typography variant="subtitle2" sx={{ fontSize: "11px" }}>
					Host: {event.host.full_name}
				</Typography>
				<Typography variant="subtitle2" sx={{ fontSize: "11px" }}>
					Invitees: {event.invitees.map(invitee => invitee.full_name).join(', ')}
				</Typography>
			</Box>
		</Paper>
	);
};

export default AppointmentCell;
