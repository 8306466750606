import "../../styles/lead-details.css";

import * as React from "react";

import { Box, IconButton, Popover, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import EditIcon from "@mui/icons-material/Edit";
import EditStudentDialog from "../Student/EditStudentDialog";
import FileUploader from "./FileUploader";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import ReactCountryFlag from "react-country-flag";
import Typography from "@mui/material/Typography";
import { updateStudent } from "../../store/student.slice";
import { useEdvantageContext } from "../../EdvantageProvider";
import { useState } from "react";

function AboutStudentCard(props) {
	const { lead } = props;
	const [anchorEl, setAnchorEl] = useState(null);

	const dispatch = useDispatch();
	const studentState = useSelector((state) => state.student);
	const { uuid } = useEdvantageContext();

	const [openEditStudentDialog, setOpenEditStudentDialog] = useState(false);

	// Handle the popover open
	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	// Handle the popover close
	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const handleFileChange = (file) => {
		// Process the file upload here (e.g., send it to the server)
		handlePopoverClose();
		const formData = new FormData();
		formData.append("profile_picture", file);
		dispatch(updateStudent({ id: lead.student.id, student: formData, uuid }));
	};

	const handleEditStudent = () => {
		setOpenEditStudentDialog(true);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'profile-popover' : undefined;

	return (
		<Grid item xs={12} md={6} sx={{ display: "flex" }}>
			<Card className="card" sx={{ flexGrow: 1, display: "flex", flexDirection: "row" }}>
				<CardContent sx={{ flex: 1 }}>
					<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
						<Typography component="h2" variant="h5" className="title">
							Student
						</Typography>
						<IconButton aria-hidden="false" color="primary" onClick={handleEditStudent} aria-label="edit">
							<EditIcon />
						</IconButton>
					</Box>
					<Typography variant="subtitle1" className="cardInfo">
						Name: {lead.student.full_name}
					</Typography>
					<Typography variant="subtitle1" paragraph className="cardInfo">
						Email:{" "}
						<Link href={`mailto:${lead.student.user.email}`} underline="hover">
							{lead.student.user.email}
						</Link>
					</Typography>
					<Typography variant="subtitle1" paragraph className="cardInfo">
						Phone:{" "}
						<Link
							href={`https://wa.me/${lead.student.calling_code.replace("+", "")}${lead.student.phone_number}`}
							target="_blank"
							rel="noopener noreferrer"
							underline="hover"
						>
							{lead.student.calling_code}
							{lead.student.phone_number}
						</Link>
					</Typography>
					<Typography variant="subtitle1" paragraph className="cardInfo">
						Address:{" "}
						{lead.student.address.country ? (
							<small>
								{lead.student.address.address_line_1}, {lead.student.address.address_line_2},{" "}
								{lead.student.address.city} - {lead.student.address.postal_code},{" "}
								{lead.student.address.country}
							</small>
						) : (
							<small>N/A</small>
						)}
					</Typography>
					<Typography variant="subtitle1" paragraph className="cardInfo">
						Nationality:{" "}
						{lead.student.nationality ? (
							<Tooltip title={lead.student.nationality}>
								<span>
									<ReactCountryFlag
										countryCode={lead.student.nationality_code}
										svg
										style={{ width: "1.5em", height: "1.5em", marginRight: "8px" }}
									/>
								</span>
							</Tooltip>
						) : (
							"N/A"
						)}
					</Typography>
					<Typography variant="subtitle1" paragraph className="cardInfo">
						Date of Birth: {lead.student.dob || "N/A"}
					</Typography>
					<Typography variant="subtitle1" paragraph className="cardInfo">
						Gender: {lead.student.gender}
					</Typography>
				</CardContent>

				<Box
					aria-describedby={id}
					onClick={handlePopoverOpen}
					sx={{ cursor: "pointer", position: "relative", opacity: 1 }}
					onMouseEnter={(e) => (e.currentTarget.style.opacity = 0.85)}
					onMouseLeave={(e) => (e.currentTarget.style.opacity = 1)}
				>
					<CardMedia
						component="img"
						sx={{
							width: "auto",
							height: 215,
							borderRadius: "8px",
							objectFit: "cover",
							margin: "16px",
						}}
						image={lead.student.profile_picture || "/static/images/passport-avatar.png"}
						alt={"Profile picture"}
					/>
				</Box>

				{/* Conditionally render the Popover */}
				{open && (
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handlePopoverClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
					>
						<FileUploader onFileChange={handleFileChange} />
					</Popover>
				)}
			</Card>
			<EditStudentDialog
				open={openEditStudentDialog}
				setOpenDialog={setOpenEditStudentDialog}
				student={lead.student}
				studentState={studentState}
			/>
		</Grid>
	);
}

AboutStudentCard.propTypes = {
	lead: PropTypes.object.isRequired,
};

export default AboutStudentCard;
