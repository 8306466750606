import * as React from "react";

import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";

import { useEdvantageContext } from "../../EdvantageProvider";

export default function AdditionalServicesForm(props) {
	const { leadData, setLeadData } = props;
	const { services } = useEdvantageContext();

	const handleChange = (event) => {
		const { name, checked } = event.target;
		let updatedServices = [...(leadData.additional_services || [])];

		if (checked) {
			// Add the service if checked
			updatedServices.push(name);
		} else {
			// Remove the service if unchecked
			updatedServices = updatedServices.filter(
				(service) => service !== name
			);
		}

		setLeadData({ ...leadData, additional_services: updatedServices });
	};

	return (
		<Grid
			container
			spacing={2}
			justifyContent="center"
			alignItems="center"
			id="additional_services-form"
		>
			<FormGroup sx={{ width: "100%" }}>
				<Grid container spacing={2} sx={{ padding: '25px' }}>
					{services.map((service) => (
						<Grid item xs={6} key={service.id}>
							<FormControlLabel
								key={service.id}
								control={
									<Checkbox
										checked={
											leadData.additional_services?.includes(
												service.id
											) || false
										}
										onChange={handleChange}
										name={service.id}
									/>
								}
								label={service.name}
							/>
						</Grid>
					))}
				</Grid>
			</FormGroup>
		</Grid>
	);
}
