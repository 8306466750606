import * as React from "react";

import {
    Avatar,
    Box,
    ClickAwayListener,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography,
} from "@mui/material";

import CircleIcon from "@mui/icons-material/Circle";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Popper } from "@mui/base/Popper";
import Transitions from "../extended/Transitions";
import { queryStringToObject } from "../../utils/Utils";
import { updateNotifications } from "../../store/notification.slice";
import { useDispatch } from "react-redux";
import { useEdvantageContext } from "../../EdvantageProvider";
import useNotification from "../../hooks/useNotification";

export default function NotificationsPopper({ theme, open, anchorEl, notificationState, handleClose, handleScroll, setUrlParams }) {
    const dispatch = useDispatch();
    const { notifications } = useNotification();
    const { scrollToElement } = useEdvantageContext();

    const handleMarkAllRead = () => {
        const unreadNotificationIds = notifications.filter(notification => !notification.is_read).map(notification => notification.id);
        if (unreadNotificationIds.length) {
            dispatch(updateNotifications({ notificationIds: unreadNotificationIds, action: "mark_read" }));
        }
    };

    const seenIdsRef = React.useRef(new Set());
    const observerRef = React.useRef(null);

    const handleIntersect = (entries) => {

        const newSeenIds = [];
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                // Add the ID to the seenIds set if it becomes visible
                const id = entry.target.dataset.id;
                const isSeen = entry.target.dataset.isSeen === 'true';

                if (!isSeen && !seenIdsRef.current.has(id)) {
                    newSeenIds.push(id);
                    seenIdsRef.current.add(id);
                }
            }
        });

        if (newSeenIds.length) {
            dispatch(updateNotifications({ notificationIds: newSeenIds, action: "mark_seen" }));
        }
    };

    const handleClick = (e, queryString) => {
        if (queryString) {
            const queryObject = queryStringToObject(queryString);
            setUrlParams(prevParams => ({
                ...prevParams,
                ...queryObject,
            }));

            if (queryObject.comment_id) {
                scrollToElement(queryObject.comment_id);
            }
        }
    };

    React.useEffect(() => {
        // Create a new IntersectionObserver instance when the Popper is opened
        if (open) {
            observerRef.current = new IntersectionObserver(handleIntersect, {
                root: null,
                threshold: 0.1,
            });

            // Observe each item
            const itemElements = document.querySelectorAll('.list-item');
            itemElements.forEach(item => {
                observerRef.current.observe(item);
            });

            // Cleanup function to unobserve items when the component unmounts
            return () => {
                itemElements.forEach(item => {
                    observerRef.current.unobserve(item);
                });
                observerRef.current.disconnect(); // Disconnect observer
            };
        }
    }, [open, notifications]);

    React.useEffect(() => {
        // Disable scroll event globally when University component is active
        window.disableWheel = true;

        // Clean up when the component is unmounted
        return () => {
            window.disableWheel = false;
        };
    }, []);

    return (
        <Popper
            placement="bottom-end"
            open={open}
            anchorEl={anchorEl}
            role={undefined}
            transition
            disablePortal
        >
            {({ TransitionProps }) => (
                <Transitions in={open} {...TransitionProps}>
                    <Paper sx={{ width: 300, boxShadow: theme.shadows[6] }}>
                        <ClickAwayListener onClickAway={handleClose}>
                            <Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: "16px" }}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: "600",
                                            fontSize: "1.1rem",
                                            color: theme.palette.text.primary,
                                        }}
                                    >
                                        Notifications
                                    </Typography>
                                    {notificationState.unread_count > 0 && (
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: theme.palette.primary.main,
                                                cursor: "pointer",
                                                fontSize: "0.8rem",
                                                textAlign: "right"
                                            }}
                                            onClick={handleMarkAllRead}
                                        >
                                            Mark all read
                                        </Typography>
                                    )}
                                </Box>
                                <Divider />
                                <PerfectScrollbar style={{ maxHeight: "300px", overflowY: "auto" }} onScroll={handleScroll}>
                                    <List component="nav" sx={{ maxHeight: 220 }}>
                                        {notifications.map((notification, index) => (
                                            <React.Fragment key={`fragment-${index}`}>
                                                <ListItem
                                                    key={notification.id} // Use notification.id for key
                                                    className="list-item" // Add a class name to the list item
                                                    data-id={notification.id}
                                                    data-is-seen={notification.is_seen}
                                                    sx={{
                                                        backgroundColor: notification.is_read ? "#eeeeee" : "white", // Highlight unread with a light background
                                                    }}
                                                    onClick={(e) => handleClick(e, notification.link)}
                                                >
                                                    <ListItemIcon sx={{ minWidth: "auto", alignItems: "center", mr: "0.5rem" }}>
                                                        <CircleIcon
                                                            sx={{
                                                                fontSize: 8,
                                                                color: notification.is_read ? "#eeeeee" : "#1976d2",
                                                                marginRight: "10px",
                                                            }}
                                                        />
                                                        <Avatar alt="User Avatar" src="/static/images/avatars/avatar_1.jpg" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "15px",
                                                                    fontStyle: "italic",
                                                                    color: notification.is_read
                                                                        ? "text.secondary" // Lighter color for read
                                                                        : "text.primary",  // Dark color for unread
                                                                }}
                                                            >
                                                                {notification.message}
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItem>
                                                <Divider />
                                            </React.Fragment>
                                        ))}
                                    </List>
                                </PerfectScrollbar>
                            </Box>
                        </ClickAwayListener>
                    </Paper>
                </Transitions>
            )}
        </Popper>
    );
}
