import * as React from 'react';

import { Autocomplete, CircularProgress, Grid, TextField } from '@mui/material';

import GenericMultiSelect from '../common/GenericMultiSelect';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCitiesForCountries, setCities } from '../../store/city.slice';
import ReactCountryFlag from 'react-country-flag';

function PreferencesForm(props) {

    const { leadData, setLeadData, setIsPreferenceFormValid } = props;
    const dispatch = useDispatch();

    const majorState = useSelector(state => state.major);
    const majors = majorState.majors;

    const programState = useSelector(state => state.program);
    const programs = programState.programs;

    const countryState = useSelector(state => state.country);
    const countries = countryState.countries;

    const cityState = useSelector(state => state.city);
    const cities = cityState.cities;

    const [formErrors, setFormErrors] = React.useState({});
    const [touched, setTouched] = React.useState({});


    React.useEffect(() => {
        const preferemceFormErrors = validatePreference();
        setIsPreferenceFormValid(Object.keys(preferemceFormErrors).length === 0);
    }, [leadData.consultation_type, leadData.preferred_programs, leadData.preferred_majors, leadData.cities, leadData.countries]);

    const validatePreference = () => {
        const errors = {};
        if (!leadData.consultation_type) {
            errors.consultation_type = 'Consultation type is required';
        }
        if (!leadData.preferred_programs.length) {
            errors.preferred_programs = 'At least one program is required';
        }
        if (!leadData.preferred_majors.length) {
            errors.preferred_majors = 'At least one major is required';
        }
        setFormErrors(errors);

        return errors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        let updatedLeadData = { ...leadData, [name]: value };
        if (name === 'preferred_countries') {
            // Determine which countries were removed
            const removedCountryIds = leadData.preferred_countries.filter(countryId => !value.includes(countryId));

            if (removedCountryIds.length) {
                // Get the country objects for the removed IDs
                const removedCountries = countries.filter(country => removedCountryIds.includes(country.id));

                // Remove cities associated with removed countries
                const filteredCities = leadData.preferred_cities.filter(city => {
                    // Check if the city belongs to any removed country
                    return !removedCountries.some(removedCountry => removedCountry.code2 === city.countryCode);
                });

                // Update preferred cities in leadData
                updatedLeadData.preferred_cities = filteredCities;

                // Optionally dispatch action to update cities in your state
                const newCities = cities.filter(city => !removedCountries.some(removedCountry => removedCountry.code2 === city.countryCode));
                dispatch(setCities(newCities));
            } else {
                // If no countries are removed, fetch cities for the selected countries
                const selectedCountries = value.map(countryId => {
                    return countries.find(c => c.id === countryId) || null; // Return the country object or null if not found
                }).filter(country => country !== null);
                dispatch(fetchCitiesForCountries(selectedCountries));
            }
        }

        setLeadData(updatedLeadData);
        // Mark the field as touched
        setTouched({ ...touched, [name]: true });

        // Validate the specific field being changed
        validateField(name, value);
    };

    const validateField = (name, value) => {
        let error = '';
        switch (name) {
            case 'consultation_type':
                if (!value) error = 'Consultation type is required';
                break;
            case 'preferred_programs':
                if (!value.length) error = 'At least one program is required';
                break;
            case 'preferred_majors':
                if (!value.length) error = 'At least one major is required';
                break;
            default:
                break;
        }

        setFormErrors({ ...formErrors, [name]: error });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <GenericMultiSelect
                    name='preferred_programs'
                    label='Preferred Programs'
                    value={leadData.preferred_programs}
                    onChange={handleChange}
                    error={Boolean(touched.preferred_programs && formErrors.preferred_programs)}
                    helperText={touched.preferred_programs && formErrors.preferred_programs}
                    options={programs}
                    getOptionLabel={(option) => option.name}
                    optionValueKey="id"
                />
            </Grid>
            <Grid item xs={12}>
                <GenericMultiSelect
                    name='preferred_majors'
                    label='Preferred Majors'
                    value={leadData.preferred_majors}
                    onChange={handleChange}
                    error={Boolean(touched.preferred_majors && formErrors.preferred_majors)}
                    helperText={touched.preferred_majors && formErrors.preferred_majors}
                    options={majors}
                    getOptionLabel={(option) => option.name}
                    optionValueKey="id"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <GenericMultiSelect
                    name='preferred_countries'
                    label='Preferred Countries'
                    value={leadData.preferred_countries}
                    limitTags={1}
                    onChange={handleChange}
                    error={Boolean(touched.preferred_countries && formErrors.preferred_countries)}
                    helperText={touched.preferred_countries && formErrors.preferred_countries}
                    options={countries}
                    getOptionLabel={(option) => option.name}
                    optionValueKey="id"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Autocomplete
                    multiple
                    name='preferred_cities'
                    options={cities}
                    loading={cityState.loading} // Show loading spinner if cities are being fetched
                    value={leadData.preferred_cities}
                    onChange={(event, newValue) => handleChange({ target: { name: 'preferred_cities', value: newValue } })}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Preferred Cities"
                            error={Boolean(touched.preferred_cities && formErrors.preferred_cities)}
                            helperText={touched.preferred_cities && formErrors.preferred_cities}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {cityState.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                    renderOption={(props, option) => {
                        // Destructure the key from the props
                        const { key, ...restProps } = props;
                        return (
                            <li
                                key={`${option.countryCode}-${option.name}`} // Directly assign the key
                                {...restProps} // Spread remaining props
                            >
                                <ReactCountryFlag
                                    countryCode={option.countryCode}
                                    svg
                                    style={{
                                        width: "1.5em",
                                        height: "1.5em",
                                        marginRight: "8px",
                                    }}
                                />
                                {option.name}
                            </li>
                        );
                    }}
                    getOptionLabel={(option) => option.name}
                />
            </Grid>
        </Grid>
    );
}

PreferencesForm.propTypes = {
    leadData: PropTypes.object.isRequired,
    setLeadData: PropTypes.func.isRequired,
    error: PropTypes.object,
};

export default PreferencesForm;
