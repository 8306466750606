import * as React from "react";

import {
	clearUserState,
	resendPassword
} from "../../store/user.slice";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";

import { useDispatch } from "react-redux";


function ResetUserDialog(props) {
  const dispatch = useDispatch();

  function handleClickClose() {
    dispatch(clearUserState());
		props.setOpenDialog(false);
		}

  const { open, setOpenDialog, user, userState } = props;

 const handleYesClick = () => {
      dispatch(resendPassword({ id: user.id })).then(
        (response) => {
          if (response.type === "user/resendPassword/fulfilled") {
            dispatch(clearUserState());
            setOpenDialog(false);
          }
        }
      );
  }

	return (
		<Dialog
        open={open}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmation"}
        </DialogTitle>
        <DialogContent>
        {userState.error &&
            (userState.action === "resendPassword") && (
              <Alert
                sx={{ width: "100%", mt: 2, mb: 2 }}
                severity="error"
              >
                {props.userState.error}
              </Alert>
            )}
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to reset the user password?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} variant="outlined" size="small">NO</Button>
          <Button onClick={handleYesClick} variant="contained" size="small" color="primary">YES</Button>
        </DialogActions>
      </Dialog>
	);
}

ResetUserDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpenDialog: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	userState: PropTypes.object.isRequired,
};

export default ResetUserDialog;
