import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "../wrappers/fetchWrapper";

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/program`;

const initialState = {
	action: "",
	programs: [],
	loading: false,
	error: null,
	message: null,
};

const name = "program";

const programSlice = createSlice({
	name: name,
	initialState,
	reducers: {
		// Optionally add some synchronous reducers if needed
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		clearMessage: (state) => {
			state.message = null;
		},
		clearProgramState: (state) => {
			state.programs = [];
			state.loading = false;
			state.error = null;
			state.message = null;
			localStorage.removeItem("programs"); // Clear local storage
		},
		setPrograms: (state, action) => {
			state.programs = action.payload;
			localStorage.setItem("programs", JSON.stringify(state.programs));
		},
		changeProgramState: (state, action) => {
			const { action: actionType, data } = action.payload;

			if (actionType === "create") {
				// Add new programs to the list
				state.programs = [...state.programs, data];
			} else if (actionType === "update") {
				// Update the existing program
				state.programs = state.programs.map(program => program.id === data.id ? data : program);
			} else if (actionType === "delete") {
				// Delete the program by id
				state.programs = state.programs.filter(program => program.id !== data.id);
			}
		}
	},
	extraReducers: (builder) => {
		builder
			// Add Cred
			.addCase(addProgram.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(addProgram.fulfilled, (state, action) => {
				state.action = "addProgram";
				state.loading = false;
				state.message = "Program Added Successfully";
				state.programs = [...state.programs, ...action.payload];
			})
			.addCase(addProgram.rejected, (state, action) => {
				state.action = "addProgram";
				state.loading = false;
				if (Array.isArray(action.payload)) {
					state.error = action.payload;
				} else if (typeof action.payload === "object") {
					state.error = ["Unknown Error!"];
				} else {
					state.error = action.payload;
				}
			})
			// Update Cred
			.addCase(updateProgarm.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(updateProgarm.fulfilled, (state, action) => {
				state.loading = false;
				state.message = "Program Updated Successfully";
				const index = state.programs.findIndex(
					(program) => program.id === action.payload.id
				);
				if (index !== -1) {
					state.programs[index] = action.payload;
				}
			})
			.addCase(updateProgarm.rejected, (state, action) => {
				state.loading = false;
				state.error =
					typeof action.payload === "object"
						? ["Unknown Error!"]
						: action.payload;
			})
			.addCase(fetchPrograms.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(fetchPrograms.fulfilled, (state, action) => {
				state.loading = false;
				state.programs = action.payload;
			})
			.addCase(fetchPrograms.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(deleteProgram.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.message = null;
			})
			.addCase(deleteProgram.fulfilled, (state, action) => {
				state.loading = false;
				state.message = "Program Deleted Successfully";
				state.programs = state.programs.filter(
					(program) => program.id !== action.payload.id
				);
			})
			.addCase(deleteProgram.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});

export const { changeProgramState, setLoading, clearMessage, clearProgramState, setPrograms } =
	programSlice.actions;
export const programReducer = programSlice.reducer;

export const addProgram = createAsyncThunk(
	`${name}/addProgram`,
	async (payload, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.post(`${baseUrl}/add`, payload);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const fetchPrograms = createAsyncThunk(
	`${name}/fetchPrograms`,
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.get(
				`${baseUrl}/fetch?timestamp=${new Date().getTime()}`
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const updateProgarm = createAsyncThunk(
	`${name}/updateProgarm`,
	async ({ id, program }, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.put(`${baseUrl}/${id}`, program);
			return response.data;
		} catch (error) {
			return rejectWithValue({ error: error });
		}
	}
);

export const deleteProgram = createAsyncThunk(
	`${name}/deleteProgram`,
	async ({ id, uuid }, { rejectWithValue }) => {
		try {
			const response = await fetchWrapper.delete(
				`${baseUrl}/delete/${id}`, { uuid }
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
