import * as React from 'react';

import { addProgram, setLoading } from '../../store/program.slice';

import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { PROGRAM_CATEGORY_CHOICES } from '../../const';
import PropTypes from 'prop-types';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import TextField from '@mui/material/TextField';
import { generateMenuItems } from '../../utils/Utils';
import { useDispatch } from 'react-redux';

function AddProgramDialog(props) {

    const dispatch = useDispatch();

    const [form, setForm] = React.useState({ programs: [{ name: '', category: '' }] });
    const [formErrors, setFormErrors] = React.useState({});
    const [isFormValid, setIsFormValid] = React.useState(false);
    const [disableAdd, setDisableAdd] = React.useState(true);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const errors = validateForm();
        const isValid = !Object.values(errors).some(error => error);

        setFormErrors(errors);
        setIsFormValid(isValid);

        if (isValid) {
            dispatch(setLoading(true));
            dispatch(addProgram({ programs: form.programs, uuid: props.uuid }))
                .then((response) => {
                    if (response.type === 'program/addProgram/fulfilled') {
                        handleCloseDialog();
                    }
                });
        }
    };

    const validateForm = () => {
        let errors = {};
        form.programs.forEach((program, index) => {
            if (!program.name.trim()) {
                errors[`program${index}`] = 'Program is required';
            }
            if (!program.category.trim()) {
                errors[`category${index}`] = 'Category is required';
            }
        });
        return errors;
    };

    const handleChange = (index, field, value) => {
        const updatedPrograms = [...form.programs];
        updatedPrograms[index] = { ...updatedPrograms[index], [field]: value };
        setForm({ ...form, programs: updatedPrograms });

        let errors = { ...formErrors };
        errors[`${field}${index}`] = value.trim() ? '' : `${field === 'name' ? 'Program' : 'Category'} is required`;
        setFormErrors(errors);

        const isValid = Object.values(errors).every(error => !error);
        setIsFormValid(isValid);
        setDisableAdd(!isValid || form.programs.length > 15);
    };

    const handleAddInput = () => {
        setForm({ ...form, programs: [...form.programs, { name: '', category: '' }] });
        setDisableAdd(true);
    };

    const handleDeleteInput = (index) => {
        const updatedPrograms = [...form.programs];
        updatedPrograms.splice(index, 1);
        setForm({ ...form, programs: updatedPrograms });

        let errors = { ...formErrors };
        delete errors[`program${index}`];
        delete errors[`category${index}`];
        setFormErrors(errors);

        const isValid = Object.values(errors).every(error => !error);
        setIsFormValid(isValid);
        setDisableAdd(false);
    };

    const handleCloseDialog = () => {
        props.setOpenDialog(false);
        setForm({ programs: [{ name: '', category: '' }] });
        setFormErrors({});
        setIsFormValid(false);
    };

    return (
        <Dialog
            open={props.open}
            onClose={handleCloseDialog}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">Add Program</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please fill out the details below to add new programs.
                </DialogContentText>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    {props.programState.error && props.programState.action === 'addProgram' && <Alert sx={{ width: '100%', mt: 2, mb: 2 }} severity="error">{props.programState.error}</Alert>}
                    <Grid container spacing={2}>
                        {form.programs.map((program, index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={4}>
                                    <TextField
                                        select
                                        name={`category${index}`}
                                        label="Category"
                                        value={program.category}
                                        onChange={(e) => handleChange(index, 'category', e.target.value)}
                                        required
                                        error={Boolean(formErrors[`category${index}`])}
                                        helperText={formErrors[`category${index}`]}
                                        fullWidth
                                    >
                                        {generateMenuItems(PROGRAM_CATEGORY_CHOICES)}
                                    </TextField>
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField
                                        name={`program${index}`}
                                        required
                                        fullWidth
                                        id={`program${index}`}
                                        label={`Program ${index + 1}`}
                                        value={program.name}
                                        onChange={(e) => handleChange(index, 'name', e.target.value)}
                                        error={Boolean(formErrors[`program${index}`])}
                                        helperText={formErrors[`program${index}`]}
                                    />
                                </Grid>
                                {form.programs.length > 1 && (
                                    <Grid item xs={1}>
                                        <IconButton
                                            aria-label="delete"
                                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}
                                            onClick={() => handleDeleteInput(index)}
                                        >
                                            <RemoveCircleIcon fontSize="inherit" sx={{ color: '#f44336' }} />
                                        </IconButton>
                                    </Grid>
                                )}
                            </React.Fragment>
                        ))}
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handleAddInput}
                                startIcon={<AddIcon />}
                                disabled={disableAdd}
                            >
                                Add
                            </Button>
                        </Grid>
                    </Grid>
                    <DialogActions sx={{ justifyContent: 'flex-start', pl: 0 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={!isFormValid}
                        >
                            Save
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            color="error"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleCloseDialog}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

AddProgramDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpenDialog: PropTypes.func.isRequired,
    programState: PropTypes.object.isRequired,
};

export default AddProgramDialog;
