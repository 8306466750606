import "jspdf-autotable"; // Import the autoTable plugin

import { LOGO_SRC, formatFee } from "../../const";

import { Button } from "@mui/material";
import React from "react";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import jsPDF from "jspdf";

export default function UniversityExportButton({ rows, columns, universities, filterCourse }) {
	const filteredFields = [
		"name",
		"major",
		"program",
		"intake",
		"status",
		"fee",
		"is_sponsored",
		"city",
		"country",
	];

	const handleExport = () => {
		const doc = new jsPDF("p", "pt", "a4");

		// Add logo at the top
		doc.addImage(LOGO_SRC, "PNG", 40, 20, 150, 40);

		// Add the title below the logo
		doc.setFontSize(18);
		doc.setFont("helvetica", "bold");
		doc.text("Shortlisted Universities", doc.internal.pageSize.getWidth() / 2, 80, { align: "center" });

		const tableColumn = columns
			.filter((col) => filteredFields.includes(col.field))
			.map((col) => col.headerName);

		const universityRows = rows.filter((row) => row.isUniversity);

		const tableRows = filterCourse
			? rows.map((row) =>
				columns
					.filter((col) => filteredFields.includes(col.field))
					.map((col) => {
						if (col.field === "is_sponsored" && row[col.field] !== "") {
							return row[col.field] ? "Yes" : "No";
						}
						return row[col.field] || "";
					})
			)
			: universityRows.flatMap((row) => {
				const universityRow = columns
					.filter((col) => filteredFields.includes(col.field))
					.map((col) => row[col.field]);

				const courses = universities.find((university) => university.id === row.id)?.courses;

				const courseRows = courses?.map((course) =>
					columns
						.filter((col) => filteredFields.includes(col.field))
						.map((col) => {
							if (col.field === "intake") {
								return course[col.field]?.text || "";
							}
							if (col.field === "is_sponsored" && course[col.field] !== "") {
								return course[col.field] ? "Yes" : "No";
							}
							if (col.field === "fee") {
								return `${course["currency"] || " "} ${formatFee(course[col.field]) || ""}` || "";
							}
							return course[col.field] || "";
						})
				);

				return [universityRow, ...(courseRows || [])];
			});

		// Use autoTable to generate the table inside the PDF
		doc.autoTable({
			startY: 100, // Starts below the title
			head: [tableColumn], // Add column headers
			body: tableRows, // Add table rows
			styles: {
				fontSize: 9, // Set the font size for table content
				font: "helvetica",
				textColor: "#000",
				lineColor: "#ddd", // Line color for borders
				lineWidth: 0.5,
			},
			headStyles: {
				fillColor: "#007bff", // Header background color
				textColor: "#fff", // Header text color
				fontStyle: "bold",
			},
			alternateRowStyles: {
				fillColor: "#f2f2f2", // Alternate row background color
			},
			margin: { top: 10, right: 10, bottom: 20, left: 10 }, // Margins for the table
			theme: "striped", // Striped rows
			columnStyles: {
				0: { cellWidth: 70 }, // Adjust the width of each column
				1: { cellWidth: 60 },
				2: { cellWidth: 80 },
				3: { cellWidth: 60 },
				4: { cellWidth: 60 },
				5: { cellWidth: 60 },
				6: { cellWidth: 60 },
				7: { cellWidth: 60 },
			},
			didDrawPage: (data) => {
				// Add footer
				let pageCount = doc.internal.getNumberOfPages();
				let str = "Page " + pageCount;
				doc.setFontSize(10);
				doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 10);
			},
		});

		doc.save("universities.pdf");
	};

	return (
		<Button onClick={handleExport} startIcon={<SaveAltIcon />} variant="text" color="primary">
			Download PDF
		</Button>
	);
}
