import { useDispatch, useSelector } from 'react-redux';

import { createSelector } from 'reselect';
import { fetchComments } from '../store/comment.slice';
import { useEffect } from 'react';

// Memoized selector function
const selectCommentsByModelAndInstance = (modelName, instanceId) =>
    createSelector(
        (state) => state.comment.comments,
        (comments) => comments[modelName]?.[instanceId] || []
    );

function useComment(modelName, instanceId) {

    const dispatch = useDispatch();

    const comments = useSelector(selectCommentsByModelAndInstance(modelName, instanceId));
    const commentStatus = useSelector((state) => state.comment.status[modelName]?.[instanceId] || 'idle');

    useEffect(() => {
        async function onLoad() {
            if (commentStatus === 'idle') {
                dispatch(fetchComments({ modelName, instanceId }));
            }
        }

        onLoad();
    }, [commentStatus, dispatch, modelName, instanceId]);

    return {
        comments,
        commentStatus
    };
}

export default useComment;
