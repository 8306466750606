import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../wrappers/fetchWrapper';

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/comment`;

const initialState = {
    status: {},
    comments: {},
    loading: false,
    error: null,
};

const name = "comment"

const commentSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        clearCommentState: (state) => {
            state.status = {};
            state.comments = {};
            state.loading = false;
            state.error = null;
        },
        setComments: (state, action) => {
            state.comments = action.payload;
        },
        changeCommentState: (state, action) => {
            const { action: actionType, data } = action.payload;

            if (actionType === 'create') {
                // Add new comment to the list
                const { model_name, instance_id, comment } = data;
                if (!state.comments[model_name]) state.comments[model_name] = {};
                state.comments[model_name][instance_id] = [
                    ...(state.comments[model_name][instance_id] || []),
                    comment,
                ];
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchComments.pending, (state, action) => {
                const { modelName, instanceId } = action.meta.arg;
                if (!state.status[modelName]) state.status[modelName] = {};
                state.status[modelName][instanceId] = 'loading';
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchComments.fulfilled, (state, action) => {
                const { modelName, instanceId, commets } = action.payload;
                if (!state.comments[modelName]) state.comments[modelName] = {};
                if (!state.status[modelName]) state.status[modelName] = {};
                state.comments[modelName][instanceId] = commets.results;
                state.status[modelName][instanceId] = 'succeeded';
                state.loading = false;
            })
            .addCase(fetchComments.rejected, (state, action) => {
                if (Array.isArray(action.payload)) {
                    state.error = action.payload;
                } else if (typeof action.payload === "object") {
                    state.error = ["Unknown Error!"];
                } else {
                    state.error = action.payload;
                }
                const { modelName, instanceId } = action.meta.arg;
                if (!state.status[modelName]) state.status[modelName] = {};
                state.status[modelName][instanceId] = 'failed';
                state.loading = false;
            })
            .addCase(addComment.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addComment.fulfilled, (state, action) => {
                const { model_name, instance_id, comment } = action.payload;
                if (!state.comments[model_name]) state.comments[model_name] = {};
                state.comments[model_name][instance_id] = [
                    ...(state.comments[model_name][instance_id] || []),
                    comment,
                ];
                state.loading = false;

            })
            .addCase(addComment.rejected, (state, action) => {
                state.loading = false;
                if (Array.isArray(action.payload)) {
                    state.error = action.payload;
                } else if (typeof action.payload === "object") {
                    state.error = ["Unknown Error!"];
                } else {
                    state.error = action.payload;
                }
            });
    }
});

export const { changeCommentState, clearCommentState, setComments } = commentSlice.actions;
export const commentReducer = commentSlice.reducer;

export const fetchComments = createAsyncThunk(
    `${name}/fetchComments`,
    async ({ modelName, instanceId }, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.get(
                `${baseUrl}/fetch?model_name=${modelName}&instance_id=${instanceId}&timestamp=${new Date().getTime()}`
            );
            return { modelName, instanceId, commets: response.data };
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const addComment = createAsyncThunk(
    `${name}/addComment`,
    async (comment, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.post(`${baseUrl}/add`, comment);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
