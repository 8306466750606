import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Button } from "@mui/material";
import jsPDF from "jspdf";
import React, { useRef } from "react";
import { LOGO_SRC } from "../../const";

/**
 * A button component to export the school data as a PDF. The button is rendered
 * as a text button with a "Download PDF" label and a SaveAltIcon on the left.
 * When clicked, it generates a PDF file using jsPDF and saves it to the user's
 * local file system.
 *
 * The PDF file includes a table with the school data, with the columns filtered
 * to only include the required fields. The table is styled with a striped
 * pattern and the header row is rendered with a blue background color.
 *
 * @param {Array} rows The data rows to be included in the PDF.
 * @param {Array} columns The columns to be included in the PDF.
 * @param {Array} schools The list of schools to be used in the export.
 * @param {Function} filterCourse A function to filter the courses in the export,
 * given the current course filter.
 */
export default function SchoolExportButton({
    rows,
    columns,
    schools,
    filterCourse,
}) {
    const pdfContainerRef = useRef(); // Reference to the container with HTML

    // Filter the columns to only include the required fields
    const filteredFields = [
        "name",
        "city",
        "country",
    ];

    // Handle export functionality
    const handleExport = () => {
        const doc = new jsPDF("p", "pt", "a4");

        // Use doc.html() to convert the HTML content to a PDF
        doc.html(pdfContainerRef.current, {
            callback: function (doc) {
                doc.save("schools.pdf");
            },
            x: 10,
            y: 10,
            html2canvas: { scale: 1 }, // Adjust the scale to fit more content on the page
            margin: [20, 10, 10, 10], // Margins around the page
        });
    };

    // Generate the HTML table dynamically, including the logo and title
    const generateHTMLContent = () => {
        const schoolRows = rows.filter((row) => row.isUniversity);
        const tableRows = filterCourse
            ? rows.map((row) =>
                columns
                    .filter((col) => filteredFields.includes(col.field))
                    .map((col) => {
                        if (
                            col.field === "is_sponsored" &&
                            row[col.field] !== ""
                        ) {
                            return row[col.field] ? "Yes" : "No";
                        }
                        return row[col.field] || "";
                    })
            )
            : schoolRows.flatMap((row) => {
                const schoolRow = columns
                    .filter((col) => filteredFields.includes(col.field))
                    .map((col) => row[col.field]);

                const courses = schools.find(
                    (school) => school.id === row.id
                )?.courses;

                const courseRows = courses?.map((course) =>
                    columns
                        .filter((col) => filteredFields.includes(col.field))
                        .map((col) => {
                            if (col.field === "intake") {
                                return course[col.field]?.text || "";
                            }
                            if (
                                col.field === "is_sponsored" &&
                                course[col.field] !== ""
                            ) {
                                return course[col.field] ? "Yes" : "No";
                            }
                            if (col.field === "fee") {
                                return (
                                    `${course["currency_symbol"] || ""} ${course[col.field] || ""
                                    }` || ""
                                );
                            }
                            return course[col.field] || "";
                        })
                );

                return [schoolRow, ...(courseRows || [])];
            });

        // Map the column headers
        const tableColumn = columns
            .filter((col) => filteredFields.includes(col.field))
            .map((col) => col.headerName);

        return (
            <div style={{ width: "100%" }}>
                {/* Logo */}
                <img
                    src={LOGO_SRC}
                    alt="Logo"
                    style={{
                        width: "150px",
                        height: "auto",
                        objectFit: "contain",
                        marginBottom: "10px",
                    }}
                />

                {/* Title */}
                <h1
                    style={{
                        whiteSpace: "nowrap",
                        fontSize: "18px",
                        textAlign: "center",
                        marginBottom: "20px",
                    }}
                >
                    Shortlisted Universities
                </h1>

                {/* Table */}
                <table
                    border="0"
                    cellPadding="5"
                    cellSpacing="0"
                    style={{ width: "100%", fontSize: "10px" }}
                >
                    <thead>
                        <tr
                            style={{
                                backgroundColor: "#007bff",
                                color: "#fff",
                            }}
                        >
                            {tableColumn.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows.map((row, rowIndex) => (
                            <tr
                                key={rowIndex}
                                style={{
                                    backgroundColor:
                                        rowIndex % 2 === 0 ? "#f2f2f2" : "#fff", // Striped rows: even rows light grey, odd rows white
                                }}
                            >
                                {row.map((cell, cellIndex) => (
                                    <td
                                        key={cellIndex}
                                    >
                                        {cell}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <>
            <div style={{ display: "none" }}>
                {/* Hidden container to hold the HTML content for PDF generation */}
                <div ref={pdfContainerRef}>{generateHTMLContent()}</div>
            </div>
            <Button
                onClick={handleExport}
                startIcon={<SaveAltIcon />}
                variant="text"
                color="primary"
            >
                Download PDF
            </Button>
        </>
    );
}
