import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../wrappers/fetchWrapper';

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/intake`;

const initialState = {
    action: "",
    intakes: [],
    loading: false,
    message: null,
    error: null,
};

const name = "intake"

const intakeSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        clearIntakeState: (state) => {
            state.intakes = [];
            state.loading = false;
            state.error = null;
        },
        clearMessage: (state) => {
            state.message = null;
        },
        setError: (state, action) => {
            state.action = action.payload.action;
            state.error = action.payload.error;
        },
        setIntakes: (state, action) => {
            state.intakes = action.payload;
        },
        setMessage: (state, action) => {
            state.message = action.payload;
        },
        changeIntakeState: (state, action) => {
            const { action: actionType, data } = action.payload;

            if (actionType === 'create') {
                // Add new intakes to the list
                state.intakes = [...state.intakes, data];
            } else if (actionType === 'update') {
                // Update the existing intake
                state.intakes = state.intakes.map(intake => intake.id === data.id ? data : intake);
            } else if (actionType === 'delete') {
                // Delete the intake by id
                state.intakes = state.intakes.filter(intake => intake.id !== data.id);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addIntake.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(addIntake.fulfilled, (state, action) => {
                state.action = 'addIntake';
                state.loading = false;
                state.message = "Intake Added Successfully";
                state.intakes = [...state.intakes, ...action.payload];
            })
            .addCase(addIntake.rejected, (state, action) => {
                state.action = 'addIntake';
                state.loading = false;
                if (Array.isArray(action.payload)) {
                    state.error = action.payload;
                } else if (typeof action.payload === 'object') {
                    state.error = ['Unknown Error!'];
                } else {
                    state.error = action.payload;
                }
            })
            .addCase(fetchIntakes.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchIntakes.fulfilled, (state, action) => {
                state.loading = false;
                state.intakes = action.payload;
            })
            .addCase(fetchIntakes.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteIntake.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(deleteIntake.fulfilled, (state, action) => {
                state.loading = false;
                state.message = "Intake Deleted Successfully";
                state.intakes = state.intakes.filter(intake => intake.id !== action.payload.id);
            })
            .addCase(deleteIntake.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(toggleIntakeActiveStatus.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(toggleIntakeActiveStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.message = "Intake Updated Successfully";
                state.intakes = state.intakes.map(intake => intake.id === action.payload.id ? action.payload : intake);
            })
            .addCase(toggleIntakeActiveStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const { changeIntakeState, clearIntakeState, clearMessage, setError, setMessage, setIntakes } = intakeSlice.actions;
export const intakeReducer = intakeSlice.reducer;

export const addIntake = createAsyncThunk(
    `${name}/addIntake`,
    async (payload, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.post(`${baseUrl}/add`, payload);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const fetchIntakes = createAsyncThunk(
    `${name}/fetchIntakes`,
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.get(`${baseUrl}/fetch?timestamp=${new Date().getTime()}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteIntake = createAsyncThunk(
    `${name}/deleteIntake`,
    async ({ id, uuid }, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.delete(`${baseUrl}/delete/${id}/`, { uuid });
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const toggleIntakeActiveStatus = createAsyncThunk(
    `${name}/toggleIntakeActiveStatus`,
    async ({ id, isActive, uuid }, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.put(`${baseUrl}/update/${id}/`, { is_active: isActive, uuid });
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
