import * as React from "react";

import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

export default function Loading(){
    return (
        <React.Fragment>
             <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                <LinearProgress />
                <LinearProgress />
                <LinearProgress />
            </Stack>
        </React.Fragment>
    )
}