import { Delete, ExpandMore, KeyboardArrowRight } from "@mui/icons-material";
import { Box, IconButton, Link } from "@mui/material";
import React from "react";
import { renderCenteredCell } from "../../utils/Utils";

/**
 * Generates the columns for the school data grid.
 *
 * @param {Object} expandedRows Object where the keys are the IDs of the expanded
 * rows and the values are true or false.
 * @param {Function} handleExpandClick Function to be called when a row is expanded
 * or collapsed.
 * @param {Function} handleDelete Function to be called when the delete button is
 * clicked.
 * @param {Function} onViewDetails Function to be called when the school details
 * button is clicked.
 *
 * @returns {Array} An array of objects representing the columns for the data grid.
 */
export default function schoolColumns(
    expandedRows,
    handleExpandClick,
    handleDelete,
    onViewDetails
) {
    return [
        {
            field: "Actions",
            headerName: "",
            flex: 0.5,
            width: 50,
            renderCell: (params) =>
                params.row.isSchool && (
                    <IconButton onClick={() => handleDelete(params.row.id)}>
                        <Delete />
                    </IconButton>
                ),
        },
        {
            field: "expand",
            headerName: "",
            width: 50,
            renderCell: (params) =>
                params.row.isSchool ? (
                    <IconButton
                        sx={{ marginRight: 1 }}
                        onClick={() => handleExpandClick(params.row.id)}
                    >
                        {expandedRows[params.row.id] ? (
                            <ExpandMore />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </IconButton>
                ) : null,
        },
        {
            field: "name",
            headerName: "School Name",
            flex: 2,
            minWidth: 200,
            renderCell: (params) => (
                <Box
                    sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        textAlign: "left",
                        wordBreak: "break-word",
                        whiteSpace: "normal", // Allow text to wrap
                        lineHeight: 1.5,
                        overflow: "visible",
                    }}
                >
                    <Link
                        component="button"
                        rel="noreferrer"
                        underline="hover"
                        sx={{ width: "100%", textAlign: "left" }}
                        onClick={() => onViewDetails(params.row.id)}
                    >
                        {params.value}
                    </Link>
                </Box>
            ),
        },
        {
            field: "program",
            headerName: "Program",
            flex: 1,
            minWidth: 100,
            renderCell: (params) => renderCenteredCell(params.value),
        },
        {
            field: "city",
            headerName: "City",
            flex: 1,
            minWidth: 100,
            renderCell: (params) => renderCenteredCell(params.value),
        },
        {
            field: "country",
            headerName: "Country",
            flex: 1,
            minWidth: 100,
            renderCell: (params) => renderCenteredCell(params.value),
        },
        {
            field: "google_maps_url",
            headerName: "Google Maps",
            flex: 2,
            minWidth: 200,
            renderCell: (params) => (
                <Box
                    sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "normal",
                        lineHeight: 1.5,
                        overflowWrap: "anywhere",
                    }}
                >
                    <Link
                        href={params.value}
                        target="_blank"
                        rel="noreferrer"
                        underline="hover"
                    >
                        {params.value}
                    </Link>
                </Box>
            ),
        },
    ];
}
