import {
	APPLICATION_METHOD_DETAILS,
	UNIVERSITY_STATUS_DETAILS,
} from "../../const";
import { Box, Chip, IconButton, Link } from "@mui/material";
import { Delete, ExpandMore, KeyboardArrowRight } from "@mui/icons-material";

import React from "react";
import { renderCenteredCell } from "../../utils/Utils";

export default function universityColumns(
	expandedRows,
	handleExpandClick,
	handleDelete,
	onViewDetails
) {
	return [
		{
			field: "Actions",
			headerName: "",
			width: 50,
			renderCell: (params) => params.row.isUniversity && (
				<IconButton onClick={() => handleDelete(params.row.id)}>
					<Delete />
				</IconButton>
			),
		},
		{
			field: "expand",
			headerName: "",
			// flex: 0.5,
			width: 50,
			renderCell: (params) =>
				params.row.isUniversity ? (
					<IconButton
						sx={{ marginRight: 1 }}
						onClick={() => handleExpandClick(params.row.id)}
					>
						{expandedRows[params.row.id] ? (
							<ExpandMore />
						) : (
							<KeyboardArrowRight />
						)}
					</IconButton>
				) : null,
		},
		{
			field: "name",
			headerName: "University Name",
			// flex: 2,
			width: 200,
			renderCell: (params) => (
				<Box
					sx={{
						height: "100%",
						display: "flex",
						alignItems: "center",
						textAlign: "left",
						wordBreak: "break-word",
						whiteSpace: "normal", // Allow text to wrap
						lineHeight: 1.5,
						overflow: "visible",
					}}
				>
					<Link
						component="button"
						rel="noreferrer"
						underline="hover"
						sx={{ width: "100%", textAlign: "left" }}
						onClick={() => onViewDetails(params.row.id)}
					>
						{params.value}
					</Link>
				</Box>
			),
		},
		{
			field: "major",
			headerName: "Major",
			// flex: 1,
			minWidth: 100,
			renderCell: (params) => renderCenteredCell(params.value),
		},
		{
			field: "program",
			headerName: "Program",
			// flex: 2,
			minWidth: 200,
			renderCell: (params) => renderCenteredCell(params.value),
		},
		{
			field: "intakes",
			headerName: "Intakes",
			// flex: 1.5,
			minWidth: 150,
			renderCell: (params) => renderCenteredCell(params.value.join(", ")),
		},
		{
			field: "application_method",
			headerName: "Application Method",
			// flex: 1,
			minWidth: 150,
			renderCell: (params) => {
				const application_method = params.value;
				const { color } = APPLICATION_METHOD_DETAILS[
					application_method
				] || { color: "defaultColor" };

				return (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							height: "100%",
							whiteSpace: "no-wrap",
							wordWrap: "break-word",
						}}
					>
						<Chip
							label={application_method || "N/A"}
							size="small"
							variant="outlined"
							sx={{
								color: color,
								borderColor: color,
								display: params.row.isUniversity
									? "none"
									: "inline-flex",
							}}
						/>
					</Box>
				);
			},
		},
		{
			field: "fee",
			headerName: "Fee",
			// flex: 1.5,
			minWidth: 150,
			valueGetter: (value, row) => {
				if (row.isUniversity) return "";
				if (!row.fee) return "N/A"; // Fallback for null or empty fee
				const formatter = new Intl.NumberFormat("en-US", {
					style: "currency",
					currency: row.currency || "USD", // Default to USD if currency is null
				});
				return formatter.format(row.fee);
			},
			renderCell: (params) => renderCenteredCell(params.value),
		},
		{
			field: "status",
			headerName: "Status",
			// flex: 1,
			minWidth: 100,
			renderCell: (params) => {
				const { color } = UNIVERSITY_STATUS_DETAILS[params.value] || {
					color: "defaultColor",
				};

				return (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							height: "100%",
							whiteSpace: "no-wrap",
							wordWrap: "break-word",
						}}
					>
						<Chip
							label={params.value}
							variant="outlined"
							size="small"
							sx={{
								color: color,
								borderColor: color,
								display: params.row.isUniversity
									? "none"
									: "inline-flex",
							}}
						/>
					</Box>
				);
			},
		},
		{
			field: "is_sponsored",
			headerName: "Sponsored",
			// flex: 1,
			minWidth: 100,
			renderCell: (params) => (
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						height: "100%",
						whiteSpace: "no-wrap",
						wordWrap: "break-word",
					}}
				>
					<Chip
						label={params.value ? "Yes" : "No"}
						size="small"
						sx={{
							backgroundColor: params.value
								? "#FFD700"
								: "#FF9494",
							color: "white",
							display: params.row.isUniversity
								? "none"
								: "inline-flex",
						}}
					/>
				</Box>
			),
		},
		{
			field: "brochure_url",
			headerName: "Brochure",
			// flex: 2,
			minWidth: 150,
			// maxWidth: 200,
			renderCell: (params) => (
				<Link
					href={params.value}
					target="_blank"
					rel="noreferrer"
					underline="hover"
					sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
				>
					{params.value}
				</Link>
			),
		},
		{
			field: "city",
			headerName: "City",
			// flex: 1,
			minWidth: 100,
			// maxWidth: 150,
			renderCell: (params) => renderCenteredCell(params.value),
		},
		{
			field: "country",
			headerName: "Country",
			// flex: 1,
			minWidth: 100,
			renderCell: (params) => renderCenteredCell(params.value),
		},
		{
			field: "google_maps_url",
			headerName: "Google Maps",
			// flex: 2,
			minWidth: 200,
			renderCell: (params) => (
				<Link
					href={params.value}
					target="_blank"
					rel="noreferrer"
					underline="hover"
					sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
				>
					{params.value}
				</Link>
			),
		},
	];
}
