import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../wrappers/fetchWrapper';

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/major`;

const initialState = {
    action: '',
    majors: [],
    loading: false,
    error: null,
    message: null,
};

const name = "major"

const majorSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        // Optionally add some synchronous reducers if needed
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        clearMessage: (state) => {
            state.message = null;
        },
        clearMajorState: (state) => {
            state.majors = [];
            state.loading = false;
            state.error = null;
            state.message = null;
        },
        setMajors: (state, action) => {
            state.majors = action.payload;
        },
        changeMajorState: (state, action) => {
            const { action: actionType, data } = action.payload;

            if (actionType === 'create') {
                // Add new major to the list
                state.majors = [...state.majors, data];
            } else if (actionType === 'update') {
                // Update the existing major
                const index = state.majors.findIndex(major => major.id === data.id);
                if (index !== -1) {
                    state.majors[index] = data;
                }
            } else if (actionType === 'delete') {
                // Delete the major by id
                state.majors = state.majors.filter(major => major.id !== data.id);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            // Add Cred
            .addCase(addMajor.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(addMajor.fulfilled, (state, action) => {
                state.action = 'addMajor';
                state.loading = false;
                state.message = "Major Added Successfully";
                state.majors = [...state.majors, ...action.payload];
            })
            .addCase(addMajor.rejected, (state, action) => {
                state.action = 'addMajor';
                state.loading = false;
                if (Array.isArray(action.payload)) {
                    state.error = action.payload;
                } else if (typeof action.payload === 'object') {
                    state.error = ['Unknown Error!'];
                } else {
                    state.error = action.payload;
                }
            })
            // Update Cred
            .addCase(updateMajor.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(updateMajor.fulfilled, (state, action) => {
                state.loading = false;
                state.message = "Major Updated Successfully";
                const index = state.majors.findIndex(major => major.id === action.payload.id);
                if (index !== -1) {
                    state.majors[index] = action.payload;
                }
            })
            .addCase(updateMajor.rejected, (state, action) => {
                state.loading = false;
                state.error = typeof action.payload === 'object' ? ['Unknown Error!'] : action.payload;
            })
            .addCase(fetchMajors.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(fetchMajors.fulfilled, (state, action) => {
                state.loading = false;
                state.majors = action.payload;
            })
            .addCase(fetchMajors.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteMajor.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addCase(deleteMajor.fulfilled, (state, action) => {
                state.loading = false;
                state.message = "Major Deleted Successfully";
                state.majors = state.majors.filter(major => major.id !== action.payload.id);
            })
            .addCase(deleteMajor.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const { setLoading, clearMessage, clearMajorState, changeMajorState, setMajors } = majorSlice.actions;
export const majorReducer = majorSlice.reducer;

export const addMajor = createAsyncThunk(
    `${name}/addMajor`,
    async (major, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.post(`${baseUrl}/add`, major);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const fetchMajors = createAsyncThunk(
    `${name}/fetchMajors`,
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.get(`${baseUrl}/fetch?timestamp=${new Date().getTime()}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateMajor = createAsyncThunk(
    `${name}/updateMajor`,
    async ({ id, major }, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.put(`${baseUrl}/update/${id}/`, major);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteMajor = createAsyncThunk(
    `${name}/deleteMajor`,
    async (id, { rejectWithValue }) => {
        try {
            const response = await fetchWrapper.delete(`${baseUrl}/delete/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
