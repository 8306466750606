import "../../styles/lead-details.css";

import * as React from "react";

import { ENGLISH_TEST_STATUS_CHOICES, GRADE_SYSTEM_CHOICES } from "../../const";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

/**
 * EligibilityCard is a MUI Card component that displays information about a lead's eligibility.
 *
 * The card displays the lead's university grades, TOEFL score, GRE score, and sponsorship.
 * The card also includes an edit button on the top right that triggers the
 * setOpenEditEligibilityDialog prop when clicked.
 *
 * @param {Object} props - Component props.
 * @param {function} props.setOpenEditEligibilityDialog - Function to set the open state of the EditEligibilityDialog component.
 * @param {Object} props.lead - The lead whose eligibility information is being displayed.
 */
function EligibilityCard(props) {
	const { setOpenEditEligibilityDialog, lead } = props;

	const englishTestStatus = ENGLISH_TEST_STATUS_CHOICES.find(
		(status) => status.value === lead.english_test_status
	);

	return (
		<Grid item xs={12} md={6} sx={{ display: "flex" }}>
			<Card className="card" sx={{ flexGrow: 1 }}>
				<CardContent sx={{ flex: 1 }}>
					<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
						<Typography component="h2" variant="h5" className="title">
							Eligibility
						</Typography>
						<IconButton aria-hidden="false" color="primary" onClick={() => setOpenEditEligibilityDialog(true)} aria-label="edit">
							<EditIcon />
						</IconButton>
					</Box>
					<Typography
						variant="subtitle1"
						className="cardInfo"
					>
						Grades: {lead.gpa} <em>({GRADE_SYSTEM_CHOICES[lead.grade_system]})</em>
					</Typography>
					{/* Render the label "English Test Status" with Chip for the value */}
					<Typography variant="subtitle1" className="cardInfo" gutterBottom>
						English Test Status:
						<Chip
							label={englishTestStatus?.label}
							color={englishTestStatus?.color || "default"}
							size="small"
							variant="outlined"
							sx={{ ml: 1 }}
						/>
					</Typography>
					<Typography
						variant="subtitle1"
						paragraph
						className="cardInfo"
					>
						TOEFL Score: {lead.toefl_score || 'N/A'}
					</Typography>
					<Typography
						variant="subtitle1"
						paragraph
						className="cardInfo"
					>
						IELTS Score: {lead.ielts_score || 'N/A'}
					</Typography>
					<Typography
						variant="subtitle1"
						paragraph
						className="cardInfo"
					>
						Has Sponsorship: {lead.has_sponsorship ? 'Yes' : 'No'}
					</Typography>
					{lead.has_sponsorship && (
						<Typography
							variant="subtitle1"
							paragraph
							className="cardInfo"
						>
							Sponsorship Details: {lead.sponsorship_details}
						</Typography>
					)}
				</CardContent>
				<CardMedia
					component="img"
					sx={{
						width: 160,
						display: { xs: "none", sm: "block" },
					}}
					image={lead.image}
					alt={lead.imageLabel}
				/>
			</Card>
		</Grid>
	);
}

EligibilityCard.propTypes = {
	lead: PropTypes.object.isRequired,
};

export default EligibilityCard;
