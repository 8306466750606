import {
    Alert,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import { CONSULTATION_CHOICES, LEAD_STATUS_CHOICES } from '../../const';
import { useDispatch, useSelector } from "react-redux";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EdvantageAutocomplete from "../common/EdvantageAutocomplete";
import React from 'react';
import TextField from '@mui/material/TextField';
import { updateLead } from "../../store/lead.slice";
import { useEdvantageContext } from "../../EdvantageProvider";

/**
 * A dialog to edit the basic information of a lead.
 *
 * @param {Object} props component props
 * @param {boolean} props.open whether the dialog is open or not
 * @param {Function} props.onClose callback function to close the dialog
 * @param {Object} props.lead the lead object to edit
 *
 * @returns {React.Component} the edit lead information dialog component
 */
const EditAboutLeadDialog = (props) => {
    const { open, onClose, lead } = props;

    const { employees, employeesLoading: loading, uuid } = useEdvantageContext();
    const employeeState = useSelector((state) => state.employee);

    const dispath = useDispatch();

    // Local state to manage form input
    const [formData, setFormData] = React.useState({
        status: lead.status || '',
        case_manager: lead.case_manager || '',
        consultation_type: lead.consultation_type || '',
    });

    const initialData = {
        status: lead.status || '',
        case_manager: lead.case_manager || '',
        consultation_type: lead.consultation_type || '',
    };

    const isFormUnchanged =
        formData.status === initialData.status &&
        formData.case_manager === initialData.case_manager &&
        formData.consultation_type === initialData.consultation_type;

    // Handle form change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle save action
    const handleSave = () => {
        dispath(updateLead({ id: lead.id, lead: formData, uuid })).then(
            (response) => {
                if (response.type === "lead/updateLead/fulfilled") {
                    onClose();
                }
            }
        );
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Edit Lead Information</DialogTitle>
            <DialogContent>
                {employeeState.error && (
                    <Alert
                        sx={{ width: "100%", mt: 2, mb: 2 }}
                        severity="error"
                    >
                        {employeeState.error}
                    </Alert>
                )}
                <TextField
                    select
                    name="status"
                    label="Status"
                    margin='dense'
                    value={formData.status}
                    onChange={handleChange}
                    fullWidth
                    required
                >
                    {LEAD_STATUS_CHOICES.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <EdvantageAutocomplete
                    name="case_manager"
                    label="Case Manager"
                    value={formData.case_manager}
                    margin="dense"
                    onChange={(e) =>
                        handleChange({ target: { name: "case_manager", value: e.target.value } })
                    }
                    options={employees}
                    getOptionLabel={(option) => option.full_name}
                    getOptionValue={(newValue) => newValue.id}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value
                    }
                    loading={loading}
                    required
                />
                <FormControl
                    fullWidth
                    margin="dense"
                >
                    <InputLabel id="consultation-select-label">
                        Consultation Type
                    </InputLabel>
                    <Select
                        labelId="consultation-select-label"
                        id="consultation-select"
                        value={formData.consultation_type}
                        label="Consultation Type"
                        name="consultation_type"
                        onChange={handleChange}
                    >
                        {Object.entries(CONSULTATION_CHOICES).map(
                            ([value, label]) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            )
                        )}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions sx={{ pr: 3, pb: 2 }}>
                <Button onClick={onClose} variant='contained' color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSave} variant='contained' color="primary" disabled={isFormUnchanged}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditAboutLeadDialog;
