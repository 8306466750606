import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { createEvent, updateEvent } from "../../store/event.slice";
import { useDispatch, useSelector } from "react-redux";

import { Autocomplete } from "@react-google-maps/api";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import EdvantageAutocomplete from "../common/EdvantageAutocomplete";
import GenericMultiSelect from "../common/GenericMultiSelect";
import dayjs from "dayjs";
import { isValidURL } from "../../utils/Utils";
import parsePhoneNumber from "libphonenumber-js";
import { useEdvantageContext } from "../../EdvantageProvider";

const EditorDialog = ({
	open,
	selectedEvent,
	action,
	students,
	scheduler,
	onClose,
}) => {
	const { employees } = useEdvantageContext();

	const dispatch = useDispatch();
	const eventState = useSelector((state) => state.event);
	const [searchResult, setSearchResult] = React.useState(null);

	const [event, setEvent] = useState(
		selectedEvent || {
			title: "",
			start: new Date(scheduler.state.start.value),
			end: new Date(scheduler.state.end.value),
			host: "",
			invitees: [],
			event_mode: "Phone",
			phone_number: "",
			video_call_link: "",
			address: "",
		}
	);

	const [errors, setErrors] = useState({
		title: "",
		host: "",
		invitees: "",
		phone_number: "",
		video_call_link: "",
		address: "",
	});

	const [isValid, setIsValid] = useState(true);
	const [hasChanged, setHasChanged] = useState(false);

	const onPlaceChanged = () => {
		if (searchResult) {
			const place = searchResult.getPlace();
			handleChange("address", place.formatted_address);
		}
	};

	const isEventChanged = (currentEvent, initialEvent) => {
		// Convert Date objects to strings for proper comparison
		const formatEvent = (event) => ({
			...event,
			start: event.start.toString(),
			end: event.end.toString(),
		});

		const formattedCurrentEvent = formatEvent(currentEvent);
		const formattedInitialEvent = formatEvent(initialEvent);

		// Compare the fields of both events
		return (
			JSON.stringify(formattedCurrentEvent) !==
			JSON.stringify(formattedInitialEvent)
		);
	};

	const checkIfEventChanged = useMemo(
		() => action === "edit" && isEventChanged(event, selectedEvent),
		[event, selectedEvent, action]
	);

	useEffect(() => {
		setHasChanged(checkIfEventChanged);
	}, [checkIfEventChanged]);

	const validateField = (field, value) => {
		const newErrors = {};

		switch (field) {
			case "title":
				if (value.length < 3) {
					newErrors.title = "Enter a valid title";
				} else {
					newErrors.title = "";
				}
				break;

			case "host":
				if (!value) {
					newErrors.host = "Enter a valid host";
				} else {
					newErrors.host = "";
				}
				break;

			case "invitees":
				if (!value.length) {
					newErrors.invitees = "Enter at least one invitee";
				} else {
					newErrors.invitees = "";
				}
				break;

			case "phone_number": {
				const phoneNumber = parsePhoneNumber(value);
				if (
					event.event_mode === "Phone" &&
					(!phoneNumber || !phoneNumber.isValid())
				) {
					newErrors.phone_number = "Enter a valid phone number";
				} else {
					newErrors.phone_number = "";
				}
				break;
			}

			case "video_call_link":
				if (event.event_mode === "VC" && !isValidURL(value)) {
					newErrors.video_call_link = "Enter a valid video call link";
				} else {
					newErrors.video_call_link = "";
				}
				break;

			case "address":
				if (event.event_mode === "F2F" && !value) {
					newErrors.address = "Enter a valid address";
				} else {
					newErrors.address = "";
				}
				break;

			default:
				break;
		}

		return newErrors;
	};

	const handleChange = (field, value) => {
		setEvent((prev) => ({
			...prev,
			[field]: value,
		}));

		const fieldErrors = validateField(field, value);
		const newErrors = { ...errors, ...fieldErrors };

		setErrors(newErrors);
		setIsValid(Object.values(newErrors).every((error) => !error));
	};

	const handleConfirm = () => {
		const validationErrors = Object.keys(event).reduce((acc, field) => {
			const fieldErrors = validateField(field, event[field]);
			return { ...acc, ...fieldErrors }; // Accumulate errors for all fields
		}, {});

		setErrors(validationErrors);

		const formIsValid = Object.values(validationErrors).every(
			(error) => !error
		);
		setIsValid(formIsValid);

		if (formIsValid) {
			if (action === "edit") {
				// Extract the changed fields only
				const changedFields = Object.keys(event).reduce((acc, key) => {
					if (event[key] !== selectedEvent[key]) {
						acc[key] = event[key];
					}
					return acc;
				}, {});

				dispatch(
					updateEvent({ id: selectedEvent.id, event: changedFields })
				).then((response) => {
					if (response.type === "event/updateEvent/fulfilled") {
						onClose();
					}
				});
			} else if (action === "create") {
				dispatch(createEvent(event)).then((response) => {
					if (response.type === "event/createEvent/fulfilled") {
						onClose();
					}
				});
			}
		}
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			sx={{ minWidth: { xs: "100%", sm: "500px", md: "800px" } }}
		>
			<DialogTitle>
				{action === "edit" ? "Edit Event" : "Create Event"}
			</DialogTitle>
			<DialogContent>
				{eventState.error && (
					<Alert
						sx={{ width: "100%", mt: 2, mb: 2 }}
						severity="error"
					>
						{eventState.error}
					</Alert>
				)}
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							label="Title"
							value={event.title}
							onChange={(e) =>
								handleChange("title", e.target.value)
							}
							fullWidth
							margin="dense"
							placeholder="New Meeting"
							required
							error={!!errors.title}
							helperText={errors.title}
						/>
					</Grid>
					<Grid item xs={6}>
						<DateTimePicker
							label="Start"
							value={dayjs(event.start)}
							onChange={(newValue) =>
								handleChange("start", newValue)
							}
							sx={{ width: "100%" }}
						/>
					</Grid>
					<Grid item xs={6}>
						<DateTimePicker
							label="End"
							value={dayjs(event.end)}
							minDateTime={dayjs(event.start)}
							onChange={(newValue) =>
								handleChange("end", newValue)
							}
							sx={{ width: "100%" }}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<EdvantageAutocomplete
							name="host"
							label="Host"
							value={event.host}
							margin="dense"
							onChange={(e) =>
								handleChange("host", e.target.value)
							}
							error={!!errors.host}
							helperText={errors.host}
							options={employees}
							getOptionLabel={(option) => option.full_name}
							getOptionValue={(newValue) => newValue.id}
							isOptionEqualToValue={(option, value) =>
								option.id === value
							}
							required
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<GenericMultiSelect
							name="invitees"
							label="Invitees"
							limitTags={1}
							value={event.invitees}
							onChange={(e) =>
								handleChange("invitees", e.target.value)
							}
							error={!!errors.invitees}
							helperText={errors.invitees}
							options={students}
							getOptionLabel={(option) => option.full_name}
							optionValueKey="id"
							margin="dense"
							required
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6}>
						<FormControl fullWidth margin="dense">
							<InputLabel id="appointment-select-label">
								Mode
							</InputLabel>
							<Select
								labelId="appointment-select-label"
								id="appointment-select"
								value={event.event_mode}
								label="Mode"
								onChange={(e) =>
									handleChange("event_mode", e.target.value)
								}
							>
								<MenuItem key="phone" value="Phone">
									Phone
								</MenuItem>
								<MenuItem key="vc" value="VC">
									VC
								</MenuItem>
								<MenuItem key="f2f" value="F2F">
									F2F
								</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={6}>
						{event.event_mode === "Phone" && (
							<TextField
								label="Phone Number"
								value={event.phone_number}
								onChange={(e) =>
									handleChange("phone_number", e.target.value)
								}
								fullWidth
								margin="dense"
								placeholder="+123456789"
								error={!!errors.phone_number}
								helperText={errors.phone_number}
							/>
						)}
						{event.event_mode === "VC" && (
							<TextField
								label="Video Call Link"
								value={event.video_call_link}
								onChange={(e) =>
									handleChange(
										"video_call_link",
										e.target.value
									)
								}
								fullWidth
								margin="dense"
								placeholder="https://zoom.us/j/1234567890"
							/>
						)}
						{event.event_mode === "F2F" && (
							// <TextField
							// 	label="Address"
							// 	value={event.address}
							// 	onChange={(e) =>
							// 		handleChange("address", e.target.value)
							// 	}
							// 	fullWidth
							// 	margin="dense"
							// 	placeholder="1234 Elm Street"
							// />
							<Autocomplete
								onPlaceChanged={onPlaceChanged}
								onLoad={(autocomplete) => setSearchResult(autocomplete)}
							>
								<TextField
									fullWidth
									label="Address"
									name="name"
									value={event.address}
									onChange={(e) =>
										handleChange("address", e.target.value)
									}
									margin="dense"
									required
									error={Boolean(errors.name)}
									helperText={errors.name}
								/>
							</Autocomplete>
						)}
					</Grid>
				</Grid>
			</DialogContent>
			<Box sx={{ padding: 2 }}>
				<DialogActions>
					<Button onClick={onClose} variant="outlined">
						Cancel
					</Button>
					<Button
						onClick={handleConfirm}
						variant="contained"
						color="primary"
						disabled={
							!isValid || (action === "edit" && !hasChanged)
						}
					>
						{action === "edit" ? "Save" : "Create"}
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default EditorDialog;
