import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";

import { fetchUniversities } from "../store/university.slice";

function useUniversity() {
	const dispatch = useDispatch();
	const lockRef = useRef(false);

	const universities = useSelector((state) => state.university.universities);
	const universityStatus = useSelector((state) => state.university.status);

	useEffect(() => {
		async function onLoad() {
			if (universityStatus === "idle" && !lockRef.current) {
				lockRef.current = true;
				dispatch(fetchUniversities()).finally(() => {
					// Release lock after completion
					lockRef.current = false;
				});
			}
		}

		onLoad();
	}, [universityStatus, dispatch]);

	return {
		universities,
		universityStatus,
	};
}

export default useUniversity;
