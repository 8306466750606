import * as React from "react";

import { clearMessage, fetchSchoolApplications } from "../../store/schoolApplication.slice";

import AddIcon from "@mui/icons-material/Add";
import AddSchoolApplicationDialog from "../Application/AddSchoolApplicationDialog";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";
import SchoolApplicationTable from "../Application/SchoolApplicationTable";
import { Skeleton } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { fetchLeads } from "../../store/lead.slice";
import { fetchSchools } from "../../store/school.slice";
import { useDispatch } from "react-redux";
import { useEdvantageContext } from "../../EdvantageProvider";
import useEdvantageFetch from "../../hooks/useEdvantageFetch";

function SchoolApplication(props) {
    const dispatch = useDispatch();

    const { data: applications, state: applicationState } = useEdvantageFetch('schoolApplication', 'applications', fetchSchoolApplications);
    const { data: schools, state: schoolState } = useEdvantageFetch('school', 'schools', fetchSchools);
    const { data: leads, state: leadState } = useEdvantageFetch('lead', 'leads', fetchLeads);

    const { artifactLoading, employeeById, programById, uuid } = useEdvantageContext();

    const [openDialog, setOpenDialog] = React.useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        dispatch(clearMessage());
    };

    const { schoolById, courseById } = React.useMemo(() => {
        const schoolMap = new Map();
        const courseMap = new Map();

        schools.forEach((school) => {
            schoolMap.set(school.id, school);
            school.courses.forEach((course) => {
                courseMap.set(course.id, course);
            });
        });

        return { schoolById: schoolMap, courseById: courseMap };
    }, [schools]);

    const renderSkeletonTable = () => {
        return (
            <Box sx={{ width: "100%", mt: 2 }}>
                {/* Header Row */}
                <Skeleton variant="rectangular" width="100%" height={40} sx={{ mb: 1 }} />
                {/* Main Content Area */}
                <Skeleton variant="rectangular" width="100%" height={300} sx={{ mb: 1 }} />
                {/* Footer Row */}
                <Skeleton variant="rectangular" width="100%" height={40} />
            </Box>
        );
    };

    const renderTableOrMessage = () => {
        if (applications.length > 0 && leads.length > 0 && schools.length > 0) {
            return (
                <SchoolApplicationTable
                    applicationState={applicationState}
                    courseById={courseById}
                    employeeById={employeeById}
                    leads={leads}
                    programById={programById}
                    schoolById={schoolById}
                    setUrlParams={props.setUrlParams}
                    uuid={uuid}
                />
            );
        }
        if (!applicationState.loading && !schoolState.loading && !leadState.loading) {
            return <Alert severity="warning" sx={{ width: "100%", mt: 2, mb: 2 }}>No Application Found! Add a new application</Alert>;
        }
        return renderSkeletonTable();
    };

    return (
        <Box sx={{ width: "100%", overflowX: "auto" }}>
            {applicationState.message && (
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={true}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                >
                    <Alert sx={{ width: "100%", mb: 2 }} severity="success">
                        {applicationState.message}
                    </Alert>
                </Snackbar>
            )}
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                {applicationState.error &&
                    applicationState.action === "updateSchoolApplication" && (
                        <Alert
                            sx={{ width: "100%", mt: 2, mb: 2 }}
                            severity="error"
                        >
                            {applicationState.error}
                        </Alert>
                    )}
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "end",
                        mb: 2,
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleOpenDialog}
                    >
                        Add New Application
                    </Button>
                </Box>
                {renderTableOrMessage()}
                {!artifactLoading && schools.length > 0 && (
                    <AddSchoolApplicationDialog
                        open={openDialog}
                        setOpenDialog={setOpenDialog}
                        schoolApplicationState={applicationState}
                        schools={schools}
                        schoolApplications={applications}
                        uuid={uuid}
                    />
                )}
            </Box>
        </Box>
    );
}

export default SchoolApplication;
