import { Autocomplete, Checkbox, TextField } from "@mui/material";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import PropTypes from "prop-types";
import React from "react";

function GenericMultiSelect({
	name,
	label,
	value,
	limitTags,
	onChange,
	error,
	helperText,
	options,
	getOptionLabel,
	optionValueKey,
	margin,
	required,
	index,
}) {
	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	const checkedIcon = <CheckBoxIcon fontSize="small" />;

	return (
		<Autocomplete
			id={`${name}-select`}
			sx={{ width: "100%" }}
			multiple
			limitTags={limitTags || -1}
			options={options}
			autoHighlight
			getOptionLabel={getOptionLabel}
			value={value
				.map((id) =>
					options.find((option) => option[optionValueKey] === id)
				)
				.filter(Boolean)}
			onChange={(event, newValue) => {
				onChange({
					target: {
						name,
						value: newValue.map((option) => option[optionValueKey]),
					},
				}, index);
			}}
			renderOption={(props, option, { selected }) => (
				<li {...props} key={option.id}>
					<Checkbox
						icon={icon}
						checkedIcon={checkedIcon}
						style={{ marginRight: 8 }}
						checked={selected}
					/>
					{getOptionLabel(option)}
				</li>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					margin={margin || "none"}
					label={label || ""}
					error={error || false}
					helperText={helperText || ""}
					required={required || false}
					inputProps={{
						...params.inputProps,
						autoComplete: "new-password", // disable autocomplete and autofill
					}}
				/>
			)}
		/>
	);
}

GenericMultiSelect.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	value: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
			PropTypes.object,
		])
	).isRequired,
	limitTags: PropTypes.number,
	onChange: PropTypes.func.isRequired,
	error: PropTypes.bool,
	helperText: PropTypes.string,
	options: PropTypes.array.isRequired,
	getOptionLabel: PropTypes.func.isRequired,
	optionValueKey: PropTypes.string.isRequired,
	margin: PropTypes.string,
	required: PropTypes.bool,
	index: PropTypes.number,
};

export default GenericMultiSelect;
