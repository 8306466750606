import * as React from "react";

import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { APPLICATION_STATUS_CHOICES } from "../../const";
import EdvantageAutocomplete from "../common/EdvantageAutocomplete";
import PropTypes from "prop-types";
import { addSchoolApplication } from "../../store/schoolApplication.slice";
import { generateMenuItems } from "../../utils/Utils";
import { useEdvantageContext } from "../../EdvantageProvider";

function AddSchoolApplicationDialog(props) {
    const dispatch = useDispatch();

    const leadState = useSelector((state) => state.lead);
    const { programById } = useEdvantageContext();

    const [form, setForm] = React.useState({
        lead: props.leadId || "",
        school: "",
        course: "",
        start_date: "",
        end_date: "",
        status: "",
        file: null,
    });
    const [filteredSchools, setFilteredSchools] = React.useState(
        props.schools
    );

    const getFilteredSchools = (leadId, schools) => {
        const activeSchoolIds = props.schoolApplications
            .filter(
                (app) =>
                    app.lead_id === leadId &&
                    ["Submitted", "Accepted", "Pending", "Conditional Offer", "Unconditional Offer"].includes(
                        app.status
                    )
            )
            .map((app) => app.school_id);

        // Filter out schools with active applications
        const filteredSchools = schools.filter(
            (school) => !activeSchoolIds.includes(school.id)
        );
        return filteredSchools;
    };

    React.useEffect(() => {
        if (props.leadId) {
            // Filter out universities with active applications
            setFilteredSchools(getFilteredSchools(props.leadId, props.schools));
        }
    }, [props.leadId, props.schools]);

    const [formErrors, setFormErrors] = React.useState({});
    const [isFormValid, setIsFormValid] = React.useState(false);

    const [availableCourses, setAvailableCourses] = React.useState([]);

    const ALLOWED_FILE_TYPES = ["application/pdf", "image/jpeg", "image/png"];
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

    const today = new Date().toISOString().split("T")[0];
    // Determine the minimum date for end_date
    const minEndDate = form.start_date ? form.start_date : today;

    React.useEffect(() => {
        if (form.school) {
            setAvailableCourses(filteredSchools.find((school) => school.id === form.school).courses);
        }
    }, [filteredSchools, form.school]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        let errors = {};
        for (const field of Object.keys(form)) {
            const fieldErrors = validateForm(field, form[field]);
            errors = { ...errors, ...fieldErrors };
        }

        let isValid = true;
        for (let error of Object.values(errors)) {
            if (error) {
                isValid = false;
                break;
            }
        }

        setFormErrors(errors);
        setIsFormValid(isValid);

        if (isValid) {
            // Create FormData object
            const formData = new FormData();
            formData.append("lead", form.lead);
            formData.append("school", form.school);
            formData.append("school_course", form.course);
            formData.append("start_date", form.start_date);
            formData.append("end_date", form.end_date);
            formData.append("status", form.status);
            formData.append("uuid", props.uuid);

            // Append file if it exists
            if (form.file) {
                formData.append("offer_letter", form.file);
            }

            // Pass FormData to addApplication action
            dispatch(addSchoolApplication(formData)).then((response) => {
                if (response.type === "school_application/addSchoolApplication/fulfilled") {
                    handleCloseDialog();
                }
            });
        }
    };

    const validateForm = (fieldName, value) => {
        let errors = {};

        switch (fieldName) {
            case "lead":
                errors.lead = value ? "" : "Lead is required";
                break;
            case "start_date":
                errors.start_date = value ? "" : "Start date is required";
                break;
            case "school":
                errors.school = value ? "" : "School is required";
                break;
            case "course":
                errors.course = value ? "" : "Course is required";
                break;
            case "status":
                errors.status = value.trim() ? "" : "Status is required";
                break;
            case "file":
                if (["Conditional Offer", "Unconditional Offer"].includes(form.status) && !value) {
                    errors.file = "Offer letter is required for this status";
                } else {
                    errors.file = "";
                }
                break;
            default:
                break;
        }

        return errors;
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const newErrors = { ...formErrors };

        if (file) {
            if (file.size > MAX_FILE_SIZE) {
                newErrors.file = "File size exceeds the maximum limit.";
            } else if (!ALLOWED_FILE_TYPES.includes(file.type)) {
                newErrors.file = "Invalid file type. Please upload a PDF or image.";
            } else {
                delete newErrors.file; // Clear any previous file errors if the file is valid
            }

            // Update form state if no errors exist
            if (!newErrors.file) {
                setForm((prevForm) => ({ ...prevForm, file }));
            }
        } else {
            setForm((prevForm) => ({ ...prevForm, file: null }));
            newErrors.file = "Offer letter is required for this status";
        }

        setFormErrors(newErrors);

        let isValid = true;
        for (let error of Object.values(newErrors)) {
            if (error) {
                isValid = false;
                break;
            }
        }
        setIsFormValid(isValid);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "status" && !["Conditional Offer", "Unconditional Offer"].includes(value)) {
            setForm({ ...form, [name]: value, file: null });
        } else {
            setForm({ ...form, [name]: value });
        }
        const fieldErrors = validateForm(name, value);
        const newFormErrors = { ...formErrors, ...fieldErrors };

        let isValid = true;
        for (let error of Object.values(newFormErrors)) {
            if (error) {
                isValid = false;
                break;
            }
        }
        setIsFormValid(isValid);
        setFormErrors(newFormErrors);

        // If the lead changes, filter schools based on active applications
        if (name === "lead") {
            // Filter out schools with active applications
            setFilteredSchools(getFilteredSchools(value, props.schools));

            // Update form with filtered schools
            setForm({ ...form, lead: value, school: "", course: "" });
        }
    };

    const handleCloseDialog = (evt, reason) => {
        if (reason === "backdropClick") {
            return;
        }

        props.setOpenDialog(false);
        // Reset form state when dialog is closed
        setForm({
            lead: props.leadId || "",
            school: "",
            course: "",
            start_date: "",
            end_date: "",
            status: "",
            file: null,
        });
        setFormErrors({});
        setIsFormValid(false);
    };

    return (
        <Dialog
            open={props.open}
            onClose={handleCloseDialog}
            aria-labelledby="form-dialog-title"
            sx={{
                "& .MuiDialog-paper": {
                    minWidth: {
                        xs: "100%", // full width on small screens
                        sm: "600px", // 600px on medium screens and up
                    },
                },
            }}
        >
            <DialogTitle id="form-dialog-title">Add School Application</DialogTitle>
            <Divider />
            <DialogContent>
                <DialogContentText>
                    Please fill out the details below to add a new application.
                </DialogContentText>
                <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 3 }}
                >
                    {props.schoolApplicationState.error &&
                        props.schoolApplicationState.action === "addSchoolApplication" && (
                            <Alert
                                sx={{ width: "100%", mt: 2, mb: 2 }}
                                severity="error"
                            >
                                {props.schoolApplicationState.error}
                            </Alert>
                        )}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <EdvantageAutocomplete
                                name="lead"
                                label="Lead"
                                value={form.lead}
                                onChange={handleChange}
                                error={Boolean(formErrors.lead)}
                                helperText={formErrors.lead}
                                options={leadState.leads}
                                getOptionLabel={(option) =>
                                    option.student.full_name
                                }
                                getOptionValue={(newValue) => newValue.id}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <EdvantageAutocomplete
                                name="school"
                                label="School"
                                value={form.school}
                                onChange={handleChange}
                                error={Boolean(formErrors.school)}
                                helperText={formErrors.school}
                                options={filteredSchools}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(newValue) => newValue.id}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <EdvantageAutocomplete
                                name="course"
                                label="Course"
                                value={form.course}
                                onChange={handleChange}
                                error={Boolean(formErrors.course)}
                                helperText={formErrors.course}
                                options={availableCourses}
                                getOptionLabel={(option) =>
                                    programById.get(option.program).name
                                }
                                getOptionValue={(newValue) => newValue.id}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value
                                }
                                noOptionsText={
                                    availableCourses.length === 0
                                        ? "No courses found for the selected intake and school."
                                        : "No options available"
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="start_date"
                                label="Start Date"
                                type="date"
                                value={form.start_date}
                                onChange={handleChange}
                                error={Boolean(formErrors.start_date)}
                                helperText={formErrors.start_date}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    min: today,
                                }}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="end_date"
                                label="End Date"
                                type="date"
                                value={form.end_date}
                                onChange={handleChange}
                                error={Boolean(formErrors.end_date)}
                                helperText={formErrors.end_date}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    min: minEndDate,
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                select
                                fullWidth
                                label="Status"
                                name="status"
                                value={form.status}
                                onChange={handleChange}
                                error={Boolean(formErrors.status)}
                                helperText={formErrors.status}
                                required
                            >
                                {generateMenuItems(APPLICATION_STATUS_CHOICES)}
                            </TextField>
                        </Grid>
                        {["Conditional Offer", "Unconditional Offer"].includes(form.status) && (
                            <Grid item xs={12}>
                                <Button variant="outlined" component="label" fullWidth>
                                    Upload Offer Letter
                                    <input type="file" hidden onChange={handleFileChange} />
                                </Button>
                                {form.file && <p>{form.file.name}</p>}
                                {formErrors.file && <p style={{ fontSize: "0.75rem", color: '#d32f2f' }}>{formErrors.file}</p>}
                            </Grid>
                        )}
                    </Grid>
                    <DialogActions sx={{ justifyContent: "flex-end" }}>
                        <Button
                            type="button"
                            variant="contained"
                            color="secondary"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleCloseDialog}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={!isFormValid}
                        >
                            Add New Application
                        </Button>
                    </DialogActions>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

AddSchoolApplicationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpenDialog: PropTypes.func.isRequired,
    schoolApplicationState: PropTypes.object.isRequired,
    schools: PropTypes.arrayOf(PropTypes.object).isRequired,
    schoolApplications: PropTypes.arrayOf(PropTypes.object).isRequired,
    uuid: PropTypes.string.isRequired,
};

export default AddSchoolApplicationDialog;
