import * as React from "react";

import { Box, Chip, IconButton, Typography } from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import DeleteConfirmationDialog from "../DeleteConfirmationDialog";
import EditServiceDialog from "./EditServiceDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { deleteService } from "../../store/service.slice";
import { useDispatch } from "react-redux";
import Avatar from "@mui/material/Avatar";

const columns = (handleActionClick) => [
	{
		field: "id",
		headerName: "ID",
		flex: 1,
	},
	{
		field: "name",
		headerName: "Service",
		description: "The name of service.",
		sortable: true,
		flex: 1,
	},
	{
		field: "added_by",
		headerName: "Added By",
		flex: 1,
		renderCell: (params) => {
			return (
				<Chip
					avatar={
						<Avatar src="/static/images/avatars/avatar_12.jpg" />
					}
					label={params.value}
					variant="outlined"
					size="small"
					color="primary"
				/>
			);
		},
	},
	{
		field: "updated_by",
		headerName: "Updated By",
		minWidth: 200,
		flex: 1,
		renderCell: (params) => {
			return (
				<Chip
					avatar={
						<Avatar src="/static/images/avatars/avatar_12.jpg" />
					}
					label={params.value}
					variant="outlined"
					size="small"
					color="primary"
				/>
			);
		},
	},
	{
		field: "updated_at",
		headerName: "Last Update Time",
		flex: 1,
		renderCell: (params) => {
			const formattedDate = new Date(params.value).toLocaleString(
				"en-US",
				{
					year: "numeric",
					month: "2-digit",
					day: "2-digit",
					hour: "2-digit",
					minute: "2-digit",
				}
			);
			return (
				<Typography
					sx={{
						display: "flex",
						height: "100%",
						justifyContent: "flex-start",
						alignItems: "center",
					}}
				>
					{formattedDate}
				</Typography>
			);
		},
	},
	{
		field: "actions",
		headerName: "",
		flex: 1,
		renderCell: (params) => (
			<div
				style={{
					display: "flex",
					height: "100%",
					justifyContent: "center",
					alignItems: "center",
					gap: "8px",
				}}
			>
				<IconButton
					aria-label="edit"
					onClick={() =>
						handleActionClick(params.id, params.row.name, "edit")
					}
				>
					<EditIcon />
				</IconButton>
				<IconButton
					aria-label="delete"
					onClick={() =>
						handleActionClick(params.id, params.row.name, "delete")
					}
				>
					<DeleteIcon />
				</IconButton>
			</div>
		),
	},
];

export default function ServiceTable(props) {
	const dispatch = useDispatch();

	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
	const [openEditDialog, setOpenEditDialog] = React.useState(false);
	const [serviceId, setServiceId] = React.useState(null);
	const [serviceName, setServiceName] = React.useState("");

	const contentText = (
		<>
			Are you sure you want to delete the service{" "}
			<Typography component="span" sx={{ fontWeight: "bold" }}>
				{serviceName}
			</Typography>
			?
		</>
	);

	const handleActionClick = (id, name, action) => {
		setServiceId(id);
		setServiceName(name);
		if (action === "edit") {
			setOpenEditDialog(true);
		} else if (action === "delete") {
			setOpenDeleteDialog(true);
		}
	};

	const handleClose = (action) => {
		if (action === "edit") {
			setOpenEditDialog(false);
		} else if (action === "delete") {
			setOpenDeleteDialog(false);
		}
		setServiceId(null);
		setServiceName("");
	};

	const handleConfirmDelete = () => {
		// Logic to delete the program
		dispatch(deleteService(serviceId)).then((response) => {
			if (response.type === "service/deleteService/fulfilled") {
				handleClose();
			}
		});
	};

	return (
		<Box
			sx={{
				minHeight: 400,
				maxHeight: 700,
				width: "100%",
				backgroundColor: "#FFFFFF",
				borderRadius: "10px",
				overflow: "hidden",
			}}
		>
			<DataGrid
				rows={props.serviceState.services}
				columns={columns(handleActionClick)}
				initialState={{
					columns: {
						columnVisibilityModel: {
							id: false,
						},
					},
					sorting: {
						sortModel: [
							{
								field: "updated_at", // Sort by updated_at field
								sort: "desc", // Sort in descending order
							},
						],
					},
				}}
				pageSizeOptions={[5, 10, 50, 100]}
				checkboxSelection={false}
				disableRowSelectionOnClick
			/>
			{serviceName && serviceId && (
				<EditServiceDialog
					open={openEditDialog}
					handleClose={() => handleClose("edit")}
					service={{ id: serviceId, name: serviceName }}
					serviceState={props.serviceState}
				/>
			)}

			<DeleteConfirmationDialog
				open={openDeleteDialog}
				handleClose={() => handleClose("delete")}
				onConfirm={handleConfirmDelete}
				contentText={contentText}
				state={props.serviceState}
			/>
		</Box>
	);
}
