import './index.css';
import 'bootstrap/dist/css/bootstrap.css';

import { store } from './store';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { EdvantageProvider } from './EdvantageProvider';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { pdfjs } from 'react-pdf';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;


// Setup passive event listeners globally
(function() {
    let supportsPassive = false;
    try {
        // Check if the browser supports passive event listeners
        let opts = Object.defineProperty({}, 'passive', {
            get: function() {
                supportsPassive = true;
                return true; // Ensures that this method returns a value
            }
        });
        window.addEventListener("test", null, opts);
    } catch (e) {
        console.error("Error checking passive support:", e);
    }

    const originalAddEventListener = EventTarget.prototype.addEventListener;
    EventTarget.prototype.addEventListener = function(type, fn, options) {
      const passiveOption = supportsPassive && (type === 'touchstart' || type === 'scroll' || type === 'wheel');
      const newOptions = passiveOption ? { passive: true, capture: false } : options;

      if (type === 'scroll' && window.disableScroll) {
        return; // Don't trigger the scroll event
      } else if (type === 'wheel' && window.disableWheel) {
        return; // Don't trigger the wheel event
      }

      originalAddEventListener.call(this, type, fn, newOptions || false);
  };
})();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <BrowserRouter>
          <EdvantageProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <App />
            </LocalizationProvider>
          </EdvantageProvider>
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
