import { applicationReducer, clearApplicationState } from './application.slice';
import { authReducer, logout } from './auth.slice';
import { cityReducer, clearCityState } from './city.slice';
import { clearCommentState, commentReducer } from './comment.slice';
import { clearDraftLeadState, draftLeadReducer } from './draftLead.slice';
import { clearEventState, eventReducer } from './event.slice';
import { clearLeadState, leadReducer } from './lead.slice';
import { clearMajorState, majorReducer } from './major.slice';
import { clearNotificationState, notificationReducer } from './notification.slice';
import { clearProgramState, programReducer } from './program.slice';
import { clearSchoolApplicationState, schoolApplicationReducer } from './schoolApplication.slice';
import { clearSchoolState, schoolReducer } from './school.slice';
import { clearServiceState, serviceReducer } from './service.slice';
import { clearStudentState, studentReducer } from './student.slice';
import { clearUniversityState, universityReducer } from './university.slice';
import { clearUserState, userReducer } from './user.slice';

import { configureStore } from '@reduxjs/toolkit';
import { countryReducer } from './country.slice';
import { currencyReducer } from './currency.slice';
import customizationReducer from './customizationReducer';
import { documentReducer } from './document.slice';
import { employeeReducer } from './employee.slice';
import { groupReducer } from './group.slice';
import { intakeReducer } from './intake.slice';

export * from './auth.slice';

const logoutMiddleware = ({ dispatch }) => (next) => (action) => {
    if (action.type === logout.pending.type) {
        dispatch(clearApplicationState());
        dispatch(clearCityState());
        dispatch(clearCommentState());
        dispatch(clearDraftLeadState());
        dispatch(clearEventState());
        dispatch(clearLeadState());
        dispatch(clearMajorState());
        dispatch(clearNotificationState());
        dispatch(clearProgramState());
        dispatch(clearSchoolApplicationState());
        dispatch(clearSchoolState());
        dispatch(clearServiceState());
        dispatch(clearStudentState());
        dispatch(clearUniversityState());
        dispatch(clearUserState());
    }
    return next(action);
};

export const store = configureStore({
    reducer: {
        application: applicationReducer,
        auth: authReducer,
        city: cityReducer,
        comment: commentReducer,
        country: countryReducer,
        currency: currencyReducer,
        customization: customizationReducer,
        draftLead: draftLeadReducer,
        document: documentReducer,
        employee: employeeReducer,
        event: eventReducer,
        group: groupReducer,
        intake: intakeReducer,
        lead: leadReducer,
        major: majorReducer,
        notification: notificationReducer,
        program: programReducer,
        school: schoolReducer,
        schoolApplication: schoolApplicationReducer,
        service: serviceReducer,
        student: studentReducer,
        university: universityReducer,
        user: userReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(logoutMiddleware),
});
